import { Avatar, Button, Col, List, Row, Skeleton, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import lodash from "lodash";

import { GeneralContext } from "../../../../../../../../contexts/general/Context";
import {
  authUserAtom,
  groupAtom,
  showModalsAtom,
  teamMembersAtom,
  unassignedListAtom,
} from "../../../../../../../../contexts/generalStore/store";
import { PERMISSIONS } from "../../../../../../../../util/constants/permission";
import { getTeamMembers } from "../../../../../../../../controller/employee/getTeamMembers";
import { getUnassignedList } from "../../../../../../../../controller/admin/getUnassignedList";
import AssignMember from "./components/modal/AssignMember";
import ApplyOnBehalfModal from "../../../teams/components/modals/applyOnBehalfModal/ApplyOnBehalfModal";
import ShareBalance from "../../../../../../../modals/shareBalance/ShareBalance";
import { ShareAltOutlined, UnorderedListOutlined } from "@ant-design/icons";
import LinksDrawer from "../../../../../snapLinks/drawer/LinksDrawer";
import CmlIcon from "../../../../../common/icon/CmlIcon";
import { NoResultFound } from "../../../../../common/noResultFound/NoResultFound";

const UserList = () => {
  const { currentTheme } = useContext(GeneralContext);
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [linksListData, setLinksListData] = useState([]);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [isAllDataFetched, setIsAllRequestFetched] = useState(true);
  const [paginationToken, setPaginationToken] = useState("");
  const [openShareBalanceModal, setOpenShareBalanceModal] = useState(false);
  const [openLinksDrawer, setOpenLinksDrawer] = useState(false);

  const [members, setMembers] = useAtom(unassignedListAtom);
  const [teamMembers, setTeamMembers] = useAtom(teamMembersAtom);

  const group = useAtomValue(groupAtom);
  const authUser = useAtomValue(authUserAtom);
  const [showApplyModal, setShowApplyModal] = useAtom(showModalsAtom);

  const callApi = async (token = "") => {
    try {
      let response;
      if (
        PERMISSIONS.apis.teamMembersAllowedGroups.some((val) =>
          group.includes(val)
        )
      ) {
        response = await getTeamMembers(authUser);
      }
      if (
        PERMISSIONS.apis.allMembersAllowedGroups.some((val) =>
          group.includes(val)
        )
      ) {
        //for admins only
        response = await getUnassignedList(authUser, token);
      }
      if (response?.data)
        setPaginationToken(
          response.data?.ContinuationToken
            ? response.data.ContinuationToken
            : null
        );
      if (response.data?.ContinuationToken) {
        setIsAllRequestFetched(false);
      } else {
        setIsAllRequestFetched(true);
      }
      return response?.data?.Items;
    } catch (error) {
      return [];
    }
  };

  const getMembers = async () => {
    const response = await callApi();
    if (
      PERMISSIONS.apis.teamMembersAllowedGroups.some((val) =>
        group.includes(val)
      )
    ) {
      setTeamMembers([...response]);
    }
    if (
      PERMISSIONS.apis.allMembersAllowedGroups.some((val) =>
        group.includes(val)
      )
    ) {
      setMembers([...response]);
    }
    setList([...response]);
    setInitLoading(false);
  };

  useEffect(() => {
    getMembers();
  }, []);

  useEffect(() => {
    if (
      PERMISSIONS.apis.teamMembersAllowedGroups.some((val) =>
        group.includes(val)
      )
    ) {
      setList([...teamMembers]);
    }
    if (
      PERMISSIONS.apis.allMembersAllowedGroups.some((val) =>
        group.includes(val)
      )
    ) {
      setList([...members]);
    }
  }, [members, teamMembers]);

  const onLoadMore = async () => {
    setLoading(true);
    setList(
      members.concat(
        [...new Array(3)].map(() => ({
          loading: true,
        }))
      )
    );
    const res = await callApi(paginationToken);
    let newData;
    if (
      PERMISSIONS.apis.teamMembersAllowedGroups.some((val) =>
        group.includes(val)
      )
    ) {
      newData = teamMembers.concat(res);
      setTeamMembers(newData);
    }
    if (
      PERMISSIONS.apis.allMembersAllowedGroups.some((val) =>
        group.includes(val)
      )
    ) {
      newData = members.concat(res);
      setMembers(newData);
    }
    setMembers(newData);
    setList(newData);
    setLoading(false);
    window.dispatchEvent(new Event("resize"));
  };

  const loadMore =
    !initLoading && !loading && !isAllDataFetched ? (
      <div className="mt-10 mb-10">
        <Button
          onClick={onLoadMore}
          type="primary"
          icon={<CmlIcon iconName="unfold_more" />}
        >
          Load more ...
        </Button>
      </div>
    ) : null;

  const handleShareBalance = async () => {
    setOpenShareBalanceModal(true);
  };

  const openAssignModal = (item) => {
    setShowAssignModal(true);
    setSelectedUser(item);
  };

  const openApplyModal = (item) => {
    setShowApplyModal({ ...showApplyModal, showApplyOnBehalfModal: true });
    setSelectedUser(item);
  };

  const onLinksDrawerClose = () => {
    setOpenLinksDrawer(false);
  };

  const onLinksDrawerOpen = async () => {
    setOpenLinksDrawer(true);
  };

  const handleDisabled = (item) => {
    if (
      PERMISSIONS.apis.applyOnBehalfMembersAllowedGroups.some((value) =>
        group.includes(value)
      ) &&
      item?.hasRefreshToken
    ) {
      return false;
    }
    return true;
  };

  const ShareLinks = () => {
    return (
      <>
        {PERMISSIONS.apis.applyOnBehalfMembersAllowedGroups.some((value) =>
          group.includes(value)
        ) &&
          list.length > 0 && (
            <>
              <Tooltip
                placement="topLeft"
                title="Generate Team's Balance Shareable Link"
              >
                <ShareAltOutlined
                  style={{
                    color: currentTheme.text,
                  }}
                  onClick={handleShareBalance}
                />
              </Tooltip>
              <Tooltip placement="topLeft" title="Shareable Link List">
                <UnorderedListOutlined
                  style={{
                    color: currentTheme.text,
                  }}
                  onClick={onLinksDrawerOpen}
                />
              </Tooltip>

              <ShareBalance
                open={openShareBalanceModal}
                setOpen={setOpenShareBalanceModal}
              />
              <LinksDrawer
                open={openLinksDrawer}
                onClose={onLinksDrawerClose}
                linksListData={linksListData}
                setLinksListData={setLinksListData}
              />
            </>
          )}
      </>
    );
  };

  return (
    <Row>
      <Col xs={24} md={12}>
        <div>
          {!initLoading ? (
            <div className="cml-card">
              <Row>
                <Col span={23}>
                  <List
                    loading={initLoading}
                    itemLayout="vertical"
                    loadMore={loadMore}
                    dataSource={list}
                    locale={{ emptyText: <NoResultFound /> }}
                    renderItem={(item) => (
                      <List.Item
                        actions={[
                          <div className="ml-48">
                            {PERMISSIONS.apis.unassignedMemberAllowedGroups.some(
                              (value) => group.includes(value)
                            ) && (
                              <Button
                                onClick={() => openAssignModal(item)}
                                size="small"
                              >
                                Assign
                              </Button>
                            )}
                            {PERMISSIONS.apis.applyOnBehalfMembersAllowedGroups.some(
                              (value) => group.includes(value)
                            ) && (
                              <Button
                                type="primary"
                                onClick={() => openApplyModal(item)}
                                disabled={handleDisabled(item)}
                                icon={<CmlIcon iconName={"send"} />}
                                size="small"
                              >
                                Apply on Behalf
                              </Button>
                            )}
                          </div>
                        ]}
                      >
                        <Skeleton
                          avatar
                          title={false}
                          loading={item.loading}
                          active
                        >
                          <List.Item.Meta
                            style={{ cursor: "default" }}
                            avatar={<Avatar src={item?.pictureLink} />}
                            title={item?.employeeName}
                            description={
                              <div>
                                <div className="neutral-text">
                                  {item?.email}
                                </div>
                                <div>
                                  {item?.leaveBalance?.firstConfig &&
                                    Object.entries(
                                      item?.leaveBalance?.firstConfig
                                    )?.map(([key, value]) => (
                                      <div key={key}>
                                        <span className="neutral-text">
                                          {lodash.startCase(key)}:{" "}
                                          <span>
                                            <b>{value}</b>
                                          </span>
                                        </span>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            }
                          />
                        </Skeleton>
                      </List.Item>
                    )}
                  />
                </Col>
                <Col span={1}>
                  <div className="right-aligned-buttons">{ShareLinks()}</div>
                </Col>
              </Row>
            </div>
          ) : (
            <Skeleton loading={initLoading} active avatar></Skeleton>
          )}

          <AssignMember
            open={showAssignModal}
            setOpen={setShowAssignModal}
            user={selectedUser}
          />

          <ApplyOnBehalfModal userData={selectedUser} />
        </div>
      </Col>
    </Row>
  );
};
export default UserList;
