import { dateCountForNonSandwich } from "./dateCountForNonSandwich";
import { dateCountForSandwich } from "./dateCountForSandwich";

export const dateCountCrossYear = (
  getStandardDates,
  dates,
  holidays,
  expireDate,
  setDuration,
  isSandwichFirstYear,
  isSandwichSecondYear,
  start,
  end,
  weekendFirst,
  weekendSecond
) => {
  let firstYearDaysNonSandwich = 0;
  let secondYearDaysNonSandwich = 0;
  let firstYearDays = 0;
  let secondYearDays = 0;
  while (dates.start <= dates.end) {
    if (dates.start < expireDate[0]) {
      if (isSandwichFirstYear) {
        const dates = getStandardDates(expireDate[0], start);
        const dateCount = dateCountForSandwich(dates, expireDate, setDuration);
        firstYearDays = dateCount.firstYearDays;
      } else {
        const dates = getStandardDates(expireDate[0], start);
        const dateCount = dateCountForNonSandwich(
          dates,
          holidays,
          weekendFirst,
          expireDate,
          setDuration
        );
        firstYearDaysNonSandwich = dateCount.firstYearWorkingDays;
      }
    }
    if (dates.start < expireDate[1] && dates.start >= expireDate[0]) {
      if (isSandwichSecondYear) {
        const dates = getStandardDates(end, expireDate[0]);
        const dateCount = dateCountForSandwich(dates, expireDate, setDuration);
        secondYearDays = dateCount.secondYearDays;
      } else {
        const dates = getStandardDates(end, expireDate[0]);
        const dateCount = dateCountForNonSandwich(
          dates,
          holidays,
          weekendSecond,
          expireDate,
          setDuration
        );
        secondYearDaysNonSandwich = dateCount.secondYearWorkingDays;
      }
    }

    dates.start.setDate(dates.start.getDate() + 1);
  }

  const duration =
    firstYearDays +
    firstYearDaysNonSandwich +
    secondYearDays +
    secondYearDaysNonSandwich;
  const firstYearWorkingDays = firstYearDays + firstYearDaysNonSandwich;
  const secondYearWorkingDays = secondYearDays + secondYearDaysNonSandwich;
  setDuration(`${duration} ${duration > 1 ? "days" : "day"}`);
  return { firstYearWorkingDays, secondYearWorkingDays, duration };
};
