import { checkBalances } from "./balanceCheckerHelper/CheckerApply";

export const hasBalanceCheckerApply = (
  start,
  end,
  leave,
  tenantConfig,
  expireDate,
  userData,
  userLeaveDetails,
  calculateConsumedDays,
  ownApply
) => {
  const type = leave
    ? leave
    : Object.keys(tenantConfig[0]?.leavePolicy?.maxLeaveAndType)[0];
  const duration = calculateConsumedDays(start, end);
  if (ownApply) {
    return checkBalances(
      end,
      start,
      userData,
      userLeaveDetails,
      expireDate,
      duration,
      type
    );
  }
  return true;
};
