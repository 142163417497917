import React, { useState, useEffect } from "react";
import lodash from "lodash";

import CmlIcon from "../../dashboard/components/common/icon/CmlIcon";
import { Button } from "antd";

// TODO: Refactor this component with some simple logic
const TableAction = ({ actions, row }) => {
  // TODO: isOwnTable should be a props, to make the component more reusable
  const [isOwnTable, setIsOwnTable] = useState(true);
  useEffect(() => {
    const actionList = Object.keys(actions);
    if (actionList.includes("handleApprove" && "handleReject")) {
      setIsOwnTable(false);
    }
  }, [actions]);

  if (
    lodash.toLower(row.status) === "pending" ||
    lodash.toLower(row.status) === "modification"
  ) {
    return (
      <>
        {isOwnTable && (
          <>
            {"handleEdit" in actions && (
              <Button
                type="link"
                title="Edit this leave request"
                size="small"
                icon={<CmlIcon iconName="edit_note" /> }
                onClick={(event) => actions.handleEdit(row, event)}
              />
            )}
            {"handleCancel" in actions && (
              <Button
                type="link"
                title="Cancel this leave request"
                size="small"
                icon={<CmlIcon iconName="delete" /> }
                onClick={(event) => actions.handleCancel(row, event)}
              />
            )}
          </>
        )}
        {!isOwnTable && (
          <>
            {"handleApprove" in actions && (
            <Button
              type="link"
              title="Approve this leave request"
              size="small"
              icon={<CmlIcon iconName="done" /> }
              onClick={(event) => actions.handleApprove(row, event)}
            />
            )}
            {"handleReject" in actions && (
            <Button
              type="link"
              title="Reject this leave request"
              size="small"
              icon={<CmlIcon iconName="close" /> }
              onClick={(event) => actions.handleReject(row, event)}
            />
            )}
            {"handleModify" in actions && (
            <Button
              type="link"
              title="Request change for this leave request"
              size="small"
              icon={<CmlIcon iconName="update" /> }
              onClick={(event) => actions.handleModify(row, event)}
            />
            )}
          </>
        )}
      </>
    );
  } else if (
    lodash.toLower(row.status) === "canceled" ||
    lodash.toLower(row.status) === "rejected"
  ) {
    return (
      <>
        {"handleEdit" in actions && (
          <Button
            type="link"
            size="small"
            disabled
            icon={<CmlIcon iconName="edit_note" /> }
          />
        )}
        {"handleCancel" in actions && (
          <Button
            type="link"
            size="small"
            disabled
            icon={<CmlIcon iconName="delete" /> }
          />
        )}
        {"handleApprove" in actions && (
          <Button
            type="link"
            size="small"
            disabled
            icon={<CmlIcon iconName="done" /> }
          />
        )}
        {"handleReject" in actions && (
          <Button
            type="link"
            size="small"
            disabled
            icon={<CmlIcon iconName="close" /> }
          />
        )}
        {"handleModify" in actions && (
          <Button
            type="link"
            size="small"
            disabled
            icon={<CmlIcon iconName="contract_edit" /> }
          />
        )}
      </>
    );
  } else {
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        {"handleEdit" in actions && (
          <Button
            type="link"
            size="small"
            disabled
            icon={<CmlIcon iconName="edit_note" /> }
          />
        )}
        {"handleCancel" in actions &&
          new Date(
            row?.details?.workDate
              ? row?.details?.workDate
              : row?.details?.startDate
          ) > new Date() && (
            <Button
              type="link"
              size="small"
              title="Cancel this leave request"
              onClick={(event) => actions.handleCancel(row, event)}
              icon={<CmlIcon iconName="delete" /> }
            />
          )}
        {"handleCancel" in actions &&
          new Date(
            row?.details?.workDate
              ? row?.details?.workDate
              : row?.details?.startDate
          ) < new Date() &&
          lodash.toLower(row.status) === "approved" && (
            <Button
              type="link"
              size="small"
              disabled
              icon={<CmlIcon iconName="delete" /> }
            />
          )}
        {"handleCancel" in actions &&
          new Date(
            row?.details?.workDate
              ? row?.details?.workDate
              : row?.details?.startDate
          ) < new Date() &&
          lodash.toLower(row.status) !== "approved" && (
            <Button
              type="link"
              size="small"
              title="Cancel this leave request"
              onClick={(event) => actions.handleCancel(row, event)}
              icon={<CmlIcon iconName="delete" /> }
            />
          )}
        {"handleApprove" in actions && (
          <Button
            type="link"
            size="small"
            disabled
            icon={<CmlIcon iconName="done" /> }
          />
        )}
        {"handleReject" in actions && (
          <Button
            type="link"
            size="small"
            disabled
            icon={<CmlIcon iconName="close" /> }
          />
        )}
        {"handleModify" in actions && (
          <Button
            type="link"
            size="small"
            disabled
            icon={<CmlIcon iconName="contract_edit" /> }
          />
        )}
      </div>
    );
  }
};

export default TableAction;
