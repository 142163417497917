import React from "react";
import LeaveApprovalSkeleton from "./components/LeaveApprovalSkeleton";
import OvertimeApprovalSkeleton from "./components/OvertimeApprovalSkeleton";

const ApprovalSkeleton = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "50px",
      }}
    >
      <LeaveApprovalSkeleton />
      <OvertimeApprovalSkeleton />
    </div>
  );
};

export default ApprovalSkeleton;
