import React, { useState, useContext, useEffect } from "react";
import lodash from "lodash";
import {
  spawnErrorToast,
  spawnSuccessToast,
} from "../../../../../../../../util/toast/spawnToast";
import { Form, Tooltip, Input, Select, Button, Alert, Skeleton } from "antd";

import { GeneralContext } from "../../../../../../../../contexts/general/Context";

import { saveMemberSettings } from "../../../../../../../../controller/common/saveMemberSettings";
import { getMemberSettings } from "../../../../../../../../controller/common/getMemberSettings";
import CmlIcon from "../../../../../common/icon/CmlIcon";

const { Option } = Select;
const INPUT_TYPES = ["text", "textarea", "pdf", "png", "jpeg"];

const MandatoryFields = ({ authUser }) => {
  const { currentTheme } = useContext(GeneralContext);
  const [isEdit, setIsEdit] = useState(true);
  const [fieldExistAlert, setFieldExistAlert] = useState(false);
  const [inputFields, setInputFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialInputFields, setInitialInputFields] = useState([]);

  useEffect(() => {
    const fetchMemberSettings = async (authUser) => {
      try {
        const response = await getMemberSettings(authUser);
        const config = JSON.parse(response.data?.data);
        if (Object.keys(config).length > 0) {
          setInputFields(config);
          setInitialInputFields(config);
        }
      } catch (error) {
        spawnErrorToast("Cannot find member settings");
        return;
      } finally {
        setLoading(false);
      }
    };
    fetchMemberSettings(authUser);
  }, []);

  const addField = () => {
    let newfield = { label: "", name: "", type: "" };
    setInputFields([...inputFields, newfield]);
  };

  const removeField = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  };

  const handleDropdownChange = (index, event) => {
    let data = [...inputFields];
    data[index]["type"] = event;
    setInputFields(data);
  };

  const handleInputChange = (index, event) => {
    let data = [...inputFields];
    data[index]["label"] = event.target.value;
    setInputFields(data);
  };

  const isDuplicateInputField = () => {
    const labelsSet = new Set();
    return inputFields.some((field) => {
      if (labelsSet.has(field.label)) {
        return true;
      }
      labelsSet.add(field.label);
      return false;
    });
  };

  const setMandatoryFields = async () => {
    if (!isDuplicateInputField()) {
      setFieldExistAlert(false);
      const inputFieldLength = inputFields.length;
      for (let index = 0; index < inputFieldLength; index++) {
        inputFields[index].name = lodash.camelCase(inputFields[index].label);
      }
      try {
        await saveMemberSettings(authUser, inputFields);
        spawnSuccessToast("Updated Successfully");
        setIsEdit(false);
      } catch (error) {
        spawnErrorToast("Failed to Update");
      }
    } else {
      setFieldExistAlert(true);
    }
  };

  const handleCancel = () => {
    setInputFields(initialInputFields);
  };

  return (
    <>
      <div className="mb-5">Profile definition</div>
      {loading ? (
        <Skeleton active />
      ) : (
        <div className="cml-card">
          <div className="mb-10 ">
            <span className="neutral-text mr-9">Add new field</span>
            <Button
              type="primary"
              size="small"
              onClick={addField}
              title="Add new field"
              icon={<CmlIcon iconName={"add"} />}
            />
          </div>
          {inputFields.length === 0 && !isEdit && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <span style={{ color: currentTheme.text, alignSelf: "center" }}>
                Not available
              </span>
              <Tooltip title="profile information fields have not been set yet">
                <div className="icon-holder-button-div">
                  <CmlIcon _className={"medium-icon-style"} iconName={"info"} />
                </div>
              </Tooltip>
            </div>
          )}
          <Form layout="vertical">
            {inputFields.map((input, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Form.Item colon={false} label={<div>Field name</div>}>
                  <Input
                    disabled={!isEdit}
                    name="label"
                    placeholder="Name"
                    value={input.label}
                    onChange={(event) => handleInputChange(index, event)}
                    style={{
                      color: currentTheme.text,
                      minWidth: "200px",
                    }}
                  />
                </Form.Item>

                <Form.Item
                  colon={false}
                  label={<div>type</div>}
                  style={{
                    marginLeft: 50,
                    marginRight: 10,
                  }}
                >
                  <Select
                    disabled={!isEdit}
                    name="type"
                    placeholder="Select Type"
                    value={input.type}
                    style={{
                      maxWidth: "300px",
                      minWidth: "70px",
                    }}
                    onChange={(value) => handleDropdownChange(index, value)}
                  >
                    {INPUT_TYPES.map((type) => (
                      <Option value={type} key={type}>
                        {type}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                {isEdit && (
                  <div
                    className="icon-holder-button-div"
                    onClick={() => removeField(index)}
                  >
                    <CmlIcon
                      _className={"medium-icon-style"}
                      iconName={"delete"}
                    />
                  </div>
                )}
              </div>
            ))}
          </Form>
          {fieldExistAlert && (
            <Alert
              showIcon
              type="error"
              closable
              onClose={() => setFieldExistAlert(false)}
              style={{
                color: currentTheme.text,
                background: currentTheme.background,
                marginBottom: 20,
              }}
              message="Duplicate Field"
            />
          )}
          {isEdit && (
            <>
              <div className="left-aligned-buttons">
                <Button
                  type="primary"
                  onClick={setMandatoryFields}
                  icon={<CmlIcon iconName={"hard_drive"} />}
                >
                  Save
                </Button>
                <Button
                  onClick={handleCancel}
                  icon={<CmlIcon iconName={"cancel"} />}
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default MandatoryFields;
