export const HttpStatusCode = {
  // Successful responses
  OK: 200,
  CREATED : 201,
  ACCEPTED : 202,
  UPDATED : 204,

  // Client error responses
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,

  // Server error responses
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
};
