import { post } from "../../lib/fetch";

import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";

export const insertTenant = async (payload, authUser) => {
  const url = `${endpoints.TENANT_ENDPOINT}`;
  const headers = await getHeaders(authUser);
  return await post({ url, payload, headers });
};
