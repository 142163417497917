import { Modal } from 'antd';
import React from 'react';
import ApplyLeave from '../../../../../../apply/Apply';
import { useAtom } from 'jotai';
import { showModalsAtom } from '../../../../../../../../../contexts/generalStore/store';

const ApplyOnBehalfModal = ({
  userData,
  fromAdmin
}) => {
  const [open, setOpen] = useAtom(showModalsAtom);
  const handleClose = () => {
    setOpen({ ...open, showApplyOnBehalfModal: false });
  };

  return (
    <div>
      <Modal
        title="Apply on Behalf Leave"
        open={open.showApplyOnBehalfModal}
        onCancel={handleClose}
        okButtonProps={{
          style: { display: "none" },
        }}
        cancelButtonProps={{
          style: { display: "none" },
        }}
        mask={true}
        maskClosable={false}
      >
        <ApplyLeave userData={userData} fromAdmin={fromAdmin} showingInModal={true} />
      </Modal>
    </div>
  );
};

export default ApplyOnBehalfModal;
