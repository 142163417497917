import { put } from "../../lib/fetch";

import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";

export const unassignSuperAdmin = async (authUser, payload) => {
  const url = `${endpoints.SUPER_ADMIN_UNASSIGN}`;
  const headers = await getHeaders(authUser);
  return await put({ url, payload, headers });
};
