import React, { useContext, useEffect, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import lodash from "lodash";
import { Button, Empty } from "antd";

import {
  authUserAtom,
  selectedSuperAdminAtom,
  showModalsAtom,
  superAdminsAtom,
} from "../../../../contexts/generalStore/store";
import { getAllSuperAdminHandler } from "../../../../core/assignUnassignSuperAdmin/getSuperAdminsHandler";
import DynamicTable from "../../../tables/dynamicTable/DynamicTable";
import AssignModal from "./components/modals/AssignModal";
import UnassignModal from "./components/modals/UnassignModal";
import DataDivNonTimeFilter from "../skeleton/common/DataDivNonTimeFilter";
import DataDivTimeFilter from "../skeleton/common/DataDivTimeFilter";
import CmlIcon from "../common/icon/CmlIcon";

const SuperAdminList = () => {
  const authUser = useAtomValue(authUserAtom);
  const [superAdmins, setSuperAdmins] = useAtom(superAdminsAtom);
  const [showModal, setShowModal] = useAtom(showModalsAtom);
  const setSelectedSuperAdmin = useSetAtom(selectedSuperAdminAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const getStateArguments = () => {
    return {
      authUser: authUser,
      setIsLoading: setIsLoading,
      setSuperAdmins: setSuperAdmins,
    };
  };

  useEffect(() => {
    const args = getStateArguments();
    getAllSuperAdminHandler(args);
  }, []);

  const handleAssign = (rowIndex, row) => {
    setSelectedSuperAdmin(row);
    setShowModal({ ...showModal, showSuperAdminAssignModal: true });
  };

  const handleUnassign = (rowIndex, row) => {
    setSelectedSuperAdmin(row);
    setShowModal({ ...showModal, showSuperAdminUnAssignModal: true });
  };

  const Action = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Button
          onClick={() => {
            handleAssign(rowIndex, row);
          }}
          type={row.group.length > 1 ? "default" : "primary" }
          icon={<CmlIcon iconName="redo" />}
          disabled={row.group.length > 1}
        >
          Assign
        </Button>
        <Button
          type={row.group.includes("superAdmin") && row.group.length === 1 ? "default" : "primary" }
          icon={<CmlIcon iconName="undo" />}
          onClick={() => {
            handleUnassign(rowIndex, row);
          }}
          disabled={row.group.includes("superAdmin") && row.group.length === 1}
        >
          Unassign
        </Button>
      </div>
    );
  };

  const groupFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        {Array.isArray(row?.group)
          ? row?.group.map((item, index) => (
              <div key={index}>{lodash.startCase(item)} </div>
            ))
          : lodash.startCase(row.group)}
      </>
    );
  };

  const columns = [
    {
      dataIndex: "email",
      title: "Super Admin Email",
      width: "350px",
      align: "start",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          record?.email?.toLowerCase().includes(value.toLowerCase()) ||
          record?.group?.some((item) =>
            item.toLowerCase().includes(value.toLowerCase())
          )
        );
      },
    },
    {
      dataField: "group",
      render: groupFormatter,
      title: "Current Group",
      width: "350px",
      align: "start",
    },
    {
      dataField: "action",
      title: "Action",
      render: Action,
      width: "350px",
      align: "center",
    },
  ];

  const handleTableSearch = (value) => {
    setSearchText(value);
  };

  const tableProps = {
    loading: {
      indicator: <DataDivNonTimeFilter />,
      spinning: isLoading,
      size: "small",
    },
  };
  return (
    <>
      <div className="cml-card">
        {/* <div className="neutral-text">Super Admin List</div> */}
        {superAdmins.length > 0 ? (
          <DynamicTable
            rowKey="email"
            tableProps={tableProps}
            columns={columns}
            data={superAdmins}
            handleTableSearch={handleTableSearch}
            inProgress={isLoading}
            isAllDataFetched={true}
            scroll={{ x: 768 }}
            size="small"
          />
        ) : superAdmins.length === 0 && !isLoading ? (
          <>
            <Empty />
          </>
        ) : (
          <div style={{ height: "200px" }}>
            <DataDivTimeFilter />
          </div>
        )}
      </div>

      {showModal.showSuperAdminAssignModal && <AssignModal />}
      {showModal.showSuperAdminUnAssignModal && <UnassignModal />}
    </>
  );
};

export default SuperAdminList;
