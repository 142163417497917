export const route = {
  SUPER_ADMIN: "/superAdmin",
  TENANT_ADMIN: "/tenantAdmin",
  TEAM_LEAD: "/teamLead",
  EMPLOYEE: "/employee",
  UNASSIGNED_EMPLOYEE: "/unassignedEmployee",
  LOGIN: "/login",
  SETTINGS: "settings",
  POLICY: "policy",
  TEAM_LEAD_SETTINGS: "/teamLead/settings",
  TENANT_ADMIN_SETTINGS: "/tenantAdmin/settings",
  EMPLOYEE_SETTINGS: "/employee/settings",
  PRIVACY_POLICY: "/policy",
  SUPER_ADMIN_PRIVACY_POLICY: "/superAdmin/policy",
  TENANT_ADMIN_PRIVACY_POLICY: "/tenantAdmin/policy",
  EMPLOYEE_PRIVACY_POLICY: "/employee/policy",
  TEAM_LEAD_PRIVACY_POLICY: "/teamLead/policy",
  UNASSIGNED_EMPLOYEE_PRIVACY_POLICY: "/teamLead/policy",
  ERROR_PAGE: "*",
  HOME: "/",
  DASHBOARD: "/dashboard",
};
export const role = {
  SUPER_ADMIN: "superAdmin",
  TENANT_ADMIN: "tenantAdmin",
  TEAM_LEAD: "teamLead",
  EMPLOYEE: "employee",
  UNASSIGNED_EMPLOYEE: "unassignedEmployee",
};

export const ONE_MB = 1024 * 1024;

export const FILE_TYPE_SIZE = {
  "application/pdf": 1 * ONE_MB,
  "image/png": 3 * ONE_MB,
  "image/jpeg": 3 * ONE_MB,
};
