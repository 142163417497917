import { unassignSuperAdmin } from "../../controller/superadmin/unassignSuperAdmin";
import {
  spawnErrorToast,
  spawnSuccessToast,
} from "../../util/toast/spawnToast";

const removeCommonKeys = (superAdmin) => {
  delete superAdmin.group;
  delete superAdmin.tenant;
  delete superAdmin.team;
};

const removeAttributeFromLeadOrEmployee = (superAdmin) => {
  if (superAdmin.domainName) {
    delete superAdmin.domainName;
    delete superAdmin.consumedLeaveBalance;
    delete superAdmin.hasRefreshToken;
    delete superAdmin.leaveAdjustmentTypeHistory;
    delete superAdmin.leaveBalance;
    delete superAdmin.teamHistory;
    delete superAdmin.teamName;
    delete superAdmin.tenantName;
    return;
  }
  removeCommonKeys(superAdmin);
};

const handleSuperAdminListChange = (
  selectedSuperAdmin,
  teamSensitiveGroups,
  teamNonSensitiveGroups,
  setSuperAdmins
) => {
  setSuperAdmins((prev) => {
    const newSuperAdmins = prev.map((superAdmin) => {
      if (superAdmin.email === selectedSuperAdmin.email) {
        if (teamSensitiveGroups.includes(superAdmin.group[1])) {
          removeAttributeFromLeadOrEmployee(superAdmin);
        } else if (teamNonSensitiveGroups.includes(superAdmin.group[1])) {
          removeCommonKeys(superAdmin);
        }
        return {
          ...superAdmin,
          group: ["superAdmin"],
          gsi1sk: "-",
        };
      }
      return superAdmin;
    });
    return newSuperAdmins;
  });
};

export const unassignSuperAdminHandler = async ({
  authUser,
  selectedSuperAdmin,
  teamSensitiveGroups,
  teamNonSensitiveGroups,
  setSuperAdmins,
  handleModalClose,
  setIsLoading,
}) => {
  setIsLoading(true);
  const payload = {
    superAdminEmail: selectedSuperAdmin?.email,
  };
  try {
    await unassignSuperAdmin(authUser, payload);
    handleSuperAdminListChange(
      selectedSuperAdmin,
      teamSensitiveGroups,
      teamNonSensitiveGroups,
      setSuperAdmins
    );
    spawnSuccessToast("Employee was successfully removed from tenant");
    handleModalClose();
  } catch (error) {
    spawnErrorToast("Could Not Unassign At The Moment");
  } finally {
    setIsLoading(false);
  }
};
