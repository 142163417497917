import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import lodash from "lodash";
import { handleRequestAction } from "../../../core/leaveRequest/requestActions/requestActionHandler";
import { getDateInTextFormat } from "../../../util/formatter/getDateInTextFormat";
import LeaveStatusTag from "../../tags/LeaveStatusTag";
import CmlIcon from "../../dashboard/components/common/icon/CmlIcon";

const ActionModal = ({
  leaveRequest,
  showModal,
  setShowModal,
  actionType,
  setChangedLeaveReq,
  teamName,
}) => {
  const [commentForModification, setCommentForModification] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [inProgress, setInProgress] = useState(false);

  const getStateArguments = (payload) => {
    return {
      leaveRequest: leaveRequest,
      payload: payload,
      actionType: actionType,
      setChangedLeaveReq: setChangedLeaveReq,
      setShowModal: setShowModal,
      setInProgress: setInProgress,
      setCommentForModification: setCommentForModification,
    };
  };
  const saveLeaveRequestChanges = async (payload) => {
    const args = getStateArguments(payload);
    handleRequestAction(args);
  };

  const handleSubmit = () => {
    if (
      actionType.buttonText === "Modify" &&
      commentForModification.trim().length === 0
    ) {
      setIsValid(false);
      return;
    }
    let payload = {
      creationDate: leaveRequest.creationDate,
      reqId: leaveRequest.reqId,
      status: actionType.status,
      startDate: leaveRequest.details?.startDate,
      employeeEmail: leaveRequest.employeeEmail,
      userType: leaveRequest?.fromWhom ? leaveRequest?.fromWhom : "employee",
    };
    if (teamName) {
      payload["teamName"] = teamName;
    }
    if (actionType?.modalComment) {
      payload.comment = commentForModification;
    }
    setIsValid(true);
    saveLeaveRequestChanges(payload);
  };

  const commentValidation = (e) => {
    if (e.target.value.trim().length === 0) {
      setIsValid(false);
      setCommentForModification("");
      return;
    }
    setIsValid(true);
    setCommentForModification(e.target.value);
  };

  const renderCommentInput = (
    <Form.Item
      label={<div className="neutral-text">Comment</div>}
      name="description"
      rules={
        actionType?.buttonText === "Modify" && [
          { required: true, message: "Please write comment" },
          {
            max: 200,
            message: "Comment must not be larger than 200 characters",
          },
        ]
      }
    >
      <Input.TextArea
        value={commentForModification}
        onChange={(value) => commentValidation(value)}
        autoSize={{ minRows: 3, maxRows: 5 }}
        showCount
        maxLength={200}
      />
    </Form.Item>
  );

  const getDurationString = (duration) => {
    const durationInt = lodash.toInteger(duration);
    if (durationInt === 1) {
      return `${durationInt} day`;
    } else {
      return `${durationInt} days`;
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCommentForModification("");
    setIsValid(true);
  };

  return (
    <Modal
      title={`${actionType?.buttonText} Leave Request`}
      open={showModal}
      onCancel={handleCloseModal}
      okButtonProps={{
        style: { display: "none" },
      }}
      cancelButtonProps={{
        style: { display: "none" },
      }}
      confirmLoading={inProgress}
      mask={true}
      maskClosable={false}
    >
      <div className="cml-card mt-20 mb-20">
        {leaveRequest?.employeeName && (
          <>
            <div className="neutral-text">Name</div>
            <div>{leaveRequest?.employeeName}</div>
          </>
        )}
        <div className="neutral-text">Email</div>
        <div>{leaveRequest?.employeeEmail}</div>
      </div>
      <Row className="mb-10">
        <Col span={12}>
          <div className="neutral-text">Duration</div>
          <div>{getDurationString(leaveRequest?.details?.duration)}</div>
        </Col>
        <Col span={12}>
          <div className="neutral-text">Leave Type</div>
          <div>{lodash.startCase(leaveRequest?.details?.leaveType)}</div>
        </Col>
      </Row>
      <Row className="mb-10">
        <Col span={12}>
          <div className="neutral-text">Start Date</div>
          <div>{getDateInTextFormat(leaveRequest?.details?.startDate)}</div>
        </Col>
        <Col span={12}>
          <div className="neutral-text">End Date</div>
          <div>{getDateInTextFormat(leaveRequest?.details?.endDate)}</div>
        </Col>
      </Row>
      <div className="neutral-text mt-10">Status</div>
      <div><LeaveStatusTag status={leaveRequest?.status} /></div>

      <div className="neutral-text mt-10">Description</div>
      <div>{leaveRequest?.details?.description}</div>

      <Form onFinish={handleSubmit} layout="vertical" style={{ width: "100%" }}>
        <div className="neutral-text mt-10">
          {(actionType?.buttonText !== "Modify" ||
            actionType?.buttonText !== "Approve") &&
            `Are you sure to ${actionType?.buttonText} the leave request?`}
        </div>
        {(actionType?.buttonText === "Modify" ||
          actionType?.buttonText === "Approve") &&
          renderCommentInput}
        <Form.Item>
          <div className="right-aligned-buttons mt-20">
            <Button
              type="primary"
              htmlType="submit"
              loading={inProgress}
              icon={<CmlIcon iconName="task_alt" />}
            >
              {actionType?.buttonText}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ActionModal;
