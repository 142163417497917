const defaultAccentColor = "#103F48";
const accentTextColor = "white";

export const lightTheme = {
  type: "light",
  accent: defaultAccentColor,
  accentText: accentTextColor,
  primary: "#ffffff",
  secondary: "#dedede",
  hover: "#e5e5e7b8",
  text: "#1b1b1b",
  tabText: "#C5CACB",
  cardNeutralText: "#545A5B",
  delete: "#D11515",
  hoverTabText: "black",
  inputTextFocused: "#f5f5f7",
  inputTextDisabled: "#ecedf1",
  background: "#f5f5f7",
  layoutBackground: "#d9d9d94d",
  neutral: "#878787",
  menuItem: "#818181cc",
  menu: "#fafafdf0",
  calendarBorder: "#b1afaf",
  tableEvenRow: "#f0f0f0a3",
  emailChips: "#eff0f0",
  inputBox: "#efefef",
  borderColor: "transparent",
  shimmer: `linear-gradient(to right,
      rgba(196, 196, 196, 0),
      rgba(196, 196, 196, 0.2),
      rgba(196, 196, 196, 0.4),
      rgba(196, 196, 196, 0.2),
      rgba(196, 196, 196, 0))`,
  card: {
    background: "#ffffff",
    hover: "#ebebeb",
  },
};

export const darkTheme = {
  type: "dark",
  accent: defaultAccentColor,
  accentText: accentTextColor,
  themeInvertedColor: "#ffffff", 
  primary: "#232b2d",
  secondary: "#2A3233",
  hover: "#2F393C",
  text: "#c5cacb",
  tabText: "#C5CACB",
  cardNeutralText: "#545A5B",
  delete: "#D11515",
  hoverTabText: "white",
  inputTextFocused: "#f5f5f7",
  inputTextDisabled: "#838383",
  background: "#051518",
  drawerHeaderBackground: "#05181c",
  layoutBackground: "#051518cc",
  neutral: "#545A5B",
  menu: "#232B2D",
  menuItem: "#7A7F80",
  formLabel: "#C5CACB",
  calendarBorder: "#39474b",
  tableEvenRow: "#424b4d8a",
  emailChips: "#3e4343",
  inputBox: "#121F23",
  borderColor: "transparent",
  buttonHover: "#424a4c",
  buttonPrimaryHover: "#155460",
  shimmer: `linear-gradient(to right,
      rgba(33, 33, 33, 0),
      rgba(33, 33, 33, 0.3),
      rgba(33, 33, 33, 0.6),
      rgba(33, 33, 33, 0.3),
      rgba(33, 33, 33, 0))`,
  card: {
    background: "#232B2D",
    hover: defaultAccentColor,
  },
  modalTitle: "#5b6264",
  disabledText: "#7f8485",
};

export const datepickerIndicatorBackground = {
  PENDING: "#f57f17",
  APPROVED: "#00695c",
  MODIFICATION: "#01579b",
  REJECTED: "#cc3300",
};
