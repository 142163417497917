export const getTimeOffsetFromTimezoneString = (timezoneString) => {
  const match = timezoneString.match(/\(GMT([-+]\d+):(\d+)\)/);
  if (match) {
    const hours = match[1];
    const minutes = match[2];
    const sign = hours.startsWith("-") ? "-" : "+";
    const paddedHours = Math.abs(hours).toString().padStart(2, "0");
    const paddedMinutes = minutes.padStart(2, "0");
    return `${sign}${paddedHours}:${paddedMinutes}`;
  }
  return null;
};

export const getTimezoneDate = (timezone, isoString) => {
  const isoDate = new Date(isoString);
  const offsetParts = timezone.offset.split(":");
  const offsetMinutes =
    parseInt(offsetParts[0]) * 60 + parseInt(offsetParts[1]);
  const convertedDate = new Date(isoDate.getTime() + offsetMinutes * 60000);
  return convertedDate;
};
