import lodash from "lodash";

import { getTimeManipulatedDates } from "../../datetime/standardDateHandler/getStandardDate";
import { filterLeavesByDateRange } from "../filterTakenLeaves/filterTakenLeaves";

// Check if there is any overlap
export const overlapChecker = (
  takenLeaveDates,
  dates,
  fromEdit,
  startDateBeforeEdit,
  endDateBeforeEdit
) => {
  let takenLeaveDatesCopy = lodash.clone(takenLeaveDates);
  if (fromEdit) {
    takenLeaveDatesCopy = filterLeavesByDateRange(
      takenLeaveDates,
      startDateBeforeEdit,
      endDateBeforeEdit
    );
  }
  const appliedDates = getTimeManipulatedDates(dates.end, dates.start);
  const isOverlap = takenLeaveDatesCopy.some((leaveRequest) => {
    const leaveStart = new Date(leaveRequest);
    const leaveEnd = new Date(leaveStart.getTime());
    leaveEnd.setDate(leaveEnd.getDate() + 1);
    const leaveDates = getTimeManipulatedDates(leaveEnd, leaveStart);
    return (
      appliedDates.end <= leaveDates.end &&
      appliedDates.start >= leaveDates.start
    );
  });
  return isOverlap;
};
