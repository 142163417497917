import { breakDownSingleYear } from "../breakDownInSingleYear";

export const firstYearBreakDown = (
  hasBalance,
  userData,
  type,
  firstYearWorkingDays,
  secondYearWorkingDays,
  leaveBreakDown,
  end,
  start,
  userLeaveDetails,
  expireDate,
  remainingLeaveTypes
) => {
  if (!hasBalance) {
    const selectedLeaveTypeCount = userData?.leaveBalance?.firstConfig[type];
    const remainingAfterSelectedLeave =
      firstYearWorkingDays - selectedLeaveTypeCount;
    leaveBreakDown = {
      firstConfig: {
        [type]: selectedLeaveTypeCount,
      },
    };
    let durationToCheck = {
      firstYearWorkingDays: remainingAfterSelectedLeave,
      secondYearWorkingDays: secondYearWorkingDays,
    };
    return breakDownSingleYear(
      userData,
      hasBalance,
      leaveBreakDown,
      end,
      start,
      userLeaveDetails,
      expireDate,
      remainingLeaveTypes,
      durationToCheck,
      "firstConfig",
      "firstYearWorkingDays"
    );
  } else {
    leaveBreakDown.firstConfig[type] = firstYearWorkingDays;
    return leaveBreakDown;
  }
};
