import { PlusOutlined } from "@ant-design/icons";
import React, { useContext, useState } from "react";

import MemberList from "./components/memberList/MemberList";
import { GeneralContext } from "../../../../../../contexts/general/Context";
import { Button, Col, Row } from "antd";
import AddTeamModal from "./components/modals/addTeam/AddTeamModal";
import LayoutTeamSelectInput from "../../../select/searchRequest/LayoutTeamSelectInput";
import CmlIcon from "../../../common/icon/CmlIcon";

const Teams = () => {
  const { currentTheme } = useContext(GeneralContext);
  const [showModal, setShowModal] = useState(false);
  const [teamName, setTeamName] = useState("invalid");

  const handleSelectedTeam = (teamName) => {
    setTeamName(teamName);
  };

  return (
    <div className="page-container">
      <Row className="mb-10">
        <Col xs={24} md={11}>
          <LayoutTeamSelectInput selectedTeam={handleSelectedTeam} />
        </Col>
        <Col xs={24} md={12}>
          <div className="mt-10 ml-10">
            <Button
              type="primary"
              title="Add team"
              size="small"
              icon={<CmlIcon iconName={"add"} />}
              onClick={() => setShowModal(true)}
            />
          </div>
        </Col>
      </Row>
      <MemberList teamName={teamName} />
      {showModal && <AddTeamModal open={showModal} setOpen={setShowModal} />}
    </div>
  );
};

export default Teams;
