import { getSpecificTeamBalance } from "../../../controller/admin/getSpecificTeamBalance";
import { spawnErrorToast } from "../../../util/toast/spawnToast";

const handlePopulateMemberList = (memberList, setMemberList, data) => {
  data.forEach((item) => {
    if (!memberList.includes(item.email)) {
      setMemberList((prev) => [...prev, item.email]);
    }
  });
};

export const handleMemberFetching = async (
  {
    authUser,
    selectedTeam,
    isLoading,
    setIsLoading,
    memberList,
    setMemberList,
  },
  token
) => {
  setIsLoading({
    ...isLoading,
    inProgressMember: true,
  });
  try {
    const response = await getSpecificTeamBalance(
      authUser,
      selectedTeam,
      token
    );
    if (response?.data?.Items) {
      handlePopulateMemberList(memberList, setMemberList, response.data?.Items);
      if (response.data?.ContinuationToken) {
        handleMemberFetching(
          {
            authUser,
            selectedTeam,
            isLoading,
            setIsLoading,
            memberList,
            setMemberList,
          },
          response.data?.ContinuationToken
        );
      }
    }
  } catch (error) {
    spawnErrorToast("Something went wrong. Please try again later");
  } finally {
    setIsLoading({
      ...isLoading,
      inProgressMember: false,
    });
  }
};
