import axios from "axios";
import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";

export const getTeamOverTimeList = async (
  authUser,
  queryParams,
  token,
  teamName
) => {
  let url = `${endpoints.TEAM_OVERTIME_APPLICATION}?fromDate=${queryParams.from}&toDate=${queryParams.to}`;
  if (token) {
    url = `${url}?fromDate=${queryParams.from}&toDate=${queryParams.to}&continuationToken=${token}`;
  }
  if (teamName && !token) {
    url = `${endpoints.TEAM_OVERTIME_APPLICATION}?fromDate=${queryParams.from}&toDate=${queryParams.to}&teamName=${teamName}`;
  }
  if (teamName && token) {
    url = `${endpoints.TEAM_OVERTIME_APPLICATION}?fromDate=${queryParams.from}&toDate=${queryParams.to}&teamName=${teamName}&continuationToken=${token}`;
  }
  const headers = await getHeaders(authUser);
  return await axios.get(url, headers);
};
