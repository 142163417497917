import lodash from "lodash";

export const handleIsInWeekend = (
  expireDate,
  weekendFirst,
  weekendSecond,
  dateForCheck
) => {
  if (dateForCheck < expireDate[0]) {
    return weekendFirst.includes(dateForCheck.getDay());
  } else {
    return weekendSecond.includes(dateForCheck.getDay());
  }
};

export const handleIsSandwich = (
  isSandwichFirstYear,
  isSandwichSecondYear,
  dateForCheck,
  expireDate
) => {
  if (dateForCheck < expireDate[0]) {
    return isSandwichFirstYear;
  } else {
    return isSandwichSecondYear;
  }
};

const getDateForForwardCheck = (dates) => {
  if (dates.start < dates.end) {
    const nextDate = lodash.clone(new Date(dates.end));
    nextDate.setDate(nextDate.getDate() + 1);
    return nextDate;
  } else {
    const nextDate = lodash.clone(new Date(dates.start));
    nextDate.setDate(nextDate.getDate() + 1);
    return nextDate;
  }
};

export const getDateForCheck = (dates, isBackward) => {
  if (isBackward) {
    const prevDate = lodash.clone(new Date(dates.start));
    prevDate.setDate(prevDate.getDate() - 1);
    return prevDate;
  } else {
    const nextDate = getDateForForwardCheck(dates);
    return nextDate;
  }
};

export const handleDateValueChange = (dateForCheck, isBackward) => {
  if (isBackward) {
    dateForCheck.setDate(dateForCheck.getDate() - 1);
    return dateForCheck;
  } else {
    dateForCheck.setDate(dateForCheck.getDate() + 1);
    return dateForCheck;
  }
};
