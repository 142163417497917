import styled from "@emotion/styled";

const CardDiv = styled.div`
  border: none;
  min-width: 200px;
  min-height: 80px;
  gap: 3px;
  font-size: 16px;
  justify-content: center;
  display: flex;
  padding: 10px;
  flex-direction: column;
  color: ${(props) => props.theme.text};
`;
const InputTitleDiv = styled.div`
  font-weight: 400;
  text-align: "left";
`;

const TabActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 25px;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: start;
    padding-bottom: 0px;
    margin-bottom: 15px;
  }
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: "row";
  gap: 15px;
  margin-top: 8px;
`;

export { CardDiv, InputTitleDiv, TabActionButtonWrapper, ActionButtonWrapper };
