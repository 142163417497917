import { css } from "@emotion/react";

export const getGlobalStyles = (currentTheme) => {
  return css`
    h1, h2, h3, h4, h5 {
      font-weight: 400;
      line-height: normal;
      color: ${currentTheme.text};
      margin: 0px;
    }

    // CAUTION: Please don't prettify following lines. Control your prettify plugin, it designed for really low resolution displays
    // Here, all of us are working in high resolution displays.
    b, strong {
      font-weight: 400;
    }

    p, span, div {
      color: ${currentTheme.text};
    }

    a {
      color: ${currentTheme.accent};
      :hover {
        color: ${currentTheme.themeInvertedColor};
      }
    }

    .neutral-text {
      color: ${currentTheme.cardNeutralText};
    }
    .text {
      color: ${currentTheme.text};
    }

    // .landing-page-navbar {
    //   width: 100%;
    //   height: 60px;
    //   display: flex;
    //   justify-content: space-between;
    //   align-items: center;
    //   background-color: ${currentTheme.accent};
    //   padding: 0 15px 0px 15px;
    // }

    .ant-layout-header {
      padding-inline: 15px;
      color: ${currentTheme.text};
      line-height: 20px;
      background: ${currentTheme.accent};
    }

    .header-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 64px;
    }

    .ant-layout-content {
      background-image: radial-gradient(
        circle at 50% 50%,
        #283234 0%,
        #495659 74.1%,
        #586264 100%
      );
      border-radius: 0px;
      font-size: 16px;
      /* For WebKit-based browsers (Chrome, Safari) */
      ::-webkit-scrollbar {
        width: 8px; /* width of the scrollbar */
        height: 8px; /* height of the scrollbar */
      }
      ::-webkit-scrollbar-track {
        background-color: transparent; /* background color of the track */
      }
      ::-webkit-scrollbar-thumb {
        background-color: #888; /* color of the thumb */
        border-radius: 5px;
        padding: 5px 5px 5px 5px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background-color: #555; /* color of the thumb on hover */
      }
    }

    ${currentTheme.type === "dark"
      ? `
    .content-container {
      background-image: radial-gradient(circle at 50% 50%, #283234 0%, #495659 74.1%, #586264 100%);
      opacity: 1;
      padding: 24px;
      overflow: auto;
      border-radius: unset;
    }
    `
      : `
    `}

    .user-role {
      color: ${currentTheme.neutral};
    }

    .user-role:hover {
      color: ${currentTheme.text};
    }

    //ant radioButtonGroup default style start
    .ant-radio-button-wrapper {
      background: ${currentTheme.inputBox} !important;
      border-color: ${currentTheme.inputBox} !important;
      border: none;
      height: 52px;
      color: ${currentTheme.tabText};
      :first-child {
        border-radius: 10px 0 0 10px;
        border-inline-start: none;
      }
      :last-child {
        border-radius: 0 10px 10px 0;
      }
      :hover {
        color: ${currentTheme.text};
      }
    }

    .ant-radio-button-wrapper > .ant-radio-button.ant-radio-button-checked {
      background: ${currentTheme.accent} !important;
      color: ${currentTheme.tabText} !important;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: ${currentTheme.tabText};
      :hover {
        color: ${currentTheme.text};
      }
    }

    .ant-radio-group-outline
      .ant-radio-button-wrapper:first-child
      .ant-radio-button {
      border-radius: 10px 0 0 10px;
    }

    .ant-radio-group-outline
      .ant-radio-button-wrapper:last-child
      .ant-radio-button {
      border-radius: 0 10px 10px 0;
    }

    .ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      )::before {
      display: none;
    }

    .ant-radio-button-wrapper:not(:first-child)::before {
      background-color: transparent;
    }

    .cml-tab-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 7px;
      margin-bottom: 7px;
      gap: 5px;
      :last-child {
        font-size: 16px;
      }
    }

    //ant radioButtonGroup default style end

    //ant upload start

    .ant-upload-wrapper .ant-upload-select,
    .ant-upload-wrapper {
      width: 100%;
      position: relative;
    }

    .ant-upload-wrapper
      .ant-upload-list
      .ant-upload-list-item
      .ant-upload-icon
      .anticon {
      color: ${currentTheme.text};
      font-size: 24px;
    }

    .ant-upload-list-item-container {
      color: ${currentTheme.text};
    }

    .ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
      font-size: 16px;
      :hover {
        background: transparent;
      }
    }

    .ant-upload-wrapper
      .ant-upload-list
      .ant-upload-list-item
      .ant-upload-list-item-actions
      .anticon {
      color: #d9363e;
      transition: all 0.3s;
      font-size: 24px;
      :hover {
        color: #d1585e;
      }
    }

    //ant upload end

    //icon style start
    .no-data-icon {
      display: flex;
      justify-content: center;
      color: ${currentTheme.neutral};
      font-size: 112px;
    }

    // .normal-icon-style {
    //   font-size: 40px;
    //   color: ${currentTheme.text};
    // }
    .medium-icon-style {
      font-size: 36px;
      color: ${currentTheme.tabText};
    }

    .thumbnail-icon-danger {
      color: red;
    }

    .cml_picture_thumbnail_button {
      position: absolute;
      height: fit-content;
      top: 10px;
      right: 10px;
      cursor: pointer;
      background: #e9e9e9ba;
      border-radius: 5px 5px;
      padding: 5px 5px 0px 5px;
      :hover {
        background: #d9d7d7ba;
      }
    }
    .cml_pdf_action_button {
      height: fit-content;
      cursor: pointer;
      background: #e9e9e9ba;
      border-radius: 5px 5px;
      padding: 5px 5px 0px 5px;
      :hover {
        background: #d9d7d7ba;
      }
    }

    .button-icon {
      font-size: 40px;
    }

    .menu-icon {
      font-size: 30px;
      margin-right: 5px;
    }

    // TODO: Check it's impact and remove this tab-icon class from the codebase
    .tab-icon {
      font-size: 36px;
      cursor: pointer;
    }

    .action-button-wrapper {
      display: flex;
      flex-direction: row;
      align-self: center;
      justify-content: center;
    }
    // TODO: Analyze and remove these unnecessary styles
    .table-action .tab-icon:not(.cancel-icon):hover {
      color: #1d8295;
    }

    //icon style end

    .icon-holder-button-div {
      background: transparent;
      width: fit-content;
      cursor: pointer;
      margin-top: 15px;
    }

    .content_container {
      display: flex;
      flex-direction: row;
      gap: 50px;
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
      }
    }

    .cml_upload_control_container {
      background: #121f23;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px 10px;
      padding: 20px;
      width: 325px;
    }

    .cml_upload_control_container .cml_upload_background {
      height: 179px;
      background: #07191d;
      cursor: pointer;
      border-radius: 10px 10px;
      padding: 23px 30px 0px 32px;
    }

    .cml_upload_image {
      width: 100%;
      border-radius: 10px 10px;
    }

    .cml_upload_control_container
      .cml_upload_background
      .cml_upload_content_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p:nth-child(2) {
        font-size: 16px;
        color: ${currentTheme.text};
        margin: 0;
      }
      p:nth-child(3) {
        font-size: 16px;
        color: ${currentTheme.neutral};
        margin: 0;
        text-align: center;
        span:first-child {
          font-size: 16px;
          color: ${currentTheme.text};
          margin: 0;
          font-weight: bold;
        }
      }
    }

    .cml_upload_control_container
      .cml_upload_background
      .cml_upload_document_wrapper {
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: center;
      align-items: center;
      div:nth-child(2) {
        margin-top: 0px;
      }
    }

    .cml_upload_control_container .cml_upload_control_button {
      height: 52px;
      width: 153px;
      background: ${currentTheme.accent};
      border: none;
      border-radius: 10px 10px;
      margin-top: -30px;
      :hover {
        background: ${currentTheme.accent};
        p {
          color: ${currentTheme.text};
        }
      }
      p {
        margin-block-start: 0;
        margin-block-end: 0;
        color: ${currentTheme.tabText};
      }
    }

    .threshold-number-control {
      width: 100%;
      border: none;
    }

    //defined-div end

    // login page starts

    .login {
      display: flex;
      justify-content: center;
      min-height: 100vh;
      background-image: radial-gradient(
        circle at 50% 50%,
        #283234 0%,
        #495659 74.1%,
        #586264 100%
      );
      opacity: 1;

      [data-amplify-authenticator] [data-amplify-router] {
        border-radius: 10px;
        background-color: #232b2d;
        border: none;
      }
      .reset-password-btn {
        background: transparent;
        color: #047d95;
        border: none;
        margin-bottom: 30px;
        :hover {
          color: #c5cacb;
        }
      }
      [data-amplify-authenticator] [data-amplify-form] {
        padding-bottom: 10px;
      }
      .federated-sign-in-button {
        border-radius: 10px;
        background: #121f23;
        border-color: #121f23;
        height: 50px;
        :hover .amplify-text {
          color: #fff;
        }
        :hover {
          background: #121f23;
          border-color: #121f23;
        }
      }

      .federated-sign-in-button .amplify-text {
        color: #c5cacb;
      }
      .amplify-input {
        background: #121f23;
        height: 50px;
        border: none;
        border-radius: 10px;
        color: #fff;
      }
      .amplify-input[name="password"],
      .amplify-input[name="confirm_password"] {
        background: #121f23;
        height: 50px;
        border: none;
        border-radius: 10px 0px 0px 10px;
      }

      .amplify-button.amplify-field__show-password {
        border-radius: 0px 10px 10px 0px;
        border-color: transparent;
        color: #c5cacb;
        background: #121f23;
        :hover {
          color: #fff;
        }
      }

      .amplify-input:focus {
        background: #121f23;
        border-color: transparent;
        color: #fff;
        box-shadow: none;
      }

      .amplify-button:focus {
        background: #121f23;
        border-color: transparent;
        color: #fff;
        box-shadow: none;
      }

      .amplify-divider--label::after {
        display: none;
      }
      hr.amplify-divider--horizontal {
        border-bottom: #545a5b 1px solid;
      }

      .amplify-button--primary {
        background: #103f48;
        border-radius: 10px;
        font-weight: normal;
      }

      h3.amplify-heading {
        color: #c5cacb;
      }

      .amplify-button.amplify-button--small {
        background: transparent;
        color: #c5cacb;
        width: fit-content;
        display: flex;
        align-self: center;
        :hover {
          background: transparent;
          color: #047d95;
        }
      }
    }
    p.footer-note {
      color: #5a6b6e;
    }
    //login page ends

    // TODO: Remove tons of unnecessary or unused styles
    .full-height-body-alt {
      background-color: ${currentTheme.background};
      color: ${currentTheme.text};
    }

    .ant-btn.ant-btn-icon-only .anticon {
      margin-left: -10px;
    }
    .anticon.anticon-close-circle.icon-disabled {
      color: ${currentTheme.neutral};
      cursor: default;
      transform: none;
      font-size: 1.5rem;
    }
    .anticon.anticon-close-circle.icon {
      font-size: 1.5rem;
      color: #0b6273;
    }

    .new-team-error .alert-danger {
      text-align: center;
    }

    // Theme Toggle Design Desktop (Original Credit: https://uiverse.io/alexruix/splendid-liger-23)
    .switch {
      font-size: 14px;
      position: relative;
      display: inline-block;
      width: 3.5em;
      height: 2em;
    }
    .switch-mobile {
      font-size: 12px;
    }
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }
    .slider {
      --background: ${currentTheme.background};
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--background);
      transition: 0.5s;
      border-radius: 30px;
    }
    .slider:before {
      position: absolute;
      content: "";
      height: 1.4em;
      width: 1.4em;
      border-radius: 50%;
      left: 10%;
      bottom: 15%;
      box-shadow: inset 8px -4px 0px 0px #f6f1d5;
      background: ${currentTheme.background};
      transition: 0.5s;
    }
    input:checked + .slider {
      background-color: #f6f1d5;
    }
    input:checked + .slider:before {
      transform: translateX(100%);
      box-shadow: inset 15px -4px 0px 15px #fdb813;
    }

    .tenant-settings .form-floating > .form-control,
    .tenant-settings .form-floating > .form-control-plaintext {
      color: ${currentTheme.text};
      background: ${currentTheme.background};
    }

    .tenant-settings .form-floating > .form-select,
    .tenant-settings .form-control,
    .tenant-settings .form-control:focus {
      color: ${currentTheme.text};
      background: ${currentTheme.background};
    }

    .tenant-settings .form-control:focus,
    .tenant-settings .form-floating > .form-select:focus {
      border-color: ${currentTheme.accent};
    }

    .tenant-settings .form-control:disabled {
      color: ${currentTheme.text};
      background: ${currentTheme.primary};
    }

    .tenant-settings .card-body {
      background: ${currentTheme.background};
    }

    .tenant-settings .card-header {
      background: ${currentTheme.primary};
    }

    .tenant-settings .form-check-input:checked {
      background-color: ${currentTheme.accent};
      border-color: ${currentTheme.accent};
    }

    //react multi email start
    .tenant-settings .multi-email-label {
      color: ${currentTheme.text};
    }

    .react-multi-email,
    .react-multi-email > input,
    .react-multi-email.focused,
    .tenant-settings .react-multi-email,
    .tenant-settings .react-multi-email.focused,
    .tenant-settings .react-multi-email input {
      background: ${currentTheme.inputBox};
      color: ${currentTheme.text};
    }

    .react-multi-email {
      min-height: 50px;
      border-radius: 10px 10px;
    }

    @media (max-width: 768px) {
      .react-multi-email {
        min-height: 65px;
      }
    }

    .react-multi-email.focused,
    .tenant-settings .react-multi-email.focused {
      border-color: ${currentTheme.borderColor};
    }

    .multi-email-disabled,
    .multi-email-disabled input {
      pointer-events: none;
    }

    .react-multi-email [data-tag] {
      background: ${currentTheme.emailChips};
      color: ${currentTheme.text};
    }

    .react-multi-email.empty > span[data-placeholder] {
      opacity: 0.4;
    }

    .multi-email-disabled [data-tag="true"] {
      background: ${currentTheme.emailChips};
      color: #989b9b;
    }

    //react multi email end

    //menu drawer start

    .menu-drawer .ant-drawer-body {
      padding: 0px;
      margin-left: -5px;
    }

    //menu drawer end

    //antd form start

    .ant-form-item
      .ant-form-item-label
      > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      )::after {
      display: inline-block;
      margin-inline-start: 10px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: "*";
    }

    .ant-form-item
      .ant-form-item-label
      > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
      )::before {
      display: none;
    }

    .ant-form-vertical .ant-form-item-label {
      padding: 0;
    }

    .ant-form-item {
      margin-bottom: 10px;
    }

    //antd form end

    //antd card start
    .ant-card .ant-card-body {
      padding: 24px;
      border-radius: 0 0 8px 8px;
      @media (max-width: 768px) {
        padding: 10px;
      }
    }

    //antd card end

    //antd input start

    .ant-input-search > .ant-input-group .ant-input {
      height: 50px;
      border-radius: 10px 0px 0px 10px !important;
    }

    .ant-input-search-button {
      background: ${currentTheme.accent} !important;
      height: 50px !important;
      box-shadow: none;
      border-radius: 0px 10px 10px 0px !important;
    }

    .ant-input-search-button .ant-btn-icon {
      margin: 0 0 5px 0;
    }

    .ant-input-number .ant-input-number-input {
      background: ${currentTheme.inputBox};
      height: 50px;
      border-radius: 10px 10px;
      border: none;
    }

    .ant-input-number:not(.ant-input-number-disabled) {
      background: ${currentTheme.inputBox} !important;
      color: ${currentTheme.text};
    }

    .ant-input-number.ant-input-number-disabled {
      background: ${currentTheme.inputBox} !important;
      color: ${currentTheme.inputTextDisabled};
      height: 50px;
      border-radius: 10px 10px;
      border: none;
    }

    .ant-input,
    .ant-input-number {
      border-radius: 10px 10px;
    }

    .ant-input-disabled[value] {
      color: ${currentTheme.neutral};
    }

    //antd input end

    //timezone starts

    .custom-timezone-select__dropdown-indicator {
      color: ${currentTheme.neutral} !important;
    }

    .custom-timezone-select__menu-list .custom-timezone-select__option {
      color: ${currentTheme.text};
    }
    .custom-timezone-select__menu-list
      .custom-timezone-select__option--is-selected {
      color: white;
    }
    .custom-timezone-select__control {
      border-radius: 10px 10px !important;
    }

    .custom-timezone-select__single-value.custom-timezone-select__single-value--is-disabled {
      color: ${currentTheme.inputTextDisabled} !important;
      opacity: 0.5;
      cursor: not-allowed;
    }

    //timezone ends

    //antd select start
    // TODO: Shouldn't be overridden CSS class "css-dev-only-do-not-override-dfjnss"
    // The Whole ant-select control seems to be over styled, need to investigate

    .ant-select-disabled {
      .ant-select-selector {
        .ant-select-selection-item {
          color: ${currentTheme.neutral} !important;
        }
      }
      .ant-select-arrow {
        color: ${currentTheme.neutral} !important;
      }
    }

    .ant-select {
      .ant-select-selector {
        border-radius: 10px 10px !important;
        height: 50px !important;
        border: none !important;
        background-color: ${currentTheme.inputBox} !important;
        box-shadow: none !important;
        .ant-select-selection-item {
          line-height: 48px !important;
          color: ${currentTheme.text};
          font-size: 16px;
        }
      }

      .ant-select-arrow {
        color: #fff;
      }
    }

    .ant-select-dropdown {
      background-color: ${currentTheme.inputBox};
      padding: 0;

      .ant-select-item-option-content {
        font-size: 16px;
        font-weight: 200;
      }

      .ant-select-item {
        border-radius: 0;
      }
      .ant-select-item-option {
        background: ${currentTheme.inputBox};
        color: ${currentTheme.text} !important;
        :hover:not(
            .ant-select-item-option-disabled,
            .ant-select-item-option-selected
          ) {
          color: ${currentTheme.text};
          background-color: ${currentTheme.accent} !important;
          border-radius: 0;
        }
      }
      .ant-select-item-option-selected {
        background-color: #263238 !important;
        border-radius: 10;
        span {
          color: ${currentTheme.text};
        }
      }
    }

    //antd select end

    ${currentTheme.type === "dark" &&
    `
      .ant-input,
      .ant-input-affix-wrapper{
        background: ${currentTheme.inputBox};
        color: ${currentTheme.text};
        border:none;
        border-radius:10px 10px;
        font-size: 16px;
        font-weight: normal;
        padding: 0px 15px;
      }
      .ant-input::placeholder{
        color: ${currentTheme.cardNeutralText};
        font-weight: normal;
      }
      .ant-input-group-addon .ant-btn-primary ant-input-search-button{
        border: none;
      }
    `}

    // antd collapse start

    .ant-collapse
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      color: ${currentTheme.text};
    }
    // antd collapse end

    // antd switch start

    .ant-switch.customSwitchDisabled {
      background-color: ${currentTheme.inputBox} !important;
    }
    // antd switch end

    // antd checkbox start

    .ant-checkbox .ant-checkbox-inner {
      box-sizing: border-box;
      position: relative;
      top: 0;
      inset-inline-start: 0;
      display: block;
      width: 28px;
      height: 28px;
      direction: ltr;
      background-color: ${currentTheme.inputBox};
      border: 1px solid ${currentTheme.inputBox};
      border-radius: 4px;
      border-collapse: separate;
      transition: all 0.3s;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${currentTheme.accent} !important;
      border-color: ${currentTheme.accent}!important;
      border: 1px solid ${currentTheme.accent}!important;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: white;
      position: relative;
      margin: 0 0 0 3px;
    }

    .ant-checkbox-group
      .ant-checkbox-wrapper.ant-checkbox-wrapper-checked.ant-checkbox-group-item:hover
      .ant-checkbox-checked
      .ant-checkbox-inner,
    .ant-checkbox-wrapper.ant-checkbox-wrapper-checked:hover
      .ant-checkbox-checked
      .ant-checkbox-inner {
      background-color: #185f6d;
    }
    .ant-checkbox-checked:after {
      border: 2px solid #185f6d !important;
    }

    .ant-checkbox-group
      .ant-checkbox-wrapper.ant-checkbox-wrapper.ant-checkbox-group-item:hover
      .ant-checkbox
      .ant-checkbox-inner,
    .ant-checkbox-wrapper.ant-checkbox-wrapper:hover
      .ant-checkbox
      .ant-checkbox-inner {
      border: 2px solid #185f6d;
    }
    .ant-checkbox-wrapper {
      span {
        vertical-align: middle;
      }
    }

    .ant-checkbox-group {
      gap: 20px;
    }

    // antd checkbox end

    // antd Button overrides start
    button.ant-btn {
      height: 50px;
      font-size: 16px;
      font-weight: 300;
      color: ${currentTheme.text};
      border-radius: 10px;
      border: none;
      box-shadow: none;
      span {
        vertical-align: middle;
      }
    }
    button.ant-btn-default {
      background-color: ${currentTheme.inputBox};
      :hover {
        color: ${currentTheme.themeInvertedColor} !important;
        background-color: ${currentTheme.buttonHover};
      }
      :disabled {
        background-color: ${currentTheme.neutral};
        span {
          color: ${currentTheme.disabledText};
        }
      }
    }
    button.ant-btn-primary {
      background-color: ${currentTheme.accent};
      :hover {
        color: ${currentTheme.themeInvertedColor} !important;
        background-color: ${currentTheme.buttonPrimaryHover} !important;
      }
      :disabled {
        background-color: ${currentTheme.buttonPrimaryHover};
        span {
          color: ${currentTheme.disabledText};
        }
      }
    }
    button.ant-btn-link.ant-btn-icon-only {
      span span {
        // This is the Link Color
        color: #089ebb;
        text-decoration: none;
        :hover {
          color: ${currentTheme.text};
        }
      }
    }
    .ant-btn-sm {
      height: 28px !important;
      .material-symbols-outlined {
        font-size: 28px;
      }
    }
    .ant-btn-sm.ant-btn-icon-only {
      width: 28px !important;
    }
    button.ant-btn-default.ant-btn-icon-only {
      width: 44px !important;
    }
    .ant-btn-loading-icon {
      font-size: 36px;
    }

    // antd dropdown button
    .top-nav-button.ant-dropdown-trigger {
      background: #7f7f7fa1;
      border-radius: 10px;
      height: 38px;
      padding: 0px 10px;
      :hover {
        background: #c5c5c580 !important;
      }
    }
    //ant Button overrides end

    .cml_cancel_button {
      height: 52px;
      border-radius: 10px;
      background-color: ${currentTheme.inputBox};
      font-size: 16px;
      color: ${currentTheme.tabText};
      :hover {
        color: ${currentTheme.text};
        background: ${currentTheme.inputBox} !important;
      }
    }

    //antd notification start
    .ant-notification
      .ant-notification-notice
      .ant-notification-notice-icon-error.anticon,
    .ant-notification
      .ant-notification-notice
      .ant-notification-notice-icon-success.anticon {
      color: #fff !important;
    }
    .ant-notification
      .ant-notification-notice
      .ant-notification-notice-with-icon
      .ant-notification-notice-message,
    .ant-notification .ant-notification-notice .ant-notification-notice-close {
      color: #fff !important;
    }
    .custom-notification.custom-notification-success {
      background-color: #52c41a;
    }
    .custom-notification.custom-notification-error {
      background-color: #dc143c;
    }

    //antd notification end

    //antd text area start

    .ant-input-textarea-show-count .ant-input-data-count {
      color: ${currentTheme.neutral};
    }

    //antd text area end

    // antd input start

    .ant-input[disabled],
    .ant-input-disabled.customInput,
    .ant-input[disabled].customInput {
      background-color: ${currentTheme.inputBox} !important;
      color: ${currentTheme.neutral} !important;
      border: none;
      box-shadow: none;
      cursor: not-allowed;
      opacity: 1;
    }

    .ant-input.customInput {
      background-color: ${currentTheme.inputBox};
      border: none;
      box-shadow: none;
      height: 50px;
    }

    .ant-input-number .ant-input-number-handler-wrap {
      background-color: ${currentTheme.inputBox};
    }
    .ant-input-number .ant-input-number-input {
      color: ${currentTheme.text};
    }
    .ant-input-number .ant-input-number-input:disabled {
      color: ${currentTheme.neutral};
    }
    .ant-input {
      height: 50px;
      border-radius: 10px 10px;
    }

    .ant-input-number .ant-input-number-handler-down-inner,
    .ant-input-number .ant-input-number-handler-up-inner {
      color: ${currentTheme.neutral} !important;
    }

    .ant-input-number .ant-input-number-handler-down-inner:hover,
    .ant-input-number .ant-input-number-handler-up-inner:hover {
      color: ${currentTheme.text} !important;
    }

    .ant-input-number .ant-input-number-handler {
      border-inline-start: 1px solid ${currentTheme.neutral} !important;
    }

    // antd input end

    //antd popover start

    ${currentTheme.type === "dark"
      ? `
      .ant-popover .ant-popover-inner {
      background: #1c2223;
      }
      .ant-popover .ant-popover-arrow:before {
        background: #1c2223;
      }
    `
      : `
        .ant-popover .ant-popover-inner {
          background: ${currentTheme.background};
        }
        .ant-popover .ant-popover-arrow:before {
          background: ${currentTheme.background};
        }
    `}

    .ant-popover .ant-popover-title,
    .ant-popover .ant-popover-inner-content {
      color: ${currentTheme.text};
    }

    //antd popover end

    //antd date picker start

    .ant-picker {
      border-radius: 10px 10px;
    }

    .ant-picker-dropdown .ant-picker-panel-container {
      background: transparent;
      border-radius: 10px;
    }

    .ant-picker-dropdown
      .ant-picker-cell-in-view.ant-picker-cell-today
      .ant-picker-cell-inner::before {
      border: 1px solid ${currentTheme.accent};
    }

    .ant-picker.ant-picker-status-success.ant-picker-has-feedback.ant-picker-disabled {
      background-color: ${currentTheme.inputBox};
    }

    .ant-picker .ant-picker-suffix {
      color: ${currentTheme.text};
    }
    .ant-picker.ant-picker-disabled .ant-picker-suffix {
      color: #575757;
      opacity: 0.6;
    }

    .ant-picker-input > input[disabled] {
    }

    .ant-picker .ant-picker-input > input[disabled] {
      color: ${currentTheme.inputTextDisabled};
      border: none;
      box-shadow: none;
      cursor: not-allowed;
      opacity: 0.3;
    }

    .ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected
      .ant-picker-cell-inner.ant-picker-cell-inner-pending,
    .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected
      .ant-picker-cell-inner.ant-picker-cell-inner-pending {
      color: white;
    }

    .ant-picker-date-panel .ant-picker-header {
      background: ${currentTheme.accent};
    }

    .ant-picker-date-panel .ant-picker-header .ant-picker-header-next-btn,
    .ant-picker-date-panel .ant-picker-header .ant-picker-header-prev-btn,
    .ant-picker-date-panel .ant-picker-header .ant-picker-header-super-prev-btn,
    .ant-picker-date-panel
      .ant-picker-header
      .ant-picker-header-super-next-btn {
      color: white;
      :hover {
        color: white;
      }
    }

    .ant-picker-date-panel
      .ant-picker-header
      .ant-picker-header-view
      .ant-picker-month-btn {
      color: white;
      pointer-events: none;
      :hover {
        color: white;
      }
    }

    .ant-picker-date-panel
      .ant-picker-header
      .ant-picker-header-view
      .ant-picker-year-btn {
      color: white;
      pointer-events: none;
      :hover {
        color: white;
      }
    }

    .ant-picker-date-panel .ant-picker-body {
      background: ${currentTheme.background};
    }

    .ant-picker-date-panel .ant-picker-body .ant-picker-content thead tr th {
      color: ${currentTheme.text};
    }

    .ant-picker-date-panel
      .ant-picker-cell:not(.ant-picker-cell-in-view)
      .ant-picker-cell-inner {
      display: none;
      color: transparent;
    }

    .ant-picker-cell:not(.ant-picker-cell-in-view)
      .ant-picker-cell-inner-pending {
      display: none;
    }

    .ant-picker-cell:not(.ant-picker-cell-in-view)
      .ant-picker-cell-inner-approved {
      display: none;
    }

    .ant-picker-cell:not(.ant-picker-cell-in-view)
      .ant-picker-cell-inner-modification {
      display: none;
    }

    .ant-picker-cell .ant-picker-cell-inner-holiday {
      color: #ff1744;
      font-weight: 500;
      cursor: pointer;
    }

    .ant-picker-date-panel .ant-picker-cell:not(.ant-picker-cell-in-view) {
      pointer-events: none;
    }

    .ant-picker-cell-selected .ant-picker-cell-inner {
      background-color: ${currentTheme.accent};
      color: white;
    }

    .ant-picker-cell.ant-picker-cell-in-view {
      pointer-events: none;
    }

    .ant-picker-cell.ant-picker-cell-in-view .ant-picker-cell-inner {
      pointer-events: all;
    }

    .ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-in-view
      .ant-picker-cell-inner {
      color: ${currentTheme.neutral};
      cursor: pointer;
    }

    .ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-in-view
      .ant-picker-cell-inner-holiday {
      color: #ff1744;
      cursor: pointer;
      opacity: 0.5;
    }

    .ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-in-view
      .ant-picker-cell-inner-approved,
    .ant-picker-cell.ant-picker-cell-in-view
      .ant-picker-cell-inner.ant-picker-cell-inner-approved {
      color: #00e676;
      cursor: pointer;
      opacity: 0.7;
    }

    .ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-in-view
      .ant-picker-cell-inner-pending,
    .ant-picker-cell.ant-picker-cell-in-view
      .ant-picker-cell-inner.ant-picker-cell-inner-pending {
      color: #ff8f00;
      cursor: pointer;
      opacity: 0.7;
    }

    .ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-in-view
      .ant-picker-cell-inner-modification,
    .ant-picker-cell.ant-picker-cell-in-view
      .ant-picker-cell-inner.ant-picker-cell-inner-modification {
      color: #2979ff;
      cursor: pointer;
      opacity: 0.7;
    }

    .ant-picker-cell.ant-picker-cell-disabled::before {
      background: transparent;
    }

    .ant-picker-cell.ant-picker-cell-disabled:not(
        .ant-picker-cell-in-view
      )::before {
      background: transparent;
    }

    .ant-picker-date-panel .ant-picker-header {
      background: ${currentTheme.accent};
    }
    .ant-picker-date-panel .ant-picker-header .ant-picker-header-next-btn,
    .ant-picker-date-panel .ant-picker-header .ant-picker-header-prev-btn,
    .ant-picker-date-panel .ant-picker-header .ant-picker-header-super-prev-btn,
    .ant-picker-date-panel
      .ant-picker-header
      .ant-picker-header-super-next-btn {
      color: white;
      :hover {
        color: white;
      }
    }
    .ant-picker-date-panel
      .ant-picker-header
      .ant-picker-header-view
      .ant-picker-month-btn {
      color: white;
      pointer-events: none;
      :hover {
        color: white;
      }
    }
    .ant-picker-date-panel
      .ant-picker-header
      .ant-picker-header-view
      .ant-picker-year-btn {
      color: white;
      pointer-events: none;
      :hover {
        color: white;
      }
    }
    .ant-picker-date-panel .ant-picker-body {
      background: ${currentTheme.background};
    }
    .ant-picker-date-panel .ant-picker-body .ant-picker-content thead tr th {
      color: ${currentTheme.text};
    }
    .ant-picker-date-panel
      .ant-picker-cell:not(.ant-picker-cell-in-view)
      .ant-picker-cell-inner {
      display: none;
      color: transparent;
    }
    .ant-picker-cell:not(.ant-picker-cell-in-view)
      .ant-picker-cell-inner-pending {
      display: none;
    }
    .ant-picker-cell:not(.ant-picker-cell-in-view)
      .ant-picker-cell-inner-approved {
      display: none;
    }
    .ant-picker-cell:not(.ant-picker-cell-in-view)
      .ant-picker-cell-inner-modification {
      display: none;
    }
    .ant-picker-cell .ant-picker-cell-inner-holiday {
      color: #ff1744;
      font-weight: 500;
      cursor: pointer;
    }
    .ant-picker-date-panel .ant-picker-cell:not(.ant-picker-cell-in-view) {
      pointer-events: none;
    }
    .ant-picker-cell-selected .ant-picker-cell-inner {
      background-color: ${currentTheme.accent} !important;
      color: white;
    }
    .ant-picker-cell.ant-picker-cell-in-view {
      pointer-events: none;
    }
    .ant-picker-cell.ant-picker-cell-in-view .ant-picker-cell-inner {
      pointer-events: all;
    }
    .ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-in-view
      .ant-picker-cell-inner {
      color: ${currentTheme.neutral};
      cursor: pointer;
    }
    .ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-in-view
      .ant-picker-cell-inner-holiday {
      color: #ff1744;
      cursor: pointer;
      opacity: 0.5;
    }
    .ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-in-view
      .ant-picker-cell-inner-approved {
      color: #00e676;
      cursor: pointer;
      opacity: 0.7;
    }
    .ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-in-view
      .ant-picker-cell-inner-pending {
      color: #ff8f00;
      cursor: pointer;
      opacity: 0.7;
    }
    .ant-picker-cell.ant-picker-cell-disabled.ant-picker-cell-in-view
      .ant-picker-cell-inner-modification {
      color: #2979ff;
      cursor: pointer;
      opacity: 0.7;
    }
    .ant-picker-cell.ant-picker-cell-disabled::before {
      background: transparent;
    }
    .ant-picker-cell.ant-picker-cell-disabled:not(
        .ant-picker-cell-in-view
      )::before {
      background: transparent;
    }

    .ant-picker,
    .ant-picker .ant-picker-input > input {
      background: ${currentTheme.inputBox};
      color: ${currentTheme.text};
      border-color: ${currentTheme.borderColor} !important;
      height: 50px;
    }

    .ant-picker .ant-picker-input > input::placeholder {
      background: ${currentTheme.inputBox};
      color: ${currentTheme.text};
      border-color: ${currentTheme.borderColor};
    }
    .ant-picker-focused {
      box-shadow: none;
    }

    .ant-picker.customDatePicker {
      border: none;
      background: ${currentTheme.inputBox} !important;
      width: 100%;
    }

    .ant-picker-dropdown .ant-picker-cell-in-view {
      color: ${currentTheme.text};
    }
    //antd date picker end

    //ant-spin start

    .ant-spin-dot-item {
      background-color: ${currentTheme.text};
    }

    //ant-spin end

    //ant-table start

    .ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > th,
    .ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > td,
    .ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder {
      background: ${currentTheme.secondary} !important;
    }

    .ant-table-wrapper
      .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan])::before {
      display: none;
    }
    .ant-table-wrapper .ant-table-tbody > tr > td {
      border-bottom: none;
      height: 50px;
    }
    .ant-table-wrapper .ant-table-thead > tr > th {
      border-bottom: none;
    }
    .ant-empty-description {
      color: ${currentTheme.text};
      opacity: 0.4;
    }

    .ant-spin-container .ant-table,
    .ant-table-tbody {
      background: ${currentTheme.secondary} !important;
      color: ${currentTheme.text};
    }

    .ant-table-container > .ant-table-content > table > thead > tr > th {
      background: ${currentTheme.inputBox};
      color: ${currentTheme.text};
      font-weight: normal;
      height: 50px;
    }

    .ant-table-wrapper
      .ant-table-container
      table
      > thead
      > tr:first-child
      > *:last-child,
    .ant-table-wrapper
      .ant-table-container
      table
      > thead
      > tr:first-child
      > *:first-child {
      border-start-end-radius: 0px;
      border-start-start-radius: 0px;
    }

    .ant-table-wrapper .ant-table-expanded-row-fixed {
      background: ${currentTheme.secondary};
      color: ${currentTheme.text};
    }

    .ant-table-row:hover > td,
    .ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: ${currentTheme.hover} !important;
      color: ${currentTheme.text};
    }

    .ant-table-measure-row {
      display: none;
    }

    //ant-table end

    //ant modal start

    .ant-modal {
      button.ant-modal-close {
        color: ${currentTheme.text};
      }
      .ant-modal-content,
      .ant-modal-header {
        background: ${currentTheme.background};
      }
      .ant-modal-header {
        margin-left: -22px;
        margin-right: -22px;
        padding-left: 22px;
        padding-right: 22px;
        padding-bottom: 5px;
        border-bottom: ${currentTheme.primary} 1px solid;
        .ant-modal-title {
          color: ${currentTheme.modalTitle};
          font-weight: 400;
        }
      }
    }

    //ant modal end

    .apply-alert .ant-alert-content .ant-alert-description {
      white-space: pre-line;
    }

    //defined progress bar start

    .progress-info-text {
      text-align: center;
      margin-top: 5px;
    }

    .remaining-percentage {
      height: 32px;
      background-color: ${currentTheme.accent};
    }

    .consumed-percentage {
      height: 32px;
      background-color: ${currentTheme.neutral};
    }

    //defined progress bar end

    //calender view calendar start

    .rbc-date-cell.rbc-now.rbc-off-range {
      display: none;
    }

    .rbc-event:focus,
    .rbc-day-slot .rbc-background-event:focus {
      outline: none;
    }

    .rbc-toolbar-label,
    .rbc-month-view,
    .rbc-toolbar .rbc-btn-group button {
      color: ${currentTheme.text} !important;
      background: none;
      border: none !important;
    }
    .rbc-timeslot-group .rbc-time-slot .rbc-label,
    .rbc-agenda-view {
      color: ${currentTheme.text};
      background: none;
      border: 1px solid ${currentTheme.calendarBorder};
    }
    .rbc-row.rbc-month-header .rbc-header {
      border-bottom: 1px solid ${currentTheme.calendarBorder};
      border-top: 1px solid ${currentTheme.calendarBorder};
      border-right: 1px solid ${currentTheme.calendarBorder};
      border-left: none;
    }
    .rbc-row.rbc-month-header .rbc-header:first-child {
      border-left: 1px solid ${currentTheme.calendarBorder};
    }
    .rbc-month-row {
      border-bottom: 1px solid ${currentTheme.calendarBorder};
      border-right: 1px solid ${currentTheme.calendarBorder};
      border-left: 1px solid ${currentTheme.calendarBorder};
    }
    .rbc-toolbar .rbc-toolbar-label {
      border: none;
    }
    .rbc-toolbar .rbc-btn-group button:hover {
      color: white !important;
      background: ${currentTheme.accent};
    }

    .rbc-toolbar .rbc-toolbar-label + .rbc-btn-group button:hover {
      color: ${currentTheme.type === "dark" ? "white" : "black"};
    }

    .rbc-toolbar .rbc-toolbar-label + .rbc-btn-group button {
      background-color: ${currentTheme.type === "dark" ? "#384143" : "#f3f2f2"};
      color: black;
    }
    .rbc-toolbar .rbc-toolbar-label + .rbc-btn-group button.rbc-active {
      color: white;
      background: ${currentTheme.accent};
    }
    .rbc-toolbar .rbc-btn-group button:disabled {
      color: grey !important;
      pointer-events: none;
    }
    .rbc-toolbar .rbc-btn-group button:disabled:hover {
      cursor: not-allowed;
      background: transparent;
    }
    .rbc-off-range-bg {
      background-color: transparent !important;
      pointer-events: none;
    }
    .rbc-today {
      background-color: transparent;
    }
    .rbc-date-cell.rbc-off-range span,
    .rbc-date-cell.rbc-off-range div {
      color: transparent !important;
      pointer-events: none;
    }
    .rbc-agenda-date-cell,
    .rbc-agenda-time-cell {
      min-width: 90px;
    }
    .rbc-date-cell.rbc-now > div {
      color: #158499 !important;
    }
    .rbc-header + .rbc-header {
      border-left: none;
    }
    .rbc-day-bg + .rbc-day-bg {
      border-left: 1px solid ${currentTheme.calendarBorder} !important;
    }
    .rbc-month-row + .rbc-month-row {
      border-top: none !important;
    }
    .rbc-month-view {
      border: 1px solid ${currentTheme.calendarBorder};
    }
    .rbc-agenda-view table.rbc-agenda-table,
    .rbc-timeslot-group .rbc-time-slot .rbc-label {
      border: none;
    }
    .rbc-agenda-view table.rbc-agenda-table thead > tr > th,
    .rbc-timeslot-group {
      border-bottom: 1px solid ${currentTheme.calendarBorder} !important;
    }
    .rbc-time-view {
      border: 1px solid ${currentTheme.calendarBorder} !important;
    }
    .rbc-time-header-content,
    .rbc-time-content > * + * > * {
      border-left: 1px solid ${currentTheme.calendarBorder} !important;
    }
    .rbc-time-header.rbc-overflowing {
      border-right: 1px solid ${currentTheme.calendarBorder} !important;
    }
    .rbc-day-slot .rbc-time-slot {
      border-top: none !important;
    }
    .rbc-time-content {
      border-top: 1px solid ${currentTheme.calendarBorder} !important;
    }

    //calender view calendar end

    //antd menu start
    .ant-menu-light.ant-menu-inline .ant-menu-item {
      margin-left: -15px;
    }

    .ant-menu-item-divider {
      background: ${currentTheme.neutral};
      margin: 20px 0px 20px 0px;
    }

    .ant-menu-root {
      height: 100%;
      background-color: ${currentTheme.menu};
      border-inline-end: none !important;
      li:first-child {
        margin-top: 20px;
      }
    }

    .ant-menu-item {
      background-color: transparent !important;
      .ant-menu-title-content {
        color: ${currentTheme.menuItem};
        font-size: 16px;
      }
      .menu-icon {
        color: ${currentTheme.menuItem};
      }
    }

    .ant-menu-item-active {
      background-color: transparent !important;
    }

    .ant-menu-item-selected {
      background-color: transparent !important;
      .ant-menu-title-content {
        color: ${currentTheme.text};
        font-size: 16px;
      }
      .menu-icon {
        color: ${currentTheme.text};
      }
    }
    //antd menu end

    .ant-radio.ant-radio-checked .ant-radio-inner {
      border-color: rgb(16 96 111);
      background-color: rgb(16 96 111);
    }

    // antd drawer start

    .ant-drawer {
      .ant-drawer-header {
        background-color: ${currentTheme.drawerHeaderBackground};
        .ant-drawer-header-title {
          button.ant-drawer-close {
            color: ${currentTheme.text};
          }
          div.ant-drawer-title {
            color: ${currentTheme.text};
            font-weight: 400;    
          }
        }
      }
      .ant-drawer-content {
        background: ${currentTheme.background};
      }
    }

    //antd drawer end

    //antd list start

    .ant-list {
      ul.ant-list-items {
        li.ant-list-item {
          border-block-end: ${currentTheme.secondary} 1px solid;
          :last-child {
            border-block-end: none;
          }
          h4.ant-list-item-meta-title {
            margin-bottom: 3px;
            color: ${currentTheme.text}; 
            font-weight: 400;
          }
        }
      }
    }
    .ant-list.cml-drawer-list {
      ul.ant-list-items {
        li.ant-list-item {
          border-block-end: none;
          background-color: ${currentTheme.primary};
          border-radius: 10px;
          margin-bottom: 20px;
          padding-left: 12px;
        }
      }
    }

    //antd list end

    //antd step start

    .ant-steps-item.ant-steps-item-wait .ant-steps-item-icon {
      background-color: transparent;
      border-color: ${currentTheme.neutral};
    }

    .ant-steps-item.ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
      color: ${currentTheme.text};
    }

    .ant-steps-item.ant-steps-item-process.ant-steps-item-active
      .ant-steps-item-icon {
      background-color: ${currentTheme.neutral};
      border: none;
    }

    :where(.css-dev-only-do-not-override-dfjnss).ant-steps
      .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: ${currentTheme.neutral};
    }

    :where(.css-dev-only-do-not-override-dfjnss).ant-steps
      .ant-steps-item-wait
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after {
      background-color: ${currentTheme.neutral};
    }

    :where(.css-dev-only-do-not-override-dfjnss).ant-steps
      .ant-steps-item-finish
      > .ant-steps-item-container
      > .ant-steps-item-tail::after {
      background-color: ${currentTheme.neutral};
    }

    //antd step end

    .user-nameORemail,
    .user-teamAndRole,
    .theme-toggle,
    .logout-item {
      pointer-events: none !important;
    }

    .ant-dropdown-menu .ant-dropdown-menu-item-active {
      background: none !important;
    }

    .theme-toggle
      .ant-dropdown-menu-title-content
      .theme-toggler-wrapper
      .toggle-switch,
    .logout-item
      .ant-dropdown-menu-title-content
      .logout-btn-wrapper
      #logout-button {
      pointer-events: initial;
      color: #308697;
      :hover {
        color: #3aa6bb;
      }
    }

    .ant-dropdown-menu-item-active {
      background: transparent;
    }

    .ant-switch.ant-switch-checked .ant-switch-inner {
      background-color: ${currentTheme.accent};
    }
    .ant-switch .ant-switch-inner {
      background-color: ${currentTheme.neutral};
    }

    ${currentTheme.type === "dark" &&
    `
      .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item{
        color:  ${currentTheme.text};
      }
      .ant-dropdown .ant-dropdown-menu{
        background-color: ${currentTheme.menu};
      }

    `}

    // antd dropdown ends

    //administration card starts

    .flex-item:hover .card-icon,
    .flex-item:hover p {
      color: ${currentTheme.text};
    }

    .flex-container {
      display: flex;
      flex-wrap: wrap;
      gap: 50px;
    }

    .flex-container > div.flex-item {
      background: ${currentTheme.card.background};
      width: 195px;
      height: 140px;
      text-align: center;
      border-radius: 10px 10px;
      :hover {
        background: ${currentTheme.card.hover};
        cursor: pointer;
        .card-icon,
        p {
          color: ${currentTheme.text};
        }
      }
    }
    .page-container {
      margin-top: 20px;
    }

    .flex_page_heading {
      display: flex;
      gap: 15px;
    }

    .cml-card {
      padding: 20px;
      background: ${currentTheme.primary};
      border-radius: 10px;
    }

    .card-icon {
      display: flex;
      justify-content: center;
      color: #545a5b;
      font-size: 75px;
      cursor: pointer;
      margin-top: 12px;
    }

    .card-text {
      display: flex;
      margin-top: 5px;
      justify-content: center;
      align-items: center;
      color: #545a5b;
      pointer-events: none;
      cursor: pointer;
      font-weight: 500;
    }

    //administration card ends

    .switch input {
      accent-color: ${currentTheme.accent};
    }

    .config-holiday-col {
      padding: 5px 25px;
    }
    .config-holiday-row {
      width: 90%;
      border-radius: 10px;
      padding: 5px 5px 5px 15px;
      margin-top: 7px;
      background: ${currentTheme.primary};
      flex-direction: row;
      justify-content: space-between;
    }
    .config-holiday-details {
      width: 90%;
      padding: 5px;
      display: flex;
      flex-direction: row;
    }
    .config-holiday-remove {
      width: 5%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .config-holiday-remove-icon {
      margin: 0;
    }
    .config-holiday-date {
      font-weight: 600;
    }
    .config-holiday-desc {
      width: 100%;
      word-wrap: break-word;
    }
    .config-holiday-hr {
      margin: 15px 0 10px 0 !important;
      width: 87%;
    }

    .leave-type-row {
      padding-bottom: 10px;
      display: flex;
      align-items: center;
    }
    .leave-type-row:last-child {
      padding-bottom: 0;
    }
    .form-control-plaintext {
      border: 1px solid;
      border-color: #e6e6e6;
      border-radius: 5px;
      color: ${currentTheme.text};
      background: ${currentTheme.background};
    }
    .leave-type-checkbox {
      text-align: left;
      margin: 5px;
    }
    .leave-type-new-input {
      display: flex;
      align-items: center;
      margin-top: 5px;
    }

    @media only screen and (max-width: 767px) {
      .config-holiday-row {
        width: auto;
        border-radius: 5px;
      }
      .config-holiday-details {
        flex-direction: column;
      }
      .config-holiday-remove {
        width: 10%;
      }
      .config-holiday-hr {
        width: 100%;
      }
    }

    // antd skeleton start

    ${currentTheme.type === "dark" &&
    ` :where(
        .css-dev-only-do-not-override-dfjnss
      ).ant-skeleton.ant-skeleton-active .ant-skeleton-title,
      :where(
        .css-dev-only-do-not-override-dfjnss
      ).ant-skeleton.ant-skeleton-active .ant-skeleton-paragraph > li,
      .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
        background: linear-gradient(90deg, #082329 25%, #051518 37%, rgba(0, 0, 0, 0.04) 63%);
        background-size: 400% 100% ;
        animation-name: css-dev-only-do-not-override-dfjnss-ant-skeleton-loading ;
        animation-duration: 1.4s ;
        animation-timing-function: ease ;
        animation-iteration-count: infinite ;
      }
      :where(.css-dfjnss).ant-skeleton.ant-skeleton-active .ant-skeleton-title,
      :where(.css-dfjnss).ant-skeleton.ant-skeleton-active
      .ant-skeleton-paragraph
      > li,
       {
      background: linear-gradient(90deg, #082329 25%, #051518 37%, rgba(0, 0, 0, 0.04) 63%);
      background-size: 400% 100%;
      animation-name: css-dfjnss-ant-skeleton-loading;
      animation-duration: 1.4s;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
    }
    `}

    // antd skeleton end

    ${currentTheme.type === "dark" &&
    `
      .modal-header .btn-close {
        filter: invert(85%) brightness(1000%);
        -webkit-filter: invert(85%) brightness(1000%);
        opacity: 0.8;
      }
      .form-control-plaintext, .multi-email-disabled {
        border: none !important;
      }
    `}
  `;
};
