import { getSpecificTeamBalance } from "../../../controller/admin/getSpecificTeamBalance";
import { clientMessage } from "../../../util/constants/clientMessages";
import { spawnErrorToast } from "../../../util/toast/spawnToast";

export const handleTeamBalanceQuery = async ({
  selectedTeam,
  authUser,
  token,
  setIsLoading,
  setIsAllDataFetched,
  setTeamBalanceList,
  setTeamUserList,
  setContinueToken,
}) => {
  setIsLoading(true);
  try {
    const response = await getSpecificTeamBalance(
      authUser,
      selectedTeam,
      token
    );
    if (token) {
      return response?.data?.Items;
    }
    if (response?.data?.Items) {
      setTeamBalanceList(response.data?.Items);
      setTeamUserList(response.data?.Items);
      setContinueToken(response.data?.ContinuationToken);
      if (!response.data?.ContinuationToken) {
        setIsAllDataFetched(true);
      } else {
        setIsAllDataFetched(false);
      }
    } else {
      setTeamBalanceList([]);
    }
  } catch (error) {
    spawnErrorToast(
      clientMessage?.unassignedEmployees?.FAILED_TO_RETRIEVE_DATA
    );
  } finally {
    setIsLoading(false);
  }
};
