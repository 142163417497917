import { unassignEmployeeFromTeam } from "../../../controller/admin/unassignEmployeeFromTeam";
import {
  spawnErrorToast,
  spawnSuccessToast,
} from "../../../util/toast/spawnToast";

export const handleUnassignFromTeam = async ({
  employee,
  authUser,
  setIsLoading,
  handleClose,
  removeEmployeeFromList,
}) => {
  setIsLoading(true);
  const payload = {
    email: employee?.email,
    date: new Date().toISOString(),
  };
  try {
    await unassignEmployeeFromTeam(authUser, payload);
    removeEmployeeFromList(employee?.email);
    spawnSuccessToast(
      `${employee?.employeeName} was successfully removed from ${employee?.teamName}`
    );
  } catch (error) {
    spawnErrorToast(
      error?.response?.data ?? "Could Not Unassign At The Moment"
    );
  } finally {
    setIsLoading(false);
    handleClose();
  }
};
