import styled from "@emotion/styled";
export const LoadingContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Center the loading spinner vertically */
`;

export const LoadingSpinner = styled("div")`
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
`;
//   @keyframes spin {
//     0% { transform: rotate(0deg); }
//     100% { transform: rotate(360deg); }
//   }
