import { Input } from "antd";
import { useAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";

import {
  apiCallAtom,
  apiCallDataFetchedAtom,
  authUserAtom,
  groupedOvertimeItemsAtom,
  showModalsAtom,
  tenantConfigAtom,
} from "../../../../../../contexts/generalStore/store.js";
import OvertimeTableAction from "../tableAction/OvertimeTableAction";
import CancelExtraLeaveBalance from "../../modals/CancelExtraLeaveBalance";
import { getOverTimeList } from "../../../../../../controller/common/getOverTimeList.js";
import { spawnErrorToast } from "../../../../../../util/toast/spawnToast.js";

import DynamicTable from "../../../../../tables/dynamicTable/DynamicTable.jsx";
import { TitleSearchWrapper } from "../../../../../tables/dynamicTable/DynamicTable-styled.jsx";
import DataDivNonTimeFilter from "../../../skeleton/common/DataDivNonTimeFilter.jsx";
import DataDivTimeFilter from "../../../skeleton/common/DataDivTimeFilter.jsx";
import { NoResultFound } from "../../../common/noResultFound/NoResultFound.jsx";
import OvertimeExpandedRow from "../../overtime-overview-expand";
import { transformToOvertimeOverviewResp } from "../../../../../../util/transformers/overtimeGroupItems";
import mainColumns from "../overtimeTableColumns";

const OwnOverTimeTable = ({ updateBalanceUponOvertime }) => {
  const [overlay, setOverlay] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [tableRow, setTableRow] = useState(null);

  const [showModal, setShowModal] = useAtom(showModalsAtom);
  const [isAllOverTimeListFetched, setIsAllOverTimeListFetched] = useAtom(apiCallDataFetchedAtom);
  const authUser = useAtomValue(authUserAtom);
  const [inProgress, setInProgress] = useAtom(apiCallAtom);
  const [groupedItems, setGroupedItems] = useAtom(groupedOvertimeItemsAtom);
  const tenantConfig = useAtomValue(tenantConfigAtom);

  const getOverTime = async (token) => {
    const minDate = new Date(tenantConfig[0]?.startYear);
    const maxDate = new Date(tenantConfig[tenantConfig.length - 1]?.expiredAt);
    const queryParams = { from: minDate.toISOString(), to: maxDate.toISOString() };

    try {
      setInProgress((prev) => ({ ...prev, inProgressOwnOverTime: true }));
      const response = await getOverTimeList(authUser, queryParams, token);

      if (!response?.data?.groupedItems && response?.data?.Items.length > 0) {
        const fallbackDataDataTransformation = transformToOvertimeOverviewResp(response.data);
        setGroupedItems(fallbackDataDataTransformation.groupedItems);
      } else {
        setGroupedItems((prev) => [...prev, ...(response?.data?.groupedItems ?? [])]);
      }

      if (response?.data?.ContinuationToken) {
        setIsAllOverTimeListFetched((prev) => ({
          ...prev,
          isAllOwnOverTimeDataFetched: false,
        }));
        setLastEvaluatedKey(response.data.ContinuationToken);
      } else {
        setIsAllOverTimeListFetched((prev) => ({
          ...prev,
          isAllOwnOverTimeDataFetched: true,
        }));
      }
    } catch (error) {
      console.log(error);
      spawnErrorToast("Error while fetching over time list");
    } finally {
      setInProgress((prev) => ({ ...prev, inProgressOwnOverTime: false }));
      setOverlay(false);
    }
  };

  useEffect(() => {
    setGroupedItems([]);
    getOverTime();
  }, []);

  const handleModalClose = () => {
    setTableRow(null);
    setShowModal((prev) => ({ ...prev, showCancelOvertimeModal: false }));
  };

  const handleCancel = (row) => {
    setTableRow(row);
    setShowModal((prev) => ({ ...prev, showCancelOvertimeModal: true }));
  };

  const handleTableSearch = (value) => {
    setSearchText(value);
  };

  const filteredItems = groupedItems.filter((item) =>
      item?.authorEmail?.toLowerCase().includes(searchText.toLowerCase())
  );

  const callApiToLoadMoreData = () => {
    getOverTime(lastEvaluatedKey);
  };

  if (overlay) {
    return <DataDivTimeFilter style={{ height: "200px" }} />;
  }

  return (
      <>
        <CancelExtraLeaveBalance
            isModalOpen={showModal.showCancelOvertimeModal}
            onModalClose={handleModalClose}
            tableRow={tableRow}
            updateBalanceUponOvertime={updateBalanceUponOvertime}
        />

        <div className="cml-card">
          {groupedItems.length > 0 && (
              <TitleSearchWrapper>
                <Input.Search
                    placeholder="Start typing to search"
                    onChange={(e) => handleTableSearch(e.target.value)}
                    style={{ width: window.screen.width > 500 ? "300px" : "100%" }}
                    enterButton
                />
              </TitleSearchWrapper>
          )}
          {filteredItems.length > 0 ? (
              <DynamicTable
                  rowKey="authorEmail"
                  tableProps={{
                    loading: {
                      indicator: <DataDivNonTimeFilter />,
                      spinning: overlay,
                      size: "small",
                    },
                  }}
                  columns={mainColumns}
                  data={filteredItems}
                  handleTableSearch={handleTableSearch}
                  inProgress={inProgress.inProgressOwnOverTime}
                  isAllDataFetched={isAllOverTimeListFetched.isAllOwnOverTimeDataFetched}
                  size="small"
                  callApiToLoadMore={callApiToLoadMoreData}
                  scroll={{ x: 768 }}
                  expandedRowRender={{
                    expandedRowRender: (record) => (
                      <OvertimeExpandedRow
                          record={record}
                          renderActionCell={(record) => (
                              <OvertimeTableAction
                                  actions={{ handleCancel }}
                                  row={record}
                                  group={
                                    authUser.signInUserSession
                                        ? authUser?.signInUserSession?.idToken?.payload?.group
                                        : authUser?.idToken?.payload?.group
                                  }
                                  fromOnBehalf={false}
                              />
                          )}
                      />
                  )}}
              />
          ) : (
              <NoResultFound />
          )}
        </div>
      </>
  );
};

export default OwnOverTimeTable;
