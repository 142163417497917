import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";
import { post } from "../../lib/fetch";

export const applyLeave = async (payload, user) => {
  const headers = await getHeaders(user);
  let url = `${endpoints.TEAM_MEMBER_APPLICATION}`;
  if (payload?.leadEmail === "" && !payload?.fromAdmin) {
    url = `${endpoints.TEAM_USER_APPLICATION}`;
  }
  return await post({ url, payload, headers });
};
