export const checkBalances = (
  end,
  start,
  userData,
  userLeaveDetails,
  expireDate,
  duration,
  type
) => {
  if (end < expireDate[0]) {
    const currentBalance = userData?.email
      ? userData?.leaveBalance?.firstConfig?.[type]
      : userLeaveDetails?.firstConfig[type];
    if (duration.firstYearWorkingDays > currentBalance) {
      return false;
    } else {
      return true;
    }
  } else if (
    end < expireDate[1] &&
    end > expireDate[0] &&
    start < expireDate[0]
  ) {
    const firstYearBalance = userData?.email
      ? userData?.leaveBalance?.firstConfig?.[type]
      : userLeaveDetails?.firstConfig[type];

    const secondYearBalance = userData?.email
      ? userData?.leaveBalance?.secondConfig?.[type]
      : userLeaveDetails?.secondConfig[type];

    if (
      duration.secondYearWorkingDays > secondYearBalance &&
      duration.firstYearWorkingDays > firstYearBalance
    ) {
      return false;
    } else if (
      duration.secondYearWorkingDays > secondYearBalance ||
      duration.firstYearWorkingDays > firstYearBalance
    ) {
      return false;
    } else {
      return true;
    }
  } else if (
    end < expireDate[1] &&
    end > expireDate[0] &&
    start > expireDate[0]
  ) {
    const secondYearBalance = userData?.email
      ? userData?.leaveBalance?.secondConfig?.[type]
      : userLeaveDetails?.secondConfig[type];
    if (duration.secondYearWorkingDays > secondYearBalance) {
      return false;
    } else {
      return true;
    }
  } else {
    const secondYearBalance = userData?.email
      ? userData?.leaveBalance?.secondConfig?.[type]
      : userLeaveDetails?.secondConfig[type];
    if (duration.secondYearWorkingDays > secondYearBalance) {
      return false;
    } else {
      return true;
    }
  }
};
