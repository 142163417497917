import React from "react";
import { CardDiv, ShimmerDiv } from "../../common/Common-styled";

const EntitlementSkeleton = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "270px",
        }}
      >
        <ShimmerDiv
          height={"40px"}
          width={"120px"}
          style={{ marginLeft: "25px" }}
        />
        <ShimmerDiv height={"40px"} width={"350px"} />
      </div>
      <CardDiv
        padding={"24px"}
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 192,
          }}
        >
          <ShimmerDiv height={"40px"} width={"200px"} />
          <ShimmerDiv height={"40px"} width={"700px"} />
        </div>
        <div
          style={{
            display: "flex",
            gap: 192,
          }}
        >
          <ShimmerDiv height={"40px"} width={"200px"} />
          <ShimmerDiv height={"40px"} width={"700px"} />
        </div>
        <div
          style={{
            display: "flex",
            gap: 192,
          }}
        >
          <ShimmerDiv height={"40px"} width={"200px"} />
          <ShimmerDiv height={"40px"} width={"700px"} />
        </div>
      </CardDiv>
    </div>
  );
};

export default EntitlementSkeleton;
