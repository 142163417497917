import { useAtomValue, useSetAtom } from "jotai";
import React, { useRef } from "react";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";

import {
  continueTokenAtom,
  selectedRoleAtom,
  selectedSearchTypeAtom,
  selectedStatusAtom,
} from "../../../../../../../../contexts/generalStore/store";
import {
  DatePickerWrapper,
  IconWrapper,
  SelectTitleWrapper,
  UnderLineText,
} from "../../history-styled";
import { getTimeManipulatedDates } from "../../../../../../../../core/datetime/standardDateHandler/getStandardDate";

const DatePickerInput = ({
  date,
  setDate,
  title,
  placeholderText,
  minDate,
}) => {
  const selectedStatus = useAtomValue(selectedStatusAtom);
  const searchType = useAtomValue(selectedSearchTypeAtom);
  const selectedRole = useAtomValue(selectedRoleAtom);
  const setLastEvaluatedKey = useSetAtom(continueTokenAtom);
  const setSelectedRole = useSetAtom(selectedRoleAtom);

  const handleDisable = () => {
    if (
      searchType === "teamSpecific" &&
      selectedRole &&
      selectedRole !== "invalid"
    )
      return false;
    if (
      searchType === "userSpecific" &&
      selectedStatus &&
      selectedStatus !== "invalid"
    )
      return false;
    return true;
  };

  const handleChange = (selectedDate) => {
    const date = dayjs(selectedDate).toDate();
    setLastEvaluatedKey("");
    const standardDate = getTimeManipulatedDates(date);
    setDate(standardDate.start);
  };

  const handleClear = () => {
    setLastEvaluatedKey("");
    setDate();
    if (searchType === "userSpecific") {
      setSelectedRole("invalid");
    }
  };

  const handleDisableDate = (current) => {
    current = dayjs(current).toDate();
    return current && current < minDate;
  };

  return (
    <div>
      <SelectTitleWrapper>
        <UnderLineText>{title}</UnderLineText>
        <DatePickerWrapper>
          <DatePicker
            className="customDatePicker"
            style={{
              width: "100%",
            }}
            placeholderText={placeholderText}
            disabled={handleDisable()}
            onChange={handleChange}
            value={!isNaN(date) && date ? dayjs(date) : null}
            inputReadOnly={true}
            minDate={minDate}
            allowClear={false}
            disabledDate={(current) => handleDisableDate(current.toDate())}
            showToday={false}
          />
          <IconWrapper>
            <CloseCircleFilled
              className={!isNaN(date) && date ? "icon" : "icon-disabled"}
              onClick={() => handleClear()}
            />
          </IconWrapper>
        </DatePickerWrapper>
      </SelectTitleWrapper>
    </div>
  );
};

export default DatePickerInput;
