import { useAtom, useAtomValue } from "jotai";
import React, { useContext, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button } from "antd";

import {
  adminHistoryAtom,
  adminHistoryEndDate,
  adminHistoryStartDate,
  apiCallAtom,
  apiCallDataFetchedAtom,
  authUserAtom,
  continueTokenAtom,
  memberListAtom,
  selectedLeaveAtom,
  selectedMemberAtom,
  selectedRoleAtom,
  selectedSearchTypeAtom,
  selectedStatusAtom,
  selectedTeamForMembersRequestsAtom,
} from "../../../../../../contexts/generalStore/store";

import DatePickerInput from "./component/datePickerInput/DatePickerInput";

import MembersSelectInput from "./component/MembersSelectInput/MembersSelectInput";
import RoleInput from "./component/roleInput/RoleInput";
import StatusSelectInput from "./component/statusSelectInput/StatusSelectInput";
import TeamInput from "./component/teamInput/TeamInput";
import TypeInput from "./component/TypeInput/TypeInput";
import HistoryTable from "./component/HistoryTable/HistoryTable";
import { InputWrapper } from "./history-styled";
import { spawnErrorToast } from "../../../../../../util/toast/spawnToast";
import { handleTeamSpecificSearch } from "../../../../../../core/history/handleSearch/handleTeamSpecificSearch/handleTeamSpecificSearch";
import { handleUserSpecificSearch } from "../../../../../../core/history/handleSearch/handleUserSpecificSearch/handleUserSpecificSearch";
import { handleMemberFetching } from "../../../../../../core/history/specificTeamMembers/specificTeamMember";
import CancelModalAdmin from "./component/cancelModalAdmin/CancelModalAdmin";
import CmlIcon from "../../../common/icon/CmlIcon";

const History = ({ setShowCardChild, setSelectedCard }) => {
  const selectedTeam = useAtomValue(selectedTeamForMembersRequestsAtom);
  const selectedMember = useAtomValue(selectedMemberAtom);
  const selectedStatus = useAtomValue(selectedStatusAtom);
  const selectedRole = useAtomValue(selectedRoleAtom);
  const searchType = useAtomValue(selectedSearchTypeAtom);
  const authUser = useAtomValue(authUserAtom);
  const selectedLeave = useAtomValue(selectedLeaveAtom);

  const [isLoading, setIsLoading] = useAtom(apiCallAtom);
  const [memberList, setMemberList] = useAtom(memberListAtom);
  const [startDate, setStartDate] = useAtom(adminHistoryStartDate);
  const [endDate, setEndDate] = useAtom(adminHistoryEndDate);
  const [allDataFetchedIndicator, setAllDataFetchedIndicator] = useAtom(
    apiCallDataFetchedAtom
  );
  const [lastEvaluatedKey, setLastEvaluatedKey] = useAtom(continueTokenAtom);
  const [adminHistory, setAdminHistory] = useAtom(adminHistoryAtom);

  const getStateArguments = () => {
    return {
      memberList: memberList,
      setMemberList: setMemberList,
      selectedTeam: selectedTeam,
      selectedMember: selectedMember,
      selectedStatus: selectedStatus,
      selectedRole: selectedRole,
      startDate: startDate,
      endDate: endDate,
      allDataFetchedIndicator: allDataFetchedIndicator,
      setAllDataFetchedIndicator: setAllDataFetchedIndicator,
      isLoading: isLoading,
      setIsLoading: setIsLoading,
      lastEvaluatedKey: lastEvaluatedKey,
      setLastEvaluatedKey: setLastEvaluatedKey,
      selectedLeave: selectedLeave,
      searchType: searchType,
      authUser: authUser,
      adminHistory: adminHistory,
      setAdminHistory: setAdminHistory,
    };
  };

  useEffect(() => {
    if (
      selectedTeam &&
      selectedTeam !== "invalid" &&
      searchType &&
      searchType === "userSpecific"
    ) {
      const args = getStateArguments();
      handleMemberFetching(args);
    }
  }, [selectedTeam]);

  const handleSearch = async () => {
    const args = getStateArguments();
    if (searchType === "invalid" || !searchType) {
      spawnErrorToast("Please select a valid search type");
      return;
    }
    if (!selectedTeam || selectedTeam === "invalid") {
      spawnErrorToast("Please select a team");
      return;
    }
    if (searchType === "teamSpecific") {
      handleTeamSpecificSearch(args);
    }
    if (searchType === "userSpecific") {
      handleUserSpecificSearch(args);
    }
  };

  const handleStartDateChange = (start) => {
    setStartDate(new Date(start));
    handleStartDateChangeEffect(start);
  };

  const handleStartDateChangeEffect = (start) => {
    setEndDate(new Date(start));
  };

  const handleEndDateChange = (end) => {
    setEndDate(new Date(end));
  };

  /**
   * TODO: Need to fix this component with Row, Col to make it small device friendly.
   * Also need to make the style of form-input control's labels unified.
   */
  return (
    <div className="page-container">
      <div className="cml-card">
        <InputWrapper>
          <TypeInput />
          <TeamInput />
          <MembersSelectInput />
          <StatusSelectInput />
        </InputWrapper>
        <InputWrapper>
          <RoleInput />
          <DatePickerInput
            title="From"
            placeholderText={"Start Date"}
            date={startDate ? startDate : null}
            setDate={handleStartDateChange}
          />
          <DatePickerInput
            title="To"
            placeholderText={"End Date"}
            date={endDate ? endDate : null}
            minDate={!isNaN(startDate) && startDate ? startDate : new Date()}
            setDate={handleEndDateChange}
          />
          {/* TODO: Hack solution for the time being, till the page is made small device compliant */}
          <div style={{ marginTop: "44px" }}>
            <Button
              type="primary"
              icon={<CmlIcon iconName="search" />}
              loading={isLoading.inProgress}
              onClick={() => {
                handleSearch();
              }}
            >
              Search
            </Button>
          </div>
        </InputWrapper>
        <div className="mt-30">
          <HistoryTable />
        </div>
      </div>
      <CancelModalAdmin leaveRequest={selectedLeave} />
    </div>
  );
};

export default History;
