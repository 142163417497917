import React from "react";
import { ShimmerDiv } from "../../common/Common-styled";

const DataDivNonTimeFilter = () => {
  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <ShimmerDiv height={"100%"} width={"100%"} />
    </div>
  );
};

export default DataDivNonTimeFilter;
