import React, { useEffect, useState } from "react";
import { Row, Col, Form, DatePicker, Button } from "antd";
import dayjs from "dayjs";
import CmlIcon from "../../common/icon/CmlIcon";

const FilterRequest = ({
  searchButtonClicked,
  setDefaultValue,
  userChangedDate,
  setUserChangedDate,
  prev,
  next,
}) => {
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [hasLoaded, setHasLoaded] = useState(false);

  const handleStartDateTimeChange = (start) => {
    const startDate = start.toDate();
    startDate.setHours(0, 0, 0, 0);
    setStartDateTime(startDate);
    setUserChangedDate(true);
  };
  const handleEndDateTimeChange = (end) => {
    const endDate = end.toDate();
    endDate.setHours(23, 59, 0, 0);
    setEndDateTime(endDate);
    setUserChangedDate(true);
  };

  const handleDisableDate = (current) => {
    return current < startDateTime;
  };
  const handleSearchButtonClicked = () => {
    searchButtonClicked(startDateTime, endDateTime);
  };
  const applyDefaultValue = () => {
    const previous30Days = new Date();
    previous30Days.setDate(previous30Days.getDate() - 30);
    previous30Days.setHours(0, 0, 0, 0);
    setStartDateTime(previous30Days);

    const next30Days = new Date();
    next30Days.setDate(next30Days.getDate() + 30);
    next30Days.setHours(23, 59, 0, 0);
    setEndDateTime(next30Days);
  };

  useEffect(() => {
    if (userChangedDate) {
      if (startDateTime && endDateTime) {
        setStartDateTime(startDateTime);
        setEndDateTime(endDateTime);
      } else {
        setStartDateTime(prev);
        setEndDateTime(next);
      }
    } else {
      if (!hasLoaded && !userChangedDate) {
        applyDefaultValue();
        setHasLoaded(true);
      } else {
        setDefaultValue({ startDateTime, endDateTime });
      }
    }
  }, [hasLoaded, userChangedDate, setDefaultValue, startDateTime, endDateTime, next, prev]);

  return (
    <Row>
      <Col xs={24} md={10}>
        <div className="cml-card">
          <Form layout="vertical">
            <Row>
              <Col sm={24} md={12}>
                <Form.Item label={<div className="neutral-text">Start Date</div>}>
                  <DatePicker
                    className="form-control full-width"
                    value={dayjs(startDateTime)}
                    onChange={handleStartDateTimeChange}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    showToday={false}
                    allowClear={false}
                    inputReadOnly={true}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={12}>
                <Form.Item label={<div className="neutral-text">End Date</div>} className="ml-10">
                  <DatePicker
                    className="form-control full-width"
                    value={dayjs(endDateTime)}
                    onChange={(end) => handleEndDateTimeChange(end)}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    showToday={false}
                    allowClear={false}
                    disabledDate={(current) => handleDisableDate(current.toDate())}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col sm={24} md={8}>
                <div className="mt-10">
                  <Button
                    type="primary"
                    onClick={handleSearchButtonClicked}
                    icon={<CmlIcon iconName={"search"} />}
                  >
                    Search
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default FilterRequest;
