import React, { useContext } from "react";
import {
  faCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardDiv } from "../../../../../common/Common-styled";

import { GeneralContext } from "../../../../../../../../contexts/general/Context"; 


const EmptyTenantConfig = ({
  handleAddTenantConfig,
  onDisplayTenantConfig,
  setOnDisplayTenantConfig,
}) => {
  const { currentTheme } = useContext(GeneralContext);

  return (
    <CardDiv padding={"5px 20px 20px 20px"}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: 15,
          color: currentTheme.text
        }}
      >
        <p>No tenant config is found in your tenant. </p>
        <FontAwesomeIcon
          icon={faCirclePlus}
          className="mx-2"
          style={{ marginTop: 15, fontSize: 20 }}
          title="Add new config"
          onClick={handleAddTenantConfig}
        />
      </div>
    </CardDiv>
  );
};

export default EmptyTenantConfig;
