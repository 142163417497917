import React, { useState } from "react";
import { useAtom, useAtomValue } from "jotai";


import OvertimeActionModal from "./actionModal/OvertimeActionModal";
import { authUserAtom, groupedOvertimeItemsAtom } from "../../../../../contexts/generalStore/store";
import { actionOvertime } from "../../../../../controller/common/actionOverTime";
import { spawnErrorToast, spawnSuccessToast } from "../../../../../util/toast/spawnToast";
import { updateItemStatusByReqId } from "../../../../../util/transformers/overtimeGroupItems";

const ApproveOvertimeModal = ({ isModalOpen, onModalClose, tableRow }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [groupedItems, setGroupedItems] = useAtom(groupedOvertimeItemsAtom);

  const authUser = useAtomValue(authUserAtom);

  const updateTableData = (changedLeaveReq) => {
    const updatedList = updateItemStatusByReqId(groupedItems, changedLeaveReq.reqId, "approved");
    setGroupedItems(updatedList);
  };

  const handleSubmit = async (comment) => {
    const payload = {
      authorEmail: tableRow.authorEmail,
      workDate: tableRow?.details?.workDate,
      action: "APPROVE",
      reqId: tableRow?.reqId,
      comment: comment
    };
    setInProgress(true);
    try {
      await actionOvertime(authUser, payload);
      updateTableData(payload);
      spawnSuccessToast("Application approved successfully");
      setConfirmLoading(true);
      onModalClose();
    } catch (err) {
      spawnErrorToast("Couldn't approved overtime request at this moment");
    } finally {
      setInProgress(false);
    }
  };

  return (
    <>
      <OvertimeActionModal
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        tableRow={tableRow}
        title={"Approve overtime request"}
        inProgress={inProgress}
        confirmButtonLabel={"Approve"}
        handleSubmit={handleSubmit}
        confirmAction={"approve"}
      />
    </>

  );
};

export default ApproveOvertimeModal;
