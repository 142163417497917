import { getMembersLeaveHistory } from "../../../controller/admin/getMembersLeaveHistory";
import { spawnErrorToast } from "../../../util/toast/spawnToast";

export const handleHistorySearch = async (
  selectedTeam,
  selectedMember,
  selectedStatus,
  selectedRole,
  startDate,
  endDate,
  allDataFetchedIndicator,
  setAllDataFetchedIndicator,
  isLoading,
  setIsLoading,
  lastEvaluatedKey,
  setLastEvaluatedKey,
  searchType,
  authUser,
  adminHistory,
  setAdminHistory
) => {
  if (!lastEvaluatedKey || lastEvaluatedKey === "") {
    setAdminHistory([]);
  }
  setIsLoading({ ...isLoading, inProgress: true });
  const start = !isNaN(Date.parse(startDate))
    ? startDate.toISOString()
    : startDate;
  const end = !isNaN(Date.parse(endDate)) ? endDate.toISOString() : endDate;
  try {
    const response = await getMembersLeaveHistory(
      authUser,
      searchType,
      selectedTeam,
      selectedMember,
      selectedStatus,
      selectedRole,
      start,
      end,
      lastEvaluatedKey
    );
    if (lastEvaluatedKey) {
      setAdminHistory([...response?.data?.Items, ...adminHistory]);
    } else {
      setAdminHistory(response?.data?.Items);
    }
    if (response?.data?.ContinuationToken) {
      setAllDataFetchedIndicator({
        ...allDataFetchedIndicator,
        isAllDataFetched: false,
      });
    } else if (response?.data?.ContinuationToken) {
      setLastEvaluatedKey(response.data.ContinuationToken);
    }
  } catch (error) {
    spawnErrorToast("Something went wrong. Please try again later");
  } finally {
    setIsLoading({ ...isLoading, inProgress: false });
  }
};
