import React, { useContext, useEffect, useState } from "react";
import lodash from "lodash";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { Button, Drawer, Layout, Menu, Skeleton } from "antd";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { HeaderWrapper, LogoutButtonWrapper } from "../dashboard-styled";
import ApplyLeave from "../components/apply/Apply";
import CalendarView from "../components/calendarView/CalendarView";
import OwnLeaveRequest from "../components/requests/OwnRequest";
import {
  adminHistoryAtom,
  adminHistoryEndDate,
  adminHistoryStartDate,
  apiCallAtom,
  apiCallDataFetchedAtom,
  authUserAtom,
  groupAtom,
  multiGroupSelectedAtom,
  overTimeListAtom,
  selectedMemberAtom,
  selectedRoleAtom,
  selectedSearchTypeAtom,
  selectedStatusAtom,
  selectedTeamAtom,
  selectedTeamForMembersRequestsAtom,
  selectedTeamForOvertimeAtom,
  selectedTeamForRequestsAtom,
  superAdminsAtom,
  teamBalanceListAtom,
  teamConfigAtom,
  teamListAtom,
  teamMembersAtom,
  tenantConfigAtom,
  tenantsAtom,
  unassignedListAtom,
  userLeaveDetailsAtom,
} from "../../../contexts/generalStore/store";
import appLogo from "../../../img/appIcon.svg";
import AdministrationView from "../components/administration/AdministrationView";
import MobileNavDropdown from "../../dropdown/mobileView/MobileNavDropdown";
import LeaveRequestApproval from "../components/approval/LeaveRequestApproval";
import { PERMISSIONS } from "../../../util/constants/permission";
import { apiCalls } from "../../../util/apiCalls/apiCalls";
import { GeneralContext } from "../../../contexts/general/Context";
import { spawnErrorToast } from "../../../util/toast/spawnToast";
import Tenants from "../components/tenants/tenantsList/Tenants";
import SuperAdminList from "../components/superAdminList/SuperAdminList";
import Help from "../components/help/Help";
import ConfigSetting from "../components/administration/components/config/ConfigSettings";
import MessageContainer from "../components/messageContainer/MessageContainer";
import { tenantConfigMessage } from "../../../util/constants/tenantConfigMessage";
import TeamMembers from "../components/teamMembers/TeamMembers";
import ApprovalSkeleton from "../components/skeleton/approval/ApprovalSkeleton";
import ApplyLeaveSkeleton from "../components/skeleton/applyLeave/ApplyLeaveSkeleton";
import RequestSkeleton from "../components/skeleton/requests/RequestSkeleton";
import AdministrationSkeleton from "../components/skeleton/administration/AdministrationSkeleton";
import SettingsView from "../components/settings/Settings";
import CmlIcon from "../components/common/icon/CmlIcon";
import EntitlementView from "../components/entitlement/Entitlement";

const { Header, Content } = Layout;

const LeaveCalender = ({ inProgress, tenantConfig, parsedSelectedGroup }) => {
  return (
    <>
      <CalendarView
        inProgress={inProgress}
        tenantConfig={tenantConfig}
        parsedSelectedGroup={parsedSelectedGroup}
      />
    </>
  );
};
const Apply = ({
  status = true,
  ownApply = true,
  userData = null,
  inProgress,
  hasRefreshToken,
  tenantConfig,
  parsedSelectedGroup,
}) => {
  return (
    <>
      <h1>Apply</h1>
      {!inProgress && tenantConfig?.length ? (
        <ApplyLeave
          status={status}
          ownApply={ownApply}
          userData={userData}
          hasRefreshToken={hasRefreshToken}
        />
      ) : !inProgress &&
        !tenantConfig?.length &&
        !PERMISSIONS.view.menu.noTenantConfig_NoRefreshTokenAllowedGroups.some(
          (value) => parsedSelectedGroup?.includes(value)
        ) ? (
        <>
          <MessageContainer
            messages={tenantConfigMessage.EMPTY_TENANT_CONFIG}
          />
        </>
      ) : (
        <ApplyLeaveSkeleton />
      )}
    </>
  );
};
const Request = ({ inProgress, tenantConfig, parsedSelectedGroup }) => {
  return (
    <>
      <h1>Applications</h1>
      {!inProgress && tenantConfig?.length ? (
        <OwnLeaveRequest />
      ) : !inProgress &&
        !tenantConfig?.length &&
        !PERMISSIONS.view.menu.noTenantConfig_NoRefreshTokenAllowedGroups.some(
          (value) => parsedSelectedGroup?.includes(value)
        ) ? (
        <>
          <MessageContainer
            messages={tenantConfigMessage.EMPTY_TENANT_CONFIG}
          />
        </>
      ) : (
        <RequestSkeleton />
      )}
    </>
  );
};
const Approval = ({ inProgress, tenantConfig, parsedSelectedGroup }) => {
  return (
    <>
      {!inProgress && tenantConfig?.length ? (
        <LeaveRequestApproval />
      ) : !inProgress &&
        !tenantConfig?.length &&
        !PERMISSIONS.view.menu.noTenantConfig_NoRefreshTokenAllowedGroups.some(
          (value) => parsedSelectedGroup?.includes(value)
        ) ? (
        <>
          <MessageContainer
            messages={tenantConfigMessage.EMPTY_TENANT_CONFIG}
          />
        </>
      ) : (
        <>
          <h1>Approval</h1>
          <ApprovalSkeleton />
        </>
      )}
    </>
  );
};
const Members = ({ inProgress, tenantConfig, parsedSelectedGroup }) => {
  return (
    <>
      <h1>Team Members</h1>
      {!inProgress && tenantConfig?.length ? (
        <TeamMembers />
      ) : !inProgress &&
        !tenantConfig?.length &&
        !PERMISSIONS.view.menu.noTenantConfig_NoRefreshTokenAllowedGroups.some(
          (value) => parsedSelectedGroup?.includes(value)
        ) ? (
        <>
          <MessageContainer
            messages={tenantConfigMessage.EMPTY_TENANT_CONFIG}
          />
        </>
      ) : (
        <Skeleton active />
      )}
    </>
  );
};
const EntitleMent = ({ inProgress, tenantConfig, parsedSelectedGroup }) => {
  return (
    <>
      <h1>Entitlement</h1>
      {!inProgress && tenantConfig?.length ? (
        <EntitlementView />
      ) : !inProgress &&
        !tenantConfig?.length &&
        !PERMISSIONS.view.menu.noTenantConfig_NoRefreshTokenAllowedGroups.some(
          (value) => parsedSelectedGroup?.includes(value)
        ) ? (
        <>
          <MessageContainer
            messages={tenantConfigMessage.EMPTY_TENANT_CONFIG}
          />
        </>
      ) : (
        <Skeleton active />
      )}
    </>
  );
};
const Settings = ({ inProgress, tenantConfig, parsedSelectedGroup }) => {
  return (
    <>
      {!inProgress && tenantConfig?.length ? (
        <SettingsView />
      ) : !inProgress &&
        !tenantConfig?.length &&
        PERMISSIONS.view.menu.applyItemAllowedGroups.some((value) =>
          parsedSelectedGroup?.includes(value)
        ) ? (
        <>
          <MessageContainer
            messages={tenantConfigMessage.EMPTY_TENANT_CONFIG}
          />
        </>
      ) : (
        <>
          <h1>Settings</h1>
          <AdministrationSkeleton />
        </>
      )}
    </>
  );
};
const Administration = ({ inProgress, tenantConfig, parsedSelectedGroup }) => {
  return (
    <>
      {!inProgress && tenantConfig?.length ? (
        <AdministrationView />
      ) : !inProgress &&
        !tenantConfig?.length &&
        PERMISSIONS.view.card.policyAllowedGroups.some((value) =>
          parsedSelectedGroup?.includes(value)
        ) ? (
        <>
          <ConfigSetting />
        </>
      ) : !inProgress &&
        !tenantConfig?.length &&
        PERMISSIONS.view.menu.applyItemAllowedGroups.some((value) =>
          parsedSelectedGroup?.includes(value)
        ) ? (
        <>
          <MessageContainer
            messages={tenantConfigMessage.EMPTY_TENANT_CONFIG}
          />
        </>
      ) : (
        <>
          <h1>Administration</h1>
          <AdministrationSkeleton />
        </>
      )}
    </>
  );
};
const Reports = () => <>reports</>;
const TenantsList = () => {
  return (
    <>
      <h1>Registered Tenants</h1>
      <Tenants />
    </>
  );
};
const SuperAdmins = () => {
  return (
    <>
      <h1>System SuperAdmins</h1>
      <SuperAdminList />
    </>
  );
};
const HelpUser = ({ inProgress }) => {
  return (
    <>
      <h1>Help</h1>
      {!inProgress ? <Help /> : <Skeleton active />}
    </>
  );
};

const WaitingSkeleton = () => <Skeleton active />;

const renderContent = (
  selectedKey,
  inProgress,
  hasRefreshToken,
  tenantConfig,
  parsedSelectedGroup
) => {
  switch (selectedKey) {
    case "leaveCalendar":
      return (
        <LeaveCalender
          inProgress={inProgress}
          tenantConfig={tenantConfig}
          parsedSelectedGroup={parsedSelectedGroup}
        />
      );
    case "apply":
      return (
        <Apply
          status={true}
          ownApply={true}
          userData={null}
          inProgress={inProgress}
          hasRefreshToken={hasRefreshToken}
          tenantConfig={tenantConfig}
        />
      );
    case "request":
      return (
        <Request
          inProgress={inProgress}
          tenantConfig={tenantConfig}
          parsedSelectedGroup={parsedSelectedGroup}
        />
      );
    case "approval":
      return (
        <Approval
          inProgress={inProgress}
          tenantConfig={tenantConfig}
          parsedSelectedGroup={parsedSelectedGroup}
        />
      );
    case "members":
      return (
        <Members
          inProgress={inProgress}
          tenantConfig={tenantConfig}
          parsedSelectedGroup={parsedSelectedGroup}
        />
      );
    case "entitlement":
      return (
        <EntitleMent
          inProgress={inProgress}
          tenantConfig={tenantConfig}
          parsedSelectedGroup={parsedSelectedGroup}
        />
      );
    case "administration":
      return (
        <Administration
          inProgress={inProgress}
          tenantConfig={tenantConfig}
          parsedSelectedGroup={parsedSelectedGroup}
        />
      );
    case "settings":
      return (
        <Settings
          inProgress={inProgress}
          tenantConfig={tenantConfig}
          parsedSelectedGroup={parsedSelectedGroup}
        />
      );
    case "reports":
      return <Reports inProgress={inProgress} />;
    case "tenantsList":
      return <TenantsList inProgress={inProgress} />;
    case "superAdmins":
      return <SuperAdmins inProgress={inProgress} />;
    case "help":
      return <HelpUser inProgress={inProgress} />;
    default:
      return <WaitingSkeleton />;
  }
};

/**
 * @deprecated DesktopView should be used for all type of screen sizes. This should not used
 */

const MobileViewDashboard = () => {
  const { currentTheme } = useContext(GeneralContext);
  const [collapsed, setCollapsed] = useState(true);
  const tabInfo = JSON.parse(localStorage.getItem("selectedTabInfo"));
  const [selectedKey, setSelectedKey] = useState(tabInfo);
  const [hasRefreshToken, setHasRefreshToken] = useState(false);
  const [inProgress, setInProgress] = useState(true);

  const setTeamList = useSetAtom(teamListAtom);
  const setUserLeaveDetails = useSetAtom(userLeaveDetailsAtom);
  const setGroup = useSetAtom(groupAtom);
  const setSelectedTeamForRequest = useSetAtom(selectedTeamForRequestsAtom);
  const setSelectedTeam = useSetAtom(selectedTeamAtom);
  const setUnassignedList = useSetAtom(unassignedListAtom);
  const setSelectedTeamForHistory = useSetAtom(
    selectedTeamForMembersRequestsAtom
  );
  const setSelectedMember = useSetAtom(selectedMemberAtom);
  const setSelectedStatus = useSetAtom(selectedStatusAtom);
  const setSelectedRole = useSetAtom(selectedRoleAtom);
  const setSearchType = useSetAtom(selectedSearchTypeAtom);
  const setStartDateForHistory = useSetAtom(adminHistoryStartDate);
  const setEndDateForHistory = useSetAtom(adminHistoryEndDate);
  const setAdminHistory = useSetAtom(adminHistoryAtom);
  const setTeamBalanceList = useSetAtom(teamBalanceListAtom);
  const setOverTimeList = useSetAtom(overTimeListAtom);
  const setSelectedTeamForOvertime = useSetAtom(selectedTeamForOvertimeAtom);
  const setTeamMembers = useSetAtom(teamMembersAtom);
  const setTenants = useSetAtom(tenantsAtom);
  const setSuperAdmins = useSetAtom(superAdminsAtom);
  const setTeamConfig = useSetAtom(teamConfigAtom);

  const [allDataFetchedIndicator, setAllDataFetchedIndicator] = useAtom(
    apiCallDataFetchedAtom
  );
  const [isLoading, setIsLoading] = useAtom(apiCallAtom);
  const [tenantConfig, setTenantConfig] = useAtom(tenantConfigAtom);

  const authUser = useAtomValue(authUserAtom);
  const multiGroupSelected = useAtomValue(multiGroupSelectedAtom);

  const stringifyGroup = authUser?.signInUserSession
    ? authUser?.signInUserSession?.idToken?.payload?.group
    : authUser?.idToken?.payload?.group;

  const parsedSelectedGroup = JSON.parse(localStorage.getItem("selectedGroup"));

  try {
    if (stringifyGroup) {
      setGroup(lodash.camelCase(parsedSelectedGroup));
    }
  } catch (error) {
    console.error(error);
  }

  const bootstrap = async () => {
    setInProgress(true);
    try {
      const { tenantConfig, teamConfig, userDetails, teams } = await apiCalls(
        parsedSelectedGroup,
        authUser
      );
      setTenantConfig(tenantConfig?.data?.Items);
      setTeamConfig(teamConfig?.data?.Items[0]);
      setHasRefreshToken(userDetails?.data?.Items[0]?.hasRefreshToken);
      setUserLeaveDetails(userDetails?.data?.Items[0]?.leaveBalance);
      setTeamList(
        teams?.data?.Items[0]?.teamList ? teams?.data?.Items[0]?.teamList : []
      );
    } catch (error) {
      spawnErrorToast("Error while fetching data");
    } finally {
      setInProgress(false);
    }
  };

  const handleBootStrapSelectedKeys = () => {
    if (
      PERMISSIONS.view.menu.unassignedUserHome.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("help"));
      setSelectedKey("help");
    } else if (
      PERMISSIONS.view.menu.calenderItemAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("leaveCalendar"));
      setSelectedKey("leaveCalendar");
    } else if (
      PERMISSIONS.view.menu.applyItemAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("apply"));
      setSelectedKey("apply");
    } else if (
      PERMISSIONS.view.menu.ownLeaveRequestAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("request"));
      setSelectedKey("request");
    } else if (
      PERMISSIONS.view.menu.approveLeaveRequestAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("approval"));
      setSelectedKey("approval");
    } else if (
      PERMISSIONS.view.menu.approveLeaveRequestAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("members"));
      setSelectedKey("members");
    } else if (
      PERMISSIONS.view.menu.entitlementsAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("entitlement"));
      setSelectedKey("entitlement");
    } else if (
      PERMISSIONS.view.menu.administrationAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("administration"));
      setSelectedKey("administration");
    } else if (
      PERMISSIONS.view.menu.settingsTabAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("settings"));
      setSelectedKey("settings");
    } else if (
      PERMISSIONS.view.menu.reportAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("reports"));
      setSelectedKey("reports");
    } else if (
      PERMISSIONS.view.menu.tenantsListAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("tenantsList"));
      setSelectedKey("tenantsList");
    } else if (
      PERMISSIONS.view.menu.superAdminsListAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("superAdmins"));
      setSelectedKey("superAdmins");
    }
  };

  useEffect(() => {
    if (JSON.parse(stringifyGroup).length === 1 && parsedSelectedGroup) {
      bootstrap();
      if (!tabInfo) {
        handleBootStrapSelectedKeys();
      }
    }
  }, [tabInfo]);

  useEffect(() => {
    if (JSON.parse(stringifyGroup).length > 1 && parsedSelectedGroup) {
      bootstrap();
      handleBootStrapSelectedKeys();
    }
  }, [multiGroupSelected]);

  const handleSelectedKey = (key) => {
    localStorage.setItem("selectedTabInfo", JSON.stringify(key));
    setSelectedKey(key);
    setCollapsed(!collapsed);
    setSelectedTeam("invalid");
    setSelectedTeamForRequest("invalid");
    setUnassignedList([]);
    setSelectedTeamForHistory("invalid");
    setSelectedMember("invalid");
    setSelectedStatus("invalid");
    setSelectedRole("invalid");
    setSearchType("invalid");
    setSelectedTeamForOvertime("invalid");
    setStartDateForHistory(null);
    setEndDateForHistory(null);
    setAdminHistory([]);
    setTeamBalanceList([]);
    setOverTimeList([]);
    setTeamMembers([]);
    setTenants([]);
    setSuperAdmins([]);
    setAllDataFetchedIndicator({
      ...allDataFetchedIndicator,
      isAllDataFetched: true,
    });
    setIsLoading({ ...isLoading, inProgress: false });
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const getMenuItems = () => {
    const menuItems = [];

    if (
      PERMISSIONS.view.menu.unassignedUserHome.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "help",
        icon: <CmlIcon _className={"menu-icon"} iconName={"help"} />,
        label: <div style={{ fontSize: "16px" }}>Help</div>,
        style: {
          backgroundColor: "transparent",
          color:
            selectedKey === "help" ? currentTheme.text : currentTheme.menuItem,
          marginBottom: "5px",
          marginTop: "25px",
          width: "100%",
        },
      });
    }
    if (
      PERMISSIONS.view.menu.tenantsListAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "tenantsList",
        icon: <CmlIcon _className={"menu-icon"} iconName={"apartment"} />,
        label: <div style={{ fontSize: "16px" }}>Tenants List</div>,
        style: {
          backgroundColor: "transparent",
          color:
            selectedKey === "tenantsList"
              ? currentTheme.text
              : currentTheme.menuItem,
          marginBottom: "5px",
          marginTop: "25px",
          width: "100%",
        },
      });
    }
    if (
      PERMISSIONS.view.menu.superAdminsListAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "superAdmins",
        icon: <CmlIcon _className={"menu-icon"} iconName={"groups"} />,
        label: <div style={{ fontSize: "16px" }}>Super admins</div>,
        style: {
          backgroundColor: "transparent",
          color:
            selectedKey === "superAdmins"
              ? currentTheme.text
              : currentTheme.menuItem,
          marginBottom: "5px",
          width: "100%",
        },
      });
    }
    if (
      PERMISSIONS.view.menu.calenderItemAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "leaveCalendar",
        icon: <CmlIcon _className={"menu-icon"} iconName={"calendar_month"} />,
        label: <div style={{ fontSize: "16px" }}>Leave Calendar</div>,
        style: {
          backgroundColor: "transparent",
          color:
            selectedKey === "leaveCalendar"
              ? currentTheme.text
              : currentTheme.menuItem,
          marginBottom: "5px",
          marginTop: "25px",
          width: "100%",
        },
      });
    }
    if (
      PERMISSIONS.view.menu.applyItemAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "apply",
        icon: <CmlIcon _className={"menu-icon"} iconName={"edit_square"} />,
        label: <div style={{ fontSize: "16px" }}>Apply</div>,
        style: {
          backgroundColor: "transparent",
          color:
            selectedKey === "apply" ? currentTheme.text : currentTheme.menuItem,
          marginBottom: "5px",
          width: "100%",
        },
      });
    }
    if (
      PERMISSIONS.view.menu.ownLeaveRequestAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "request",
        icon: <CmlIcon _className={"menu-icon"} iconName={"text_snippet"} />,
        label: <div style={{ fontSize: "16px" }}>Request</div>,
        style: {
          backgroundColor: "transparent",
          color:
            selectedKey === "request"
              ? currentTheme.text
              : currentTheme.menuItem,
          marginBottom: "5px",
          width: "100%",
        },
      });
    }
    if (
      PERMISSIONS.view.menu.approveLeaveRequestAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "approval",
        icon: <CmlIcon _className={"menu-icon"} iconName={"approval"} />,
        label: <div style={{ fontSize: "16px" }}>Approval</div>,
        style: {
          backgroundColor: "transparent",
          color:
            selectedKey === "approval"
              ? currentTheme.text
              : currentTheme.menuItem,
          marginBottom: "5px",
          width: "100%",
        },
      });
    }
    if (
      PERMISSIONS.view.menu.teamMembersTabAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "members",
        icon: <CmlIcon _className={"menu-icon"} iconName={"groups"} />,
        label: <div style={{ fontSize: "16px" }}>Team Members</div>,
        style: {
          backgroundColor: "transparent",
          color:
            selectedKey === "members"
              ? currentTheme.text
              : currentTheme.menuItem,
          marginBottom: "5px",
          width: "100%",
        },
      });
    }
    if (
      PERMISSIONS.view.menu.entitlementsAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "entitlement",
        icon: (
          <CmlIcon _className={"menu-icon"} iconName={"bar_chart_4_bars"} />
        ),
        label: <div style={{ fontSize: "16px" }}>Entitlement</div>,
        style: {
          backgroundColor: "transparent",
          color:
            selectedKey === "entitlement"
              ? currentTheme.text
              : currentTheme.menuItem,
          marginBottom: "5px",
          width: "100%",
        },
      });
    }
    if (
      PERMISSIONS.view.menu.administrationAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      ) ||
      PERMISSIONS.view.menu.settingsTabAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        type: "divider",
      });
    }
    if (
      PERMISSIONS.view.menu.settingsTabAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "settings",
        icon: <CmlIcon _className={"menu-icon"} iconName={"settings"} />,
        label: <div style={{ fontSize: "16px" }}>Settings</div>,
        style: {
          backgroundColor: "transparent",
          color:
            selectedKey === "settings"
              ? currentTheme.text
              : currentTheme.menuItem,
          marginBottom: "5px",
          width: "100%",
        },
      });
    }
    if (
      PERMISSIONS.view.menu.administrationAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "administration",
        icon: <CmlIcon _className={"menu-icon"} iconName={"settings"} />,
        label: <div style={{ fontSize: "16px" }}>Administration</div>,
        style: {
          backgroundColor: "transparent",
          color:
            selectedKey === "administration"
              ? currentTheme.text
              : currentTheme.menuItem,
          marginBottom: "5px",
          width: "100%",
        },
      });
    }
    if (
      PERMISSIONS.view.menu.reportAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "reports",
        icon: <CmlIcon _className={"menu-icon"} iconName={"monitoring"} />,
        label: <div style={{ fontSize: "16px" }}>Reports</div>,
        style: {
          backgroundColor: "transparent",
          color:
            selectedKey === "reports"
              ? currentTheme.text
              : currentTheme.menuItem,
          marginBottom: "5px",
          width: "100%",
        },
      });
    }
    return menuItems;
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Layout
        style={{
          background: currentTheme.background,
        }}
      >
        <Header
          style={{
            padding: 0,
            backgroundColor: "rgb(16, 63, 72)",
          }}
        >
          <HeaderWrapper>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "8px",
                  minHeight: "50px",
                  width: "100px",
                  marginLeft: "15px",
                }}
              >
                <img
                  style={{
                    color: "black",
                  }}
                  src={appLogo}
                  alt="appLogo"
                  width={100}
                  height={40}
                  referrerPolicy="no-referrer"
                />
              </div>
            </div>
            <LogoutButtonWrapper>
              <MobileNavDropdown
                role={parsedSelectedGroup}
                user={
                  authUser?.attributes && authUser?.attributes?.name
                    ? authUser?.attributes?.name
                    : authUser?.attributes?.email
                    ? authUser?.attributes?.email
                    : authUser?.idToken?.payload?.name
                    ? authUser?.idToken?.payload?.name
                    : authUser?.idToken?.payload?.email
                }
                teamName={
                  authUser?.signInUserSession
                    ? authUser?.signInUserSession?.idToken?.payload?.teamName
                    : authUser?.idToken?.payload?.teamName
                }
                tenantDomain={
                  authUser?.signInUserSession
                    ? authUser?.signInUserSession?.idToken?.payload
                        ?.tenantDomain
                    : authUser?.idToken?.payload?.tenantDomain
                }
                imageSource={
                  authUser?.attributes
                    ? authUser?.attributes?.picture
                    : authUser?.idToken?.payload?.picture
                }
              />
            </LogoutButtonWrapper>
          </HeaderWrapper>
        </Header>
        <Layout
          style={{
            background: currentTheme.background,
          }}
        >
          <div
            style={{
              background: currentTheme.background,
            }}
          >
            <Button
              type="text"
              icon={<MenuUnfoldOutlined />}
              onClick={toggleCollapsed}
              style={{
                fontSize: "16px",
                color: currentTheme.text,
                width: 64,
                height: 35,
              }}
            />
          </div>
          <Drawer
            className="menu-drawer"
            title={<div style={{ color: currentTheme.text }}>Menu</div>}
            placement="left"
            onClose={toggleCollapsed}
            open={!collapsed}
            style={{
              background: currentTheme.primary,
              color: currentTheme.text,
            }}
          >
            <Menu
              selectedKeys={[selectedKey]}
              mode="inline"
              theme="dark"
              onSelect={({ key }) => handleSelectedKey(key)}
              style={{
                backgroundColor: `${currentTheme.primary}`,
              }}
              items={!collapsed ? getMenuItems() : []}
            ></Menu>
          </Drawer>
          <Content
            style={{
              padding: 15,
              background: currentTheme.background,
              overflow: "auto",
            }}
          >
            {renderContent(
              selectedKey,
              inProgress,
              hasRefreshToken,
              tenantConfig,
              parsedSelectedGroup
            )}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MobileViewDashboard;
