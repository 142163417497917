import React from "react";
import CmlIcon from "../icon/CmlIcon";
import { NoResultContainer, Title } from "./Styled-NoResultFound";

export const NoResultFound = () => {
  return (
    <NoResultContainer>
      <CmlIcon _className={"no-data-icon"} iconName={"app_badging"} />
      <Title>No results found</Title>
    </NoResultContainer>
  );
};
