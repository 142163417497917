import lodash from "lodash";

const updaterSingleYear = (
  durationBreakDown,
  userLeaveBalance,
  configFlag,
  setUserLeaveDetails
) => {
  for (const key in durationBreakDown) {
    if (
      userLeaveBalance?.firstConfig?.hasOwnProperty(key) &&
      configFlag === "first"
    ) {
      userLeaveBalance.firstConfig[key] += parseInt(durationBreakDown[key]);
    }
    if (
      userLeaveBalance?.secondConfig?.hasOwnProperty(key) &&
      configFlag === "second"
    ) {
      userLeaveBalance.secondConfig[key] += parseInt(durationBreakDown[key]);
    }
  }
  setUserLeaveDetails(userLeaveBalance);
};

const updaterCrossYear = (
  durationBreakDown,
  userLeaveBalance,
  setUserLeaveDetails
) => {
  const keys = Object.keys(durationBreakDown);
  keys.forEach((key) => {
    if (key === "durationBreakDownFirst") {
      updaterSingleYear(
        durationBreakDown.durationBreakDownFirst,
        userLeaveBalance,
        "first",
        setUserLeaveDetails
      );
    }
    if (key === "durationBreakDownSecond") {
      updaterSingleYear(
        durationBreakDown.durationBreakDownFirst,
        userLeaveBalance,
        "second",
        setUserLeaveDetails
      );
    }
  });
};

export const updateBalanceUponCancelHandler = (
  leaveRequest,
  userLeaveDetails,
  setUserLeaveDetails,
  setShowModal
) => {
  const userLeaveBalance = lodash.clone(userLeaveDetails);
  if (leaveRequest?.details?.configFlag === "overlap") {
    updaterCrossYear(
      leaveRequest?.details?.durationBreakDownOverlap,
      userLeaveBalance,
      setUserLeaveDetails
    );
  } else if (leaveRequest?.details?.configFlag === "first") {
    updaterSingleYear(
      leaveRequest?.details?.durationBreakDownFirst,
      userLeaveBalance,
      leaveRequest?.details?.configFlag,
      setUserLeaveDetails
    );
  } else {
    updaterSingleYear(
      leaveRequest?.details?.durationBreakDownSecond,
      userLeaveBalance,
      leaveRequest?.details?.configFlag,
      setUserLeaveDetails
    );
  }
  setShowModal(false);
};
