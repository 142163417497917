import {
  spawnErrorToast,
  spawnSuccessToast,
} from "../../../../util/toast/spawnToast";

export const assignAdminHandler = async ({
  authUser,
  member,
  setAdmin,
  setMembers,
  members,
  handleClose,
  setIsLoading,
}) => {
  setIsLoading(true);
  try {
    const payload = {
      adminEmail: member.email,
    };
    await setAdmin(authUser, payload);
    setMembers(
      members.map((member) => {
        if (member.email === payload.adminEmail) {
          return {
            ...member,
            group: ["tenantAdmin"],
          };
        }
        return member;
      })
    );
    spawnSuccessToast("Tenant admin assigned successfully");
    handleClose();
  } catch (error) {
    spawnErrorToast("Something went wrong while assigning tenant admin");
  } finally {
    setIsLoading(false);
  }
};
