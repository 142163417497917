import React from "react";
import { Col, Row } from "antd";

import StartYear from "./components/startYear/StartYear";
import TenantDomain from "./components/tenantDomain/TenantDomain";
import LeaveAdjustmentType from "./components/leaveAdjustmentType/LeaveAdjustmentType";
import EncashmentMaxThreshold from "./components/encashmentMaxThreshold/EncashmentMaxThreshold";
import EmailList from "./components/emailList/EmailList";
import Timezone from "./components/timezone/Timezone";
import WeekEnd from "./components/weekend/WeekEnd";
import CalcOvertime from "./components/overtimeSettings/calcOvertime";

const General = ({
  isEdit,
  isNewConfig,
  onDisplayTenantConfig,
  setOnDisplayTenantConfig,
}) => {
  return (
    <Row>
      <Col sm={24} md={18} lg={13}>
        <div className="cml-card">
          <Row>
            <Col sm={24} md={7}>
              <StartYear
                  isEdit={isEdit}
                  isNewConfig={isNewConfig}
                  onDisplayTenantConfig={onDisplayTenantConfig}
                  setOnDisplayTenantConfig={setOnDisplayTenantConfig}
              />
            </Col>
            <Col sm={24} md={8}>
              <div className="ml-30">
                <TenantDomain
                    isEdit={isEdit}
                    isNewConfig={isNewConfig}
                    onDisplayTenantConfig={onDisplayTenantConfig}
                    setOnDisplayTenantConfig={setOnDisplayTenantConfig}
                />
              </div>
            </Col>
            <Col sm={24} md={7}>
              <div className="ml-30">
                <LeaveAdjustmentType
                    isEdit={isEdit}
                    isNewConfig={isNewConfig}
                    onDisplayTenantConfig={onDisplayTenantConfig}
                    setOnDisplayTenantConfig={setOnDisplayTenantConfig}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-20">
            <Col sm={24} md={15}>
              <Timezone
                  isEdit={isEdit}
                  isNewConfig={isNewConfig}
                  onDisplayTenantConfig={onDisplayTenantConfig}
                  setOnDisplayTenantConfig={setOnDisplayTenantConfig}
              />
            </Col>
            <Col sm={24} md={7}>
              <div className="ml-30">
                <EncashmentMaxThreshold
                    isEdit={isEdit}
                    isNewConfig={isNewConfig}
                    onDisplayTenantConfig={onDisplayTenantConfig}
                    setOnDisplayTenantConfig={setOnDisplayTenantConfig}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-20">
            <Col sm={24} md={22}>
              <EmailList
                  isEdit={isEdit}
                  isNewConfig={isNewConfig}
                  onDisplayTenantConfig={onDisplayTenantConfig}
                  setOnDisplayTenantConfig={setOnDisplayTenantConfig}
              />
            </Col>
          </Row>
          <div className="mt-30">
            <WeekEnd
                isEdit={isEdit}
                isNewConfig={isNewConfig}
                onDisplayTenantConfig={onDisplayTenantConfig}
                setOnDisplayTenantConfig={setOnDisplayTenantConfig}
            />
          </div>
          <div className="mt-30">
            <CalcOvertime
                isAllowedToEdit={isEdit}
                isNewConfig={isNewConfig}
                onDisplayTenantConfig={onDisplayTenantConfig}
                setOnDisplayTenantConfig={setOnDisplayTenantConfig}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default General;
