export const tableActions = {
  approve: {
    status: "approved",
    buttonText: "Approve",
    buttonBgColor: "#198754",
  },
  modify: {
    status: "modification",
    buttonText: "Modify",
    buttonBgColor: "#0275d8",
    modalComment: true,
  },
  reject: {
    status: "rejected",
    buttonText: "Reject",
    buttonBgColor: "#F44336",
  },
};
