import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "jotai";

const root = ReactDOM.createRoot(document.getElementById("root"));
const currentURL = String(window.location.href || "");

root.render(
  <Provider>
    <App currentURL={currentURL} />
  </Provider>
);

reportWebVitals();
