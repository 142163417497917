import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import { Select } from "antd";

import {
  adminHistoryEndDate,
  adminHistoryStartDate,
  continueTokenAtom,
  memberListAtom,
  modalErrorMessageAtom,
  selectedMemberAtom,
  selectedRoleAtom,
  selectedSearchTypeAtom,
  selectedStatusAtom,
  selectedTeamForMembersRequestsAtom,
  teamListAtom,
} from "../../../../../../../../contexts/generalStore/store";
import {
  IconWrapper,
  SelectTitleWrapper,
  SelectWrapper,
  UnderLineText,
} from "../../history-styled";

const TeamInput = () => {
  const [selectedTeamForMemberReq, setSelectedTeamForMemberReq] = useAtom(
    selectedTeamForMembersRequestsAtom
  );

  const setValidateMessage = useSetAtom(modalErrorMessageAtom);
  const setContinueToken = useSetAtom(continueTokenAtom);
  const setMemberList = useSetAtom(memberListAtom);
  const setStartDate = useSetAtom(adminHistoryStartDate);
  const setEndDate = useSetAtom(adminHistoryEndDate);
  const setSelectedMember = useSetAtom(selectedMemberAtom);
  const setRole = useSetAtom(selectedRoleAtom);
  const setStatus = useSetAtom(selectedStatusAtom);
  const teamList = useAtomValue(teamListAtom);
  const searchType = useAtomValue(selectedSearchTypeAtom);

  const setDefault = () => {
    setValidateMessage("");
    setContinueToken("");
    setMemberList([]);
    setStartDate();
    setEndDate();
    setStatus("invalid");
    setSelectedMember("invalid");
    setRole("invalid");
  };

  const handleOnChange = (value) => {
    setSelectedTeamForMemberReq(value);
    setDefault();
  };

  const handleReset = () => {
    setSelectedTeamForMemberReq("invalid");
    setDefault();
  };

  return (
    <SelectTitleWrapper>
      <UnderLineText>Select Team</UnderLineText>
      <SelectWrapper>
        <Select
          aria-label="Default select example"
          className="customSelect"
          style={{
            width: "100%",
          }}
          defaultValue={"invalid"}
          onChange={handleOnChange}
          value={selectedTeamForMemberReq}
          disabled={searchType !== "invalid" && searchType ? false : true}
        >
          <Select.Option value="invalid">-- Select a Team --</Select.Option>
          {typeof teamList === "object" &&
            teamList.map((team, index) => {
              return (
                <Select.Option key={index} value={team}>
                  {team}
                </Select.Option>
              );
            })}
        </Select>
        <IconWrapper>
          <CloseCircleFilled
            className={
              selectedTeamForMemberReq && selectedTeamForMemberReq !== "invalid"
                ? "icon"
                : "icon-disabled"
            }
            onClick={() => handleReset()}
          />
        </IconWrapper>
      </SelectWrapper>
    </SelectTitleWrapper>
  );
};

export default TeamInput;
