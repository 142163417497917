import React, { useEffect, useState } from "react";
import lodash from "lodash";
import { useAtom, useAtomValue } from "jotai";

import {
  groupAtom,
  userLeaveDetailsAtom,
} from "../../../../contexts/generalStore/store";
import CancelModal from "../../../modals/requestCancelModal/CancelModal";
import EditModal from "../../../modals/requestEditModal/EditModal";
import FilterRequest from "../filter/filterRequest/FilterRequest";
import OwnOverTimeTable from "../overtime/table/ownOvertimeListTable/OwnOverTimeListTable";
import OnBehalfRequests from "../onBehalf/OnBehalfRequest";
import { PERMISSIONS } from "../../../../util/constants/permission";
import { Tab } from "../common/tabs/Tab";

import OwnLeaveRequestTable from "./tables/OwnLeaveRequestTable";
import CmlIcon from "../common/icon/CmlIcon";

const OwnLeaveRequest = () => {
  const [userLeaveData, setUserLeaveData] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState();
  const [startDateTime, setStartDateTime] = useState();
  const [endDateTime, setEndDateTime] = useState();
  const [inProgressCancel, setInProgressCancel] = useState(false);
  const [inProgressEdit, setInProgressEdit] = useState(false);
  const [updateLeaveArray, setUpdateLeaveArray] = useState(false);
  const [selectedTab, setSelectedTab] = useState("ownLeaveRequest");

  const [takenLeaveDates] = useState([]);
  const [datesApproved] = useState([]);
  const [datesPending] = useState([]);
  const [datesModify] = useState([]);

  const [userLeaveDetails, setUserLeaveDetails] = useAtom(userLeaveDetailsAtom);
  const [isSearchedClicked, setIsSearchedClicked] = useState(false);
  const group = useAtomValue(groupAtom);

  const [userChangedDate, setUserChangedDate] = useState(false);

  const updateLeaveRequest = (duration, leaveRequest) => {
    const leavesCopy = [...userLeaveData];
    const leavesCopyLength = leavesCopy.length;
    for (let index = 0; index < leavesCopyLength; index++) {
      if (leavesCopy[index].reqId === leaveRequest.reqId) {
        const start = new Date(leaveRequest.startDate);
        const end = new Date(leaveRequest.endDate);
        leavesCopy[index].status = "pending";
        leavesCopy[index].details.duration = duration;
        leavesCopy[index].details.firstConfigDuration =
          leaveRequest.firstConfigDuration;
        leavesCopy[index].details.secondConfigDuration =
          leaveRequest.secondConfigDuration;
        leavesCopy[index].details.leaveType = leaveRequest.leaveType;
        leavesCopy[index].details.startDate = start.toISOString();
        leavesCopy[index].details.endDate = end.toISOString();
        leavesCopy[index].details.description = leaveRequest.description;
      }
    }
    setUserLeaveData(leavesCopy);
  };
  const updateBalanceUponOvertime = (configFlag) => {
    let leaveBalance = lodash.clone(userLeaveDetails);
    if (configFlag === "first") {
      leaveBalance.firstConfig[`casualLeave`] -= 1;
    } else {
      leaveBalance.secondConfig[`casualLeave`] -= 1;
    }
    setUserLeaveDetails(leaveBalance);
  };
  const onSelectedTab = (option) => {
    if (option !== "ownLeaveRequest") {
      setUserLeaveData([]);
    }
    setSelectedTab(option);
  };

  const getTabs = () => {
    let tabs = [
      {
        title: "Leave",
        label: "ownLeaveRequest",
        icon: <CmlIcon _className={"tab-icon"} iconName={"potted_plant"} />,
      },
      {
        title: "Overtime",
        label: "ownOverTimeRequest",
        icon: <CmlIcon _className={"tab-icon"} iconName={"schedule"} />,
      },
    ];

    if (
      group.includes(PERMISSIONS.view.components.onBehalfTableAllowedGroups)
    ) {
      tabs.push({
        title: "On Behalf",
        label: "ownOnBehalfRequest",
        icon: <CmlIcon _className={"tab-icon"} iconName={"physical_therapy"} />,
      });
    }
    return tabs;
  };
  const handleSearchButtonClicked = (startDateTime, endDateTime) => {
    setStartDateTime(startDateTime);
    setEndDateTime(endDateTime);
    setIsSearchedClicked(true);
  };
  const handleDefaultFilterValue = ({ startDateTime, endDateTime }) => {
    setStartDateTime(startDateTime);
    setEndDateTime(endDateTime);
  };
  const handleEdit = (leaveRequest, event) => {
    setSelectedLeaveRequest(leaveRequest);
    setShowEditModal(true);
    event.stopPropagation();
  };
  const handleCancel = (leaveRequest, event) => {
    setSelectedLeaveRequest(leaveRequest);
    setShowCancelModal(true);
    event.stopPropagation();
  };
  const actions = {
    handleEdit: handleEdit,
    handleCancel: handleCancel,
  };
  return (
    <div>
      <Tab onSelectedTab={onSelectedTab} tabs={getTabs()} />
      {selectedTab === "ownLeaveRequest" && (
        <>
          <div className="mt-30">
            <FilterRequest
              prev={startDateTime}
              next={endDateTime}
              userChangedDate={userChangedDate}
              setUserChangedDate={setUserChangedDate}
              setDefaultValue={handleDefaultFilterValue}
              searchButtonClicked={handleSearchButtonClicked}
              selectedTab={selectedTab}
            />
          </div>
          <div className="mt-30">
            <OwnLeaveRequestTable
              isSearchedClicked={isSearchedClicked}
              startDate={startDateTime}
              endDate={endDateTime}
              actions={actions}
              setIsSearchedClicked={setIsSearchedClicked}
              userLeaveData={userLeaveData}
              setUserLeaveData={setUserLeaveData}
            />
          </div>
        </>
      )}
      {selectedTab === "ownOverTimeRequest" && (
        <div className="mt-30">
          <OwnOverTimeTable
            updateBalanceUponOvertime={updateBalanceUponOvertime}
          />
        </div>
      )}
      {selectedTab === "ownOnBehalfRequest" && (
        <div className="mt-30">
          <OnBehalfRequests
            isSearchedClicked={isSearchedClicked}
            setIsSearchedClicked={setIsSearchedClicked}
            startDate={startDateTime}
            endDate={endDateTime}
          />
        </div>
      )}
      <CancelModal
        open={showCancelModal}
        setOpen={setShowCancelModal}
        confirmLoading={inProgressCancel}
        setConfirmLoading={setInProgressCancel}
        selectedLeaveRequest={selectedLeaveRequest}
        userLeaveData={userLeaveData}
        setUserLeaveData={setUserLeaveData}
      />

      {showEditModal && (
        <EditModal
          open={showEditModal}
          setOpen={setShowEditModal}
          confirmLoading={inProgressEdit}
          setConfirmLoading={setInProgressEdit}
          leaveRequest={selectedLeaveRequest}
          userLeaveData={userLeaveData}
          setUserLeaveData={setUserLeaveData}
          updateLeaveRequest={updateLeaveRequest}
          fromOnBehalf={false}
          datesApproved={datesApproved}
          datesModify={datesModify}
          datesPending={datesPending}
          takenLeaveDates={takenLeaveDates}
          updateLeaveArray={updateLeaveArray}
          setUpdateLeaveArray={setUpdateLeaveArray}
        />
      )}
    </div>
  );
};

export default OwnLeaveRequest;
