import React, { useState, useEffect } from "react";
import lodash from "lodash";
import TableAction from "../actions/TableAction";
import { getDateInTextFormat } from "../../../util/formatter/getDateInTextFormat";
import LeaveStatusTag from "../../tags/LeaveStatusTag";
import LeaveDetails from "../../dashboard/components/drawer/leaveDetails/LeaveDetails";
import DynamicTable from "../dynamicTable/DynamicTable";
import DataDivNonTimeFilter from "../../dashboard/components/skeleton/common/DataDivNonTimeFilter";
import { Empty, Input } from "antd";
import { callRequestApprovalApi } from "../../../util/apiCalls/apiCalls";
import { useAtomValue } from "jotai";
import { authUserAtom, groupAtom } from "../../../contexts/generalStore/store";
import { spawnErrorToast } from "../../../util/toast/spawnToast";
import DataDivTimeFilter from "../../dashboard/components/skeleton/common/DataDivTimeFilter";
import { NoResultFound } from "../../dashboard/components/common/noResultFound/NoResultFound";

const LeaveApprovalTable = ({
  requests,
  setRequests,
  actions,
  teamName,
  selectedTab,
}) => {
  const group = useAtomValue(groupAtom);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [inProgress, setInProgress] = useState(
    teamName !== "invalid" && teamName ? true : false
  );
  const [paginationToken, setPaginationToken] = useState("");

  const [isAllRequestFetched, setIsAllRequestFetched] = useState({
    isAllDataFetched: true,
  });

  const callTeamLeaveRequestApi = async (token = "") => {
    try {
      const response = await callRequestApprovalApi(
        group,
        authUserAtom,
        token,
        teamName
      );
      if (response?.data?.Items) {
        setPaginationToken(
          response.data?.ContinuationToken
            ? response.data.ContinuationToken
            : ""
        );
        setIsAllRequestFetched(
          response.data?.ContinuationToken
            ? {
                ...isAllRequestFetched,
                isAllDataFetched: false,
              }
            : {
                ...isAllRequestFetched,
                isAllDataFetched: true,
              }
        );
        return response?.data?.Items;
      }
      return [];
    } catch (error) {
      spawnErrorToast(error?.message);
      return [];
    }
  };

  const loadMoreLeaves = async () => {
    setInProgress(true);

    const leaves = await callTeamLeaveRequestApi(paginationToken);
    leaves.forEach((item, i) => {
      if (item?.creationDate !== requests[i]?.creationDate) {
        setRequests([...requests, ...leaves]);
      }
    });
    setInProgress(false);
  };

  const getLeaveList = async () => {
    setPaginationToken("");
    setInProgress(true);
    const leaves = await callTeamLeaveRequestApi();
    setRequests([...leaves]);
    setInProgress(false);
  };
  useEffect(() => {
    if (selectedTab === "leaveApproval" && teamName !== "invalid") {
      getLeaveList();
    }
    return () => {
      setRequests([]);
    };
  }, [teamName]);

  const statusCell = (cell, row) => {
    return <LeaveStatusTag status={row.status} />;
  };

  const actionCell = (cell, row, rowIndex, colIndex) => {
    return (
      <TableAction actions={actions} row={row} />
    );
  };

  const startDateCell = (cell, row, rowIndex, colIndex) => {
    return <>{getDateInTextFormat(row?.details?.startDate)}</>;
  };
  const endDateCell = (cell, row, rowIndex, colIndex) => {
    return <>{getDateInTextFormat(row?.details?.endDate)}</>;
  };
  const leaveTypeCell = (cell, row, rowIndex, colIndex) => {
    return <>{lodash.startCase(row?.details?.leaveType)}</>;
  };

  const durationCell = (cell, row, rowIndex, colIndex) => {
    return (
      <div>
        {row?.details?.duration}
        {parseInt(row?.details?.duration) === 1 && " Day"}
        {parseInt(row?.details?.duration) > 1 && " Days"}
      </div>
    );
  };
  const extraLeaveCell = (cell, row, rowIndex, colIndex) => {
    const configFlag = row?.details?.configFlag;

    if (configFlag === "first") {
      const extraLeave = row?.details?.durationBreakDownFirst.extraLeave;
      return (
        <div>
          {extraLeave}
          {parseInt(extraLeave) <= 1 && " Day"}
          {parseInt(extraLeave) > 1 && " Days"}
        </div>
      );
    }
    if (configFlag === "second") {
      const extraLeave = row?.details?.durationBreakDownSecond.extraLeave;
      return (
        <div>
          {extraLeave}
          {parseInt(extraLeave) <= 1 && " Day"}
          {parseInt(extraLeave) > 1 && " Days"}
        </div>
      );
    }
    if (configFlag === "overlap") {
      const extraLeaveFirst =
        row?.details?.durationBreakDownOverlap.durationBreakDownFirst
          .extraLeave;
      const extraLeaveSecond =
        row?.details?.durationBreakDownOverlap.durationBreakDownSecond
          .extraLeave;
      const extraLeave = parseInt(extraLeaveFirst) + parseInt(extraLeaveSecond);
      return (
        <div>
          {extraLeave}
          {parseInt(extraLeave) <= 1 && " Day"}
          {parseInt(extraLeave) > 1 && " Days"}
        </div>
      );
    }
  };

  const handleRowClick = (record) => {
    // Handle row click event
    const selectedLeave = {
      start: record.details.startDate,
      end: record.details.endDate,
      duration: record.details.duration,
      description: record.details.description,
      status: record.status,
      leaveType: record.details.leaveType,
      employeeEmail: record.employeeEmail,
    };
    setSelectedRow(selectedLeave);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const columns = [
    {
      dataIndex: "employeeEmail",
      title: "Email",
      width: "350px",
      align: "start",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(getDateInTextFormat(record?.details?.startDate))
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(getDateInTextFormat(record?.details?.endDate))
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          record?.status?.toLowerCase().includes(value.toLowerCase()) ||
          record?.employeeEmail?.toLowerCase().includes(value.toLowerCase()) ||
          record?.details?.leaveType.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      dataIndex: "leaveType",
      title: "Leave Type",
      width: "350px",
      align: "start",
      render: leaveTypeCell,
    },
    {
      dataIndex: "start",
      title: "Start Date",
      width: "350px",
      align: "start",
      render: startDateCell,
    },
    {
      dataIndex: "end",
      title: "End Date",
      width: "350px",
      align: "start",
      render: endDateCell,
    },
    {
      dataIndex: "duration",
      title: "Duration",
      width: "30px",
      align: "center",
      render: durationCell,
    },
    {
      dataIndex: "extraLeave",
      title: "Extra Leave",
      width: "180px",
      align: "center",
      render: extraLeaveCell,
    },
    {
      dataIndex: "status",
      title: "Status",
      width: "250px",
      align: "center",
      render: statusCell,
    },
    {
      title: "Actions",
      width: "180px",
      align: "center",
      render: actionCell,
    },
  ];

  const onLoadMore = () => {
    loadMoreLeaves();
  };

  const tableProps = {
    loading: {
      indicator: <DataDivNonTimeFilter />,
      spinning: inProgress,
      size: "small",
    },
  };

  const handleTableSearch = (value) => {
    setSearchText(value);
  };

  return (
    <>
      <div className="cml-card mt-30">
        {requests.length > 0 ? (
          <>
            <div className="mb-20">
              <Input.Search
                placeholder="Start typing to search"
                onChange={(e) => handleTableSearch(e.target.value)}
                style={{
                  width: window.screen.width > 500 ? "300px" : "100%",
                }}
                enterButton
              />
            </div>
            <DynamicTable
              rowKey="reqId"
              tableProps={tableProps}
              columns={columns}
              data={requests}
              inProgress={inProgress}
              isAllDataFetched={isAllRequestFetched}
              callApiToLoadMore={onLoadMore}
              handleRowClick={handleRowClick}
              scroll={{ x: 768 }}
              size="small"
            />
          </>
        ) : requests.length === 0 && inProgress ? (
          <div style={{ height: "200px" }}>
            <DataDivTimeFilter />
          </div>
        ) : (
          requests.length === 0 && !inProgress && <NoResultFound />
        )}
      </div>
      {selectedRow && (
        <LeaveDetails
          open={drawerVisible}
          onClose={closeDrawer}
          leaveDetails={selectedRow}
        />
      )}
    </>
  );
};

export default LeaveApprovalTable;
