import React, { useContext, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import lodash from "lodash";
import { Modal } from "antd";

import {
  adminHistoryAtom,
  authUserAtom,
  selectedTeamForMembersRequestsAtom,
  showModalsAtom,
} from "../../../../../../../../contexts/generalStore/store";
import { cancelLeaveRequest } from "../../../../../../../../controller/common/cancelLeave";
import { getDateInTextFormat } from "../../../../../../../../util/formatter/getDateInTextFormat";
import { spawnErrorToast, spawnSuccessToast } from "../../../../../../../../util/toast/spawnToast";
import CmlIcon from "../../../../../common/icon/CmlIcon";


const CancelModalAdmin = ({ leaveRequest }) => {
  const [showModal, setShowModal] = useAtom(showModalsAtom);
  const [adminHistory, setAdminHistory] = useAtom(adminHistoryAtom);
  const [isLoading, setIsLoading] = useState(false);
  const authUser = useAtomValue(authUserAtom);
  const selectedTeamForMemberReq = useAtomValue(
    selectedTeamForMembersRequestsAtom
  );

  const handleClose = () => {
    setShowModal({ ...showModal, showCancelModalAdmin: false });
  };

  const removeRequest = () => {
    const newRequestList = adminHistory.filter(function (request) {
      return request.reqId !== leaveRequest?.reqId;
    });
    setAdminHistory(newRequestList);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const payload = {
        startDate: leaveRequest?.details?.startDate,
        creationDate: leaveRequest?.creationDate,
        reqId: leaveRequest?.reqId,
        userType: leaveRequest?.fromWhom,
        employeeEmail: leaveRequest?.employeeEmail,
        teamName: selectedTeamForMemberReq,
      };
      await cancelLeaveRequest(authUser, payload);
      spawnSuccessToast("Leave request cancelled successfully");
      setIsLoading(false);
      removeRequest();
      handleClose();
    } catch (error) {
      setIsLoading(false);
      spawnErrorToast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.response?.data
      );
    }
  };

  return (
    <Modal
      title="Cancel Leave Request"
      open={showModal.showCancelModalAdmin}
      onOk={handleSubmit}
      onCancel={handleClose}
      okText="Submit"
      cancelText="Close"
      okButtonProps={{
        type: "primary",
        icon: <CmlIcon iconName="task_alt" />
      }}
      cancelButtonProps={{
        icon: <CmlIcon iconName="cancel" />
      }}
      confirmLoading={isLoading}
      maskClosable={false}
    >
      <p>
        Are you sure you want to cancel{" "}
        <b>{lodash.startCase(leaveRequest?.details?.leaveType)}</b> from{" "}
        <b>{getDateInTextFormat(leaveRequest?.details?.startDate)}</b> to{" "}
        <b>{getDateInTextFormat(leaveRequest?.details?.endDate)}</b> from{" "}
        <b>{leaveRequest.employeeEmail}</b> ?
      </p>
    </Modal >
  );
};

export default CancelModalAdmin;
