import styled from "@emotion/styled";

const ApplyButton = styled("button")`
  border-radius: 3px;
  border: none;
  padding: 5px 25px;
  cursor: pointer;
  color: white;

  transition: 0.2s;
  &:hover {
    transform: ${(props) => (props.disabled ? `scale(null)` : `scale(1.05)`)};
  }

  background: ${(props) =>
    props.disabled ? props.theme.neutral : props.theme.accent};
  background-size: 300% 300%;
  animation: gradient 10s ease infinite;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
const LoginButton = styled("div")`
  border-radius: 3px;
  border: none;
  padding: 5px 25px;
  cursor: pointer;
  color: ${(props) => props.theme.accent};
  transition: 0.2s;
  font-weight: 600;
  font-size: 1.2rem;
  &:hover {
    transform: scale(1.05);
  }
  animation: gradient 10s ease infinite;
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
const BtnWrapper = styled("div")`
  align-self: end;
  margin-right: 15px;
  margin-top: 10px;
`;
const TabWrapper = styled("div")`
  margin: 20px;
`;
const BodyWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: -16px;
`;
const ErrorParagraph = styled("p")`
  display: flex;
  justify-content: center;
  color: crimson;
`;
const SuccessParagraph = styled("p")`
  display: flex;
  justify-content: center;
  color: crimson;
`;
const Paragraph = styled("p")`
  display: flex;
  justify-content: center;
  color: crimson;
`;
const UserDetailsCard = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => props.theme.primary};
  margin: 20px;
  padding: 5px;
  border-radius: 5px;
`;
const UserLeaveDetailsdiv = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    gap: 0px;
  }
`;
const LeaveType = styled("p")`
  text-align: center;
  margin-top: 20px;
`;
const DropdownLogo = styled("div")`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;
const DropdwonTeamWrapper = styled("div")`
  display: flex;
  gap: 10px;
  flex-direction: row;
`;
const PicLogoWrapper = styled("div")`
  display: flex;
  gap: 0px;
  flex-direction: row;
`;
const TenantLogoWrapper = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const NavBrandWrapper = styled("div")`
  display: flex;
  gap: 10px;
  flex-direction: row;
`;
const CenterWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const DualItemUniformWrapper = styled("div")`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
const Greet = styled("div")`
  color: white;
  font-weight: 600;
`;
const TeamNameWrapper = styled("p")`
  color: white;
`;
const Item = styled("span")`
  font-weight: 500;
  font-size: 18px;
  margin: 5px 0 0 0;
`;

export {
  ApplyButton,
  BtnWrapper,
  TabWrapper,
  BodyWrapper,
  ErrorParagraph,
  SuccessParagraph,
  Paragraph,
  UserDetailsCard,
  UserLeaveDetailsdiv,
  LeaveType,
  Item,
  DropdownLogo,
  Greet,
  DropdwonTeamWrapper,
  CenterWrapper,
  DualItemUniformWrapper,
  NavBrandWrapper,
  PicLogoWrapper,
  TeamNameWrapper,
  TenantLogoWrapper,
  LoginButton,
};
