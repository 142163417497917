import {Badge, Tooltip} from "antd";
import React from "react";

const mainColumns = [
    {
        title: 'Author Email',
        dataIndex: 'authorEmail',
        key: 'authorEmail',
        width: '30%',
        align: "start",
        render: (text, record) => (
            <Tooltip title={record.items[0].authorName}>
                <span style={{ position: 'relative' }}>
                    {text}
                    <Badge
                        size={"small"}
                        color={"#155460"}
                        count={record.items.length}
                        style={{ position: 'absolute', top: '-20px', right: '-15px' }}
                    />
                </span>
            </Tooltip>
        ),
    },
    {
        title: 'Total Requested Hours',
        dataIndex: ['overview', 'totalRequestedOvertimeInHours'],
        key: 'totalRequestedOvertimeInHours',
        align: "center",
    },
    {
        title: 'Total Approved Hours',
        dataIndex: ['overview', 'totalApprovedOvertimeInHours'],
        key: 'totalApprovedOvertimeInHours',
        align: "center",
    },
    {
        title: 'Total Requested Hours In Days',
        dataIndex: ['overview', 'totalRequestedOvertimeInDays'],
        key: 'totalRequestedOvertimeInDays',
        align: "center",
    },
    {
        title: 'Total Approved Hours In Days',
        dataIndex: ['overview', 'totalApprovedOvertimeInDays'],
        key: 'totalApprovedOvertimeInDays',
        align: "center",
    }
];

export default mainColumns;
