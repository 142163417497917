import axios from "axios";

export const post = ({ url, payload, headers }) => {
  return axios.post(url, payload, headers);
};
export const put = ({ url, payload, headers }) => {
  return axios.put(url, payload, headers);
};
export const get = ({ url, headers }) => {
  return axios.get(url, headers);
};
