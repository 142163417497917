import { Button, Form, Input, Modal } from "antd";
import React, { useContext, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import {
  authUserAtom,
  teamListAtom,
} from "../../../../../../../../../contexts/generalStore/store";
import { handleCreateNewTeamRequest } from "../../../../../../../../../core/team/teamCreation/newTeamCreationHandler";
import CmlIcon from "../../../../../../common/icon/CmlIcon";

const AddTeamModal = ({ open, setOpen }) => {
  const [form] = Form.useForm();

  const [teamName, setTeamName] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [teamList, setTeamList] = useAtom(teamListAtom);
  const authUser = useAtomValue(authUserAtom);

  const getStateArguments = () => {
    return {
      teamName: teamName.trim(),
      teamList: teamList,
      authUser: authUser,
      setIsLoading: setConfirmLoading,
      setTeamList: setTeamList,
      handleModalClose: handleCancel,
    };
  };

  const handleSubmit = async () => {
    const args = getStateArguments();
    handleCreateNewTeamRequest(args);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      title="Create Team"
      open={open}
      onCancel={handleCancel}
      okButtonProps={{
        style: { display: "none" },
      }}
      cancelButtonProps={{
        style: { display: "none" },
      }}
      confirmLoading={confirmLoading}
      mask={true}
      maskClosable={false}
    >
      <>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item
            label={<div className="neutral-text mb-5">Team Name:</div>}
            name="teamName"
            rules={[
              { required: true, message: "Please enter team name" },
              {
                max: 10,
                message: "Team name must not be larger than 10 characters",
              },
            ]}
          >
            <Input
              value={teamName}
              onChange={(e) => setTeamName(e.target.value.trim())}
            />
          </Form.Item>
          <Form.Item>
            <div className="right-aligned-buttons mt-20">
              <Button
                type="primary"
                htmlType="submit"
                loading={confirmLoading}
                icon={<CmlIcon iconName="send" />}
              >
                Create
              </Button>
            </div>
          </Form.Item>
        </Form>
      </>
    </Modal>
  );
};

export default AddTeamModal;
