import React, { useState } from "react";
import lodash from "lodash";

import TableAction from "../actions/TableAction";
import { getDateInTextFormat } from "../../../util/formatter/getDateInTextFormat";
import LeaveStatusTag from "../../tags/LeaveStatusTag";
import LeaveDetails from "../../dashboard/components/drawer/leaveDetails/LeaveDetails";
import DynamicTable from "../dynamicTable/DynamicTable";
import DataDivTimeFilter from "../../dashboard/components/skeleton/common/DataDivTimeFilter";
import { NoResultFound } from "../../dashboard/components/common/noResultFound/NoResultFound";

const RequestTable = ({
  userLeaveData,
  actions,
  inProgress,
  loadMoreLeaves,
  isAllRequestFetched,
  searchText,
}) => {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const statusCell = (cell, row) => {
    return <LeaveStatusTag status={row.status} />;
  };

  const actionCell = (cell, row, rowIndex, colIndex) => {
    return (
      <TableAction actions={actions} row={row} />
    );
  };

  const startDateCell = (cell, row, rowIndex, colIndex) => {
    return <>{getDateInTextFormat(row?.details?.startDate)}</>;
  };
  const endDateCell = (cell, row, rowIndex, colIndex) => {
    return <>{getDateInTextFormat(row?.details?.endDate)}</>;
  };
  const leaveTypeCell = (cell, row, rowIndex, colIndex) => {
    return <>{lodash.startCase(row?.details?.leaveType)}</>;
  };

  const durationCell = (cell, row, rowIndex, colIndex) => {
    return (
      <div>
        {row?.details?.duration}
        {parseInt(row?.details?.duration) === 1 && " Day"}
        {parseInt(row?.details?.duration) > 1 && " Days"}
      </div>
    );
  };

  const extraLeaveCell = (cell, row, rowIndex, colIndex) => {
    const configFlag = row?.details?.configFlag;

    if (configFlag === "first") {
      const extraLeave = row?.details?.durationBreakDownFirst.extraLeave;
      return (
        <div>
          {extraLeave}
          {parseInt(extraLeave) <= 1 && " Day"}
          {parseInt(extraLeave) > 1 && " Days"}
        </div>
      );
    }
    if (configFlag === "second") {
      const extraLeave = row?.details?.durationBreakDownSecond.extraLeave;
      return (
        <div>
          {extraLeave}
          {parseInt(extraLeave) <= 1 && " Day"}
          {parseInt(extraLeave) > 1 && " Days"}
        </div>
      );
    }
    if (configFlag === "overlap") {
      const extraLeaveFirst =
        row?.details?.durationBreakDownOverlap.durationBreakDownFirst
          .extraLeave;
      const extraLeaveSecond =
        row?.details?.durationBreakDownOverlap.durationBreakDownSecond
          .extraLeave;
      const extraLeave = parseInt(extraLeaveFirst) + parseInt(extraLeaveSecond);
      return (
        <div>
          {extraLeave}
          {parseInt(extraLeave) <= 1 && " Day"}
          {parseInt(extraLeave) > 1 && " Days"}
        </div>
      );
    }
  };

  const handleRowClick = (record) => {
    const selectedLeave = {
      start: record.details.startDate,
      end: record.details.endDate,
      duration: record.details.duration,
      description: record.details.description,
      status: record.status,
      leaveType: record.details.leaveType,
      employeeEmail: record.employeeEmail,
    };
    setSelectedRow(selectedLeave);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const columns = [
    {
      dataIndex: "leaveType",
      title: "Leave Type",
      width: "350px",
      align: "start",
      render: leaveTypeCell,
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(getDateInTextFormat(record?.details?.startDate))
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(getDateInTextFormat(record?.details?.endDate))
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          record?.status?.toLowerCase().includes(value.toLowerCase()) ||
          record?.employeeEmail?.toLowerCase().includes(value.toLowerCase()) ||
          record?.details?.leaveType.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      dataIndex: "start",
      title: "Start Date",
      width: "350px",
      align: "start",
      render: startDateCell,
    },
    {
      dataIndex: "end",
      title: "End Date",
      width: "350px",
      align: "start",
      render: endDateCell,
    },
    {
      dataIndex: "duration",
      title: "Duration",
      width: "80px",
      align: "center",
      render: durationCell,
    },
    {
      dataIndex: "extraLeave",
      title: "Extra Leave",
      width: "280px",
      align: "center",
      render: extraLeaveCell,
    },
    {
      dataIndex: "status",
      title: "Status",
      width: "250px",
      align: "center",
      render: statusCell,
    },
    {
      title: "Action",
      width: "550px",
      align: "center",
      render: actionCell,
    },
  ];

  const onLoadMore = () => {
    loadMoreLeaves();
  };

  const tableProps = {
    loading: {
      indicator: <DataDivTimeFilter />,
      spinning: inProgress,
      size: "small",
    },
  };

  return (
    <div>
      {userLeaveData.length > 0 && !inProgress ? (
        <DynamicTable
          rowKey="reqId"
          tableProps={tableProps}
          columns={columns}
          data={userLeaveData}
          inProgress={inProgress}
          isAllDataFetched={isAllRequestFetched}
          callApiToLoadMore={onLoadMore}
          handleRowClick={handleRowClick}
          scroll={{ x: 768 }}
          size="small"
        />
      ) : userLeaveData.length === 0 && inProgress ? (
        <div style={{ height: "200px" }}>
          <DataDivTimeFilter />
        </div>
      ) : (
        userLeaveData.length === 0 && !inProgress && <NoResultFound />
      )}

      {selectedRow && (
        <LeaveDetails
          open={drawerVisible}
          onClose={closeDrawer}
          leaveDetails={selectedRow}
        />
      )}
    </div>
  );
};

export default RequestTable;
