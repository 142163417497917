const getEndpoint = (endpoint) => {
  return JSON.parse(localStorage.getItem("endpoints"))[endpoint];
};

export const endpoints = new Proxy(
  {
    TEAM_LIST: () => getEndpoint("teamList"),
    UNASSIGNED_EMPLOYEE_LIST: () => getEndpoint("unassignedEmployeeList"),
    TENANT_INVITE_ENDPOINT: () => getEndpoint("tenantInvite"),
    TENANT_ENDPOINT: () => getEndpoint("tenantList"),
    TEAM_MEMBER_LIST: () => getEndpoint("teamMemberList"),
    TEAM_MEMBER_APPLICATION: () => getEndpoint("teamLeadApplication"),
    TEAM_USER_APPLICATION: () => getEndpoint("employeeApplication"),
    TENANT_CONFIG: () => getEndpoint("tenantConfig"),
    EMPLOYEE_LEAVE_REQUEST: () => getEndpoint("employeeApplication"),
    EMPLOYEE_DETAILS: () => getEndpoint("employeeDetails"),
    NOTIFICATION_CALENDAR: () => getEndpoint("notificationCalendar"),
    NOTIFICATION_MAIL: () => getEndpoint("notificationMail"),
    ONBEHALF_LEAVE_REQUEST: () => getEndpoint("onbehalfLeaveRequest"),
    PUBLIC_SHARE_ID: () => getEndpoint("publicShareID"),
    PUBLIC_SHARE: () => getEndpoint("publicShare"),
    SNAP_SHOT_LINKS: () => getEndpoint("snapShotActiveLinks"),
    SETTINGS_USER_PROFILE: () => getEndpoint("settingsUserProfile"),
    SETTINGS_MEMBER_CONFIG: () => getEndpoint("settingsMemberConfig"),
    SETTINGS_TENANT_CONFIG: () => getEndpoint("settingsTenantConfig"),
    CANCEL_LEAVE_REQUEST: () => getEndpoint("cancelLeaveRequest"),
    LEAVE_HISTORY: () => getEndpoint("leaveHistory"),
    TEAM_CONFIG: () => getEndpoint("teamConfig"),
    REPORT_USERS_INFO: () => getEndpoint("reportUsersInfo"),
    GET_TENANT_ALL_USERS: () => getEndpoint("userListOfATenant"),
    SUPER_ADMIN_LIST: () => getEndpoint("superAdmins"),
    TENANT_TEAM_SUPER_ADMIN: () => getEndpoint("superAdminTenantTeams"),
    SUPER_ADMIN_ASSIGN: () => getEndpoint("superAdminAssign"),
    SUPER_ADMIN_UNASSIGN: () => getEndpoint("superAdminUnassign"),
    OVERTIME_APPLICATION: () => getEndpoint("userOvertimeApplication"),
    TEAM_OVERTIME_APPLICATION: () => getEndpoint("teamOvertimeApplication"),
  },
  {
    get: (target, key) => {
      if (typeof target[key] === "function") return target[key]();
      return target[key];
    },
  }
);
