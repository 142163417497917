import { get } from "../../lib/fetch";
import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";

export const getTenantConfig = async (authUser) => {
  const url = `${endpoints.TENANT_CONFIG}?currentYear=${new Date()
    .toISOString()
    .substring(0, 10)}&nextYear=true`;
  const headers = await getHeaders(authUser);
  return await get({ url, headers });
};
