import { get } from "../../lib/fetch";
import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";

export const getTenantMembers = async (
  authUser,
  domainName,
  LastEvaluatedKey
) => {
  const headers = await getHeaders(authUser);
  let url = endpoints.GET_TENANT_ALL_USERS.replace("{domainName}", domainName);
  if (LastEvaluatedKey && LastEvaluatedKey !== "") {
    url = `${endpoints.GET_TENANT_ALL_USERS.replace(
      "{domainName}",
      domainName
    )}?continuationToken=${LastEvaluatedKey}`;
  }
  return await get({ url, headers });
};
