import { useAtomValue } from "jotai";

import { PERMISSIONS } from "../../../../../../util/constants/permission";
import { groupAtom } from "../../../../../../contexts/generalStore/store";
import CmlIcon from "../../../common/icon/CmlIcon";

const CardOptions = ({ onCardClick }) => {
  const group = useAtomValue(groupAtom);

  return (
    <div>
      <div className="flex-container">
        {PERMISSIONS.view.card.historyAllowedGroups.some((val) =>
          group.includes(val)
        ) && (
          <div className="flex-item" onClick={() => onCardClick("history")}>
            <CmlIcon _className={"card-icon"} iconName={"history_toggle_off"} />
            <p className="card-text">History</p>
          </div>
        )}
        {PERMISSIONS.view.card.teamsAllowedGroups.some((val) =>
          group.includes(val)
        ) && (
          <div className="flex-item" onClick={() => onCardClick("teams")}>
            <CmlIcon _className={"card-icon"} iconName={"group_work"} />
            <p className="card-text">Teams</p>
          </div>
        )}
        {PERMISSIONS.view.card.peopleAllowedGroups.some((val) =>
          group.includes(val)
        ) && (
          <div className="flex-item" onClick={() => onCardClick("people")}>
            <CmlIcon _className={"card-icon"} iconName={"person"} />
            <p className="card-text">People</p>
          </div>
        )}
        {PERMISSIONS.view.card.policyAllowedGroups.some((val) =>
          group.includes(val)
        ) && (
          <div
            className="flex-item"
            onClick={() => onCardClick("required fields")}
          >
            <CmlIcon _className={"card-icon"} iconName={"clinical_notes"} />
            <p className="card-text">CML Data Format</p>
          </div>
        )}
        {PERMISSIONS.view.card.tenantConfig.some((val) =>
          group.includes(val)
        ) && (
          <div
            className="flex-item"
            onClick={() => onCardClick("systemConfiguration")}
          >
            <CmlIcon _className={"card-icon"} iconName={"settings"} />
            <p className="card-text">System configuration</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardOptions;
