import React, { useContext, useEffect, useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import lodash from "lodash";
import { Button } from "antd";

import { GeneralContext } from "../../../../../../contexts/general/Context";
import { teamConfigAtom } from "../../../../../../contexts/generalStore/store";
import { saveTeamConfig } from "../../../../../../controller/teamLead/saveTeamConfig";
import {
  spawnErrorToast,
  spawnSuccessToast,
} from "../../../../../../util/toast/spawnToast";
import CmlIcon from "../../../common/icon/CmlIcon";
import { Col, Row } from "antd";

const TeamConfig = () => {
  const setTeamConfig = useSetAtom(teamConfigAtom);
  const { currentTheme } = useContext(GeneralContext);
  const [emailList, setEmailList] = useState([]);
  const [inProgress, setInprogress] = useState(false);
  const localTeamConfig = useAtomValue(teamConfigAtom);
  const [isEdit, setIsEdit] = useState(true);
  useEffect(() => {
    resetEmailList();
  }, []);

  useEffect(() => {}, [emailList]);
  const handleEmailChange = (emails) => {
    setEmailList(lodash.clone(emails));
  };

  const resetEmailList = () => {
    if (localTeamConfig?.emailList) {
      setEmailList(lodash.clone(localTeamConfig.emailList));
    } else {
      setEmailList([]);
    }
  };

  const handleCancel = () => {
    setIsEdit(true);
    resetEmailList();
  };
  const handleSave = async () => {
    try {
      setInprogress(true);
      await saveTeamConfig({ emailList: emailList });
      const latestTeamConfig = lodash.clone(localTeamConfig);
      latestTeamConfig.emailList = lodash.clone(emailList);
      setTeamConfig(latestTeamConfig);
      spawnSuccessToast("Successfully updated email list");
    } catch (e) {
      spawnErrorToast("Failed to update email list");
      console.error(e);
    }
    setInprogress(false);
    setIsEdit(false);
  };

  return (
    <div className="page-container">
      <Row>
        <Col xs={24} md={12}>
          <div className="mb-5">
            You can add multiple emails here to send the calendar invitation
            while applying for leave or overtime
          </div>
          <div className="cml-card">
            <ReactMultiEmail
              placeholder={"Enter additional emails here..."}
              emails={emailList}
              className={isEdit ? "" : "multi-email-disabled"}
              onChange={handleEmailChange}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div key={index}>
                    {!isEdit ? (
                      <div data-tag key={index}>
                        <div data-tag-item>{email}</div>
                      </div>
                    ) : (
                      <div data-tag key={index}>
                        <div data-tag-item>{email}</div>
                        <span
                          data-tag-handle
                          onClick={() => removeEmail(index)}
                        >
                          x
                        </span>
                      </div>
                    )}
                  </div>
                );
              }}
            />
            <div className="mt-20 left-aligned-buttons">
              <Button
                type="primary"
                onClick={handleSave}
                loading={inProgress}
                icon={<CmlIcon iconName={"hard_drive"} />}
              >
                Save
              </Button>
              <Button
                onClick={handleCancel}
                icon={<CmlIcon iconName={"cancel"} />}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TeamConfig;
