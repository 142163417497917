import { post } from "../../lib/fetch";
import secureLocalStorage from "react-secure-storage";

import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";

export const saveCalendarAccess = async (code) => {
  const authUser = JSON.parse(secureLocalStorage.getItem("authUser"));
  const headers = await getHeaders(authUser);
  const url = endpoints.NOTIFICATION_CALENDAR;
  const payload = {
    action: "saveAccess",
    code: code,
  };

  return await post({ url, payload, headers });
};
