import axios from "axios";
import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";

export const getOverTimeList = async (authUser, queryParams, token) => {
  let url = `${endpoints.OVERTIME_APPLICATION}?fromDate=${queryParams.from}&toDate=${queryParams.to}`;
  if (token) {
    url = `${url}?fromDate=${queryParams.from}&toDate=${queryParams.to}&continuationToken=${token}`;
  }
  const headers = await getHeaders(authUser);
  return await axios.get(url, headers);
};
