import React from "react";
import { Button } from "antd";

import CmlIcon from "../icon/CmlIcon";

/**
 * @deprecated no need to create wrapper of a button component  
 */
const CancelButton = ({ isEdit, onClick }) => {
  return (
    <div>
      <Button
        disabled={!isEdit}
        onClick={onClick}
        icon={<CmlIcon iconName={"cancel"} />}
      >
        Cancel
      </Button>
    </div>
  );
};

export default CancelButton;
