import styled from "@emotion/styled";

const LinkDiv = styled("div")`
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.text};
  padding: 5px 10px;
  border-radius: 5px;
`;

const LinkContainer = styled("div")`
  display: flex;
  font-size: 14px;
  line-height: 25px;
  cursor: pointer;
`;

const Value = styled("div")`
  width: 98%;
  word-wrap: break-word;
  overflow: auto;
  @media (max-width: 420px) {
    margin-left: 10px;
  }
`;

export {
  LinkDiv,
  LinkContainer,
  Value
};