import React from "react";
import { useAtom } from "jotai";
import { Col, Modal, Row } from "antd";

import { showModalsAtom, specificTenantAtom } from "../../../../../../../contexts/generalStore/store";
import CmlIcon from "../../../../common/icon/CmlIcon";


const ViewTenant = () => {
  const [tenantData, setTenantData] = useAtom(specificTenantAtom);
  const [showModals, setShowModals] = useAtom(showModalsAtom);

  const handleClose = () => {
    setTenantData({});
    setShowModals({ ...showModals, showViewModal: false });
  };

  return (
    <Modal
      title="Tenant Details"
      open={showModals.showViewModal}
      onCancel={handleClose}
      cancelText="Close"
      okButtonProps={{
        style: { display: "none" },
      }}
      cancelButtonProps={{
        type: "primary",
        icon: <CmlIcon iconName="cancel" />
      }}
      maskClosable={false}
    >
      <div className="cml-card mt-20">
        <Row>
          <Col span={12}>
            <div className="neutral-text">Name: </div>
            <div>{tenantData.tenantName}</div>
          </Col>
          <Col span={12}>
            <div className="neutral-text">Address: </div>
            <div>{tenantData?.address}</div>
          </Col>
        </Row>
      </div>
      <div className="mt-20">
        <Row>
          <Col span={12}>
            <div className="neutral-text">Creation Date:</div>
            <div>{new Date(tenantData.createdAt).toDateString()}</div>
          </Col>
          <Col span={12}>
            <div className="neutral-text">Update Date:</div>
            <div>
              {tenantData.updatedAt
                ? new Date(tenantData.updatedAt).toDateString()
                : "Not updated yet"}
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div className="neutral-text">Domain:</div>
            <div>{tenantData.domainName}</div>
          </Col>
          <Col span={12}>
            <div className="neutral-text">Contact:</div>
            <div>{tenantData.contact}</div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ViewTenant;
