import React, { useEffect, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import { Input } from "antd";

import {
  apiCallDataFetchedAtom,
  authUserAtom, groupedOvertimeItemsAtom,
  showModalsAtom,
  teamOverTimeListAtom,
} from "../../../../../../contexts/generalStore/store";
import { getTeamOverTimeList } from "../../../../../../controller/common/getTeamOvertime";
import { spawnErrorToast } from "../../../../../../util/toast/spawnToast";
import OvertimeTableAction from "../tableAction/OvertimeTableAction";

import DynamicTable from "../../../../../tables/dynamicTable/DynamicTable";
import ApproveOvertimeModal from "../../modals/ApproveOvertimeModal";
import RejectOvertimeModal from "../../modals/RejectOvertimeModal";
import LeaveStatusTag from "../../../../../tags/LeaveStatusTag";
import DataDivTimeFilter from "../../../skeleton/common/DataDivTimeFilter";
import { NoResultFound } from "../../../common/noResultFound/NoResultFound";
import { TitleSearchWrapper } from "../../../../../tables/dynamicTable/DynamicTable-styled";
import OvertimeExpandedRow from "../../overtime-overview-expand";
import { transformToOvertimeOverviewResp } from "../../../../../../util/transformers/overtimeGroupItems";
import mainColumns from "../overtimeTableColumns";

const TeamOvertimeTable = ({
  selectedTeam,
  startDate,
  endDate,
  isSearchedClicked,
  setIsSearchedClicked,
}) => {
  const [overlay, setOverlay] = useState(true);
  const [selectedRequest, setSelectedRequest] = useState();
  const [searchText, setSearchText] = useState("");
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState("");
  const [hasLoaded, setHasLoaded] = useState(false);

  const [showModal, setShowModal] = useAtom(showModalsAtom);
  const [isAllOverTimeListFetched, setIsAllOverTimeListFetched] = useAtom(
    apiCallDataFetchedAtom
  );
  const authUser = useAtomValue(authUserAtom);
  const userGroup = authUser.signInUserSession
    ? authUser?.signInUserSession?.idToken?.payload?.group
    : authUser?.idToken?.payload?.group;
  const [groupedItems, setGroupedItems] = useState([]);

  const getOverTime = async (startDate, endDate, token) => {
    const queryParams = {
      from: startDate.toISOString(),
      to: endDate.toISOString(),
    };
    try {
      const response = await getTeamOverTimeList(
        authUser,
        queryParams,
        token,
        selectedTeam
      );

      if (!response?.data?.groupedItems && response?.data?.Items.length > 0) {
        const fallbackDataDataTransformation = transformToOvertimeOverviewResp(response.data);
        setGroupedItems(fallbackDataDataTransformation.groupedItems);
      } else {
        setGroupedItems((prev) => [...prev, ...(response?.data?.groupedItems ?? [])]);
      }

      if (response?.data?.ContinuationToken) {
        setIsAllOverTimeListFetched({
          ...isAllOverTimeListFetched,
          isAllTeamOverTimeDataFetched: false,
        });
        setLastEvaluatedKey(response?.data?.ContinuationToken);
      } else {
        setIsAllOverTimeListFetched({
          ...isAllOverTimeListFetched,
          isAllTeamOverTimeDataFetched: true,
        });
      }
    } catch (error) {
      spawnErrorToast("Error while fetching over time list");
    } finally {
      setOverlay(false);
    }
  };

  useEffect(() => {
    if (
        startDate &&
        endDate &&
        isSearchedClicked &&
        hasLoaded &&
        selectedTeam !== "invalid" &&
        selectedTeam
    ) {
      setOverlay(true);
      getOverTime(startDate, endDate);
    }
    return () => {
      setIsSearchedClicked(false);
    };
  }, [startDate, endDate, selectedTeam, hasLoaded, isSearchedClicked]);

  useEffect(() => {
    if (
        startDate &&
        endDate &&
        !hasLoaded &&
        !isSearchedClicked &&
        selectedTeam &&
        selectedTeam !== "invalid"
    ) {
      setOverlay(true);
      getOverTime(startDate, endDate);
      setHasLoaded(true);
    } else {
      if (userGroup.includes("tenantAdmin")) {
        setOverlay(false);
        setHasLoaded(true);
      }
    }
  }, [startDate, endDate, hasLoaded]);

  const handleCloseApproveModal = () => {
    setSelectedRequest();
    setShowModal({
      ...showModal,
      showApproveOvertimeModal: false,
    });
  };

  const handleCloseRejectModal = () => {
    setSelectedRequest();
    setShowModal({
      ...showModal,
      showRejectOvertimeModal: false,
    });
  };

  const handleApprove = (overTime) => {
    setSelectedRequest(overTime);
    setShowModal({
      ...showModal,
      showApproveOvertimeModal: true,
    });
  };

  const handleReject = (overTime) => {
    setSelectedRequest(overTime);
    setShowModal({
      ...showModal,
      showRejectOvertimeModal: true,
    });
  };

  const handleTableSearch = (value) => {
    setSearchText(value);
  };

  const filteredItems = groupedItems?.filter(item =>
      item?.authorEmail?.toLowerCase().includes(searchText.toLowerCase())
  );

  const callApiToMoreData = () => {
    getOverTime(lastEvaluatedKey);
  };

  return (
      <>
        <ApproveOvertimeModal
            isModalOpen={showModal.showApproveOvertimeModal}
            onModalClose={handleCloseApproveModal}
            tableRow={selectedRequest}
        />
        <RejectOvertimeModal
            isModalOpen={showModal.showRejectOvertimeModal}
            onModalClose={handleCloseRejectModal}
            tableRow={selectedRequest}
        />

        <div className="cml-card mt-30">
          {groupedItems?.length > 0 && (
              <TitleSearchWrapper>
                <Input.Search
                    placeholder="Start typing to search"
                    onChange={(e) => handleTableSearch(e.target.value)}
                    style={{
                      width: window.screen.width > 500 ? "300px" : "100%",
                    }}
                    enterButton
                />
              </TitleSearchWrapper>
          )}
            {filteredItems?.length > 0 ? (
              <DynamicTable
                  rowKey="authorEmail"
                  tableProps={{
                    loading: {
                      indicator: <DataDivTimeFilter />,
                      spinning: overlay,
                      size: "small",
                    },
                  }}
                  columns={mainColumns}
                  data={filteredItems}
                  handleTableSearch={handleTableSearch}
                  inProgress={overlay}
                  isAllDataFetched={
                    isAllOverTimeListFetched.isAllOwnOverTimeDataFetched
                  }
                  size={"small"}
                  callApiToLoadMore={callApiToMoreData}
                  scroll={{x: 768}}
                  expandedRowRender={{
                    expandedRowRender: (record) => <OvertimeExpandedRow
                        record={record}
                        renderActionCell={(record) => (
                            <OvertimeTableAction
                                actions={{ handleApprove, handleReject }}
                                row={record}
                                group={userGroup}
                                fromOnBehalf={false}
                            />
                        )}
                    />,
                  }}
              />
        ) : filteredItems.length === 0 && overlay ? (
            <div style={{ height: "200px" }}>
              <DataDivTimeFilter />
            </div>
        ) : (
            filteredItems.length === 0 &&
          !overlay && <NoResultFound />
        )}
      </div>
    </>
  );
};

export default TeamOvertimeTable;
