import { useAtomValue } from "jotai";

import { PERMISSIONS } from "../../../../../../util/constants/permission";
import { groupAtom } from "../../../../../../contexts/generalStore/store";
import CmlIcon from "../../../common/icon/CmlIcon";

const CardOptions = ({ onCardClick }) => {
  const group = useAtomValue(groupAtom);

  return (
    <div className="page-container">
      <div className="flex-container">
        {PERMISSIONS.view.settingsCard.profileSettingsAllowedGroups.some(
          (val) => group.includes(val)
        ) && (
          <>
            <div className="flex-item" onClick={() => onCardClick("profile")}>
              <CmlIcon _className={"card-icon"} iconName={"person"} />
              <p className="card-text">Profile</p>
            </div>
          </>
        )}
        {PERMISSIONS.view.teamConfig.teamConfigAllowedGroups.some((val) =>
          group.includes(val)
        ) && (
          <div className="flex-item" onClick={() => onCardClick("teamConfig")}>
            <CmlIcon _className={"card-icon"} iconName={"groups"} />
            <p className="card-text"> Team config</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardOptions;
