import {
  Authenticator,
  useTheme,
  View,
  Image,
  Text,
  Heading,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { Button } from "antd";
import React from "react";
import { useEffect, useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import { useNavigate, useLocation } from "react-router";
import { useSetAtom } from "jotai";
import secureLocalStorage from "react-secure-storage";

import appLogo from "../../img/appIcon.svg";
import { authUserAtom } from "../../contexts/generalStore/store";

const Login = (currentURL) => {
  const setAuthUser = useSetAtom(authUserAtom);
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "/";
  const [locationURL] = useState(currentURL?.currentURL);
  const [hasError, setHasError] = useState(false);
  const [customErrorMessage, setCustomErrorMessage] = useState("");

  const components = {
    Header() {
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="App logo"
            src={appLogo}
            height={"180px"}
            width={"280px"}
          />
        </View>
      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text className="footer-note">
            Copyright &copy; 2023 Craftsmen LTD. All rights reserved
          </Text>
        </View>
      );
    },

    SignIn: {
      Header() {
        const { tokens } = useTheme();

        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Sign in to your account
          </Heading>
        );
      },
      Footer() {
        const { toResetPassword } = useAuthenticator();

        return (
          <View textAlign="center">
            {hasError && <Text color={"Red"}> {customErrorMessage}</Text>}
            <Button
              className="reset-password-btn"
              fontWeight="normal"
              onClick={toResetPassword}
              size="small"
              variation="link"
            >
              Reset Password
            </Button>
          </View>
        );
      },
    },

    ConfirmSignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
            color={"white"}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    ResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            Enter Information:
          </Heading>
        );
      },
    },
  };

  const formFields = {
    signIn: {
      username: {
        placeholder: "Enter your email",
      },
    },
    forceNewPassword: {
      password: {
        placeholder: "Enter your Password:",
      },
    },
    resetPassword: {
      username: {
        placeholder: "Enter your email:",
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: "Enter your Confirmation Code:",
        label: "New Label",
        isRequired: false,
      },
      confirm_password: {
        placeholder: "Enter your Password Please:",
      },
    },
    confirmSignIn: {
      confirmation_code: {
        label: "New Label",
        placeholder: "Enter your Confirmation Code:",
        isRequired: false,
      },
    },
  };

  const checkForErrorInURL = async (url) => {
    const urlObject = new URL(url);
    const errorDescription = urlObject.searchParams.get("error_description");
    if (errorDescription && errorDescription.includes("failed with error")) {
      setCustomErrorMessage(errorDescription.split("failed with error")[1]);
      setHasError(true);
    }
  };

  useEffect(() => {
    if (locationURL !== undefined) {
      checkForErrorInURL(locationURL);
    }
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from, locationURL]);

  return (
      <div className="login gutter-sm">
        <Authenticator
          variation="default"
          loginMechanisms={["email"]}
          socialProviders={["google"]}
          formFields={formFields}
          components={components}
          hideSignUp={true}
        >
          {({ user }) => {
            secureLocalStorage.setItem("authUser", JSON.stringify(user));
            setAuthUser(user);
          }}
        </Authenticator>
      </div>
  );
};

export default Login;
