import React, { useState, useEffect } from "react";
import { Button, Row, Col, Skeleton, message } from "antd";
import { useAtomValue } from "jotai";
import ajv from "ajv";

import { getTenantConfigList } from "../../../../../../controller/admin/getTenantConfigList";
import {
  determineCurrentTenantConfig,
  getTenantConfigByExpireDate,
} from "../../../../../../controller/common/tenantConfig";
import {
  spawnErrorToast,
  spawnSuccessToast,
} from "../../../../../../util/toast/spawnToast";

import EmptyTenantConfig from "./component/emptyTenantConfig/EmptyTenantConfig";

import { saveTenantConfig } from "../../../../../../controller/admin/saveTenantConfig";
import General from "./component/general/General";
import Holidays from "./component/holidays/Holidays";
import LeaveDefinition from "./component/leaveDefinition/LeaveDefinition";
import SaveButton from "../../../common/buttons/SaveButton";
import CancelButton from "../../../common/buttons/CancelButton";
import PageTitleSelect from "../../../common/selects/PageTitleSelect";
import { isYearMonthDayFormatted } from "../../../../../../util/formatter/getDateInTextFormat";
import { authUserAtom } from "../../../../../../contexts/generalStore/store";
import { schema_Config_creation } from "../../../../../../core/validation/configCreationValidation";

import { createTenantConfig } from "../../../../../../controller/admin/createConfig";
import { getDefaultTenantConfig } from "../../../../../../util/data/defaultTenantConfig";

import { Tab } from "../../../common/tabs/Tab";
import CmlIcon from "../../../common/icon/CmlIcon";

const TenantConfig = ({ setTitleDiv }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isNewConfig, setIsNewConfig] = useState(false);
  const [onDisplayTenantConfig, setOnDisplayTenantConfig] = useState({});
  const [tenantConfigList, setTenantConfigList] = useState([]);
  const [initialTenantConfig, setInitialTenantConfig] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState("general");

  const [noticePeriodList, setNoticePeriodList] = useState([]);
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [holidayList, setHolidayList] = useState([]);

  const authUser = useAtomValue(authUserAtom);

  const deepClone = (object) => {
    return JSON.parse(JSON.stringify(object));
  };

  useEffect(() => {}, [loading, currentTab]);

  useEffect(() => {
    setLoading(true);
    const setTenantConfig = async (authUser) => {
      try {
        const response = await getTenantConfigList(authUser);
        if (response?.data?.Items?.length > 0) {
          setTenantConfigList(response.data.Items);
          const initialConfig = determineCurrentTenantConfig(
            response.data.Items
          );
          const tenantConfig = deepClone(initialConfig);
          setOnDisplayTenantConfig(tenantConfig);
          setInitialTenantConfig(deepClone(initialConfig));
          setNoticePeriodListFromTenantConfig(tenantConfig);
          setLeaveTypesListFromTenantConfig(tenantConfig);
          setHolidayListFromTenantConfig(tenantConfig);
        } else {
          setOnDisplayTenantConfig({});
          setInitialTenantConfig({});
          spawnErrorToast("Could not get tenant config");
        }
      } catch (e) {
        spawnErrorToast("Could not get tenant config");
        setOnDisplayTenantConfig({});
        setInitialTenantConfig({});
      } finally {
        setIsEdit(true);
        setLoading(false);
      }
    };
    setTenantConfig(authUser);
  }, []);

  const handleAddTenantConfig = () => {
    const initialTenantConfig = getDefaultTenantConfig(
      authUser,
      tenantConfigList
    );
    if (tenantConfigList?.length !== 0) {
      setIsEdit(!isEdit);
    }
    setIsNewConfig(true);
    setOnDisplayTenantConfig(initialTenantConfig);
  };

  useEffect(() => {
    setTitleDiv(
      <>
        <PageTitleSelect
          options={getSelectOptions()}
          value={onDisplayTenantConfig?.expiredAt}
          onChange={handleTenantConfigChange}
          loading={loading}
        />

        <Button
          type="primary"
          icon={<CmlIcon iconName="add" />}
          disabled={!(tenantConfigList?.length < 2 && !loading)}
          onClick={handleAddTenantConfig}
        ></Button>
      </>
    );
  }, [tenantConfigList]);

  useEffect(() => {
    setTitleDiv(
      <>
        <PageTitleSelect
          options={getSelectOptions()}
          value={onDisplayTenantConfig?.expiredAt}
          onChange={handleTenantConfigChange}
          loading={loading}
        />

        <Button
          className="accent-color-small-button"
          type={tenantConfigList?.length < 2 && !loading ? "primary" : "default"}
          onClick={handleAddTenantConfig}
          disabled={!(tenantConfigList?.length < 2 && !loading)}
          icon={<CmlIcon iconName={"add"} />}
        >
        </Button>
      </>
    );
    setNoticePeriodListFromTenantConfig(onDisplayTenantConfig);
    setLeaveTypesListFromTenantConfig(onDisplayTenantConfig);
    setHolidayListFromTenantConfig(onDisplayTenantConfig);
  }, [onDisplayTenantConfig]);

  const setNoticePeriodListFromTenantConfig = (tenantConfig) => {
    const noticePeriod = [];
    let index = 0;
    for (const item in tenantConfig?.leavePolicy?.noticePeriodOfLeave) {
      const day = tenantConfig.leavePolicy.noticePeriodOfLeave[item].day;
      const notice = tenantConfig.leavePolicy.noticePeriodOfLeave[item].notice;
      noticePeriod.push({
        day: day,
        notice: notice,
        key: index,
        isNewRow: false,
      });
      index++;
    }
    setNoticePeriodList(noticePeriod);
  };

  const setLeaveTypesListFromTenantConfig = (tenantConfig) => {
    const leaveTypes = [];
    let index = 0;
    for (const leaveType in tenantConfig?.leavePolicy?.maxLeaveAndType) {
      const value = tenantConfig?.leavePolicy?.maxLeaveAndType?.[leaveType];
      const appliedNotice =
        tenantConfig?.leavePolicy?.appliedNotice?.[leaveType];
      leaveTypes.push({
        leaveType: leaveType,
        value: value,
        appliedNotice: appliedNotice,
        isNewRow: false,
        key: index,
      });
      index++;
    }
    setLeaveTypeList(leaveTypes);
  };

  const customSort = (a, b) => new Date(a.date) - new Date(b.date);

  const setHolidayListFromTenantConfig = (tenantConfig) => {
    const holidays = [];
    let index = 0;
    for (const date in tenantConfig?.holiday) {
      const title = tenantConfig.holiday[date].title;
      holidays.push({
        title: title,
        date: date,
        key: index,
        isNewRow: false,
      });
      index++;
    }
    const sortedDays = holidays.sort(customSort);
    setHolidayList(sortedDays);
  };
  const getSelectOptions = () => {
    const options = [];
    tenantConfigList?.forEach((tenantConfig) => {
      options.push({
        value: tenantConfig?.expiredAt,
        label: tenantConfig?.expiredAt?.split("T")[0],
      });
    });
    return options;
  };
  const handleTenantConfigChange = (e) => {
    const tenantConfig = getTenantConfigByExpireDate(tenantConfigList, e);
    setOnDisplayTenantConfig(tenantConfig);
  };

  const generatePayload = (tenantConfig) => {
    const payload = {
      emailList: tenantConfig.emailList,
      holiday: tenantConfig.holiday,
      weekend: tenantConfig.weekend,
      tenantDomain: `${
        authUser?.signInUserSession
          ? authUser?.signInUserSession?.idToken?.payload?.tenantDomain
          : authUser?.idToken?.payload?.tenantDomain
      }`,
      timezone: tenantConfig.timezone,
      leaveAdjustmentType: tenantConfig.leaveAdjustmentType,
      leavePolicy: tenantConfig.leavePolicy,
      startYear: `${new Date(tenantConfig.startYear).toISOString()}`,
    };
    return payload;
  };

  const handleSaveTenantConfig = async () => {
    let payload = {};
    const tenantConfig = deepClone(onDisplayTenantConfig);
    if (tenantConfig?.newConfig) {
      setTenantConfigFromHolidayList(tenantConfig);
      setTenantConfigFromLeaveTypeList(tenantConfig);
      setTenantConfigFromNoticePeriodList(tenantConfig);
      payload = generatePayload(tenantConfig);
      console.log(payload);
      const ajvInstance = new ajv();
      const validate = ajvInstance.compile(schema_Config_creation);
      const isValid = validate(payload);
      if (!isValid) {
        message.error(validate.errors[0].message);
        return;
      }
    } else {
      payload = tenantConfig;
      if (
        !validateHolidayList() ||
        !validateLeaveTypesList() ||
        !validateNoticePeriodList()
      ) {
        message.error("Invalid Data");
        return;
      }
      setTenantConfigFromHolidayList(tenantConfig);
      setTenantConfigFromLeaveTypeList(tenantConfig);
      setTenantConfigFromNoticePeriodList(tenantConfig);
    }

    try {
      if (tenantConfig?.newConfig) {
        await createTenantConfig(authUser, payload);
      } else {
        await saveTenantConfig(authUser, payload);
      }
      spawnSuccessToast("Saved tenant config");
    } catch (e) {
      spawnErrorToast("Failed to save");
    }
  };

  const validateHolidayList = () => {
    return holidayList.every((item) => {
      return (
        item?.title &&
        item?.title !== "" &&
        item?.date &&
        isYearMonthDayFormatted(item?.date)
      );
    });
  };

  const setTenantConfigFromHolidayList = (tenantConfig) => {
    delete tenantConfig.holiday;
    tenantConfig["holiday"] = {};
    holidayList.forEach((item) => {
      tenantConfig.holiday[item.date] = {
        title: item.title,
      };
    });
    return tenantConfig;
  };

  const setTenantConfigFromLeaveTypeList = (tenantConfig) => {
    leaveTypeList.forEach((item) => {
      const value = item?.value;
      const leaveType = item?.leaveType;
      const appliedNotice = item?.appliedNotice;

      tenantConfig.leavePolicy.maxLeaveAndType[leaveType] = value;
      tenantConfig.leavePolicy.appliedNotice[leaveType] = appliedNotice;
    });
    return tenantConfig;
  };

  const setTenantConfigFromNoticePeriodList = (tenantConfig) => {
    delete tenantConfig.leavePolicy.noticePeriodOfLeave;
    tenantConfig.leavePolicy["noticePeriodOfLeave"] = {};
    noticePeriodList.forEach((item) => {
      const day = item?.day;
      const notice = item?.notice;
      if (day && notice) {
        tenantConfig.leavePolicy.noticePeriodOfLeave[day] = {
          day: day,
          notice: notice,
        };
      }
    });
    return tenantConfig;
  };

  const validateNoticePeriodList = () => {
    return noticePeriodList.every((item) => {
      return (
        item?.day && item?.day !== "" && item?.notice && item?.notice !== ""
      );
    });
  };
  const validateLeaveTypesList = () => {
    return leaveTypeList.every((item) => {
      return item?.leaveType && item?.leaveType !== "";
    });
  };

  const handleCancel = () => {
    setOnDisplayTenantConfig(deepClone(initialTenantConfig));
  };

  const handleTabChange = (tabName) => {
    setCurrentTab(tabName);
  };

  const getTabs = () => {
    let tabs = [
      {
        title: "General",
        label: "general",
        icon: <CmlIcon _className={"tab-icon"} iconName={"build"} />,
      },
      {
        title: "Holidays",
        label: "holidays",
        icon: <CmlIcon _className={"tab-icon"} iconName={"holiday_village"} />,
      },
      {
        title: "Leave Definition",
        label: "leaveDefinition",
        icon: <CmlIcon _className={"tab-icon"} iconName={"in_home_mode"} />,
      },
    ];
    return tabs;
  };

  return (
    <div className="page-container">
      <Row>
        <Col xs={24} md={18}>
          <Tab onSelectedTab={handleTabChange} tabs={getTabs()} />
        </Col>
        <Col xs={24} md={6}>
          <div className="right-aligned-buttons">
            <SaveButton
              isEdit={isEdit}
              isNewConfig={isNewConfig}
              onClick={handleSaveTenantConfig}
            />
            <CancelButton isEdit={isEdit} onClick={handleCancel} />
          </div>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <div className="mt-30">
            {loading ? (
              <Skeleton active />
            ) : Object.entries(onDisplayTenantConfig).length === 0 ? (
              <EmptyTenantConfig
                handleAddTenantConfig={handleAddTenantConfig}
                onDisplayTenantConfig={onDisplayTenantConfig}
                setOnDisplayTenantConfig={setOnDisplayTenantConfig}
              />
            ) : (
              <>
                {currentTab === "general" && (
                  <General
                    isEdit={isEdit}
                    isNewConfig={isNewConfig}
                    onDisplayTenantConfig={onDisplayTenantConfig}
                    setOnDisplayTenantConfig={setOnDisplayTenantConfig}
                  />
                )}

                {currentTab === "holidays" && (
                  <Holidays
                    isEdit={isEdit}
                    isNewConfig={isNewConfig}
                    onDisplayTenantConfig={onDisplayTenantConfig}
                    setOnDisplayTenantConfig={setOnDisplayTenantConfig}
                    holidayList={holidayList}
                    setHolidayList={setHolidayList}
                  />
                )}
                {currentTab === "leaveDefinition" && (
                  <LeaveDefinition
                    isEdit={isEdit}
                    isNewConfig={isNewConfig}
                    onDisplayTenantConfig={onDisplayTenantConfig}
                    setOnDisplayTenantConfig={setOnDisplayTenantConfig}
                    noticePeriodList={noticePeriodList}
                    setNoticePeriodList={setNoticePeriodList}
                    leaveTypeList={leaveTypeList}
                    setLeaveTypeList={setLeaveTypeList}
                  />
                )}
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TenantConfig;
