import { put } from "../../lib/fetch";

import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";

export const setAdmin = async (authUser, payload) => {
  const url = `${endpoints.TENANT_INVITE_ENDPOINT}`;
  const headers = await getHeaders(authUser);
  return await put({ url, payload, headers });
};
