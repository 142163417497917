import { get } from "../../lib/fetch";
import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";

export const getSpecificTenantTeam = async (
  authUser,
  domainName,
  LastEvaluatedKey
) => {
  let url = "";
  if (!LastEvaluatedKey) {
    url = `${endpoints.TENANT_TEAM_SUPER_ADMIN}?domainName=${domainName}`;
  } else {
    url = `${endpoints.TENANT_TEAM_SUPER_ADMIN}?domainName=${domainName}&LastEvaluatedKey=${LastEvaluatedKey}`;
  }
  const headers = await getHeaders(authUser);
  return await get({ url, headers });
};
