import React, { useState } from "react";
import { Modal } from "antd";
import { useAtom, useAtomValue } from "jotai";

import {
  authUserAtom,
  tenantMembersAtom,
} from "../../../../../../../../../contexts/generalStore/store";
import { setAdmin } from "../../../../../../../../../controller/superadmin/setTenantAdmin";
import { assignAdminHandler } from "../../../../../../../../../core/tenant/adminAssign/assignMemberAsAdmin/assignAdminHandler";
import CmlIcon from "../../../../../../common/icon/CmlIcon";

const AdminAssignModal = ({ member, open, setOpen }) => {
  const [members, setMembers] = useAtom(tenantMembersAtom);
  const [isLoading, setIsLoading] = useState(false);
  const authUser = useAtomValue(authUserAtom);

  const handleClose = () => {
    setOpen(false);
  };

  const getStateArguments = () => {
    return {
      authUser: authUser,
      member: member,
      setAdmin: setAdmin,
      setMembers: setMembers,
      members: members,
      handleClose: handleClose,
      setIsLoading: setIsLoading,
    };
  };

  const handleSubmit = async () => {
    const args = getStateArguments();
    assignAdminHandler(args);
  };

  return (
    <>
      <Modal
        title="Assign tenant admin"
        open={open}
        onOk={handleSubmit}
        onCancel={handleClose}
        okText="Submit"
        cancelText="Close"
        okButtonProps={{
          icon: <CmlIcon iconName="task_alt" />
        }}
        cancelButtonProps={{
          icon: <CmlIcon iconName="cancel" />
        }}
        confirmLoading={isLoading}
        maskClosable={false}
      >
        <p>
          Are you sure you want to assign <b>{member.email}</b> as{" "}
          <b>tenant admin</b>?
        </p>
      </Modal>
    </>
  );
};

export default AdminAssignModal;
