import React, { useContext, useEffect } from "react";
import { Checkbox } from "antd";
import lodash from "lodash";
import { GeneralContext } from "../../../../../../../../../../contexts/general/Context";

const CalcOvertime = ({
  isAllowedToEdit,
  isNewConfig,
  onDisplayTenantConfig,
  setOnDisplayTenantConfig,
}) => {
  const { currentTheme } = useContext(GeneralContext);

  useEffect(() => {}, [onDisplayTenantConfig]);

  const getExistingOvertimeSettings = () => {
    return onDisplayTenantConfig?.shouldCalculateOvertimeHourly;
  };

  const handleShouldCalcOvertimeHourlySelect = (e) => {
    const tenantConfig = lodash.clone(onDisplayTenantConfig);
    tenantConfig.shouldCalculateOvertimeHourly = e.target.checked;
    setOnDisplayTenantConfig(tenantConfig);
  };
  return (
    <div>
      <div className={!isNewConfig ? "neutral-text" : "text"}>Overtime Settings</div>
      <Checkbox
          style={{
            color: !isNewConfig
                ? currentTheme.inputTextDisabled
                : currentTheme.text,
          }}
          checked={getExistingOvertimeSettings()}
          onChange={handleShouldCalcOvertimeHourlySelect}
      >
        Should Calculate Overtime Hourly
      </Checkbox>
    </div>
  );
};

export default CalcOvertime;
