export const hasBalanceCheckerEdit = (
  start,
  end,
  leaveRequest,
  expireDate,
  calculateConsumedDays,
  tenantConfig,
  durationBeforeEdit,
  userLeaveDetails
) => {
  const type = leaveRequest?.details?.leaveType
    ? leaveRequest?.details?.leaveType
    : Object.keys(tenantConfig[0]?.leavePolicy?.maxLeaveAndType)[0];
  const duration = calculateConsumedDays(start, end);

  if (leaveRequest?.details?.firstConfigDuration) {
    if (end < expireDate[0]) {
      const currentBalance = userLeaveDetails?.firstConfig[type];
      const firstConfigDurationBeforeEdit =
        leaveRequest?.details?.firstConfigDuration;
      if (duration.firstYearWorkingDays > firstConfigDurationBeforeEdit) {
        if (duration.firstYearWorkingDays > currentBalance) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else if (
      end < expireDate[1] &&
      end > expireDate[0] &&
      start < expireDate[0]
    ) {
      const firstYearBalance = userLeaveDetails?.firstConfig[type];
      const secondYearBalance = userLeaveDetails?.secondConfig[type];
      const firstConfigDurationBeforeEdit =
        leaveRequest?.details?.firstConfigDuration;
      const secondConfigDurationBeforeEdit =
        leaveRequest?.details?.secondConfigDuration;
      if (
        duration.firstYearWorkingDays > firstConfigDurationBeforeEdit ||
        duration.secondYearWorkingDays > secondConfigDurationBeforeEdit
      ) {
        if (
          duration.secondYearWorkingDays > secondYearBalance ||
          duration.firstYearWorkingDays > firstYearBalance
        ) {
          return false;
        } else if (
          duration.secondYearWorkingDays > secondYearBalance ||
          duration.firstYearWorkingDays > firstYearBalance
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else if (
      end < expireDate[1] &&
      end > expireDate[0] &&
      start > expireDate[0]
    ) {
      const secondYearBalance = userLeaveDetails?.secondConfig[type];
      const secondConfigDuration = leaveRequest?.details?.secondConfigDuration;
      if (duration.secondYearWorkingDays > secondConfigDuration) {
        if (duration.secondYearWorkingDays > secondYearBalance) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      const secondYearBalance = userLeaveDetails?.secondConfig[type];
      if (duration.secondYearWorkingDays > secondYearBalance) {
        return false;
      } else {
        return true;
      }
    }
  } else {
    if (end < expireDate[0]) {
      const currentBalance =
        userLeaveDetails?.firstConfig[type] + parseInt(durationBeforeEdit);
      if (duration.firstYearWorkingDays > durationBeforeEdit) {
        if (duration.firstYearWorkingDays > currentBalance) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else if (
      end < expireDate[1] &&
      end > expireDate[0] &&
      start < expireDate[0]
    ) {
      const leaveEnd = new Date(leaveRequest?.details?.endDate);
      let firstYearBalance;
      let secondYearBalance;
      if (leaveEnd < expireDate[0]) {
        firstYearBalance =
          userLeaveDetails?.firstConfig[type] + durationBeforeEdit;
        secondYearBalance = userLeaveDetails?.secondConfig[type];
      } else if (leaveEnd > expireDate[0]) {
        firstYearBalance = userLeaveDetails?.firstConfig[type];
        secondYearBalance =
          userLeaveDetails?.secondConfig[type] + durationBeforeEdit;
      }
      if (
        duration.secondYearWorkingDays > secondYearBalance ||
        duration.firstYearWorkingDays > firstYearBalance
      ) {
        return false;
      } else {
        return true;
      }
    } else if (
      end < expireDate[1] &&
      end > expireDate[0] &&
      start > expireDate[0]
    ) {
      const secondYearBalance = userLeaveDetails?.secondConfig[type];
      if (duration.secondYearWorkingDays > durationBeforeEdit) {
        if (duration.secondYearWorkingDays > secondYearBalance) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      const secondYearBalance = userLeaveDetails?.secondConfig[type];
      if (duration.secondYearWorkingDays > secondYearBalance) {
        return false;
      } else {
        return true;
      }
    }
  }
};
