import { assignEmployeeToTeam } from "../../../controller/admin/assignEmployeeToTeam";
import { defaultSelectionInTeamAssign } from "../../../util/constants/modalSelectionDefaults";
import {
  spawnErrorToast,
  spawnSuccessToast,
} from "../../../util/toast/spawnToast";

export const handleAssignToTeam = async ({
  setIsLoading,
  authUser,
  employee,
  removeEmployeeFromList,
  handleModalClose,
  setSelectedTeam,
  setSelectedGroup,
  selectedDate,
  selectedTeam,
  selectedGroup,
}) => {
  setIsLoading(true);
  try {
    const payload = {
      teamName: selectedTeam,
      group: selectedGroup,
      email: employee?.email,
      domainName: employee?.domainName,
      teamHistory: employee?.teamHistory,
      leaveBalance: employee?.leaveBalance,
      consumedLeaveBalance: employee?.consumedLeaveBalance,
      newTeamStartDate: selectedDate.toISOString(),
    };
    await assignEmployeeToTeam(authUser, payload);
    removeEmployeeFromList(employee?.email);
    spawnSuccessToast(
      `Successfully Assigned - ${employee?.employeeName} in ${selectedTeam}`
    );
    handleModalClose();
  } catch (error) {
    spawnErrorToast(
      error?.response?.data?.message ?? "Could Not Unassign At The Moment"
    );
  } finally {
    setIsLoading(false);
  }
};
