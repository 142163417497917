import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { useEffect, useState } from "react";
import {
  authUserAtom,
  rowIndexAtom,
  showModalsAtom,
  specificTenantAtom,
  tenantsAtom,
} from "../../../../../contexts/generalStore/store";
import DynamicTable from "../../../../tables/dynamicTable/DynamicTable";
import { getTenantsInfoHandler } from "../../../../../core/tenant/getTenant/getTenantHandler";
import ViewTenant from "./components/viewTenant/ViewTenant";
import AddTenant from "./components/addTenant/AddTenant";
import EditTenantDetails from "./components/editTenantDetails/EditTenantDetails";
import TenantUsers from "./components/userList/TenantUsers";
import DataDivNonTimeFilter from "../../skeleton/common/DataDivNonTimeFilter";
import { Button, Col, Empty, Row } from "antd";
import DataDivTimeFilter from "../../skeleton/common/DataDivTimeFilter";
import CmlIcon from "../../common/icon/CmlIcon";

const Tenants = () => {
  const [tenants, setTenants] = useAtom(tenantsAtom);
  const [showModals, setShowModals] = useAtom(showModalsAtom);
  const [inProgress, setInProgress] = useState(true);

  const setSpecificTenant = useSetAtom(specificTenantAtom);
  const setRowIndex = useSetAtom(rowIndexAtom);

  const authUser = useAtomValue(authUserAtom);

  const [allDataFetched, setAllDataFetched] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState();
  const [searchText, setSearchText] = useState("");

  const getStateArguments = () => {
    return {
      authUser: authUser,
      setTenants: setTenants,
      setAllDataFetched: setAllDataFetched,
      setLastEvaluatedKey: setLastEvaluatedKey,
      setInProgress: setInProgress,
      lastEvaluatedKey: lastEvaluatedKey,
    };
  };

  useEffect(() => {
    const args = getStateArguments();
    getTenantsInfoHandler(args);
  }, []);

  const handleLoadMore = async () => {
    const args = getStateArguments();
    getTenantsInfoHandler(args);
  };

  const handleAddTenant = () => {
    setShowModals({ ...showModals, showAddTenantModal: true });
  };

  const onView = (rowIndex) => {
    setSpecificTenant(tenants[rowIndex]);
    setShowModals({ ...showModals, showViewModal: true });
  };

  const onEdit = (rowIndex) => {
    setRowIndex(rowIndex);
    setSpecificTenant(tenants[rowIndex]);
    setShowModals({ ...showModals, showEditModal: true });
  };

  const onUserListClick = (rowIndex) => {
    setRowIndex(rowIndex);
    setSpecificTenant(tenants[rowIndex]);
    setShowDrawer(true);
  };

  const Action = (cell, row, rowIndex, formatExtraData) => {
    return (
      <>
        <Button
          type="link"
          size="small"
          title="See details of this Tenant"
          icon={<CmlIcon iconName="read_more" />}
          onClick={() => { onView(rowIndex); }}
        />
        <Button
          type="link"
          size="small"
          title="Edit details of this Tenant"
          icon={<CmlIcon iconName="edit_note" />}
          onClick={() => { onEdit(rowIndex); }}
        />
        <Button
          type="link"
          size="small"
          title="See all users of this Tenant"
          icon={<CmlIcon iconName="groups" />}
          onClick={() => { onUserListClick(rowIndex); }}
        />
      </>
    );
  };

  const columns = [
    {
      dataIndex: "tenantName",
      title: "Name",
      width: "250px",
      align: "start",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          record?.tenantName?.toLowerCase().includes(value.toLowerCase()) ||
          String(record?.userCount)
            ?.toLowerCase()
            .includes(String(value).toLowerCase())
        );
      },
    },
    {
      dataIndex: "userCount",
      title: "Total User ",
      width: "50px",
      align: "start",
    },
    {
      dataIndex: "action",
      title: "Action",
      width: "50px",
      align: "center",
      render: Action,
    },
  ];

  const handleTableSearch = (value) => {
    setSearchText(value);
  };

  const tableProps = {
    loading: {
      indicator: <DataDivNonTimeFilter />,
      spinning: inProgress,
      size: "small",
    },
  };

  return (
    <div className="cml-card">
      <Row>
        <Col>
          <div className="neutral-text mb-10">
            List of Tenants
            <Button
              type="primary"
              size="small"
              title="Click to add a new Tenant"
              className="ml-10"
              icon={<CmlIcon iconName={"add"} /> }
              onClick={handleAddTenant}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {tenants.length > 0 ? (
            <DynamicTable
              rowKey="domainName"
              tableProps={tableProps}
              columns={columns}
              data={tenants}
              handleTableSearch={handleTableSearch}
              inProgress={inProgress}
              isAllDataFetched={allDataFetched}
              callApiToLoadMore={handleLoadMore}
              scroll={{ x: 768 }}
              size="small"
            />
          ) : tenants.length === 0 && !inProgress ? (
            <>
              <Empty />
            </>
          ) : (
            tenants.length === 0 &&
            inProgress && (
              <div style={{ height: "200px" }}>
                <DataDivTimeFilter />
              </div>
            )
          )}
          {showModals.showEditModal && <EditTenantDetails />}
          {showModals.showAddTenantModal && <AddTenant />}
          {showModals.showViewModal && <ViewTenant />}
          {showDrawer && <TenantUsers open={showDrawer} setOpen={setShowDrawer} />}
        </Col>
      </Row>
    </div>
  );
};

export default Tenants;
