import { isValidSession } from "./refreshSession";

export const getHeaders = async (user) => {
  let authorization = "";
  const data = await isValidSession();
  if (data) {
    authorization = data?.signInUserSession
      ? data?.signInUserSession?.idToken?.jwtToken
      : data?.idToken?.jwtToken;
  } else {
    authorization = user?.signInUserSession
      ? user?.signInUserSession?.idToken?.jwtToken
      : user?.idToken?.jwtToken;
  }
  const headers = {
    headers: {
      Authorization: authorization,
    },
  };
  return headers;
};
