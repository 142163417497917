import React from "react";
import { ShimmerDiv } from "../../common/Common-styled";

const FilterSkeleton = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: window.innerWidth > 768 ? "row" : "column",
        marginBottom: "10px",
        gap: "15px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "10px",
          gap: "5px",
        }}
      >
        <ShimmerDiv height={"20px"} width={"150px"} />
        <ShimmerDiv
          height={"40px"}
          width={window.innerWidth > 768 ? "250px" : "100%"}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "10px",
          gap: "5px",
        }}
      >
        <ShimmerDiv height={"20px"} width={"150px"} />
        <ShimmerDiv
          height={"40px"}
          width={window.innerWidth > 768 ? "250px" : "100%"}
        />
      </div>
    </div>
  );
};

export default FilterSkeleton;
