import React from "react";
import { Layout, Button, Row, Col, Popover } from "antd";
import { useNavigate } from "react-router-dom";
import aboutPic from "../../img/about.png";
import {
  AnimationToLeft,
  AnimationToRight,
  PopUpImg,
  AnimationToTop,
  ServiceMotionDiv,
} from "./home-styled";
import ServiceBox from "./components/service/ServiceBox";
import appLogo from "../../img/appIcon.svg";
import CmlIcon from "../dashboard/components/common/icon/CmlIcon";

const { Header, Content } = Layout;

const buildInfo =
  <>
    <div className="aic-g5">
      <CmlIcon iconName="construction" />
      <span>Cempal frontend build information</span>
    </div>
    <div className="neutral-text mt-10">Built on</div>
    <div>2024-09-12T11:41:28.638Z</div>
    <div className="neutral-text mt-10">Environment Stage</div>
    <div>dev</div>
    <div className="neutral-text mt-10">Built from</div>
    <div>68931066d470</div>
  </>
;

const LandingPage = () => {
  const navigate = useNavigate();
  const navigateLoginFn = () => {
    navigate("/login");
  };

  return (
    <Layout>
      <Header>
        <div className="header-wrapper">
          <img src={appLogo} alt="brandLogo" referrerPolicy="no-referrer" />
          <Button type="primary" icon={<CmlIcon iconName="person" />} onClick={navigateLoginFn}>
            Login
          </Button>
        </div>
      </Header>
      <Content>
        <Row>
          <Col sm={0} md={2}>
          
          </Col>
          <Col sm={24} md={20} className="gutter-sm">
            <AnimationToRight>
              <div className="gfs glh ta-l p-40">
                <h1 className="mb-20">Our Awesome Services</h1>
                Our Leave Management App streamlines the leave request and
                approval process for organizations, saving time and
                increasing efficiency for both employees and HR departments.
                With our user-friendly platform, employees can easily
                request time off and track their leave balances, while
                managers can efficiently approve or reject requests and
                monitor team members' time off. The application integrates
                with HR systems, providing a central hub for all
                leave-related information. Customizable workflows and
                notifications ensure that no request goes unnoticed, keeping
                everyone informed throughout the process. Optimize your
                leave administration with our one-stop-shop solution.
              </div>
            </AnimationToRight>

            <Row>
              <Col>
                <AnimationToTop>
                  <div className="service-box-container">
                    <ServiceMotionDiv>
                      <ServiceBox
                        icon="SaaS"
                        title="Multi Tenant Management"
                        subtitle="Our solution designed to manage multiple companies within a single platform. The system allows for the creation and management of separate entities with unique administrative settings, while providing a centralized view of all companies."
                      />
                    </ServiceMotionDiv>

                    <ServiceMotionDiv>
                      <ServiceBox
                        icon="multiRole"
                        title="Multi Role Leave Management"
                        subtitle="A functionality that supports different roles within an organization by allowing users, team leads, and admins to interact with leave requests and approvals based on their responsibilities. It streamlines the leave management process and ensures that everyone is kept informed and that leave is managed consistently and transparently."
                      />
                    </ServiceMotionDiv>

                    <ServiceMotionDiv>
                      <ServiceBox
                        icon="publicShare"
                        title="Publicly Share Team Balance"
                        subtitle="A feature that allows organizations to share their team's leave balances publicly. This makes it easier for team leads to make informed decisions while also keeping employees informed about their own and their team's leave balances. The feature can be configured to display leave balances until the generation of the public link. Publicly Share Team Balance is a simple and effective way to increase transparency and improve the leave management process."
                      />
                    </ServiceMotionDiv>

                    <ServiceMotionDiv>
                      <ServiceBox
                        icon="ApplyLeave"
                        title="Apply Leave On the Go"
                        subtitle="An aspect allowing employees to apply for leave anytime and anywhere using their mobile devices. This convenient feature makes it easier for employees to request time off and helps team leads and admins approve or reject leave requests quickly, even when they are on the go. With Apply Leave On the Go, organizations can improve their leave management process and keep everyone informed, regardless of their location."
                      />
                    </ServiceMotionDiv>

                    <ServiceMotionDiv>
                      <ServiceBox
                        icon="Notification"
                        title="Get Notification On Time"
                        subtitle="Another highlight that ensures all parties receive timely notifications about leave requests and approvals. Employees receive notifications when their leave requests are approved or rejected, while team leads or admins receive notifications when a new leave request is submitted for approval. On the approval of the request, a Google Calendar event will be created for both parties."
                      />
                    </ServiceMotionDiv>
                  </div>
                </AnimationToTop>
              </Col>
            </Row>

            <Row className="mt-70">
              <Col span={24}>
                <div className="service-box-container">
                  <AnimationToLeft>
                    <div className="service-box transparent-div">
                      <h3 className="mb-20">A few words about Craftsmen</h3>
                      <div className="ta-l">
                        <h4 className="mt-30">Who We are</h4>
                        <p className="gfs glh">
                          <a href="https://craftsmenltd.com" target="_blank" rel="noreferrer">Craftsmen Ltd.</a> was established on the perfect blend of
                          global experience of software engineers having more than
                          10 years of experience working with world’s top software
                          companies. We are providing premium software development
                          solutions to the leading companies in the world.
                        </p>

                        <h4 className="mt-30">Our Mission</h4>
                        <p className="gfs glh">
                          Craftsmen aiming customers to achieve operational
                          efficiency and excellence. Our mission is to increase
                          customers’ business value chain through highly productive
                          automated environment. We are continuously engaged to
                          invent new ways to optimize customers’ operations through
                          technology.
                        </p>

                        <h4 className="mt-30">Our Vision</h4>
                        <p className="gfs glh">
                          We are committed to add value in our customer’s business.
                          Whatever the business domain is, we can simplify and
                          streamline business workflows by saving customer’s time
                          and cost.
                        </p>

                        <div className="mt-30">
                          <Popover content={buildInfo} trigger="hover">
                            <Button
                              type="primary"
                              icon={<CmlIcon iconName="rocket_launch" />}
                              onClick={navigateLoginFn}>
                              Get Started
                            </Button>
                          </Popover>
                        </div>
                      </div>
                    </div>
                  </AnimationToLeft>
                  <PopUpImg src={aboutPic} className="lp-info-img" alt="Office" />
                </div>
                <div className="ta-c mt-20 mb-70">
                  <p className="footer-note">
                    Copyright &copy; 2023 Craftsmen LTD. All rights reserved
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col sm={0} md={2}>
          
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default LandingPage;
