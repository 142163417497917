import { Auth } from "aws-amplify";
import secureLocalStorage from "react-secure-storage";

const handleLocalStorageClearing = () => {
  // Upon logout, clear all items except preferred theme.
  // If preferred theme is null, set it to "light"
  const tempThemeValue = localStorage.getItem("preferredTheme");
  const tempEndpoint = JSON.parse(localStorage.getItem("endpoints"));
  localStorage.clear();
  secureLocalStorage.clear();
  localStorage.setItem(
    "preferredTheme",
    tempThemeValue === "dark" ? tempThemeValue : "light"
  );
  localStorage.setItem("endpoints", JSON.stringify(tempEndpoint));
};

export const handleLogout = async () => {
  handleLocalStorageClearing();
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
};
