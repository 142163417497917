import { Modal } from "antd";
import lodash from "lodash";
import { useAtom, useAtomValue } from "jotai";

import { getDateInTextFormat } from "../../../util/formatter/getDateInTextFormat";
import { authUserAtom, userLeaveDetailsAtom } from "../../../contexts/generalStore/store";
import { cancelLeaveRequest } from "../../../controller/common/cancelLeave";
import { spawnErrorToast, spawnSuccessToast } from "../../../util/toast/spawnToast";
import { removeRequest } from "../../../core/leaveRequest/requestActions/removeRequestHandler";
import { GeneralContext } from "../../../contexts/general/Context";
import { useContext } from "react";
import { updateBalanceUponCancelHandler } from "../../../core/leaveBalance/updateBalance/updateBalanceUponCancel";
import CmlIcon from "../../dashboard/components/common/icon/CmlIcon";


const CancelModal = ({
  open,
  setOpen,
  confirmLoading,
  setConfirmLoading,
  selectedLeaveRequest,
  userLeaveData,
  setUserLeaveData
}) => {

  const { currentTheme } = useContext(GeneralContext);

  const authUser = useAtomValue(authUserAtom);
  const [userLeaveDetails, setUserLeaveDetails] = useAtom(userLeaveDetailsAtom);

  const handleSubmit = async () => {
    setConfirmLoading(true);
    try {
      const payload = {
        startDate: selectedLeaveRequest?.details?.startDate,
        creationDate: selectedLeaveRequest?.creationDate,
        reqId: selectedLeaveRequest?.reqId,
        userType: selectedLeaveRequest?.fromWhom,
      };
      await cancelLeaveRequest(authUser, payload);
      spawnSuccessToast("Leave request cancelled successfully");
      updateBalanceUponCancelHandler(
        selectedLeaveRequest,
        userLeaveDetails,
        setUserLeaveDetails,
        setOpen
      );
      removeRequest(userLeaveData, selectedLeaveRequest, setUserLeaveData);
    } catch (error) {
      spawnErrorToast("Something went wrong, please try again later");
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        title="Cancel Leave Request"
        open={open}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okText="Submit"
        cancelText="Close"
        okButtonProps={{
          type: "primary",
          icon: <CmlIcon iconName="task_alt" />
        }}
        cancelButtonProps={{
          icon: <CmlIcon iconName="cancel" />
        }}
        confirmLoading={confirmLoading}
        maskClosable={false}
      >
        <p>
          Are you sure you want to cancel{" "}
          <b>{lodash.startCase(selectedLeaveRequest?.details?.leaveType)}</b>{" "}
          from{" "}
          <b>{getDateInTextFormat(selectedLeaveRequest?.details?.startDate)}</b>{" "}
          to{" "}
          <b>{getDateInTextFormat(selectedLeaveRequest?.details?.endDate)}</b> ?
        </p>
      </Modal>
    </>
  );
};
export default CancelModal;
