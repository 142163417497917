import { get } from "../../lib/fetch";
import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";

export const getUnassignedList = async (authUser, continueToken) => {
  let url = endpoints.UNASSIGNED_EMPLOYEE_LIST;
  if (continueToken) {
    url = `${url}?continuationToken=${continueToken}`;
  }
  const headers = await getHeaders(authUser);

  return await get({ url, headers });
};
