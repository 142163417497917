import {Table, Tag} from "antd";
import LeaveStatusTag from "../../../tags/LeaveStatusTag";
import {getDateInTextFormat} from "../../../../util/formatter/getDateInTextFormat";

const OvertimeExpandedRow = ({ record, renderActionCell }) => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'authorName',
            key: 'authorName',
            render: (text, _, index) => (index === 0 ? text : ''),
        },
        {
            title: 'Description',
            dataIndex: ['details', 'description'],
            key: 'description' },
        {
            dataIndex: "details",
            title: "Date",
            render: (details) => {
                return getDateInTextFormat(details?.workDate);
            },
            align: "start",
            width: "250px",
        },
        {
            dataIndex: "status",
            title: "Status",
            align: "start",
            render: (status) => {
                return <LeaveStatusTag status={status}>{status}</LeaveStatusTag>;
            },
            width: "200px",
        },
        {
            title: 'Hours',
            dataIndex: ['details', 'hours'],
            key: 'hours',
            render: (text) => text ? text : 8
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "reqId",
            render: (text, record) => {
                return renderActionCell(record);
            },
            align: "center",
            width: "150px",
        },
    ];

    return (
        <div className="expanded-row-shift-right">
            <Table
                columns={columns}
                dataSource={record.items}
                pagination={false}
                rowKey="reqId"
            />
        </div>
);
};

export default OvertimeExpandedRow;
