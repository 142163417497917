import { Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import secureLocalStorage from "react-secure-storage";

export function RequireAuth({ children }) {
  const { route } = useAuthenticator((context) => [context.route]);
  const authUser = JSON.parse(secureLocalStorage.getItem("authUser"));
  if (!authUser) {
    return <Navigate to="/" state={{ from: route }} replace />;
  } else {
    return children;
  }
}
