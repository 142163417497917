import React from "react";

import MessageContainer from "../messageContainer/MessageContainer";

const Help = () => {
const selectedGroupJSON = localStorage.getItem("selectedGroup");
let parsedSelectedGroup = null;

if (selectedGroupJSON) {
  const parsedSelectedGroupArray = JSON.parse(selectedGroupJSON);
  if (Array.isArray(parsedSelectedGroupArray) && parsedSelectedGroupArray.length > 0) {
    parsedSelectedGroup = parsedSelectedGroupArray[0];
  }
}
  return (
    <>
      {
          parsedSelectedGroup === "unassignedEmployee" &&
          <>
            <MessageContainer
              messages={[
                "You are not assigned to any team yet!",
                "Ask admin to assign you.",
              ]}
            />
          </>
      }
    </>
  );
};

export default Help;
