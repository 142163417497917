import { useAtomValue, useSetAtom } from "jotai";
import React from "react";


import {
  adminHistoryEndDate,
  adminHistoryStartDate,
  continueTokenAtom,
  selectedRoleAtom,
  selectedSearchTypeAtom,
  selectedStatusAtom,
} from "../../../../../../../../contexts/generalStore/store";

import {
  IconWrapper,
  SelectTitleWrapper,
  SelectWrapper,
  UnderLineText,
} from "../../history-styled";
import { Select } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";

const RoleInput = () => {
  const setSelectedRole = useSetAtom(selectedRoleAtom);
  const startDate = useAtomValue(adminHistoryStartDate);
  const endDate = useAtomValue(adminHistoryEndDate);
  const searchType = useAtomValue(selectedSearchTypeAtom);
  const selectedRole = useAtomValue(selectedRoleAtom);
  const selectedStatus = useAtomValue(selectedStatusAtom);
  const setStartDate = useSetAtom(adminHistoryStartDate);
  const setEndDate = useSetAtom(adminHistoryEndDate);
  const setLastEvaluatedKey = useSetAtom(continueTokenAtom);

  const handleOnChange = (value) => {
    setSelectedRole(value);
    setLastEvaluatedKey("");
    if (searchType === "teamSpecific") {
      setStartDate();
      setEndDate();
    }
  };

  const handleDisable = () => {
    if (
      searchType === "userSpecific" &&
      !isNaN(Date.parse(startDate)) &&
      !isNaN(Date.parse(endDate))
    )
      return false;
    if (
      searchType === "teamSpecific" &&
      selectedStatus &&
      selectedStatus !== "invalid"
    )
      return false;
    return true;
  };

  const handleReset = () => {
    setSelectedRole("invalid");
    if (searchType === "teamSpecific") {
      setStartDate();
      setEndDate();
    }
    setLastEvaluatedKey("");
  };

  return (
    <div>
      <SelectTitleWrapper>
        <UnderLineText>Select Role</UnderLineText>
        <SelectWrapper>
          <Select
            aria-label="Default select example"
            className="customSelect"
            style={{
              width: "100%",
            }}
            defaultValue={"invalid"}
            onChange={handleOnChange}
            value={selectedRole}
            disabled={handleDisable()}
          >
            <Select.Option value="invalid">-- Select a role --</Select.Option>
            <Select.Option value="lead">Team Lead</Select.Option>
            <Select.Option value="employee">Member</Select.Option>
            <Select.Option value="leadEmployee">On Behalf</Select.Option>
          </Select>
          <IconWrapper>
            <CloseCircleFilled
              className={
                selectedRole && selectedRole !== "invalid"
                  ? "icon"
                  : "icon-disabled"
              }
              onClick={() => handleReset()}
              disabled={
                selectedRole && selectedRole !== "invalid" ? false : true
              }
            />
          </IconWrapper>
        </SelectWrapper>
      </SelectTitleWrapper>
    </div>
  );
};

export default RoleInput;
