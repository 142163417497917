import { motion } from "framer-motion";

const ServiceMotionDiv = (props) => (
  <motion.div className="service-box transparent-div" whileHover={{ scale: 1.1 }}>
    {props.children}
  </motion.div>
);

const AnimatedP = (props) => (
  <motion.p
    initial="hidden"
    whileInView="visible"
    viewport={{ once: false }}
    transition={{ ease: "easeOut", duration: 1.5 }}
    variants={{
      visible: { x: -0 },
      hidden: { x: "100%" },
    }}
  >
    {props.children}
  </motion.p>
);

const AnimationToRight = (props) => (
  <motion.div
    initial={{ x: "-100%" }}
    animate={{ x: 0 }}
    transition={{ ease: "easeOut", duration: 1 }}
  >
    {props.children}
  </motion.div>
);

const AnimationToLeft = (props) => (
  <motion.div
    initial="hidden"
    whileInView="visible"
    viewport={{ once: false }}
    transition={{ ease: "easeOut", duration: 1.5 }}
    variants={{
      visible: { x: -0 },
      hidden: { x: "100%" },
    }}
  >
    {props.children}
  </motion.div>
);

const AnimationToTop = (props) => (
  <motion.div
    initial={{ y: "100%" }}
    animate={{ y: 0 }}
    transition={{ ease: "easeOut", duration: 1.5 }}
  >
    {props.children}
  </motion.div>
);

const PopUpImg = (props) => (
  <motion.img
    className={props.className}
    src={props.src}
    alt={props.alt}
    initial="hidden"
    whileInView="visible"
    viewport={{ once: false }}
    transition={{ duration: 1 }}
    variants={{
      visible: { opacity: 1, scale: 1 },
      hidden: { opacity: 0, scale: 0 },
    }}
  />
);

export {
  AnimationToLeft,
  AnimationToRight,
  AnimationToTop,
  PopUpImg,
  ServiceMotionDiv,
  AnimatedP,
};
