import { Button, DatePicker, Form } from "antd";
import React, { useEffect } from "react";
import dayjs from "dayjs";

import ModalGroupSelect from "../../../../../../../../select/modalGroupSelect/ModalGroupSelect";
import ModalTeamSelectInput from "../../../../../../../../select/modalTeamSelect/ModalTeamSelectInput";
import CmlIcon from "../../../../../../../../common/icon/CmlIcon";

const AssignMemberForm = ({
  user,
  handleSubmit,
  handleDateChange,
  selectedDate,
  validateTeamSelect,
  isLoading,
}) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      assignDate: dayjs(selectedDate),
    });
  }, [selectedDate]);
  return (
    <div>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Form.Item>
          <div className="cml-card">
            <div className="neutral-text">Employee Email:</div>
            <div>{user?.email}</div>
          </div>
        </Form.Item>
        <Form.Item
          label={<div className="neutral-text">Team</div>}
          name="team"
          rules={[{ required: true, validator: validateTeamSelect }]}
          hasFeedback={true}
        >
          <ModalTeamSelectInput fromUnassignedTable={true} />
        </Form.Item>
        <Form.Item
          label={<div className="neutral-text">Group</div>}
          name="group"
          required
          hasFeedback={true}
        >
          <ModalGroupSelect fromUnassignedTable={true} />
        </Form.Item>

        <Form.Item
          label={<div className="neutral-text">Assign Date</div>}
          name={"assignDate"}
          rules={[{ required: true, message: "Please select date" }]}
          hasFeedback={true}
        >
          <DatePicker
            className="full-width"
            onChange={handleDateChange}
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentNode;
            }}
            value={dayjs(selectedDate)}
            showToday={false}
            allowClear={false}
            inputReadOnly={true}
          />
        </Form.Item>

        <Form.Item>
          <div className="right-aligned-buttons mt-15">
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              icon={<CmlIcon iconName="task_alt" />}
            >
              Assign
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AssignMemberForm;
