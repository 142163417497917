import React from "react";
import { useAtom, useSetAtom } from "jotai";
import { Select } from "antd";

import {
  selectedSuperAdminGroupAtom,
  selectedSuperAdminTeamAtom
} from "../../../../../../contexts/generalStore/store";

const SelectGroupInput = () => {
  const [selectedGroupValue, setSelectedGroupValue] = useAtom(
    selectedSuperAdminGroupAtom
  );

  const setSelectedSuperAdminTeam = useSetAtom(selectedSuperAdminTeamAtom);

  const teamNonSensitiveGroups = ["tenantAdmin", "unassignedEmployee"];

  const handleOnChange = (value) => {
    setSelectedGroupValue(value);
    if (teamNonSensitiveGroups.includes(selectedGroupValue)) {
      setSelectedSuperAdminTeam();
    }
  };

  return (
    <Select
      aria-label="Default select example"
      onChange={handleOnChange}
      defaultValue={selectedGroupValue}
    >
      <Select.Option value="tenantAdmin"> Tenant Admin </Select.Option>
      <Select.Option value="teamLead"> Team Lead </Select.Option>
      <Select.Option value="employee"> Employee </Select.Option>
      <Select.Option value="unassignedEmployee"> Unassigned Employee </Select.Option>
    </Select>
  );
};

export default SelectGroupInput;
