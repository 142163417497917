import { useEffect, useState } from "react";
import { Select } from "antd";
import { useAtomValue } from "jotai";
import { teamListAtom } from "../../../../../contexts/generalStore/store";

const LayoutTeamSelectInput = ({ selectedTeam, fullWidth }) => {
  const teamList = useAtomValue(teamListAtom);
  const [teamName, setTeamName] = useState("invalid");

  const handleOnChange = (team) => {
    setTeamName(team);
  };
  useEffect(() => {
    selectedTeam(teamName);
  }, [teamName]);

  return (
    <Select onChange={handleOnChange} defaultValue={"-- Select a Team --"} className={ fullWidth ? "full-width" : "" }>
      <Select.Option value={"invalid"}>-- Select a Team --</Select.Option>
      {typeof teamList === "object" &&
        teamList.map((team, index) => {
          return (
            <Select.Option key={index} value={team}>
              {team}
            </Select.Option>
          );
        })}
    </Select>
  );
};

export default LayoutTeamSelectInput;
