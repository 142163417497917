import { get } from "../../lib/fetch";
import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";

export const getSpecificTeamBalance = async (
  authUser,
  teamName,
  continueToken
) => {
  let url = `${endpoints.TEAM_LIST}?teamName=${teamName}`;
  if (continueToken) {
    url = `${url}&continuationToken=${continueToken}`;
  }
  const headers = await getHeaders(authUser);

  return await get({ url, headers });
};
