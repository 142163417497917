export const setHolidayWeekend = (
  tenantConfig,
  expireDate,
  weekendFirst,
  weekendSecond,
  setHoliday
) => {
  tenantConfig?.forEach((value, index) => {
    expireDate.push(new Date(value?.expiredAt));
    const holidayArray = Object.keys(value?.holiday).map((date) => {
      return {
        date: new Date(date),
        title: value?.holiday[date].title,
      };
    });

    Object.keys(value?.weekend)?.map((item) => {
      if (index === 0) {
        if (!weekendFirst.includes(parseInt(item))) {
          weekendFirst.push(parseInt(item));
        }
      }
      if (index === 1) {
        if (!weekendSecond.includes(parseInt(item))) {
          weekendSecond.push(parseInt(item));
        }
      }
    });
    setHoliday((prevState) => [...prevState, ...holidayArray]);
  });
};
