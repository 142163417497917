import { getOwnLeaveRequest } from "../../controller/employee/getOwnLeaveRequest";

const isInArray = (array, DateValue) => {
  return !!array.find((date) => {
    return date.getTime() === DateValue.getTime();
  });
};

export const getDatesInRange = (
  startDate,
  endDate,
  status,
  datesApproved,
  datesModify,
  datesPending,
  excludedDates,
  takenLeaveDates,
  fromEdit
) => {
  const date = new Date(startDate.getTime());
  while (date <= endDate) {
    if (status === "approved") {
      if (!isInArray(datesApproved, new Date(date))) {
        datesApproved.push(new Date(date));
        excludedDates.push(new Date(date));
      }
      if (!isInArray(takenLeaveDates, new Date(date))) {
        takenLeaveDates?.push(new Date(date));
      }
    } else if (status === "modification") {
      if (!isInArray(datesModify, new Date(date))) {
        datesModify.push(new Date(date));
        if (!fromEdit) {
          excludedDates.push(new Date(date));
        }
      }
      if (!isInArray(takenLeaveDates, new Date(date))) {
        takenLeaveDates?.push(new Date(date));
      }
    } else if (status === "pending") {
      if (!isInArray(datesPending, new Date(date))) {
        datesPending.push(new Date(date));
        if (!fromEdit) {
          excludedDates.push(new Date(date));
        }
      }
      if (!isInArray(takenLeaveDates, new Date(date))) {
        takenLeaveDates?.push(new Date(date));
      }
    }

    date.setDate(date.getDate() + 1);
  }
};

export const getLeaveRequest = async (
  tenantConfig,
  authUser,
  datesApproved,
  datesModify,
  datesPending,
  excludedDates,
  takenLeaveDates,
  fromEdit,
  setUpdateLeaveArray
) => {
  const firstYearStart = tenantConfig[0]?.expiredAt;
  const currentYear = new Date(firstYearStart).getFullYear();
  const twoYearsBeforeExpirationYear = currentYear - 1;
  const twoYearsBeforeExpirationDate = new Date(firstYearStart).setFullYear(
    twoYearsBeforeExpirationYear
  );
  const fromDate = new Date(twoYearsBeforeExpirationDate).toISOString();
  const toDate = new Date(
    tenantConfig[tenantConfig.length - 1]?.expiredAt
  ).toISOString();
  const takenLeavesList = await getOwnLeaveRequest(authUser, fromDate, toDate);

  takenLeavesList?.data?.Items.forEach((item) => {
    getDatesInRange(
      new Date(item.details.startDate),
      new Date(item.details.endDate),
      item.status,
      datesApproved,
      datesModify,
      datesPending,
      excludedDates,
      takenLeaveDates,
      fromEdit
    );
  });
  setUpdateLeaveArray(true);
};
