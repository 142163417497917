import React, { useEffect, useState } from "react";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { Tag } from "antd";

const LeaveStatusTag = ({ status }) => {
  const [background, setBackground] = useState("");
  const [icon, setIcon] = useState(null);
  const [badgeText, setBadgeText] = useState(status);

  useEffect(() => {
    setBadgeText(status)
    if (status.toLowerCase() === "pending") {
      setBackground("warning");
      setIcon(<SyncOutlined spin />);
    } else if (status.toLowerCase() === "approved") {
      setBackground("success");
      setIcon(<CheckCircleOutlined />);
    } else if (status.toLowerCase() === "rejected") {
      setBackground("error");
      setIcon(<CloseCircleOutlined />);
    } else if (status.toLowerCase() === "canceled") {
      setBackground("error");
      setIcon(<CloseCircleOutlined />);
    } else if (status.toLowerCase() === "modification") {
      setBackground("processing");
      setIcon(<ExclamationCircleOutlined />);
    } else {
      setBackground("default");
      setIcon(<MinusCircleOutlined />);
      setBadgeText("N/A");
    }
  }, [status]);

  return (
    <Tag icon={icon} color={background}>
      {badgeText}
    </Tag>
  );
};

export default LeaveStatusTag;
