import React, { useState, useEffect } from "react";
import { Button, List, Skeleton } from "antd";
import { CheckCircleFilled, CopyFilled } from "@ant-design/icons";
import { getSnapShotLinks } from "../../../../../controller/teamLead/getSnapActiveLinks";
import { getDateInTextFormat } from "../../../../../util/formatter/getDateInTextFormat";
import { spawnErrorToast } from "../../../../../util/toast/spawnToast";

const SnapLinks = ({ drawerOpen, linksListData, setLinksListData }) => {

  const [inProgress, setInProgress] = useState(true);
  const [isCopiedId, setIsCopiedId] = useState("");

  const populateData = (response) => {
    const origin = window.location.origin;
    response.data?.Items?.forEach((element) => {
      setLinksListData((prev) => [
        ...prev,
        {
          link: `${origin}/public?shareId=${element.shareId}`,
          createdAt: element.createdAt,
          expireDate: element.expireDate,
        },
      ]);
    });
  };

  const getLinks = async () => {
    setInProgress(true);
    try {
      const response = await getSnapShotLinks();
      populateData(response);
    } catch (error) {
      spawnErrorToast(error.response?.data?.message);
    } finally {
      setInProgress(false);
    }
  };

  useEffect(() => {
    if (drawerOpen) {
      setLinksListData([]);
      getLinks();
    }
  }, [drawerOpen]);

  const handleCopy = async (item) => {
    try {
      await navigator.clipboard.writeText(item.link);
      setIsCopiedId(item.link);
      revertCopyButtonIcon(item);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const revertCopyButtonIcon = async (row) => {
    await new Promise((resolve) => setTimeout(resolve, 1500));
    setIsCopiedId("");
  };

  return (
    <div>
      {!inProgress ? (
        <List
          className="Links-list"
          loading={inProgress}
          itemLayout={"vertical"}
          dataSource={linksListData}
          renderItem={(item) => (
            <List.Item
              actions={[
                <>
                  <Button
                    onClick={() => handleCopy(item)}
                    type="primary"
                    icon={isCopiedId === item.link ? <CheckCircleFilled /> : <CopyFilled />}
                  >
                    {isCopiedId === item.link ? "Copied" : "Copy"}
                  </Button>
                </>,
              ]}
            >
              <List.Item.Meta
                title={
                  <div className="neutral-text">
                    Link: <a href={item?.link}>{item?.link}</a>
                  </div>
                }
                description={
                  <>
                    <div className="neutral-text">
                      Creation Date: <span className="text">{getDateInTextFormat(item.createdAt)}</span>
                    </div>
                    <div className="neutral-text">
                      Expire Date: <span className="text">{getDateInTextFormat(item.expireDate)}</span>
                    </div>
                  </>
                }
              />
            </List.Item>
          )}
        />
      ) : (
        <Skeleton loading={inProgress} active></Skeleton>
      )}
    </div>
  );
};

export default SnapLinks;
