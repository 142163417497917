import { get } from "../../lib/fetch";
import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";

export const getLeaveRequest = async (authUser, continueToken) => {
  let url = `${endpoints.TEAM_MEMBER_APPLICATION}`;
  if (continueToken) {
    url = `${url}?continuationToken=${continueToken}`;
  }
  const headers = await getHeaders(authUser);
  return await get({ url, headers });
};
