import React from "react";
import { Space, Spin } from "antd";
import { LoadingContainer } from "./Loading-Styled";

const Loading = () => {
  return (
    <LoadingContainer>
      <Space size="middle">
        <Spin size="large" />
      </Space>
    </LoadingContainer>
  );
};

export default Loading;
