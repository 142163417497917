import { Button, Empty, Table } from "antd";
import React from "react";

const DynamicTable = ({
  rowKey,
  tableProps,
  columns,
  data,
  inProgress,
  isAllDataFetched,
  callApiToLoadMore,
  handleRowClick,
  scroll,
  size,
  expandedRowRender,
}) => {
  const loadMore = !isAllDataFetched ? (
    <div className="mt-10">
      <Button
        onClick={() => callApiToLoadMore()}
        type="primary"
        disabled={inProgress}
        loading={inProgress}
        hidden={true}
      >
        { inProgress === true ? "Loading" : "Show More" }
      </Button>
    </div>
  ) : null;

  const paginationOptions = {
    pageSize: data.length,
    position: ["none", "none"],
  };

  const rowClassName = (record, index) => {
    return index % 2 === 0 ? "even-row" : "odd-row";
  };

  const customNoDataMessage = <Empty description="No data" />;

  return (
    <div>
      <Table
        rowKey={rowKey}
        {...tableProps}
        columns={columns}
        dataSource={data}
        pagination={paginationOptions}
        className="table-responsive"
        size={size}
        rowClassName={rowClassName}
        locale={{ emptyText: customNoDataMessage }}
        onRow={
          handleRowClick
            ? (record, rowIndex) => {
                return {
                  onClick: () => {
                    handleRowClick(record);
                  },
                };
              }
            : () => {}
        }
        scroll={scroll}
        expandable={expandedRowRender}
      />
      {loadMore}
    </div>
  );
};

export default DynamicTable;
