import React, { useContext, useState } from "react";
import { useAtomValue, useSetAtom, useAtom } from "jotai";
import { Button, Form, Input, Modal } from "antd";

import {
  addressAtom,
  authUserAtom,
  contactAtom,
  domainAtom,
  showModalsAtom,
  tenantNameAtom,
  tenantsAtom,
} from "../../../../../../../contexts/generalStore/store";
import { GeneralContext } from "../../../../../../../contexts/general/Context";
import { addTenanthandler } from "../../../../../../../core/tenant/addTenant/addTenantHandler";
import { SendOutlined } from "@ant-design/icons";

const AddTenant = () => {
  const [domain, setDomain] = useAtom(domainAtom);
  const [contact, setContact] = useAtom(contactAtom);
  const [tenantName, setTenantName] = useAtom(tenantNameAtom);
  const [address, setAddress] = useAtom(addressAtom);
  const [showModals, setShowModals] = useAtom(showModalsAtom);

  const [inProgress, setInProgress] = useState(false);
  const { currentTheme } = useContext(GeneralContext);

  const setTenants = useSetAtom(tenantsAtom);
  const tenants = useAtomValue(tenantsAtom);
  const authUser = useAtomValue(authUserAtom);

  const handleClose = () => {
    setShowModals({ ...showModals, showAddTenantModal: false });
    setDomain("");
    setContact("");
    setAddress("");
    setTenantName("");
  };

  const getStateArguments = () => {
    return {
      tenantName: tenantName,
      domain: domain,
      contact: contact,
      address: address,
      handleClose: handleClose,
      setInProgress: setInProgress,
      setTenants: setTenants,
      tenants: tenants,
      authUser: authUser,
    };
  };

  const handleInsert = async () => {
    const args = getStateArguments();
    addTenanthandler(args);
  };

  return (
    <>
      <Modal
        title="Add a new Tenant"
        open={showModals.showAddTenantModal}
        onCancel={handleClose}
        okButtonProps={{
          style: { display: "none" },
        }}
        cancelButtonProps={{
          style: { display: "none" },
        }}
        confirmLoading={inProgress}
        mask={true}
        maskClosable={false}
      >
        <Form
          onFinish={handleInsert}
          layout="vertical"
        >
          <Form.Item
            label={<div className="neutral-text">Name : </div>}
            rules={[{ required: true, message: "Please enter name" }]}
            hasFeedback={true}
            name="tenantName"
          >
            <Input
              value={tenantName}
              onChange={(e) => setTenantName(e.target.value)}
            />

          </Form.Item>
          <Form.Item
            label={<div className="neutral-text">Domain : </div>}
            rules={[{ required: true, message: "Please enter tenant's domain" }]}
            hasFeedback={true}
            name="tenantDomain"
          >
            <Input
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={<div className="neutral-text">Contact : </div>}
            rules={[{ required: true, message: "Please enter contact" }]}
            hasFeedback={true}
            name="contact"
          >
            <Input
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={<div className="neutral-text">Address : </div>}
            rules={[{ required: true, message: "Please enter address" }]}
            hasFeedback={true}
            name="address"
          >
            <Input.TextArea
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <div className="right-aligned-buttons mt-20">
              <Button
                type="primary"
                htmlType="submit"
                loading={inProgress}
                icon={<SendOutlined />}
              >
                Create
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddTenant;
