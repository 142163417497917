import { getTenant } from "../../../controller/superadmin/getTenant";
import { HttpStatusCode } from "../../../enum";
import { spawnErrorToast } from "../../../util/toast/spawnToast";

export const getTenantsInfoHandler = async ({
  authUser,
  setTenants,
  setAllDataFetched,
  setLastEvaluatedKey,
  setInProgress,
  lastEvaluatedKey,
}) => {
  setInProgress(true);
  try {
    const res = await getTenant(authUser, lastEvaluatedKey);
    if (res?.data?.LastEvaluatedKey) {
      setLastEvaluatedKey(res?.data?.LastEvaluatedKey);
      setAllDataFetched(false);
    } else {
      setAllDataFetched(true);
    }
    if (lastEvaluatedKey) {
      setTenants((prev) => [...prev, ...res?.data?.Items]);
    } else {
      setTenants(res?.data?.Items);
    }
  } catch (error) {
    if (error?.response?.status === HttpStatusCode.UNAUTHORIZED) {
      spawnErrorToast("You are unauthorized");
    } else {
      spawnErrorToast("Something went wrong");
    }
  } finally {
    setInProgress(false);
  }
};
