import { getSpecificTenantTeam } from "../../controller/superadmin/getSpecificTenantTeam";
import { spawnErrorToast } from "../../util/toast/spawnToast";

export const getTeamSuperAdminHandler = async (
  { authUser, selectedTenantValue, setTenantTeams },
  LastEvaluatedKey
) => {
  try {
    const response = await getSpecificTenantTeam(
      authUser,
      selectedTenantValue,
      LastEvaluatedKey
    );
    if (!response?.data?.Items?.length > 0) {
      spawnErrorToast("No team is created yet");
      return;
    }
    response?.data?.Items?.map((team) =>
      setTenantTeams((prev) => [...prev, team.teamName])
    );
    if (response?.data?.LastEvaluatedKey) {
      getTeamSuperAdminHandler(response?.data?.LastEvaluatedKey);
    }
  } catch (error) {
    spawnErrorToast("Can't retrieve teams at the moment");
  }
};
