import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import { Select } from "antd";

import {
  adminHistoryEndDate,
  adminHistoryStartDate,
  apiCallAtom,
  continueTokenAtom,
  memberListAtom,
  selectedMemberAtom,
  selectedRoleAtom,
  selectedSearchTypeAtom,
  selectedStatusAtom,
  selectedTeamForMembersRequestsAtom,
} from "../../../../../../../../contexts/generalStore/store";

import {
  IconWrapper,
  SelectTitleWrapper,
  SelectWrapper,
  UnderLineText,
} from "../../history-styled";

const MembersSelectInput = ({loading}) => {
  const MemberList = useAtomValue(memberListAtom);
  const isLoading = useAtomValue(apiCallAtom);
  const setSelectedMember = useSetAtom(selectedMemberAtom);
  const setLastEvaluatedKey = useSetAtom(continueTokenAtom);
  const setStartDate = useSetAtom(adminHistoryStartDate);
  const setEndDate = useSetAtom(adminHistoryEndDate);
  const setRole = useSetAtom(selectedRoleAtom);
  const setStatus = useSetAtom(selectedStatusAtom);
  const searchType = useAtomValue(selectedSearchTypeAtom);
  const selectedMember = useAtomValue(selectedMemberAtom);
  const selectedTeam = useAtomValue(selectedTeamForMembersRequestsAtom);

  const handleOnChange = (value) => {
    setSelectedMember(value);
    setLastEvaluatedKey("");
  };

  const handleReset = () => {
    setSelectedMember("invalid");
    setLastEvaluatedKey("");
    setStartDate();
    setEndDate();
    setStatus("invalid");
    setRole("invalid");
  };

  return (
    <div>
      <SelectTitleWrapper>
        <UnderLineText>Select Member</UnderLineText>
        <SelectWrapper>
          <Select
            aria-label="Default select example"
            className="customSelect"
            style={{
              width: "100%",
            }}
            onChange={handleOnChange}
            defaultValue={"-- select a member --"}
            value={selectedMember}
            disabled={
              searchType === "userSpecific" &&
                searchType &&
                selectedTeam !== "invalid" &&
                selectedTeam
                ? false
                : true
            }
            loading={isLoading.inProgressMember}
          >
            <Select.Option value="invalid">-- Select member --</Select.Option>
            {typeof MemberList === "object" &&
              MemberList.length > 0 &&
              MemberList.map((member, index) => {
                return (
                  <Select.Option key={index} value={member}>
                    {member}
                  </Select.Option>
                );
              })}
          </Select>
          <IconWrapper>
            <CloseCircleFilled
              className={
                selectedMember && selectedMember !== "invalid"
                  ? "icon"
                  : "icon-disabled"
              }
              onClick={() => handleReset()}
            />
          </IconWrapper>
        </SelectWrapper>
      </SelectTitleWrapper>
    </div>
  );
};

export default MembersSelectInput;
