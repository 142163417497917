import { changeStatusLeaveRequestTeamLead } from "../../../controller/teamLead/leaveRequest";
import {
  spawnErrorToast,
  spawnSuccessToast,
} from "../../../util/toast/spawnToast";

const getActionButtonText = (actionType) => {
  const toastBody =
    actionType.buttonText === "Approve"
      ? "Leave Request Approved!"
      : actionType.buttonText === "Reject"
      ? "Leave Request Rejected!"
      : "Modification request successful!";
  return toastBody;
};

export const handleRequestAction = async ({
  leaveRequest,
  payload,
  actionType,
  setInProgress,
  setChangedLeaveReq,
  setShowModal,
  setCommentForModification,
}) => {
  setInProgress(true);
  try {
    await changeStatusLeaveRequestTeamLead(payload);
    setChangedLeaveReq(leaveRequest);
    setShowModal(false);
    const toastBody = getActionButtonText(actionType);
    spawnSuccessToast(toastBody);
    setCommentForModification("");
  } catch (error) {
    spawnErrorToast(error?.message ?? "Could not complete request");
  } finally {
    setInProgress(false);
  }
};
