import React, { useState, useContext, useEffect } from "react";
import { Button, DatePicker, Input, Table, Row, Col } from "antd";
import {
  getDateInTextFormat,
  toLocalDateString,
} from "../../../../../../../../util/formatter/getDateInTextFormat";
import SandwichLeave from "./components/sandwichLeave/SandwichLeave";
import PlusButton from "../../../../../common/buttons/PlusButton";
import dayjs from "dayjs";
import CmlIcon from "../../../../../common/icon/CmlIcon";

const Holidays = ({
  isEdit,
  isNewConfig,
  onDisplayTenantConfig,
  setOnDisplayTenantConfig,
  holidayList,
  setHolidayList,
}) => {
  useEffect(() => {}, [holidayList]);

  const handleAddHoliday = () => {
    const currentDate = toLocalDateString(new Date().toISOString());
    const newHoliday = {
      title: "",
      date: currentDate,
      key: holidayList.length,
      isNewRow: true,
    };

    const updatedHolidayList = [...holidayList, newHoliday];
    setHolidayList(updatedHolidayList);
  };

  const handleRemoveHoliday = (index) => {
    const updatedHolidayList = [...holidayList];
    updatedHolidayList.splice(index, 1);
    setHolidayList(updatedHolidayList);
  };

  const handleEditHoliday = (index) => {
    const updatedHolidayList = [...holidayList];
    updatedHolidayList[index].isNewRow = true;
    setHolidayList(updatedHolidayList);
  };

  const isNewRow = (row) => {
    return row?.isNewRow === true;
  };

  const handleHolidayDateChange = (date, index) => {
    const updatedHolidayList = [...holidayList];
    if (!date?.$d) {
      return;
    }
    const isoDate = new Date(date.$d).toISOString();
    updatedHolidayList[index].date = toLocalDateString(isoDate);
    setHolidayList(updatedHolidayList);
  };

  const handleHolidayTitleChange = (title, index) => {
    const updatedHolidayList = [...holidayList];
    updatedHolidayList[index]["title"] = title;
    setHolidayList(updatedHolidayList);
  };

  const getHolidayDateCol = (cell, row, rowIndex, colIndex) => {
    if (!isNewRow(row)) {
      return (
        getDateInTextFormat(row?.date)
      );
    }
    return (
      <DatePicker
        className="customDatePicker"
        format="YYYY-MM-DD"
        value={dayjs(row?.date)}
        onChange={(date) => handleHolidayDateChange(date, rowIndex)}
        showToday={false}
        allowClear={false}
        inputReadOnly={true}
      />
    );
  };

  const getHolidayTitleCol = (cell, row, rowIndex, colIndex) => {
    if (!isNewRow(row)) {
      return row?.title;
    }
    return (
      <Input
        disabled={!isEdit}
        value={row?.title}
        onChange={(e) => {
          handleHolidayTitleChange(e.target.value, rowIndex);
        }}
      />
    );
  };

  const actionCell = (cell, row, rowIndex, colIndex) => {
    return (
      <>
        <Button
          type="link"
          size="small"
          title="Edit this holiday"
          icon={<CmlIcon iconName="edit_note" />}
          onClick={() => { handleEditHoliday(rowIndex); }}
        />
        <Button
          type="link"
          size="small"
          title="Delete this holiday"
          icon={<CmlIcon iconName="delete" />}
          onClick={() => { handleRemoveHoliday(rowIndex); }}
        />
      </>
    );
  };

  const tableColumn = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: getHolidayDateCol,
    },
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      render: getHolidayTitleCol,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: actionCell,
    },
  ];

  return (
    <Row>
      <Col xs={24} md={12}>
        <div className="cml-card">
          <Row>
            <Col span={16}>
              <div className="mb-10">
                <span className="neutral-text">
                  Total Holidays Defined: {holidayList.length}
                </span>
                <span className="ml-10">
                  <PlusButton
                    isEdit={isEdit}
                    isNewConfig={isNewConfig}
                    onClick={handleAddHoliday}
                    title="Add new holiday"
                  />
                </span>
              </div>
            </Col>
            <Col span={8}>
              <div className="right-aligned-buttons">
                <SandwichLeave
                  isEdit={isEdit}
                  onDisplayTenantConfig={onDisplayTenantConfig}
                  setOnDisplayTenantConfig={setOnDisplayTenantConfig}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
            <Table
              size="small"
              dataSource={holidayList}
              columns={tableColumn}
              pagination={false}
            />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default Holidays;
