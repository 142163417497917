export const clientMessage = {
  teamBalance: {
    FAILED_TO_CREATE: "Could Not Create New Team",
    INVALID_TEAM_NAME: "Enter Valid Team Name",
    CREATING_TEAM: "Creating Team ->",
    SUCCESS: "Successfully Created!",
    EMPTY_TEAM_LIST: "No Employees Found in this Team",
  },
  unassignedEmployees: {
    FAILED_TO_RETRIEVE_DATA: "Could Not Retrieve Data",
    FAILED_TO_LOAD_MORE: "Could Not Load More Data",
    SUCCESS: "Employee Was Assigned Successfully!",
  },
};
