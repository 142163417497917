import { handleHistorySearch } from "../handleHistorySearch";
import { spawnErrorToast } from "../../../../util/toast/spawnToast";

export const handleUserSpecificSearch = ({
  selectedTeam,
  selectedMember,
  selectedStatus,
  selectedRole,
  startDate,
  endDate,
  allDataFetchedIndicator,
  setAllDataFetchedIndicator,
  isLoading,
  setIsLoading,
  lastEvaluatedKey,
  setLastEvaluatedKey,
  searchType,
  authUser,
  adminHistory,
  setAdminHistory,
}) => {
  if (!selectedTeam || selectedTeam === "invalid") {
    spawnErrorToast("Please select a team");
    return;
  } else if (!selectedMember || selectedMember === "invalid") {
    spawnErrorToast("Please select a member");
    return;
  } else if ((!endDate || isNaN(endDate)) && !isNaN(startDate)) {
    spawnErrorToast("Please select end date");
    return;
  } else {
    handleHistorySearch(
      selectedTeam,
      selectedMember,
      selectedStatus,
      selectedRole,
      startDate,
      endDate,
      allDataFetchedIndicator,
      setAllDataFetchedIndicator,
      isLoading,
      setIsLoading,
      lastEvaluatedKey,
      setLastEvaluatedKey,
      searchType,
      authUser,
      adminHistory,
      setAdminHistory
    );
  }
};
