import { getExpiredAt, getStartYear } from "../formatter/getDateInTextFormat";
import { getTimezoneDate } from "../formatter/getTimeOffsetFromTimezoneString";

export const getDefaultTenantConfig = (authUser, tenantConfigList) => {
  const startYear = getStartYear(tenantConfigList);
  const expiredAt = getExpiredAt(tenantConfigList);
  const tenantDomain = getUserTenantDomain(authUser);
  const userEmail = getUserEmail(authUser);
  const timeZone = getTimezone();
  const year = getTimezoneDate(timeZone, startYear).getFullYear();
  return {
    newConfig: true,
    createdAt: `${new Date().toISOString()}`,
    emailList: [userEmail],
    expiredAt: `${expiredAt}`,
    holiday: {
      [year + "-02-21"]: {
        title: "Language Martyrs' Day",
      },
      [year + "-03-17"]: {
        title: "Birthday Of Father of the Nation",
      },
      [year + "-03-26"]: {
        title: "Independence Day",
      },
      [year + "-04-14"]: {
        title: "Pahela Baishakh",
      },
      [year + "-05-01"]: {
        title: "May Day",
      },
      [year + "-08-15"]: {
        title: "National Mourning Day",
      },
      [year + "-12-16"]: {
        title: "Victory Day",
      },
      [year + "-12-25"]: {
        title: "Christmas Day",
      },
    },
    leaveAdjustmentType: {
      encashment: {
        maxThreshold: 20,
      },
    },
    leavePolicy: {
      appliedNotice: {
        casualLeave: true,
        sickLeave: false,
      },
      isSandwichLeave: false,
      maxLeaveAndType: {
        casualLeave: 15,
        sickLeave: 5,
      },
      noticePeriodOfLeave: {
        1: {
          day: 1,
          notice: 7,
        },
        2: {
          day: 2,
          notice: 14,
        },
        3: {
          day: 3,
          notice: 28,
        },
      },
    },
    startYear: `${startYear}`,
    tenantDomain: tenantDomain,
    timezone: timeZone,
    weekend: {
      0: {
        day: "Sunday",
      },
      6: {
        day: "Saturday",
      },
    },
  };
};

const getUserTenantDomain = (authUser) => {
  return authUser?.signInUserSession?.idToken?.payload?.tenantDomain
    ? authUser?.signInUserSession?.idToken?.payload?.tenantDomain
    : authUser?.idToken?.payload?.tenantDomain
    ? authUser?.idToken?.payload?.tenantDomain
    : null;
};

const getUserEmail = (authUser) => {
  return authUser?.signInUserSession?.idToken?.payload?.email
    ? authUser?.signInUserSession?.idToken?.payload?.email
    : authUser?.idToken?.payload?.email
    ? authUser?.idToken?.payload?.email
    : null;
};

const getTimezone = () => {
  return {
    offset: "+06:00",
    value: "Asia/Dhaka",
  };
};
