import { get } from "../../lib/fetch";
import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";

export const getOwnLeaveRequest = async (
  authUser,
  fromDateTime,
  toDateTime,
  continuationToken = ""
) => {
  let url = endpoints.EMPLOYEE_LEAVE_REQUEST;
  url = `${url}?fromDate=${fromDateTime}&toDate=${toDateTime}`;
  if (continuationToken !== "")
    url = `${url}&continuationToken=${continuationToken}`;
  const headers = await getHeaders(authUser);
  return await get({ url, headers });
};
