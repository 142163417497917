import { updateTenant } from "../../../controller/superadmin/updateTenant";
import {
  spawnErrorToast,
  spawnSuccessToast,
} from "../../../util/toast/spawnToast";

const handleOnUpdateTenantsState = (
  tenantData,
  contact,
  address,
  setTenants,
  tenants,
  rowIndex
) => {
  const tenantDetails = {
    tenantName: tenantData.tenantName,
    domainName: tenantData.domainName,
    contact: contact,
    address: address,
    createdAt: tenantData.createdAt,
    updatedAt: tenantData.updatedAt,
    userCount: tenantData.userCount,
  };
  const tempTenants = tenants;
  tempTenants[rowIndex] = tenantDetails;
  setTenants(tempTenants);
};

export const editTenantInfoHandler = async ({
  tenantData,
  contact,
  address,
  handleClose,
  setInProgress,
  setTenants,
  tenants,
  authUser,
  rowIndex,
}) => {
    console.log(tenantData);
  setInProgress(true);
  try {
    const payload = {
      tenantName: tenantData.tenantName,
      domainName: tenantData.domainName,
      contact: contact,
      address: address,
    };
    await updateTenant(payload, authUser);
    spawnSuccessToast("Data updated successfully");
    handleOnUpdateTenantsState(
      tenantData,
      contact,
      address,
      setTenants,
      tenants,
      rowIndex
    );
    handleClose();
  } catch (error) {
    spawnErrorToast("Something went wrong");
  } finally {
    setInProgress(false);
  }
};
