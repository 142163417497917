import React from "react";

import UserList from "./component/userList/UserList";

const People = () => {
  return (
    <div className="page-container">
      <UserList />
    </div>
  );
};

export default People;
