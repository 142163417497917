import React, { useEffect } from "react";

import { Input } from "antd";

const TenantDomain = ({ onDisplayTenantConfig }) => {
  useEffect(() => {}, [onDisplayTenantConfig]);

  const getTenantDomain = () => {
    return onDisplayTenantConfig?.tenantDomain;
  };

  return (
    <div>
      <div className="neutral-text">Domain</div>
      <Input
        className="neutral-text"
        value={getTenantDomain()}
        disabled={true}
      />
    </div>
  );
};

export default TenantDomain;
