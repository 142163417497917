import React, { useEffect } from "react";
import { InputNumber } from "antd";
import lodash from "lodash";

const EncashmentMaxThreshold = ({
  isEdit,
  isNewConfig,
  onDisplayTenantConfig,
  setOnDisplayTenantConfig,
}) => {
  useEffect(() => {}, [onDisplayTenantConfig]);

  const getMaxThreshold = () => {
    const leaveAdjustmentType = getLeaveAdjustmentType();
    if (!leaveAdjustmentType) {
      return;
    }
    return onDisplayTenantConfig?.leaveAdjustmentType[leaveAdjustmentType]
      .maxThreshold;
  };

  const handleMaxThresholdChange = (value) => {
    const leaveAdjustmentType = getLeaveAdjustmentType();
    if (!leaveAdjustmentType) {
      return;
    }

    const tenantConfig = lodash.clone(onDisplayTenantConfig);
    tenantConfig.leaveAdjustmentType = {
      [leaveAdjustmentType]: {
        maxThreshold: value,
      },
    };
    setOnDisplayTenantConfig(tenantConfig);
  };

  const getLeaveAdjustmentType = () => {
    if (!onDisplayTenantConfig?.leaveAdjustmentType) {
      return;
    }
    if (
      onDisplayTenantConfig?.leaveAdjustmentType.hasOwnProperty("encashment")
    ) {
      return "encashment";
    }
    if (
      onDisplayTenantConfig?.leaveAdjustmentType.hasOwnProperty("carryForward")
    ) {
      return "carryForward";
    }
  };

  return (
    <div>
      <div className={!isNewConfig ? "neutral-text" : "text"}>
        Max Threshold
      </div>
      <InputNumber
        className="threshold-number-control"
        min={1}
        max={100}
        disabled={isEdit && !isNewConfig}
        value={getMaxThreshold()}
        onChange={handleMaxThresholdChange}
      />
    </div>
  );
};

export default EncashmentMaxThreshold;
