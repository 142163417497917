import styled from "@emotion/styled";

const UnderLineText = styled.p`
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 500;
  color: ${(props) => props.theme.text};
`;
const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  @media (max-width: 1500px) {
    flex-direction: column;
    gap: 0px;
    width: 100%;
  }
`;
const SelectTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  @media (max-width: 1500px) {
    gap: 0px;
    width: 100%;
    margin-bottom: 0px;
  }
`;
const LoadingWrapper = styled.div`
  font-size: 8px;
`;
const SelectMemberWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  @media (max-width: 1500px) {
    gap: 10px;
    width: 100%;
    margin-bottom: 0px;
  }
`;
const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 350px;
  gap: 10px;
  @media (max-width: 1500px) {
    gap: 10px;
    width: 100%;
    margin-bottom: 0px;
  }
`;
const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 350px;
  gap: 10px;
  @media (max-width: 1500px) {
    gap: 10px;
    width: 100%;
    margin-bottom: 0px;
  }
`;
const SearchButtonWrapper = styled.div`
  display: block;
  padding: 49px 0 0 0;
  @media (max-width: 1500px) {
    padding: 15px 0 0 0;
  }
`;
const SelectInputWrapper = styled.div`
  background-color: ${(props) => props.theme.primary};
  padding: 0px;
  border-radius: 5px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export {
  UnderLineText,
  InputWrapper,
  SelectTitleWrapper,
  SelectInputWrapper,
  LoadingWrapper,
  SelectMemberWrapper,
  SearchButtonWrapper,
  IconWrapper,
  SelectWrapper,
  DatePickerWrapper,
};
