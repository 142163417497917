import React, { useState, useContext, useEffect } from "react";
import { Modal } from "antd";
import dayjs from "dayjs";

import { spawnErrorToast } from "../../../util/toast/spawnToast";
import { getStandardDates } from "../../../core/datetime/standardDateHandler/getStandardDate";
import { GeneralContext } from "../../../contexts/general/Context";
import { getPublicShareId } from "../../../controller/teamLead/getShareID";
import ShareBalanceForm from "./components/ShareBalanceForm";

const ShareBalance = ({ open, setOpen }) => {

  const { currentTheme } = useContext(GeneralContext);
  const [inProgress, setInProgress] = useState();
  const [expireDate, setExpireDate] = useState(dayjs().toDate());
  const [shareLink, setShareLink] = useState();
  const [isCopied, setIsCopied] = useState(false);

  const getManipulateExpTime = (expireDate) => {
    const expYear = expireDate.getFullYear();
    const expMonth = expireDate.getMonth();
    const onlyDateExp = expireDate.getDate();
    const dateExp = new Date(expYear, expMonth, onlyDateExp, 23, 59, 59, 59);
    const isoDate = getStandardDates(dateExp);
    return isoDate.start;
  };

  const generateShareableLink = (data) => {
    const origin = window.location.origin;
    setShareLink(`${origin}/public?shareId=${data}`);
  };

  const handleShareBalance = async () => {
    const payload = {
      expireDate: getManipulateExpTime(expireDate),
    };
    setInProgress(true);
    setIsCopied(false);
    setShareLink();
    try {
      const response = await getPublicShareId(payload);
      const { data } = response;
      setInProgress(false);
      generateShareableLink(data);
    } catch (error) {
      setInProgress(false);
      spawnErrorToast(error?.response?.data);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setExpireDate(dayjs().toDate());
    setShareLink();
    setIsCopied(false);
  };

  const revertCopyButtonIcon = async () => {
    await new Promise((resolve) => setTimeout(resolve, 3000));
    setIsCopied(false);
  };

  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(shareLink);
      setIsCopied(true);
      revertCopyButtonIcon();
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleExpireDateChange = (date) => {
    const expDate = date.toDate();
    expDate.setHours(23, 59, 0, 0);
    setExpireDate(expDate);
  };

  const handleDisableDate = (current) => {
    return (
      current < dayjs().toDate()
    );
  };

  return (
    <>
      <Modal
        title="Generate Share Balance Link"
        open={open}
        onCancel={handleClose}
        okButtonProps={{
          style: { display: "none" },
        }}
        cancelButtonProps={{
          style: { display: "none" },
        }}
        confirmLoading={inProgress}
        mask={true}
        maskClosable={false}
      >
        <ShareBalanceForm
          handleShareBalance={handleShareBalance}
          handleExpireDateChange={handleExpireDateChange}
          handleDisableDate={handleDisableDate}
          shareLink={shareLink}
          copyContent={copyContent}
          isCopied={isCopied}
          inProgress={inProgress}
          expireDate={expireDate}
        />
      </Modal>
    </>
  );
};

export default ShareBalance;
