export const schema_Config_creation = {
  type: "object",
  properties: {
    weekend: {
      type: "object",
      properties: {
        0: {
          type: "object",
          properties: {
            day: {
              type: "string",
              enum: ["Sunday"],
            },
          },
          required: ["day"],
          additionalProperties: false,
        },
        1: {
          type: "object",
          properties: {
            day: {
              type: "string",
              enum: ["Monday"],
            },
          },
          required: ["day"],
          additionalProperties: false,
        },
        2: {
          type: "object",
          properties: {
            day: {
              type: "string",
              enum: ["Tuesday"],
            },
          },
          required: ["day"],
          additionalProperties: false,
        },
        3: {
          type: "object",
          properties: {
            day: {
              type: "string",
              enum: ["Wednesday"],
            },
          },
          required: ["day"],
          additionalProperties: false,
        },
        4: {
          type: "object",
          properties: {
            day: {
              type: "string",
              enum: ["Thursday"],
            },
          },
          required: ["day"],
          additionalProperties: false,
        },
        5: {
          type: "object",
          properties: {
            day: {
              type: "string",
              enum: ["Friday"],
            },
          },
          required: ["day"],
          additionalProperties: false,
        },
        6: {
          type: "object",
          properties: {
            day: {
              type: "string",
              enum: ["Saturday"],
            },
          },
          required: ["day"],
          additionalProperties: false,
        },
      },
      additionalProperties: false,
      minProperties: 1,
    },
    holiday: {
      type: "object",
      minProperties: 1,
      patternProperties: {
        "^[0-9]{4}-[0-9]{2}-[0-9]{2}$": {
          type: "object",
          properties: {
            title: { type: "string" },
          },
          required: ["title"],
          additionalProperties: false,
        },
      },
      additionalProperties: false,
    },
    leaveAdjustmentType: {
      type: "object",
      oneOf: [
        {
          type: "object",
          properties: {
            carryForward: {
              type: "object",
              properties: {
                maxThreshold: {
                  type: "number",
                  minimum: 0,
                  maximum: 100,
                },
              },
              required: ["maxThreshold"],
            },
          },
          required: ["carryForward"],
          additionalProperties: false,
        },
        {
          type: "object",
          properties: {
            encashment: {
              type: "object",
              properties: {
                maxThreshold: {
                  type: "number",
                  minimum: 0,
                  maximum: 100,
                },
              },
              required: ["maxThreshold"],
            },
          },
          required: ["encashment"],
          additionalProperties: false,
        },
      ],
    },
    leavePolicy: {
      type: "object",
      properties: {
        isSandwichLeave: { type: "boolean" },
        maxLeaveAndType: {
          type: "object",
          minProperties: 1,
          patternProperties: {
            "^[^\\s]*$": {
              type: "number",
              minimum: 1,
              maximum: 100,
            },
          },
        },
        noticePeriodOfLeave: {
          type: "object",
          additionalProperties: false,
          patternProperties: {
            "^[0-9]+$": {
              type: "object",
              required: ["day", "notice"],
              additionalProperties: false,
              properties: {
                day: {
                  type: "number",
                  minimum: 1,
                  maximum: 100,
                },
                notice: {
                  type: "number",
                  minimum: 0,
                  maximum: 100,
                },
              },
            },
          },
          minProperties: 1,
        },
        appliedNotice: {
          type: "object",
          minProperties: 1,
          patternProperties: {
            "^[a-zA-Z_]+$": { type: "boolean" },
          },
        },
      },
      required: [
        "isSandwichLeave",
        "maxLeaveAndType",
        "noticePeriodOfLeave",
        "appliedNotice",
      ],
      additionalProperties: false,
    },
    emailList: {
      type: "array",
      items: {
        type: "string",
        pattern: "^\\S+@\\S+\\.\\S+$",
      },
    },
    startYear: {
      type: "string",
      pattern: "^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{3}Z$",
      minLength: 1,
    },
    timezone: {
      type: "object",
      properties: {
        value: { type: "string" },
        offset: { type: "string", pattern: "^[-+][0-9]{2}:[0-9]{2}$" },
      },
      required: ["value", "offset"],
      additionalProperties: false,
    },
    tenantDomain: { type: "string", minLength: 1 },
  },
  required: [
    "weekend",
    "holiday",
    "leaveAdjustmentType",
    "leavePolicy",
    "emailList",
    "startYear",
    "tenantDomain",
    "timezone",
  ],
  additionalProperties: false,
};
