import React from "react";
import { useSetAtom } from "jotai";
import { Select } from "antd";

import { selectedSuperAdminTeamAtom } from "../../../../../../contexts/generalStore/store";

const SelectTeamInput = (props) => {
  const setSelectedSuperAdminTeam = useSetAtom(
    selectedSuperAdminTeamAtom
  );

  const handleOnChange = (value) => {
    setSelectedSuperAdminTeam(value);
  };

  return (
    <Select
      aria-label="Default select example"
      onChange={handleOnChange}
      defaultValue={"invalid"}
    >
      <Select.Option value="invalid">-- Select a Team --</Select.Option>
      {typeof props?.teams === "object" &&
        props?.teams.length > 0 &&
        props?.teams?.map((item, index) => {
          return (
            <Select.Option key={index} value={item}>
              {item}
            </Select.Option>
          );
        })}
    </Select>
  );
};

export default SelectTeamInput;
