import { Drawer } from "antd";
import React from "react";
import SnapLinks from "../list/SnapLinks";

const LinksDrawer = ({ open, onClose, linksListData, setLinksListData }) => {
  return (
    <div>
      <Drawer
        title="Available Links"
        placement="right"
        size={window.innerWidth <= 768 ? "default" : "large"}
        onClose={onClose}
        open={open}
      >
        <SnapLinks
          drawerOpen={open}
          linksListData={linksListData}
          setLinksListData={setLinksListData}
        />
      </Drawer>
    </div>
  );
};

export default LinksDrawer;
