import { Avatar, Button, List, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import lodash from "lodash";
import {
  authUserAtom,
  specificTenantAtom,
  tenantMembersAtom,
} from "../../../../../../../../../contexts/generalStore/store";
import { getTenantMembers } from "../../../../../../../../../controller/superadmin/getTenantMembers";
import userPic from "../../../../../../../../../img/user.png";
import AdminAssignModal from "../assignModal/AdminAssignModal";
import CmlIcon from "../../../../../../common/icon/CmlIcon";

const UserList = () => {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [isAllDataFetched, setIsAllRequestFetched] = useState(true);
  const [paginationToken, setPaginationToken] = useState(false);

  const authUser = useAtomValue(authUserAtom);
  const tenantData = useAtomValue(specificTenantAtom);

  const [members, setMembers] = useAtom(tenantMembersAtom);

  const callApi = async (token = "") => {
    try {
      const response = await getTenantMembers(
        authUser,
        tenantData?.domainName,
        token
      );
      if (response?.data)
        setPaginationToken(
          response.data?.ContinuationToken
            ? response.data.ContinuationToken
            : null
        );
      if (response.data?.ContinuationToken) {
        setIsAllRequestFetched(false);
      } else {
        setIsAllRequestFetched(true);
      }
      return response?.data?.Items;
    } catch (error) {
      return [];
    }
  };

  const getMembers = async () => {
    const response = await callApi();
    setMembers([...response]);
    setList([...response]);
    setInitLoading(false);
  };

  useEffect(() => {
    getMembers();
  }, []);

  useEffect(() => {
    setList([...members]);
  }, [members]);

  const onLoadMore = async () => {
    setLoading(true);
    setList(
      members.concat(
        [...new Array(3)].map(() => ({
          loading: true,
        }))
      )
    );
    const res = await callApi(paginationToken);
    const newData = members.concat(res);
    setMembers(newData);
    setList(newData);
    setLoading(false);
    window.dispatchEvent(new Event("resize"));
  };

  const loadMore =
    !initLoading && !loading && !isAllDataFetched ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          marginBottom: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore}>loading more</Button>
      </div>
    ) : null;

  const openAssignModal = (item) => {
    setShowAssignModal(true);
    setSelectedUser(item);
  };

  const handleDisabled = (item) => {
    if (item?.group[0] !== "unassignedEmployee") {
      return true;
    }
    return false;
  };

  return (
    <div>
      {initLoading ? (
        <Skeleton active avatar />
      ) : (
        <List
          className="cml-drawer-list"
          loading={initLoading}
          itemLayout={"vertical"}
          loadMore={loadMore}
          dataSource={list}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button
                  className="ml-48"
                  onClick={() => openAssignModal(item)}
                  disabled={handleDisabled(item)}
                  type={handleDisabled(item) ? "default" : "primary"}
                  size="small"
                  icon={<CmlIcon iconName="task_alt" />}
                >
                  Assign Admin
                </Button>,
              ]}
            > 
              <Skeleton avatar title={false} loading={item.loading} active>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={item?.pictureLink ? item?.pictureLink : userPic}
                    />
                  }
                  title={item?.employeeName}
                  description={
                    <div>
                      <div className="neutral-text">{item?.email}</div>
                      <div className="neutral-text">{lodash.startCase(item.group)}</div>
                    </div>
                  }
                />
              </Skeleton>
            </List.Item>
          )}
        />
      )}
      {showAssignModal && (
        <AdminAssignModal
          member={selectedUser}
          open={showAssignModal}
          setOpen={setShowAssignModal}
        />
      )}
    </div>
  );
};

export default UserList;
