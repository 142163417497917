import React, { useEffect } from "react";
import { Select } from "antd";

const PageTitleSelect = ({ options, value, onChange, loading }) => {
  useEffect(() => {}, [options, value]);
  return (
    <div>
      <Select
        onChange={onChange}
        options={options}
        value={value ? value : loading ? "Fetching..." : "No results"}
        loading={loading ? true : false}
        disabled={!value ? true : false}
      />
    </div>
  );
};

export default PageTitleSelect;
