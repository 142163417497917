import { Button, DatePicker, Form } from 'antd';
import React, { useContext, useEffect } from 'react';
import dayjs from 'dayjs';

import { LinkContainer, LinkDiv, Value } from "../ShareBalance-styled";
import { GeneralContext } from '../../../../contexts/general/Context';
import { CheckCircleFilled, CopyFilled, SendOutlined } from '@ant-design/icons';

const ShareBalanceForm = ({
  handleShareBalance,
  handleExpireDateChange,
  handleDisableDate,
  shareLink,
  copyContent,
  isCopied,
  inProgress,
  expireDate
}) => {
  const [form] = Form.useForm();
  const { currentTheme } = useContext(GeneralContext);

  useEffect(() => {
    form.setFieldsValue({
      expireDate: dayjs(expireDate),
    });
  }, [expireDate]);

  return (
    <div>
      <Form
        form={form}
        onFinish={handleShareBalance}
        layout="vertical"
        style={{ width: "100%" }}
      >
        <Form.Item
          label={<div className='neutral-text'>Expire Date: </div>}
          name="expireDate"
          style={{
            marginBottom: 5,
            width: "100%"
          }}
          rules={[{ required: true, message: "Please select start date" }]}
        >
          <DatePicker
            style={{
              width: '100%',
            }}
            className="form-control"
            value={dayjs()}
            onChange={(start) => handleExpireDateChange(start)}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            showToday={false}
            allowClear={false}
            disabledDate={(current) =>
              handleDisableDate(current.toDate())
            }
          />
        </Form.Item>
        {shareLink &&
          <Form.Item
            label={<div className='neutral-text'>Link: </div>}
            name="link"
          >
            <LinkDiv>
              <div>
                <LinkContainer>
                  <Value onClick={copyContent}>
                    {shareLink}
                  </Value>
                  {!isCopied && (
                    <CopyFilled
                      onClick={copyContent}
                    />
                  )}
                  {isCopied && (
                    <CheckCircleFilled
                      onClick={copyContent}
                    />
                  )}
                </LinkContainer>
              </div>
            </LinkDiv>
          </Form.Item>
        }
        <Form.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "15px 0 -15px 0"
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                loading={inProgress}
                ghost
                icon={<SendOutlined />}
                style={{
                  color: "white",
                  backgroundColor: currentTheme.accent,
                  border: "none",
                }}
              >
                Generate
              </Button>
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ShareBalanceForm;
