import { Avatar, Button, Col, List, Row, Skeleton } from "antd";
import React, { useContext, useEffect, useState } from "react";
import lodash from "lodash";

import { PERMISSIONS } from "../../../../../../../../util/constants/permission";
import { useAtom, useAtomValue } from "jotai";
import {
  authUserAtom,
  groupAtom,
  showModalsAtom,
  teamBalanceListAtom,
} from "../../../../../../../../contexts/generalStore/store";
import { handleTeamBalanceQuery } from "../../../../../../../../core/team/teamBalance/teamBalanceQueryHandler";
import UnassignMember from "../modals/unassignMember/UnassignMember";
import ApplyOnBehalfModal from "../modals/applyOnBehalfModal/ApplyOnBehalfModal";
import { NoResultFound } from "../../../../../common/noResultFound/NoResultFound";
import CmlIcon from "../../../../../common/icon/CmlIcon";

const MemberList = ({ teamName }) => {
  const [initLoading, setInitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showUnassignModal, setShowUnassignModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [isAllDataFetched, setIsAllRequestFetched] = useState(true);
  const [paginationToken, setPaginationToken] = useState();

  const group = useAtomValue(groupAtom);
  const authUser = useAtomValue(authUserAtom);
  const selectedTeam = teamName;

  const [teamBalanceList, setTeamBalanceList] = useAtom(teamBalanceListAtom);
  const [showApplyModal, setShowApplyModal] = useAtom(showModalsAtom);

  const getStateArguments = () => {
    return {
      selectedTeam: selectedTeam,
      authUser: authUser,
      token: paginationToken,
      setIsLoading: setInitLoading,
      setIsAllDataFetched: setIsAllRequestFetched,
      setTeamBalanceList: setTeamBalanceList,
      setTeamUserList: setList,
      setContinueToken: setPaginationToken,
    };
  };

  useEffect(() => {
    if (selectedTeam && selectedTeam !== "invalid") {
      const args = getStateArguments();
      handleTeamBalanceQuery(args);
      setList([...teamBalanceList]);
    } else {
      setTeamBalanceList([]);
      setList([]);
    }
  }, [selectedTeam]);

  useEffect(() => {
    if (selectedTeam && selectedTeam !== "invalid") {
      setList([...teamBalanceList]);
    } else {
      setList([]);
    }
  }, [teamBalanceList]);

  const onLoadMore = async () => {
    setLoading(true);
    setList(
      teamBalanceList.concat(
        [...new Array(3)].map(() => ({
          loading: true,
        }))
      )
    );
    const args = getStateArguments();
    const res = await handleTeamBalanceQuery(args);
    const newData = teamBalanceList.concat(res);
    setTeamBalanceList(newData);
    setList(newData);
    setLoading(false);
    window.dispatchEvent(new Event("resize"));
  };

  const loadMore =
    !initLoading && !loading && !isAllDataFetched ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          marginBottom: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore}>loading more</Button>
      </div>
    ) : null;

  const openUnassignModal = (item) => {
    setShowUnassignModal(true);
    setSelectedUser(item);
  };

  const openApplyModal = (item) => {
    setShowApplyModal({ ...showApplyModal, showApplyOnBehalfModal: true });
    setSelectedUser(item);
  };

  const openDetailsDrawer = (item) => {
    setOpenDrawer(true);
    setSelectedUser(item);
  };

  const onDetailsDrawerClose = () => {
    setOpenDrawer(false);
    setSelectedUser({});
  };

  const handleDisabled = (item) => {
    if (item?.group.includes("teamLead") && item?.hasRefreshToken) {
      return false;
    }
    return true;
  };

  return (
    <Row>
      <Col xs={24} md={12}>
        <div>
          {!initLoading ? (
            <div className="cml-card">
              <List
                itemLayout="vertical"
                loading={initLoading}
                loadMore={loadMore}
                dataSource={selectedTeam ? list : []}
                locale={{ emptyText: <NoResultFound /> }}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <div className="ml-48">
                        {PERMISSIONS.apis.unassignedMemberAllowedGroups.some(
                          (value) => group.includes(value)
                        ) && (
                          <Button
                            size="small"
                            onClick={() => openUnassignModal(item)}
                            icon={<CmlIcon iconName="undo" />}
                          >
                            Unassign
                          </Button>
                        )}
                      </div>,
                      <div>
                        {PERMISSIONS.apis.applyOnBehalfLeadAllowedGroups.some(
                          (value) => group.includes(value)
                        ) && (
                          <Button
                            size="small"
                            type={handleDisabled(item) ? "default" : "primary"}
                            onClick={() => openApplyModal(item)}
                            disabled={handleDisabled(item)}
                            icon={<CmlIcon iconName="send" />}
                          >
                            Apply
                          </Button>
                        )}
                      </div>,
                    ]}
                  >
                    <Skeleton
                      avatar
                      title={false}
                      loading={item.loading}
                      active
                    >
                      <List.Item.Meta
                        style={{ cursor: "default" }}
                        avatar={<Avatar src={item?.pictureLink} />}
                        title={
                          <span>
                            <b>{item?.employeeName}</b>
                          </span>
                        }
                        description={
                          <div>
                            <div className="neutral-text">{item?.email}</div>
                            <div>
                              <span className="neutral-text"> Role : </span>
                              <span className="neutral-text">
                                {" "}
                                {lodash.startCase(item.group)}
                              </span>
                            </div>
                            <div>
                              {Object.entries(
                                item?.leaveBalance?.firstConfig
                              ).map(([key, value]) => (
                                <div key={key}>
                                  <span className="neutral-text">
                                    {lodash.startCase(key)} :{" "}
                                  </span>
                                  <span className="neutral-text">{value}</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        }
                      />
                    </Skeleton>
                  </List.Item>
                )}
              />
            </div>
          ) : (
            <Skeleton loading={initLoading} active avatar></Skeleton>
          )}

          <UnassignMember
            open={showUnassignModal}
            setOpen={setShowUnassignModal}
            member={selectedUser}
          />

          <ApplyOnBehalfModal userData={selectedUser} fromAdmin={true} />
        </div>
      </Col>
    </Row>
  );
};

export default MemberList;
