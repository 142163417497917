import { useContext } from "react";
import { Select } from "antd";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import {
  continueTokenAtom,
  memberListAtom,
  modalErrorMessageAtom,
  selectedTeamAtom,
  selectedTeamForAssignAtom,
  selectedTeamForOvertimeAtom,
  selectedTeamForRequestsAtom,
  teamBalanceListAtom,
  teamLeadRequestAtom,
  teamListAtom,
  teamOverTimeListAtom,
} from "../../../../../contexts/generalStore/store";
import { GeneralContext } from "../../../../../contexts/general/Context";

const ModalTeamSelectInput = ({
  fromOvertime,
  fromMembersTable,
  fromRequestTable,
  fromUnassignedTable,
}) => {
  const { currentTheme } = useContext(GeneralContext);

  const [selectedTeam, setSelectedTeam] = useAtom(selectedTeamAtom);
  const [selectedTeamForAssign, setSelectedTeamForAssign] = useAtom(
    selectedTeamForAssignAtom
  );
  const [selectedTeamForOvertime, setSelectedTeamForOvertime] = useAtom(
    selectedTeamForOvertimeAtom
  );
  const [selectedTeamForRequest, setSelectedTeamForRequest] = useAtom(
    selectedTeamForRequestsAtom
  );
  const setTeamBalanceList = useSetAtom(teamBalanceListAtom);
  const setValidateMessage = useSetAtom(modalErrorMessageAtom);
  const setContinueToken = useSetAtom(continueTokenAtom);
  const setTeamLeadRequestList = useSetAtom(teamLeadRequestAtom);
  const setMemberList = useSetAtom(memberListAtom);
  const setOverTimeList = useSetAtom(teamOverTimeListAtom);

  const teamList = useAtomValue(teamListAtom);

  const handleOnChange = (value) => {
    if (fromOvertime) {
      setSelectedTeamForOvertime(value);
      setOverTimeList([]);
    } else if (fromRequestTable) {
      setSelectedTeamForRequest(value);
      setTeamLeadRequestList([]);
    } else if (fromMembersTable) {
      setSelectedTeam(value);
      setMemberList([]);
      setTeamBalanceList([]);
    } else if (fromUnassignedTable) {
      setSelectedTeamForAssign(value);
    }
    setValidateMessage("");
    setContinueToken("");
  };

  return (
    <div>
      <Select
        style={{
          width: "100%",
          color: currentTheme.text,
        }}
        onChange={handleOnChange}
        defaultValue="invalid"
        value={
          fromUnassignedTable
            ? selectedTeamForAssign
            : fromMembersTable
            ? selectedTeam
            : fromRequestTable
            ? selectedTeamForRequest
            : selectedTeamForOvertime
        }
        loading={teamList.length ? false : !teamList?.length ? false : true}
      >
        <Select.Option value="invalid">-- Select a Team --</Select.Option>
        {typeof teamList === "object" &&
          teamList?.map((team, index) => {
            return (
              <Select.Option
                key={index}
                value={team}
              >
                {team}
              </Select.Option>
            );
          })}
      </Select>
    </div>
  );
};

export default ModalTeamSelectInput;
