import { useReducer } from "react";
import { GeneralContext } from "./Context";
import GeneralReducer from "./Reducer";
import { THEME_PROP } from "./Actions";
import { lightTheme, darkTheme } from "../../util/theme/colorPalette";

const storedTheme = localStorage.getItem("preferredTheme");

const initialState = {
  preferredTheme: storedTheme ? storedTheme : "dark",
  currentTheme: darkTheme,
};

const GeneralState = (props) => {
  const [state, dispatch] = useReducer(GeneralReducer, initialState);

  const toggleMyTheme = () => {
    dispatch({
      type: THEME_PROP.TOGGLE_THEME,
    });
  };

  return (
    <GeneralContext.Provider
      value={{
        currentTheme: state.currentTheme,
        toggleMyTheme,
      }}
    >
      {props.children}
    </GeneralContext.Provider>
  );
};

export default GeneralState;
