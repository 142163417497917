import React, { useContext, useEffect } from "react";
import { Checkbox } from "antd";
import lodash from "lodash";
import { GeneralContext } from "../../../../../../../../../../contexts/general/Context";
import { CardDiv } from "../../../../TenantConfig-Styled";

const WeekEnd = ({
  isEdit,
  isNewConfig,
  onDisplayTenantConfig,
  setOnDisplayTenantConfig,
}) => {
  const { currentTheme } = useContext(GeneralContext);
  const weekDays = [
    {
      label: (
        <div
          style={{
            color: !isNewConfig
              ? currentTheme.inputTextDisabled
              : currentTheme.text,
          }}
        >
          Sunday
        </div>
      ),
      value: "0",
    },
    {
      label: (
        <div
          style={{
            color: !isNewConfig
              ? currentTheme.inputTextDisabled
              : currentTheme.text,
          }}
        >
          Monday
        </div>
      ),
      value: "1",
    },
    {
      label: (
        <div
          style={{
            color: !isNewConfig
              ? currentTheme.inputTextDisabled
              : currentTheme.text,
          }}
        >
          Tuesday
        </div>
      ),
      value: "2",
    },
    {
      label: (
        <div
          style={{
            color: !isNewConfig
              ? currentTheme.inputTextDisabled
              : currentTheme.text,
          }}
        >
          Wednesday
        </div>
      ),
      value: "3",
    },
    {
      label: (
        <div
          style={{
            color: !isNewConfig
              ? currentTheme.inputTextDisabled
              : currentTheme.text,
          }}
        >
          Thursday
        </div>
      ),
      value: "4",
    },
    {
      label: (
        <div
          style={{
            color: !isNewConfig
              ? currentTheme.inputTextDisabled
              : currentTheme.text,
          }}
        >
          Friday
        </div>
      ),
      value: "5",
    },
    {
      label: (
        <div
          style={{
            color: !isNewConfig
              ? currentTheme.inputTextDisabled
              : currentTheme.text,
          }}
        >
          Saturday
        </div>
      ),
      value: "6",
    },
  ];

  const getDayByValue = (value) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const index = typeof value === "string" ? parseInt(value, 10) : value;
    if (0 <= index && index < days.length) {
      return days[index];
    }
  };

  const getExistingWeekendDays = () => {
    if (!onDisplayTenantConfig?.weekend) return [];
    const days = Object.keys(onDisplayTenantConfig?.weekend);
    return days;
  };

  useEffect(() => {}, [onDisplayTenantConfig]);

  const handleWeekendSelect = (days) => {
    let weekend = {};
    days.forEach((value) => {
      const day = getDayByValue(value);
      weekend[value] = {
        day: day,
      };
    });
    const tenantConfig = lodash.clone(onDisplayTenantConfig);
    tenantConfig.weekend = weekend;
    setOnDisplayTenantConfig(tenantConfig);
  };
  return (
    <div>
      <div className={!isNewConfig ? "neutral-text" : "text"}>Weekend</div>
      <Checkbox.Group
        options={weekDays}
        disabled={isEdit && !isNewConfig}
        value={getExistingWeekendDays()}
        onChange={handleWeekendSelect}
      />
    </div>
  );
};

export default WeekEnd;
