import { insertTenant } from "../../../controller/superadmin/insertTenant";
import {
  spawnErrorToast,
  spawnSuccessToast,
} from "../../../util/toast/spawnToast";

const handleOnInsertTenantsState = (
  tenantName,
  domain,
  contact,
  address,
  setTenants,
  tenants
) => {
  const tenant = {
    tenantName: tenantName,
    domainName: domain,
    contact: contact,
    address: address,
    createdAt: new Date(),
    userCount: 0,
  };
  setTenants([...tenants, tenant]);
};

export const addTenanthandler = async ({
  tenantName,
  domain,
  contact,
  address,
  handleClose,
  setInProgress,
  setTenants,
  tenants,
  authUser,
}) => {
  setInProgress(true);
  try {
    const payload = {
      tenantName: tenantName,
      domainName: domain,
      contact: contact,
      address: address,
    };
    await insertTenant(payload, authUser);
    handleOnInsertTenantsState(
      tenantName,
      domain,
      contact,
      address,
      setTenants,
      tenants
    );
    setInProgress(false);
    spawnSuccessToast("Tenant added successfully");
    handleClose();
  } catch (error) {
    console.log(error);
    setInProgress(false);
    spawnErrorToast("Something went wrong");
  }
};
