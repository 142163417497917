import { Drawer } from "antd";
import React, { useContext } from "react";
import lodash from "lodash";
import { useAtomValue } from "jotai";
import { tenantConfigAtom } from "../../../../contexts/generalStore/store";
import { toLocalDateString } from "../../../../util/formatter/getDateInTextFormat";

const getDrawerTitle = (tenantConfig) => {
  let title = "Annual Leave";
  return `${title} ${toLocalDateString(tenantConfig[0]?.startYear)} - ${toLocalDateString(tenantConfig[0]?.expiredAt)}`;
}

const BalanceDrawer = ({
  showBalanceDrawer,
  selectedLeaveType,
  selectedMaxLeaveAndTypeArray,
  selectedLeaveBalance,
  setShowBalanceDrawer,
}) => {
  const tenantConfig = useAtomValue(tenantConfigAtom);

  return (
    <Drawer
      title={getDrawerTitle(tenantConfig)}
      placement="right"
      onClose={() => {
        setShowBalanceDrawer(false);
      }}
      open={showBalanceDrawer}
      size={window.innerWidth <= 768 ? "default" : "large"}
    >
      <div className="cml-card">
        <div className="mb-10">
          <div className="neutral-text">Leave Type </div>
          <div>{lodash.startCase(selectedLeaveType)}</div>
        </div>
        <div className="mb-10">
          <div className="neutral-text">Accrued until today </div>
          <div>{lodash.startCase(selectedLeaveBalance)} days</div>
        </div>
        <div className="mb-10">
          <div className="neutral-text">Max Leave Balance </div>
          <div>{lodash.startCase(selectedMaxLeaveAndTypeArray?.maxLeave)} days </div>
        </div>
      </div>
    </Drawer>
  );
};

export default BalanceDrawer;
