export const filterLeavesByDateRange = (takenLeaves, start, end) => {
  const startDate = new Date(start).getTime();
  const endDate = new Date(end).getTime();

  const filteredLeaves = takenLeaves.filter((leave) => {
    const leaveDate = new Date(leave).getTime();
    return !(leaveDate >= startDate && leaveDate <= endDate);
  });

  return filteredLeaves;
}
