import styled from "@emotion/styled";

const TableWrapper = styled("div")`
  padding: 30px 20px 0 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const NoDataWrapper = styled("div")`
  background-color: #f5f5f5;
  height: 100px;
  margin: 30px auto;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
`;
const ExpireMessageDiv = styled("p")`
  color: red;
  padding: 30px;
`;
const TableHeaderWrapper = styled("p")`
  display: flex;
  font-size: 20px;
  font-weight: 600;
  justify-content: center;
  color:${(props) => props.theme.text}
`;
export { TableWrapper, TableHeaderWrapper, NoDataWrapper, ExpireMessageDiv };
