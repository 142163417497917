import React, { useContext } from "react";
import { Drawer } from "antd";
import { useAtomValue, useSetAtom } from "jotai";

import {
  specificTenantAtom,
  tenantMembersAtom,
} from "../../../../../../../contexts/generalStore/store";
import UserList from "./components/userList/UserList";

const TenantUsers = ({ open, setOpen }) => {
  const tenantData = useAtomValue(specificTenantAtom);
  const setMembers = useSetAtom(tenantMembersAtom);

  const onClose = () => {
    setOpen(false);
    setMembers([]);
  };

  return (
    <Drawer
      title={`Users of ${tenantData?.tenantName}`}
      placement="right"
      size={window.innerWidth <= 768 ? "default" : "large"}
      onClose={onClose}
      open={open}>
      <UserList />
    </Drawer>
  );
};

export default TenantUsers;
