import React, { useEffect } from "react";
import { Select } from "antd";
import lodash from "lodash";

const LeaveAdjustmentType = ({
  isEdit,
  isNewConfig,
  onDisplayTenantConfig,
  setOnDisplayTenantConfig,
}) => {
  const options = [
    {
      value: "encashment",
      label: "Encashment",
    },
    {
      value: "carryForward",
      label: "Carry Forward",
    },
  ];

  useEffect(() => {}, [onDisplayTenantConfig]);

  const handleLeaveAdjustmentTypeChange = (value) => {
    const previousValue = getLeaveAdjustmentTypeDefaultValue();
    if (!previousValue) {
      return;
    }
    const tenantConfig = lodash.clone(onDisplayTenantConfig);
    const maxThreshold =
      tenantConfig?.leaveAdjustmentType[previousValue]?.maxThreshold;
    tenantConfig.leaveAdjustmentType = {
      [value]: {
        maxThreshold: maxThreshold,
      },
    };
    setOnDisplayTenantConfig(tenantConfig);
  };

  const getLeaveAdjustmentTypeDefaultValue = () => {
    if (!Object.keys(onDisplayTenantConfig?.leaveAdjustmentType).length) {
      return "encashment";
    }
    if (
      onDisplayTenantConfig?.leaveAdjustmentType.hasOwnProperty("encashment")
    ) {
      return "encashment";
    }
    if (
      onDisplayTenantConfig?.leaveAdjustmentType.hasOwnProperty("carryForward")
    ) {
      return "carryForward";
    }
  };

  return (
    <div>
      <div className="neutral-text">Leave Adjustment</div>
      <Select
        className="full-width"
        options={options}
        disabled={isEdit && !isNewConfig}
        onChange={handleLeaveAdjustmentTypeChange}
        defaultValue={getLeaveAdjustmentTypeDefaultValue}
      >
        <Select.Option value="encashment">Encashment</Select.Option>
        <Select.Option value="carryForward">Carry Forward</Select.Option>
      </Select>
    </div>
  );
};

export default LeaveAdjustmentType;
