import { get } from "../../lib/fetch";
import secureLocalStorage from "react-secure-storage";

import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";

export const getOnBehalfLeaves = async () => {
  const authUser = JSON.parse(secureLocalStorage.getItem("authUser"));
  const headers = await getHeaders(authUser);
  const url = `${endpoints.ONBEHALF_LEAVE_REQUEST}`;
  const response = await get({ url, headers });
  return response;
};
