import React, { useEffect, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import {
  authUserAtom,
  rowIndexAtom,
  showModalsAtom,
  specificTenantAtom,
  tenantsAtom,
} from "../../../../../../../contexts/generalStore/store";
import { editTenantInfoHandler } from "../../../../../../../core/tenant/editTenant/editTenantInfoHandler";
import CmlIcon from "../../../../common/icon/CmlIcon";

const EditTenantDetails = () => {
  const [form] = Form.useForm();
  const [inProgress, setInProgress] = useState(false);
  const [tenants, setTenants] = useAtom(tenantsAtom);
  const [showModals, setShowModals] = useAtom(showModalsAtom);
  const tenantData = useAtomValue(specificTenantAtom);
  const rowIndex = useAtomValue(rowIndexAtom);
  const authUser = useAtomValue(authUserAtom);
  const [contact, setContact] = useState(tenantData.contact);
  const [address, setAddress] = useState(tenantData.address);

  useEffect(() => {
    form.setFieldsValue({
      contact: tenantData?.contact,
      address: tenantData?.address,
    });
  }, []);

  const handleClose = () => {
    setShowModals({ ...showModals, showEditModal: false });
    setContact("");
    setAddress("");
  };

  const getStateArguments = () => {
    return {
      tenantData: tenantData,
      contact: contact,
      address: address,
      handleClose: handleClose,
      setInProgress: setInProgress,
      setTenants: setTenants,
      tenants: tenants,
      authUser: authUser,
      rowIndex: rowIndex,
    };
  };

  const handleUpdate = async () => {
    const args = getStateArguments();
    editTenantInfoHandler(args);
  };

  return (
    <>
      <Modal
        title="Edit Tenant Information"
        open={showModals.showEditModal}
        onCancel={handleClose}
        okButtonProps={{
          style: { display: "none" },
        }}
        cancelButtonProps={{
          style: { display: "none" },
        }}
        confirmLoading={inProgress}
        mask={true}
        maskClosable={false}
      >
        <div className="cml-card mt-20">
          <Row>
            <Col span={12}>
              <div className="neutral-text">Name: </div>
              <div>{tenantData?.tenantName}</div>
            </Col>
            <Col span={12}>
              <div className="neutral-text">Domain: </div>
              <div>{tenantData?.domainName}</div>
            </Col>
          </Row>
        </div>
        <Form
          form={form}
          onFinish={handleUpdate}
          layout="vertical"
          className="mt-20"
        >
          <Form.Item
            label={<div className="neutral-text">Contact: </div>}
            rules={[{ required: true, message: "Please enter contact" }]}
            hasFeedback={true}
            name="contact"
          >
            <Input
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={<div className="neutral-text">Address: </div>}
            rules={[{ required: true, message: "Please enter address" }]}
            hasFeedback={true}
            name="address"
          >
            <Input.TextArea
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <div className="right-aligned-buttons">
              <Button
                type="primary"
                htmlType="submit"
                className="mt-20"
                loading={inProgress}
                icon={<CmlIcon iconName="update" />}
              >
                Update
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditTenantDetails;
