import React, { useEffect, useState } from "react";
import { toLocalDateString } from "../../../../../util/formatter/getDateInTextFormat";
import { useAtomValue } from "jotai";
import { authUserAtom } from "../../../../../contexts/generalStore/store";
import { Col, Input, Row } from "antd";
import RequestTable from "../../../../tables/ownRequest/OwnRequestTable";
import { getOwnLeaveRequest } from "../../../../../controller/employee/getOwnLeaveRequest";
import { spawnErrorToast } from "../../../../../util/toast/spawnToast";

const OwnLeaveRequestTable = ({
  startDate,
  endDate,
  teamName,
  actions,
  isSearchedClicked,
  setIsSearchedClicked,
  userLeaveData,
  setUserLeaveData,
}) => {
  const [searchText, setSearchText] = useState("");
  const [hasLoaded, setHasLoaded] = useState(false);

  const [isAllRequestFetched, setIsAllRequestFetched] = useState({
    isAllDataFetched: true,
  });

  const [inProgress, setInProgress] = useState(true);

  const [paginationToken, setPaginationToken] = useState("");

  const authUser = useAtomValue(authUserAtom);

  const handleTableSearch = (value) => {
    setSearchText(value);
  };

  const callOwnLeaveRequestApi = async (token = " ") => {
    try {
      const response = await getOwnLeaveRequest(
        authUser,
        startDate.toISOString(),
        endDate.toISOString(),
        token
      );

      if (response?.data)
        if (response.data?.ContinuationToken) {
          setIsAllRequestFetched({
            ...isAllRequestFetched,
            isAllDataFetched: false,
          });
          setPaginationToken(response.data?.ContinuationToken);
        } else {
          setIsAllRequestFetched({
            ...isAllRequestFetched,
            isAllDataFetched: true,
          });
        }
      return response.data.Items;
    } catch (error) {
      spawnErrorToast("Couldn't retrieve data");
      return [];
    }
  };

  const loadMoreLeaves = async () => {
    setInProgress(true);
    const leaves = await callOwnLeaveRequestApi(paginationToken);
    leaves.forEach((item, i) => {
      if (item?.creationDate !== userLeaveData[i]?.creationDate) {
        setUserLeaveData([...userLeaveData, ...leaves]);
      }
    });
    setInProgress(false);
  };

  const getLeaveList = async () => {
    setPaginationToken("");
    setUserLeaveData([]);
    setInProgress(true);
    const leaves = await callOwnLeaveRequestApi();
    setUserLeaveData([...leaves]);
    setInProgress(false);
  };
  useEffect(() => {
    if (isSearchedClicked) {
      getLeaveList();
    }
    return () => {
      setIsSearchedClicked(false);
    };
  }, [startDate, endDate]);
  useEffect(() => {
    if (startDate && endDate && !hasLoaded && !isSearchedClicked) {
      getLeaveList();
      setHasLoaded(true);
    }
  }, [startDate, endDate, hasLoaded]);

  return (
    <div className="cml-card">
      {userLeaveData.length !== 0 && (
      <Row className="mb-20">
        <Col span={12}>
          <Input.Search
            placeholder="Start typing to search"
            onChange={(e) => handleTableSearch(e.target.value)}
            style={{
              width: window.screen.width > 500 ? "300px" : "100%",
            }}
            enterButton
          />
        </Col>
        <Col span={12}>
          <div className="neutral-text right-aligned-buttons mt-15">
            {
              `Results shown from ${toLocalDateString(startDate)} - ${toLocalDateString(endDate)}`
            }
          </div>
        </Col>
      </Row>
      )}
      <RequestTable
        userLeaveData={userLeaveData}
        actions={actions}
        inProgress={inProgress}
        loadMoreLeaves={loadMoreLeaves}
        isAllRequestFetched={isAllRequestFetched.isAllDataFetched}
        searchText={searchText}
      />
    </div>
  );
};

export default OwnLeaveRequestTable;
