import React from "react";
import DesktopViewDashboard from "./view/DesktopView";
import MobileViewDashboard from "./view/MobileView";

const Dashboard = () => {
  return (
    <div>
      {window.innerWidth < 768 ? (
        <MobileViewDashboard />
      ) : (
        <DesktopViewDashboard />
      )}
    </div>
  );
};

export default Dashboard;
