import amplifyConfig from './aws-exports';

const { host } = window.location;

// This overrides needed because of the issue: https://github.com/aws-amplify/amplify-cli/issues/2792
// Fixes oauth multiple redirect urls issue using window.host
if (amplifyConfig.oauth.redirectSignIn.includes(',')) {
    const filterHost = url => new URL(url).host === host;
    amplifyConfig.oauth.redirectSignIn = amplifyConfig.oauth.redirectSignIn
        .split(',')
        .filter(filterHost)
        .shift();
    amplifyConfig.oauth.redirectSignOut = amplifyConfig.oauth.redirectSignOut
        .split(',')
        .filter(filterHost)
        .shift();
}
export default amplifyConfig;
