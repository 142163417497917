import React from "react";
import { Button } from "antd";

import CmlIcon from "../icon/CmlIcon";

/**
 * @deprecated no need to create wrapper of a button component  
 */

const SaveButton = ({ isEdit, onClick, isNewConfig }) => {
  return (
    <div>
      <Button
        type="primary"
        disabled={!isEdit && !isNewConfig}
        onClick={onClick}
        icon={<CmlIcon iconName={"hard_drive"} />}
      >
        Save
      </Button>
    </div>
  );
};

export default SaveButton;
