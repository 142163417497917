import React from "react";

const CmlIcon = ({ iconName, _className, color, size }) => {
  return (
    <span
      className={`material-symbols-outlined${_className ? ` ${_className}` : ''}`}
      style={{ color: color, fontSize: size }}
    >
      {iconName}
    </span>
  );
};

export default CmlIcon;
