import React, { useEffect, useState } from "react";

import CardOptions from "./components/cardOptions/CardOptions";
import Teams from "./components/teams/Teams";
import People from "./components/people/People";
import ConfigSetting from "./components/config/ConfigSettings";
import TenantConfig from "./components/tenantConfig/TenantConfig";
import History from "./components/history/History";
import { useSetAtom } from "jotai";
import {
  selectedTeamAtom,
  selectedTeamForAssignAtom,
  selectedTeamForOvertimeAtom,
  selectedTeamForRequestsAtom,
} from "../../../../contexts/generalStore/store";
import { defaultSelectionInTeamAssign } from "../../../../util/constants/modalSelectionDefaults";

const AdministrationView = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [showCardChild, setShowCardChild] = useState(false);
  const [pageTitle, setPageTitle] = useState(["administration"]);
  const [titleDiv, setTitleDiv] = useState(null);

  const setSelectedTeam = useSetAtom(selectedTeamAtom);
  const setSelectedTeamForAssign = useSetAtom(selectedTeamForAssignAtom);
  const setSelectedTeamForOvertime = useSetAtom(selectedTeamForOvertimeAtom);
  const setSelectedTeamForRequest = useSetAtom(selectedTeamForRequestsAtom);

  const handleCardClick = (index) => {
    setSelectedCard(index);
    setPageTitle(["administration", index]);
  };

  useEffect(() => {
    if (selectedCard !== null) {
      setShowCardChild(true);
    } else {
      setShowCardChild(false);
    }
  }, [selectedCard]);

  const handleTitleClick = (item) => {
    if (item === "administration") {
      setShowCardChild(false);
      setSelectedCard(null);
      setPageTitle(["administration"]);
      setSelectedTeam(defaultSelectionInTeamAssign.DEFAULT_TEAM_SELECTION);
      setSelectedTeamForAssign(
        defaultSelectionInTeamAssign.DEFAULT_TEAM_SELECTION
      );
      setSelectedTeamForOvertime(
        defaultSelectionInTeamAssign.DEFAULT_TEAM_SELECTION
      );
      setSelectedTeamForRequest(
        defaultSelectionInTeamAssign.DEFAULT_TEAM_SELECTION
      );
      setTitleDiv(null);
      return;
    }
    setSelectedCard(item);
    setShowCardChild(true);
  };

  const capitalFirstChar = (inputString) => {
    if (!inputString || typeof inputString !== "string") {
      return "";
    }

    let formattedString = inputString.charAt(0).toUpperCase();

    for (let i = 1; i < inputString.length; i++) {
      const currentChar = inputString.charAt(i);

      if (currentChar === currentChar.toUpperCase()) {
        formattedString += " " + currentChar;
      } else {
        formattedString += currentChar;
      }
    }

    return formattedString;
  };

  return (
    <div>
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          gap: 15,
          flexWrap: "wrap",
        }}
      >
        {pageTitle.map((item, index) => (
          <div
            key={index}
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              gap: 15,
            }}
            onClick={() => {
              handleTitleClick(item);
            }}
          >
            {item !== "administration" ? <h1> {">"}</h1> : null}
            <h1>{capitalFirstChar(item)}</h1>
          </div>
        ))}
        {titleDiv}
      </div>
      {showCardChild ? (
        <>
          {selectedCard === "history" && (
            <History
              setShowCardChild={setShowCardChild}
              setSelectedCard={setSelectedCard}
            />
          )}
          {selectedCard === "teams" && (
            <Teams
              setShowCardChild={setShowCardChild}
              setSelectedCard={setSelectedCard}
            />
          )}
          {selectedCard === "people" && (
            <People
              setShowCardChild={setShowCardChild}
              setSelectedCard={setSelectedCard}
            />
          )}
          {selectedCard === "required fields" && (
            <ConfigSetting
              setShowCardChild={setShowCardChild}
              setSelectedCard={setSelectedCard}
            />
          )}
          {selectedCard === "systemConfiguration" && (
            <TenantConfig setTitleDiv={setTitleDiv} />
          )}
        </>
      ) : (
        <div className="page-container">
          <CardOptions onCardClick={handleCardClick} />
        </div>
      )}
    </div>
  );
};

export default AdministrationView;
