import lodash from "lodash";
import { useAtomValue } from "jotai";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import secureLocalStorage from "react-secure-storage";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Popover } from "antd";

import { GeneralContext } from "../../../../contexts/general/Context";
import { getOwnLeaveRequest } from "../../../../controller/employee/getOwnLeaveRequest";
import { eventsInfo } from "../../../../util/constants/eventsInfo";
import LeaveDetails from "../drawer/leaveDetails/LeaveDetails";
import { tenantConfigAtom } from "../../../../contexts/generalStore/store";
import { PERMISSIONS } from "../../../../util/constants/permission";
import MessageContainer from "../messageContainer/MessageContainer";
import { tenantConfigMessage } from "../../../../util/constants/tenantConfigMessage";
import { ShimmerDiv, CardDiv } from "../common/Common-styled";
import dayjs from "dayjs";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const CalendarView = ({ inProgress, parsedSelectedGroup }) => {
  const { currentTheme } = useContext(GeneralContext);

  const tenantConfig = useAtomValue(tenantConfigAtom);

  const [events, setEvents] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [leaveDetails, setLeaveDetails] = useState({});
  const [updateLeaveArray, setUpdateLeaveArray] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [weekendFirst, setWeekendFirst] = useState([]);
  const [weekendSecond, setWeekendSecond] = useState([]);
  const [expireDate, setExpireDate] = useState([]);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const addEvent = (event) => {
    let restructuredEvent = {
      start: new Date(event?.details?.startDate),
      end: new Date(event?.details?.endDate),
      duration: event?.details?.duration,
      employeeEmail: event?.employeeEmail,
      leaveType: event?.details?.leaveType,
      title: `${event?.employeeEmail} on ${lodash.startCase(
        event?.details?.leaveType
      )}`,
      status: event?.status,
      description: event?.details?.description,
      id: event?.reqId,
    };
    setEvents((prevState) => [...prevState, restructuredEvent]);
  };

  const getLeaveRequest = async (from, to) => {
    const user = JSON.parse(secureLocalStorage.getItem("authUser"));
    const takenLeavesList = await getOwnLeaveRequest(user, from, to);
    takenLeavesList?.data?.Items.forEach((item) => {
      addEvent(item);
    });
    setUpdateLeaveArray(true);
  };

  const isInHolidayArray = (array, DateValue) => {
    const dateValueStr = new Date(DateValue).toDateString();
    return array.some(
      (item) => new Date(item.date).toDateString() === dateValueStr
    );
  };

  const getHolidays = () => {
    tenantConfig?.forEach((value, index) => {
      setExpireDate((prevState) => [...prevState, new Date(value?.expiredAt)]);
      const holidayArray = Object.keys(value?.holiday).map((date) => {
        return {
          date: new Date(date),
          title: value?.holiday[date].title,
        };
      });
      Object.keys(value?.weekend)?.map((item) => {
        if (index === 0) {
          if (!weekendFirst.includes(parseInt(item))) {
            setWeekendFirst((prevState) => [...prevState, parseInt(item)]);
          }
        }
        if (index === 1) {
          if (!weekendSecond.includes(parseInt(item))) {
            setWeekendSecond((prevState) => [...prevState, parseInt(item)]);
          }
        }
      });
      setHolidays((prevState) => [...prevState, ...holidayArray]);
    });
  };

  useEffect(() => {
    if (!inProgress && tenantConfig.length > 0) {
      getHolidays();
      const firstYearStart = tenantConfig[0]?.expiredAt;
      const currentYear = new Date(firstYearStart).getFullYear();
      const twoYearsBeforeExpirationYear = currentYear - 1;
      const twoYearsBeforeExpirationDate = new Date(firstYearStart).setFullYear(
        twoYearsBeforeExpirationYear
      );
      const from = new Date(twoYearsBeforeExpirationDate).toISOString();
      const to = new Date(
        tenantConfig[tenantConfig.length - 1]?.expiredAt
      ).toISOString();
      setFromDate(from);
      setToDate(to);
      getLeaveRequest(from, to);
    }
  }, [inProgress]);

  const handleEventClick = (event) => {
    setLeaveDetails({
      title: event.title,
      end: event.end,
      start: event.start,
      duration: event.duration,
      description: event.description,
      status: event.status,
      leaveType: event.leaveType,
      employeeEmail: event.employeeEmail,
      id: event.id,
    });
    setShowDrawer(true);
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor;
    if (event?.status === eventsInfo.name.PENDING) {
      backgroundColor = eventsInfo.color.PENDING;
    } else if (event.status === eventsInfo.name.APPROVED) {
      backgroundColor = eventsInfo.color.APPROVED;
    } else if (event.status === eventsInfo.name.MODIFICATION) {
      backgroundColor = eventsInfo.color.MODIFICATION;
    } else if (event.status === eventsInfo.name.CANCELED) {
      backgroundColor = eventsInfo.color.CANCELED;
    } else if (event.status === eventsInfo.name.REJECTED) {
      backgroundColor = eventsInfo.color.REJECTED;
    }
    const style = {
      backgroundColor: backgroundColor,
      borderRadius: "5px",
      opacity: 1,
      color: "white",
      border: "0px",
      display: "block",
      marginTop: "5px",
    };
    return {
      style: style,
    };
  };

  const isWeekendOrHoliday = (date) => {
    const day = date.getDay();
    if (expireDate[0] > date) {
      return weekendFirst.includes(day) || isInHolidayArray(holidays, date)
        ? true
        : false;
    } else {
      return weekendSecond.includes(day) || isInHolidayArray(holidays, date)
        ? true
        : false;
    }
  };

  const renderHolidayTooltip = (date) => {
    if (isInHolidayArray(holidays, date)) {
      const holiday = holidays.find(
        (item) =>
          new Date(item.date).toDateString() === new Date(date).toDateString()
      );
      return <>{holiday.title}</>;
    } else {
      return <></>;
    }
  };

  const renderTooltip = (date) => {
    const day = new Date(date).getDay();
    if (expireDate[0] > date) {
      if (weekendFirst.includes(day)) {
        return <>Weekend</>;
      } else {
        return renderHolidayTooltip(date);
      }
    } else {
      if (weekendSecond.includes(day)) {
        return <>Weekend</>;
      } else {
        return renderHolidayTooltip(date);
      }
    }
  };

  const handleDrawerClose = () => {
    setShowDrawer(false);
  };

  const onNavigate = useCallback(
    (newDate) => setCurrentDate(newDate),
    [setCurrentDate]
  );

  const getViewLastDate = (viewDate) => {
    return dayjs(viewDate).endOf("month").toDate();
  };

  let disableBackButton =
    getViewLastDate(dayjs(currentDate).subtract(1, "month")) <=
    new Date(fromDate);
  let disableNextButton =
    getViewLastDate(dayjs(currentDate).add(1, "month")) >= new Date(toDate);

  return (
    <>
      <h1>Leave Calendar</h1>
      <LeaveDetails
        open={showDrawer}
        onClose={handleDrawerClose}
        leaveDetails={leaveDetails}
      />
      <div className="page-container">
        {updateLeaveArray && !inProgress && tenantConfig?.length > 0 ? (
          <div className="cml-card">
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 600 }}
              onSelectEvent={handleEventClick}
              selectable={false}
              eventPropGetter={(event) => eventStyleGetter(event)}
              views={["month", "day", "agenda"]}
              onNavigate={onNavigate}
              components={{
                month: {
                  dateHeader: ({ date, label }) => {
                    if (!isWeekendOrHoliday(date)) {
                      return (
                        <div
                          style={{
                            color: currentTheme.text,
                          }}
                        >
                          {label}
                        </div>
                      );
                    } else {
                      return (
                        <Popover content={renderTooltip(date)} placement="auto">
                          <span
                            style={{
                              color: "#e20808",
                            }}
                          >
                            {label}
                          </span>
                        </Popover>
                      );
                    }
                  },
                },
                toolbar: (props) => {
                  const { label, onView, onNavigate, view } = props;
                  return (
                    <div className="rbc-toolbar">
                      <div className="rbc-btn-group">
                        <button
                          type="button"
                          style={{ cursor: "pointer" }}
                          onClick={() => onNavigate("TODAY")}
                        >
                          Today
                        </button>
                        <button
                          type="button"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            onNavigate("PREV");
                            if (!disableBackButton) {
                              onNavigate("PREV");
                            }
                          }}
                          disabled={disableBackButton}
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (!disableNextButton) {
                              onNavigate("NEXT");
                            }
                          }}
                          disabled={disableNextButton}
                        >
                          Next
                        </button>
                      </div>
                      <div className="rbc-toolbar-label">{label}</div>
                      <div className="rbc-btn-group">
                        <button
                          type="button"
                          style={{ cursor: "pointer" }}
                          onClick={() => onView("month")}
                          className={`${view === "month" ? "rbc-active" : ""}`}
                        >
                          Month
                        </button>
                        <button
                          type="button"
                          style={{ cursor: "pointer" }}
                          onClick={() => onView("day")}
                          className={`${view === "day" ? "rbc-active" : ""}`}
                        >
                          Day
                        </button>
                        <button
                          type="button"
                          style={{ cursor: "pointer" }}
                          onClick={() => onView("agenda")}
                          className={`${view === "agenda" ? "rbc-active" : ""}`}
                        >
                          Agenda
                        </button>
                      </div>
                    </div>
                  );
                },
              }}
            />
          </div>
        ) : !inProgress &&
          !tenantConfig?.length &&
          !PERMISSIONS.view.menu.noTenantConfig_NoRefreshTokenAllowedGroups.some(
            (value) => parsedSelectedGroup?.includes(value)
          ) ? (
          <MessageContainer
            messages={tenantConfigMessage.EMPTY_TENANT_CONFIG}
          />
        ) : (
          <CardDiv padding={"30px"}>
            <div
              style={{
                display: "flex",
                flexDirection: window.screen.width < 500 ? "column" : "row",
                justifyContent: "space-between",
                alignContent: "center",
                alignItems: window.screen.width < 500 ? "center" : "auto",
              }}
            >
              <ShimmerDiv
                height={"30px"}
                width={"200px"}
                style={{
                  marginBottom: "10px",
                }}
              />
              <ShimmerDiv
                height={"30px"}
                width={"150px"}
                style={{
                  marginBottom: "10px",
                }}
              />
              <ShimmerDiv
                height={"30px"}
                width={"200px"}
                style={{
                  marginBottom: "10px",
                }}
              />
            </div>
            <ShimmerDiv height={"600px"} width={"100%"} />
          </CardDiv>
        )}
      </div>
    </>
  );
};

export default CalendarView;
