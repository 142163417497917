import { Dropdown, Switch, Button, Avatar, Image, Select } from "antd";
import { useContext, useEffect } from "react";
import lodash from "lodash";

import {
  PicLogoWrapper,
  TenantLogoWrapper,
} from "../../../style/common/common-styled";
import tenantLogo from "../../../img/craftsmen_logo.svg";
import { handleLogout } from "../../../util/session/handleLogout";
import { GeneralContext } from "../../../contexts/general/Context";
import { useAtom, useAtomValue } from "jotai";
import {
  authUserAtom,
  multiGroupSelectedAtom,
} from "../../../contexts/generalStore/store";
import CmlIcon from "../../dashboard/components/common/icon/CmlIcon";

const NavDropdown = (props) => {
  const { toggleMyTheme, currentTheme } = useContext(GeneralContext);
  const {
    user,
    role,
    teamName,
    imageSource,
    tenantDomain,
    unassigned,
    inSettings,
  } = props;

  const authUser = useAtomValue(authUserAtom);
  const [multiGroupSelection, setMultiGroupSelected] = useAtom(
    multiGroupSelectedAtom
  );

  const stringifyGroup = authUser?.signInUserSession
    ? authUser?.signInUserSession?.idToken?.payload?.group
    : authUser?.idToken?.payload?.group;

  const parsedGroup = JSON.parse(stringifyGroup);
  const selectedParsedGroup = JSON.parse(localStorage.getItem("selectedGroup"));

  useEffect(() => {
    if (!selectedParsedGroup) {
      setMultiGroupSelected(parsedGroup[0]);
    } else {
      setMultiGroupSelected(selectedParsedGroup[0]);
    }
  }, []);

  const handleThemeToggle = () => {
    // toggleMyTheme();
  };

  const handleOnChange = (value) => {
    setMultiGroupSelected(value);
    localStorage.setItem("selectedGroup", JSON.stringify([value]));
  };

  const handleLogoutButtonClick = () => {
    console.log('Logout button clicked');
    handleLogout();
    setMultiGroupSelected();
  };

  const items = [
    {
      key: "1",
      className: "user-nameORemail",
      label: <div style={{ user }}>{user}</div>,
    },
    {
      key: "2",
      className:
        parsedGroup.length > 1 ? "user-roleSelect" : "user-teamAndRole",
      label: (
        <>
          {parsedGroup.length > 1 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              {parsedGroup.map((role, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <div
                      onClick={() => handleOnChange(role)}
                      className="user-role"
                      style={{
                        fontWeight:
                          multiGroupSelection === role ? "600" : "300",
                        color:
                          multiGroupSelection === role
                            ? "#0a869d"
                            : currentTheme.text,
                      }}
                    >
                      {lodash.startCase(role)}
                    </div>
                    <>{index !== parsedGroup.length - 1 && " |"}</>
                  </div>
                );
              })}
            </div>
          ) : (
            <>
              {teamName ? teamName : tenantDomain ? tenantDomain : "Cempal"} |{" "}
              {lodash.startCase(role)}
            </>
          )}

          <div style={{ marginTop: "15px", borderBottom: "#545a5b 1px solid" }}></div>
        </>
      ),
    },
    {
      key: "3",
      className: "theme-toggle",
      label: (
        <div
          className="theme-toggler-wrapper"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div className="themeText">Dark Theme </div>
          <Switch
            className="toggle-switch"
            checked={ true }
            onChange={handleThemeToggle}
          />
        </div>
      ),
    },
    {
      key: "4",
      className: "logout-item",
      label: (
        <>
          <div style={{ marginTop: "5px", marginBottom: "10px", borderBottom: "#545a5b 1px solid" }}></div>        
          <div
            className="logout-btn-wrapper"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              id="logout-button"
              type="link"
              className="logout-btn"
              onClick={handleLogoutButtonClick}
            >
              Logout
            </Button>
          </div>
        </>
      ),
    },
  ];

  const getImage = (image) => {
    return <img src={image} alt="User" referrerPolicy="no-referrer" />;
  };

  return (
    <Dropdown
      menu={{
        items,
      }}
      trigger={["click"]}
      placement="bottomRight"
      arrow={false}
      disableMouseEvents={true}
    >
      <Button type="text" className="top-nav-button mr-9">
        <PicLogoWrapper>
          <TenantLogoWrapper>
            <img
              src={tenantLogo}
              alt="tenantLogo"
              height="28px"
              referrerPolicy="no-referrer"
            />
          </TenantLogoWrapper>
          {imageSource ? (
            <Avatar
              src={getImage(imageSource)}
              alt="User Image"
              size={35}
              shape="circle"
            />
          ) : (
            <CmlIcon
              _className="medium-icon-style"
              iconName={"account_circle"}
            />
          )}
        </PicLogoWrapper>
      </Button>
    </Dropdown>
  );
};

export default NavDropdown;
