import React, { useState, useEffect, useContext } from "react";
import lodash from "lodash";
import { useAtomValue } from "jotai";
import { Avatar, Layout } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import userPic from "../../img/user.png";
import {
  ExpireMessageDiv,
  NoDataWrapper,
  TableHeaderWrapper,
  TableWrapper,
} from "./PublicShare-styled";
import DynamicTable from "../tables/dynamicTable/DynamicTable";
import { endpointsAtom } from "../../contexts/generalStore/store";
import { getDateInTextFormat } from "../../util/formatter/getDateInTextFormat";
import { getPublicShare } from "../../controller/public/getSnapshot";
import { GeneralContext } from "../../contexts/general/Context";

const PublicShare = () => {
  const currentURL = String(window.location.href || "");
  const shareId = currentURL.split("=")[1];

  const { currentTheme } = useContext(GeneralContext);
  const [data, setData] = useState([]);
  const [config, setConfig] = useState();
  const [message, setMessage] = useState();
  const [inProgress, setInProgress] = useState(true);
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [noData, setNoData] = useState(false);
  const [hasError, setHasError] = useState(false);
  const endpoints = useAtomValue(endpointsAtom);
  const [searchText, setSearchText] = useState("");



  useEffect(() => {
    if (endpoints && !isApiCalled) {
      getPublicShareData();
    }
  }, [endpoints]);

  const getPublicShareData = async () => {
    setInProgress(true);
    setIsApiCalled(true);
    try {
      const response = await getPublicShare(shareId);
      setInProgress(false);
      const data =
        response?.data?.Items[0]?.balanceSnapshot?.teamMembersBalance;
      if (!data) {
        setHasError(true);
        setMessage("Invalid link. Please check the link and try again.");
      }
      if (data && data.length === 0) {
        setNoData(true);
      }
      setData(data);
      setConfig(response?.data?.Items[0]);
    } catch (error) {
      setInProgress(false);
      setHasError(true);
      setMessage(error?.response?.data);
    }
  };

  const UserPic = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Avatar size={50} src={row?.pictureLink ? row?.pictureLink : userPic} />
    );
  };
  const leaveTypeCell = (record, item) => {
    return (
      <>{record?.leaveBalance?.firstConfig[item]}</>
    );
  };

  const columns = [
    {
      dataField: "",
      text: "",
      render: UserPic,
      width: "50px",
      align: "center",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          record?.email?.toLowerCase().includes(value.toLowerCase()) ||
          record?.employeeName?.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      dataIndex: "employeeName",
      title: "Name",
      width: "350px",
      align: "center",
    },
  ];

  data && data.length &&
    Object.keys(data[0]?.leaveBalance?.firstConfig).map((item, i) =>
      columns.push({
        dataIndex: `leaveBalance`,
        title: lodash.startCase(item),
        width: "350px",
        align: "center",
        render: (text, record) => {
          return leaveTypeCell(record, item);
        },
      })
    );

  const handleTableSearch = (value) => {
    setSearchText(value);
  };
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: currentTheme.background
      }}
    >
      {data && !inProgress ? (
        <TableWrapper>
          <TableHeaderWrapper>
            {config?.teamName} Member's Balance List till : {getDateInTextFormat(config.createdAt)}
          </TableHeaderWrapper>
          <DynamicTable
            tableTitle={"Team Members Balance"}
            rowKey="email"
            columns={columns}
            data={data}
            handleTableSearch={handleTableSearch}
            isAllDataFetched={true}
            scroll={{ x: 768 }}
            size="small"
          />
        </TableWrapper>
      ) : inProgress && endpoints ? (
        <TableWrapper>
          <LoadingOutlined
            style={{
              fontSize: 54,
            }}
          />
        </TableWrapper>
      ) : noData ? (
        <Layout>
          <NoDataWrapper>
            <ExpireMessageDiv>No data found</ExpireMessageDiv>
          </NoDataWrapper>
        </Layout>
      ) : (
        hasError ? (
          <Layout>
            <NoDataWrapper>
              <ExpireMessageDiv>{message}</ExpireMessageDiv>
            </NoDataWrapper>
          </Layout>
        ) : !endpoints && (
          <>
            <TableWrapper>
              <LoadingOutlined
                style={{
                  fontSize: 54,
                }}
              />
            </TableWrapper>
          </>
        )
      )}
    </div>
  );
};

export default PublicShare;
