import { notification } from "antd";

const spawnNotification = (
  type,
  message,
  description = null,
  placement = "bottomRight"
) => {
  notification[type]({
    message: message,
    description: description,
    placement: placement,
    className: `custom-notification custom-notification-${type}`,
  });
};

export const spawnSuccessToast = (
  message,
  description = null,
  placement = "bottomRight"
) => {
  spawnNotification("success", message, description, placement);
};

export const spawnErrorToast = (
  message,
  description = null,
  placement = "bottomRight"
) => {
  spawnNotification("error", message, description, placement);
};
