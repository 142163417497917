export const eventsInfo = {
  name: {
    APPROVED: "approved",
    PENDING: "pending",
    MODIFICATION: "modification",
    CANCELED: "canceled",
    REJECTED: "rejected",
  },
  color: {
    APPROVED: "#2e7d32",
    PENDING: "#f57c00",
    MODIFICATION: "#0277bd",
    CANCELED: "#930319",
    REJECTED: "#930319",
  },
};
