import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import { isValidSession } from "../../util/session/refreshSession";
import { saveCalendarAccess } from "../../controller/notification/saveCalendarAccess";
import { route } from "../../constant";
import Dashboard from "../dashboard/Dashboard";
import { useSetAtom } from "jotai";
import { authUserAtom } from "../../contexts/generalStore/store";
import LandingPage from "../landingPage/LandingPage";

const SecureRoute = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();
  const from = location.pathname;
  const [user, setUser] = useState(
    JSON.parse(secureLocalStorage.getItem("authUser"))
  );

  const setAuthUser = useSetAtom(authUserAtom);
  const [isRedirected, setIsRedirected] = useState(
    JSON.parse(localStorage.getItem("amplify-redirected-from-hosted-ui"))
  );

  const isSubset = (subset, superset) => {
    for (const item of subset) {
      if (!superset.includes(item)) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    const getAuthUserInfo = async () => {
      const data = await isValidSession();
      setUser(data);
      setAuthUser(data);
    };
    getAuthUserInfo();
  }, []);

  useEffect(() => {
    const stringifyGroup = user?.signInUserSession
      ? user?.signInUserSession?.idToken?.payload?.group
      : user?.idToken?.payload?.group;
    const selectedGroup = localStorage.getItem("selectedGroup");
    let stringifyGroupArray = [];
    let parsedSelectedGroup = [];
    try {
      stringifyGroupArray = JSON.parse(stringifyGroup);
      parsedSelectedGroup = JSON.parse(selectedGroup);
    } catch (err) {}

    if (stringifyGroup && !selectedGroup) {
      localStorage.setItem(
        "selectedGroup",
        JSON.stringify([JSON.parse(stringifyGroup)[0]])
      );
    } else if (!isSubset(parsedSelectedGroup, stringifyGroupArray)) {
      localStorage.removeItem("selectedTabInfo");
      localStorage.setItem(
        "selectedGroup",
        JSON.stringify([JSON.parse(stringifyGroup)[0]])
      );
    } else if (selectedGroup) {
      localStorage.setItem("selectedGroup", selectedGroup);
    }
  }, [user]);

  useEffect(() => {
    const saveAccess = async () => {
      const code = searchParams.get("code");
      const scope = searchParams.get("scope");
      const calendarScope =
        "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events";
      if (code && scope === calendarScope) {
        try {
          await saveCalendarAccess(code);
          toast.success("Saved Calendar permission!");
        } catch (error) {
          toast.error("Failed saving calendar permission!");
        }
      }
    };
    saveAccess();
  }, [searchParams]);

  if (user) {
    if (from === route.DASHBOARD) {
      return <Dashboard />;
    }
    return <Navigate to={route.DASHBOARD} replace />;
  } else if (!isRedirected && from === route.HOME) {
    return <LandingPage />;
  } else if (!user) {
    return <Navigate to={route.LOGIN} replace />;
  } else {
    return <Navigate to={route.ERROR_PAGE} replace />;
  }
};

export default SecureRoute;
