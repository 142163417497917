import { darkTheme, lightTheme } from "../../util/theme/colorPalette";
import { THEME_PROP } from "./Actions";

const GeneralReducer = (state, action) => {
  switch (action.type) {
    case THEME_PROP.TOGGLE_THEME:
      if (state.preferredTheme === "light") {
        localStorage.setItem("preferredTheme", "dark");
        return {
          ...state,
          preferredTheme: "dark",
          currentTheme: darkTheme,
        };
      } else {
        localStorage.setItem("preferredTheme", "light");
        return {
          ...state,
          preferredTheme: "light",
          currentTheme: lightTheme,
        };
      }

    default:
      return state;
  }
};

export default GeneralReducer;
