import React, { useState } from "react";
import { Button } from "antd";
import { getAuthUrl } from "../../../../controller/notification/getAuthUrl";
import CmlIcon from "../common/icon/CmlIcon";

const NoCalendarAccess = () => {
  const [inProgress, setInProgress] = useState(false);

  const handleAllow = async () => {
    setInProgress(true);
    const response = await getAuthUrl();
    window.location.replace(response.data.data);
  };

  return (
    <div className="cml-card">
      <div className="p-40 ta-c">
        <h1>Please, Allow Calendar Access</h1>
        <h5 className="mt-10">It is required for applying leave application</h5>
        <Button
          className="mt-20"
          type="primary"
          onClick={handleAllow}
          loading={inProgress}
          icon={<CmlIcon iconName="task_alt" />}
        >
          Allow
        </Button>
      </div>
    </div>
  );
};

export default NoCalendarAccess;
