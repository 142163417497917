import { getListOfTeams } from "../../controller/admin/getListOfTeams";
import { getTeamLeadRequests } from "../../controller/admin/getTeamLeadRequests";
import { getLeaveRequest } from "../../controller/employee/getLeaveRequest";
import { getTenantConfig } from "../../controller/employee/getTenantConfig";
import { getUserDetails } from "../../controller/employee/getUserDetails";
import { getTeamConfig } from "../../controller/teamLead/getTeamConfig";
import { PERMISSIONS } from "../constants/permission";

export const apiCalls = async (group, authUser) => {
  let tenantConfig, teamConfig, userDetails, teams;
  if (
    PERMISSIONS.apis.tenantConfigAllowedGroup.some((values) =>
      group.includes(values)
    ) &&
    PERMISSIONS.apis.userDetailsAllowedGroups.some((values) =>
      group.includes(values)
    )
  ) {
    [tenantConfig, teamConfig, userDetails] = await Promise.all([
      getTenantConfig(authUser),
      getTeamConfig(),
      getUserDetails(authUser),
    ]);
  }
  if (
    PERMISSIONS.apis.fetchAllTeamsAllowedGroups.some((values) =>
      group.includes(values)
    )
  ) {
    [teams, tenantConfig] = await Promise.all([
      getListOfTeams(authUser),
      getTenantConfig(authUser),
    ]);
  }
  return { tenantConfig, teamConfig, userDetails, teams };
};

export const callRequestApprovalApi = async (
  group,
  authUser,
  token,
  selectedTeam
) => {
  if (
    PERMISSIONS.apis.approveLeaveRequestAllowedGroups.default.some((values) =>
      group.includes(values)
    )
  ) {
    return await getLeaveRequest(authUser, token);
  }
  if (
    PERMISSIONS.apis.approveLeaveRequestAllowedGroups.selectTeam.some(
      (values) => group.includes(values)
    )
  ) {
    if (selectedTeam !== "invalid" && selectedTeam) {
      return await getTeamLeadRequests(selectedTeam, token);
    }
  }
};
