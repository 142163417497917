import React from "react";
import { CardDiv, ShimmerDiv } from "../../common/Common-styled";

const ApplyLeaveSkeleton = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <ShimmerDiv
        height={"40px"}
        width={window.innerWidth > 768 ? "20%" : "50%"}
      />
      <CardDiv
        style={{ width: window.innerWidth > 768 ? "30%" : "100%" }}
        padding={"20px"}
      >
        <ShimmerDiv
          height={"40px"}
          width={window.innerWidth > 768 ? "200px" : "100%"}
          style={{ marginBottom: "20px" }}
        />
        <>
          <ShimmerDiv
            height={"20px"}
            width={"150px"}
            style={{ marginBottom: "5px" }}
          />
          <ShimmerDiv
            height={"40px"}
            width={"100%"}
            style={{ marginBottom: "15px" }}
          />
        </>

        <>
          <ShimmerDiv
            height={"20px"}
            width={"150px"}
            style={{ marginBottom: "5px" }}
          />
          <ShimmerDiv
            height={"40px"}
            width={"100%"}
            style={{ marginBottom: "15px" }}
          />
        </>

        <>
          <ShimmerDiv
            height={"20px"}
            width={"150px"}
            style={{ marginBottom: "5px" }}
          />
          <ShimmerDiv
            height={"40px"}
            width={"100%"}
            style={{ marginBottom: "15px" }}
          />
        </>

        <>
          <ShimmerDiv
            height={"20px"}
            width={"150px"}
            style={{ marginBottom: "5px" }}
          />
          <ShimmerDiv
            height={"80px"}
            width={"100%"}
            style={{ marginBottom: "15px" }}
          />
        </>

        <>
          <ShimmerDiv
            height={"20px"}
            width={"150px"}
            style={{ marginBottom: "5px" }}
          />
          <ShimmerDiv
            height={"50px"}
            width={"100%"}
            style={{ marginBottom: "10px" }}
          />
          <ShimmerDiv
            height={"50px"}
            width={"100%"}
            style={{ marginBottom: "25px" }}
          />
        </>

        <ShimmerDiv
          height={"40px"}
          width={"100px"}
          style={{ marginBottom: "15px" }}
        />
      </CardDiv>
    </div>
  );
};

export default ApplyLeaveSkeleton;
