// TODO: Local culture-info based date formatting should be used to display date in the UI

export const getDateInTextFormat = (dateStr) => {
  const date = new Date(dateStr);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  const formattedDate = `${day} ${month}, ${year}`;
  return formattedDate;
};

/**
 * @deprecated For consistency `toLocalDateString` should be used instead, to be consistent in the UI
 * Can be removed after `toLocalDateString is implemented`
 * @param {String} isoDateString 
 * @returns 
 */
const convertIsoToDate = (isoDateString) => {
  const date = new Date(isoDateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const isYearMonthDayFormatted = (inputString) => {
  const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
  return dateFormatRegex.test(inputString);
};

/**
 * Local cultureinfo based Date String (Without Time). Should be used only to show Date in the UI
 * @param {String} isoDateString 
 * @returns Formatted Date string using browser's culture info
 * TODO: Need to implement this method
 */
export const toLocalDateString = (isoDateString) => {
  return convertIsoToDateBackSlash(isoDateString);
}

/**
 * TODO: A local culture-info based implementation should be done here.
 * Or use `toLocalDateString` when it is implemented
 * @param {String} isoDateString 
 * @returns 
 */
const convertIsoToDateBackSlash = (isoDateString) => {
  const date = new Date(isoDateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${day}/${month}/${year}`;
};
// TODO: This code is vulnerable. Date.Parse should be used instead
export const convertDateStringToObject = (dateString) => {
  const [year, month, day] = dateString.split("-").map(Number);
  return new Date(year, month - 1, day); // Month is zero-based
};

export const getStartYear = (configList) => {
  if (configList?.length === 0) {
    return new Date().toISOString();
  } else {
    return new Date(configList[configList.length - 1].expiredAt);
  }
};

export const getExpiredAt = (configList) => {
  if (configList?.length === 0) {
    return "";
  } else {
    const inputDate = new Date(configList[configList.length - 1].expiredAt);
    const oneYearLater = new Date(inputDate);
    oneYearLater.setFullYear(inputDate.getFullYear() + 1);
    const resultDateISOString = oneYearLater.toISOString();
    return resultDateISOString.split("T")[0];
  }
};
