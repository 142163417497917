import styled from "@emotion/styled";

const ShimmerDiv = styled("div")`
  height: ${(props) => `${props.height}`};
  width: ${(props) => `${props.width}`};
  background: ${(props) => `${props.theme.shimmer}`};
  background-size: 200% 100%;
  animation: shimmer 2s infinite;

  @keyframes shimmer {
    0% {
      background-position: 100% 0;
    }

    100% {
      background-position: -100% 0;
    }
  }
`;

const CardDiv = styled("div")`
  width: ${(props) => props.width};
  margin-top: 30px;
  padding: 20px;
  background: ${(props) => `${props.theme.primary}`};
  border-radius: 10px;
`;

const CustomCard = styled("div")`
  padding: ${(props) => `${props.padding}`};
  background: ${(props) => `${props.theme.primary}`};
  border-radius: 10px;
  display: inline-block;
  height: fit-content;
  width: fit-content;
`;

const SubHeading = styled("div")`
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => props.theme.text};
  margin: 0 0 0 0px;
`;

const IconTextButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export { ShimmerDiv, CardDiv, SubHeading, CustomCard, IconTextButtonWrapper };
