export const calculateNoticePeriod = (
  noticePeriodKeyList,
  noticePeriodValueList,
  consumedDays,
  setFormValidateMessage,
  setValidateForm,
  dayCountForNotice,
  callApplyLeaveApi,
  newEvent,
  firstYearDurationBeforeEdit,
  secondYearDurationBeforeEdit,
  fromEdit,
  bridgeLeaveCount
) => {
  const totalConsumedDays =
    consumedDays.firstYearWorkingDays +
    consumedDays.secondYearWorkingDays +
    bridgeLeaveCount;
  for (let [index, value] of noticePeriodKeyList.entries()) {
    if (noticePeriodKeyList.includes(totalConsumedDays.toString())) {
      if (parseInt(value) === totalConsumedDays) {
        if (dayCountForNotice < noticePeriodValueList[index].notice) {
          if (totalConsumedDays === 1) {
            if (bridgeLeaveCount > 0) {
              setFormValidateMessage(
                `Number of existing leave duration :  ${bridgeLeaveCount}
                Applied leave duration :  ${
                  totalConsumedDays - bridgeLeaveCount
                }
                Notice period for ${totalConsumedDays} ${
                  totalConsumedDays > 1 ? "days" : "day"
                } is ${noticePeriodValueList[index].notice} days`
              );
            } else {
              setFormValidateMessage(
                `Notice period for ${totalConsumedDays} ${
                  totalConsumedDays > 1 ? "days" : "day"
                } is ${noticePeriodValueList[index].notice} days`
              );
            }
          } else {
            if (bridgeLeaveCount > 0) {
              setFormValidateMessage(
                `Number of existing leave duration :  ${bridgeLeaveCount}
                Applied leave duration :  ${
                  totalConsumedDays - bridgeLeaveCount
                }
                Notice period for ${totalConsumedDays} ${
                  totalConsumedDays > 1 ? "days" : "day"
                } is ${noticePeriodValueList[index].notice} days`
              );
            } else {
              setFormValidateMessage(
                `Notice period for ${totalConsumedDays} ${
                  totalConsumedDays > 1 ? "days" : "day"
                } is ${noticePeriodValueList[index].notice} days`
              );
            }
          }

          setValidateForm(false);
          return;
        } else {
          if (fromEdit) {
            callApplyLeaveApi(
              newEvent,
              firstYearDurationBeforeEdit,
              secondYearDurationBeforeEdit
            );
            return;
          } else {
            callApplyLeaveApi(newEvent);
            return;
          }
        }
      }
    } else if (
      totalConsumedDays > noticePeriodKeyList[index] &&
      totalConsumedDays < noticePeriodKeyList[index + 1]
    ) {
      if (dayCountForNotice < noticePeriodValueList[index + 1].notice) {
        if (bridgeLeaveCount > 0) {
          setFormValidateMessage(
            `Number of existing leave duration :  ${bridgeLeaveCount}
                Applied leave duration :  ${
                  totalConsumedDays - bridgeLeaveCount
                }
                Notice period for ${totalConsumedDays} ${
              totalConsumedDays > 1 ? "days" : "day"
            } is ${noticePeriodValueList[index + 1].notice} days`
          );
        } else {
          setFormValidateMessage(
            `Notice period for ${totalConsumedDays} ${
              totalConsumedDays > 1 ? "days" : "day"
            } is ${noticePeriodValueList[index + 1].notice} days`
          );
        }
        setValidateForm(false);
      } else {
        if (fromEdit) {
          callApplyLeaveApi(
            newEvent,
            firstYearDurationBeforeEdit,
            secondYearDurationBeforeEdit
          );
          return;
        } else {
          callApplyLeaveApi(newEvent);
          return;
        }
      }
    } else if (totalConsumedDays > noticePeriodKeyList[index]) {
      if (
        dayCountForNotice <
        noticePeriodValueList[noticePeriodValueList.length - 1].notice
      ) {
        if (bridgeLeaveCount > 0) {
          setFormValidateMessage(
            `Number of existing leave duration :  ${bridgeLeaveCount}
                Applied leave duration :  ${
                  totalConsumedDays - bridgeLeaveCount
                }
                Notice period for ${totalConsumedDays} ${
              totalConsumedDays > 1 ? "days" : "day"
            } is ${noticePeriodValueList[index].notice} days`
          );
        } else {
          setFormValidateMessage(
            `Notice period for ${totalConsumedDays} ${
              totalConsumedDays > 1 ? "days" : "day"
            } is ${noticePeriodValueList[index].notice} days`
          );
        }
        setValidateForm(false);
      } else {
        if (fromEdit) {
          callApplyLeaveApi(
            newEvent,
            firstYearDurationBeforeEdit,
            secondYearDurationBeforeEdit
          );
          return;
        } else {
          callApplyLeaveApi(newEvent);
          return;
        }
      }
    }
  }
};
