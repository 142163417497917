import { getAllSuperAdmins } from "../../controller/superadmin/getAllSuperAdmins";
import { spawnErrorToast } from "../../util/toast/spawnToast";

export const getAllSuperAdminHandler = async ({
    authUser,
    setIsLoading,
    setSuperAdmins,
}) => {
    setIsLoading(true);
    try {
      const response = await getAllSuperAdmins(authUser);
      setSuperAdmins(response?.data?.Items);
    } catch (err) {
      spawnErrorToast("Can't retrieve super admins at the moment");
    } finally {
      setIsLoading(false);
    }
};
  