import React, { useContext, useEffect } from "react";
import { Button, Input, InputNumber, Table, Checkbox } from "antd";
import _ from "lodash";
import PlusButton from "../../../../../../../common/buttons/PlusButton";
import CmlIcon from "../../../../../../../common/icon/CmlIcon";

const LeaveTypes = ({
  isEdit,
  isNewConfig,
  leaveTypeList,
  setLeaveTypeList,
}) => {

  useEffect(() => {}, [leaveTypeList]);

  const getAppliedNoticeColumn = (cell, row, rowIndex, colIndex) => {
    if (!isNewRow(row)) {
      return row?.appliedNotice === true ? "Yes" : "No";
    }
    return (
      <>
        <Checkbox
          checked={row?.appliedNotice}
          onChange={() => {
            handleCheckboxChChange(rowIndex);
          }}
          disabled={!isEdit && !isNewConfig}
          style={{ marginRight: 10 }}
        ></Checkbox>
      </>
    );
  };

  const isNewRow = (row) => {
    return row?.isNewRow === true;
  };

  const getLeaveTypeDaysCol = (cell, row, rowIndex, colIndex) => {
    if (!isNewRow(row)) {
      return row?.value;
    }
    return (
      <>
        <InputNumber
          disabled={!isEdit & !isNewConfig}
          value={row?.value}
          bordered={false}
          min={1}
          onChange={(newValue) => {
            handleNoticePeriodValueChange(newValue, rowIndex);
          }}
        />
      </>
    );
  };

  const getLeaveTypeCol = (cell, row, rowIndex, colIndex) => {
    if (!isNewRow(row)) {
      return undoCamelCase(row?.leaveType);
    }
    return (
      <>
        <Input
          disabled={!isEdit && !isNewConfig}
          value={row?.leaveType}
          onChange={(e) => {
            handleLeaveTypeNameChange(e.target.value, rowIndex);
          }}
        />
      </>
    );
  };

  const actionCell = (cell, row, rowIndex, colIndex) => {
    return (
      <Button
        type="link"
        size="small"
        title="Delete this leave type"
        icon={<CmlIcon iconName="delete" />}
        onClick={() => { handleRemoveLeaveType(rowIndex); }}
      />
    );
  };

  const tableColumn = [
    {
      title: "Leave Type",
      dataIndex: "leaveType",
      key: "leaveType",
      render: getLeaveTypeCol,
    },
    {
      title: "# Days",
      dataIndex: "value",
      key: "value",
      render: getLeaveTypeDaysCol,
    },
    {
      title: "Apply Notice Period?",
      dataIndex: "appliedNotice",
      key: "appliedNotice",
      render: getAppliedNoticeColumn,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: actionCell,
    },
  ];

  const handleCheckboxChChange = (index) => {
    const updatedList = [...leaveTypeList];
    const leaveType = updatedList[index];
    leaveType.appliedNotice = !leaveType.appliedNotice;

    updatedList[index] = leaveType;
    setLeaveTypeList(updatedList);
  };

  const handleLeaveTypeNameChange = (value, index) => {
    const updatedList = [...leaveTypeList];
    const leaveType = updatedList[index];
    leaveType.leaveType = _.camelCase(value);
    updatedList[index] = leaveType;
    setLeaveTypeList(updatedList);
  };

  const handleNoticePeriodValueChange = (value, index) => {
    const updatedList = [...leaveTypeList];
    const leaveType = updatedList[index];
    leaveType.value = value;
    updatedList[index] = leaveType;
    setLeaveTypeList(updatedList);
  };

  const handleRemoveLeaveType = (index) => {
    const updatedLeaveTypeList = [...leaveTypeList];
    updatedLeaveTypeList.splice(index, 1);
    setLeaveTypeList(updatedLeaveTypeList);
  };

  const undoCamelCase = (str) => {
    const words = _.words(str, /[A-Z]?[a-z]+/g);
    return _.map(words, _.capitalize).join(" ");
  };

  const handleAddLeaveType = () => {
    const newLeaveType = {
      leaveType: "",
      value: 1,
      appliedNotice: false,
      isNewRow: true,
      key: leaveTypeList?.length + 1,
    };

    const updatedList = [...leaveTypeList, newLeaveType];
    setLeaveTypeList(updatedList);
  };

  return (
    <>
      <div className="mb-10">
        <span className="neutral-text">
          Leave Types
        </span>
        <span className="ml-10">
          <PlusButton
            isEdit={isEdit}
            isNewConfig={isNewConfig}
            onClick={handleAddLeaveType}
          />
        </span>
      </div>
      <Table
        size="small"
        dataSource={leaveTypeList}
        columns={tableColumn}
        pagination={false}
      />
    </>
  );
};

export default LeaveTypes;
