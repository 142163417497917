import React, { useEffect, useState } from "react";
import CardOptions from "./components/cardOptions/CardOptions";
import ProfileSettings from "./components/profileSettings/ProfileSettings";
import { PageTitleDiv } from "../../../../style/common/common-styled";
import TeamConfig from "./components/teamConfig/TeamConfig";

const SettingsView = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [showCardChild, setShowCardChild] = useState(false);
  const [pageTitle, setPageTitle] = useState(["settings"]);

  const handleCardClick = (index) => {
    setSelectedCard(index);
    setPageTitle(["settings", index]);
  };

  useEffect(() => {
    if (selectedCard !== null) {
      setShowCardChild(true);
    } else {
      setShowCardChild(false);
    }
  }, [selectedCard]);

  const handleTitleClick = (item) => {
    if (item === "settings") {
      setShowCardChild(false);
      setSelectedCard(null);
      setPageTitle(["settings"]);
      return;
    }
    setSelectedCard(item);
    setShowCardChild(true);
  };

  const capitalFirstChar = (inputString) => {
    if (!inputString || typeof inputString !== "string") {
      return "";
    }

    let formattedString = inputString.charAt(0).toUpperCase();

    for (let i = 1; i < inputString.length; i++) {
      const currentChar = inputString.charAt(i);

      if (currentChar === currentChar.toUpperCase()) {
        formattedString += " " + currentChar.toLowerCase();
      } else {
        formattedString += currentChar;
      }
    }

    return formattedString;
  };

  return (
    <div>
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          gap: 15,
          flexWrap: "wrap",
        }}
      >
        {pageTitle.map((item, index) => (
          <div
            key={index}
            style={{
              cursor: "pointer",
              display: "flex",
              flexDirection: "row",
              gap: 15,
            }}
            onClick={() => {
              handleTitleClick(item);
            }}
          >
            {item !== "settings" ? <h1> {">"}</h1> : null}

            <h1>{capitalFirstChar(item)}</h1>
          </div>
        ))}
      </div>
      {showCardChild ? (
        <>
          {selectedCard === "profile" && <ProfileSettings />}
          {selectedCard === "teamConfig" && <TeamConfig />}
        </>
      ) : (
        <CardOptions onCardClick={handleCardClick} />
      )}
    </div>
  );
};

export default SettingsView;
