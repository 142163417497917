import { dateCountCrossYear } from "./helper/dateCountCrossYear";
import { dateCountForNonSandwich } from "./helper/dateCountForNonSandwich";
import { dateCountForSandwich } from "./helper/dateCountForSandwich";

export const dateCount = (
  start,
  end,
  tenantConfig,
  getStandardDates,
  holidays,
  expireDate,
  setDuration,
  weekendFirst,
  weekendSecond
) => {
  const isSandwichFirstYear = tenantConfig[0]?.leavePolicy?.isSandwichLeave;
  const isSandwichSecondYear = tenantConfig[1]?.leavePolicy?.isSandwichLeave;

  const dates = getStandardDates(end, start);

  if (dates.end < expireDate[0]) {
    if (isSandwichFirstYear) {
      const dateCount = dateCountForSandwich(dates, expireDate, setDuration);
      const firstYearWorkingDays = dateCount.firstYearDays;
      const secondYearWorkingDays = dateCount.secondYearDays;
      const duration = dateCount.duration;
      return { firstYearWorkingDays, secondYearWorkingDays, duration };
    } else {
      const dateCount = dateCountForNonSandwich(
        dates,
        holidays,
        weekendFirst,
        expireDate,
        setDuration
      );
      const firstYearWorkingDays = dateCount.firstYearWorkingDays;
      const secondYearWorkingDays = dateCount.secondYearWorkingDays;
      const duration = dateCount.duration;
      return { firstYearWorkingDays, secondYearWorkingDays, duration };
    }
  } else if (expireDate[0] <= dates.start && dates.end < expireDate[1]) {
    if (isSandwichSecondYear) {
      const dateCount = dateCountForSandwich(dates, expireDate, setDuration);
      const firstYearWorkingDays = dateCount.firstYearDays;
      const secondYearWorkingDays = dateCount.secondYearDays;
      const duration = dateCount.duration;
      return { firstYearWorkingDays, secondYearWorkingDays, duration };
    } else {
      const dateCount = dateCountForNonSandwich(
        dates,
        holidays,
        weekendSecond,
        expireDate,
        setDuration
      );
      const firstYearWorkingDays = dateCount.firstYearWorkingDays;
      const secondYearWorkingDays = dateCount.secondYearWorkingDays;
      const duration = dateCount.duration;
      return { firstYearWorkingDays, secondYearWorkingDays, duration };
    }
  } else {
    const dateCount = dateCountCrossYear(
      getStandardDates,
      dates,
      holidays,
      expireDate,
      setDuration,
      isSandwichFirstYear,
      isSandwichSecondYear,
      start,
      end,
      weekendFirst,
      weekendSecond
    );
    const firstYearWorkingDays = dateCount.firstYearWorkingDays;
    const secondYearWorkingDays = dateCount.secondYearWorkingDays;
    const duration = dateCount.duration;
    return { firstYearWorkingDays, secondYearWorkingDays, duration };
  }
};
