export const dateCountForNonSandwich = (
  dates,
  holidays,
  weekend,
  expireDate,
  setDuration
) => {
  let firstYearholidayWithoutWeekend = 0;
  let secondYearholidayWithoutWeekend = 0;
  let firstYearDays = 0;
  let secondYearDays = 0;
  for (let i = 0; i < holidays.length; i++) {
    const holiday = holidays[i].date;
    if (
      holiday >= dates.start &&
      holiday <= dates.end &&
      !weekend.includes(holiday.getDay())
    ) {
      if (holiday < expireDate[0]) {
        firstYearholidayWithoutWeekend++;
      }
      if (holiday < expireDate[1] && holiday >= expireDate[0]) {
        secondYearholidayWithoutWeekend++;
      }
    }
  }

  while (dates.start <= dates.end) {
    let day = new Date(dates.start).getDay();
    if (!weekend.includes(day)) {
      if (expireDate.length === 1) {
        if (dates.start < expireDate[0]) {
          firstYearDays++;
        }
      } else {
        if (dates.start < expireDate[0]) {
          firstYearDays++;
        }
        if (dates.start < expireDate[1] && dates.start >= expireDate[0]) {
          secondYearDays++;
        }
      }
    }
    dates.start.setDate(dates.start.getDate() + 1);
  }
  const weekendExcludedDays = firstYearDays + secondYearDays;
  const totalHolidaysInRange =
    firstYearholidayWithoutWeekend + secondYearholidayWithoutWeekend;
  const duration = weekendExcludedDays - totalHolidaysInRange;
  const firstYearWorkingDays = firstYearDays - firstYearholidayWithoutWeekend;
  const secondYearWorkingDays =
    secondYearDays - secondYearholidayWithoutWeekend;
  setDuration(`${duration} ${duration > 1 ? "days" : "day"}`);
  return { firstYearWorkingDays, secondYearWorkingDays, duration };
};
