import styled from "@emotion/styled";

const Header = styled("div")`
  display: table;
  width: 100%;
  font-size: 18px;
`;

const HeaderDiv = styled("div")`
  display: table-cell;
  text-align: ${(props) => (props.align ? props.align : "")};
`;

const Required = styled("div")`
  color: red;
  font-size: 14px;
`;

export { Header, HeaderDiv, Required };
