import React from 'react';
import { useSetAtom } from 'jotai';
import { Select } from 'antd';

import { selectedTenantAtom } from '../../../../../../contexts/generalStore/store';

const SelectInput = (props) => {
    const setSelectedTenantValue = useSetAtom(selectedTenantAtom);

    const handleOnChange = (value) => {
        setSelectedTenantValue(value);
    };

    return (
        <Select
            aria-label="Default select example"
            onChange={handleOnChange}
            defaultValue={"invalid"}
            loading={props?.loading}
        >
            <Select.Option value="invalid">-- Select a Tenant --</Select.Option>
            {typeof props?.members === "object" &&
                props?.members?.map((item, index) => {
                    return (
                        <Select.Option key={index} value={item}>
                            {item}
                        </Select.Option>
                    );
                })}
        </Select>
    );
};

export default SelectInput;
