import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { useContext } from "react";
import { Select } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";

import {
  adminHistoryEndDate,
  adminHistoryStartDate,
  continueTokenAtom,
  selectedMemberAtom,
  selectedPrevSearchTypeAtom,
  selectedRoleAtom,
  selectedSearchTypeAtom,
  selectedStatusAtom,
  selectedTeamForMembersRequestsAtom,
} from "../../../../../../../../contexts/generalStore/store";
import {
  IconWrapper,
  SelectTitleWrapper,
  SelectWrapper,
  UnderLineText,
} from "../../history-styled";
import { GeneralContext } from "../../../../../../../../contexts/general/Context";

const TypeInput = () => {
  const { currentTheme } = useContext(GeneralContext);
  const setSelectedSearchType = useSetAtom(selectedSearchTypeAtom);
  const [selectedPrevSearchType, setSelectedPrevSearchType] = useAtom(
    selectedPrevSearchTypeAtom
  );
  const selectedSearchType = useAtomValue(selectedSearchTypeAtom);
  const setAdminHistoryStartDate = useSetAtom(adminHistoryStartDate);
  const setAdminHistoryEndDate = useSetAtom(adminHistoryEndDate);
  const setSelectedRole = useSetAtom(selectedRoleAtom);
  const setSelectedStatusAtom = useSetAtom(selectedStatusAtom);
  const setSelectedMemberAtom = useSetAtom(selectedMemberAtom);
  const setSelectedTeamForMembersReq = useSetAtom(
    selectedTeamForMembersRequestsAtom
  );
  const setSelectedMember = useSetAtom(selectedMemberAtom);
  const setLastEvaluatedKey = useSetAtom(continueTokenAtom);

  const setDefault = () => {
    setAdminHistoryStartDate();
    setAdminHistoryEndDate();
    setSelectedRole("invalid");
    setSelectedStatusAtom("invalid");
    setSelectedMemberAtom("invalid");
    setSelectedTeamForMembersReq("invalid");
    setSelectedMember("invalid");
    setLastEvaluatedKey("");
  };

  const handleOnChange = (value) => {
    if (value !== selectedPrevSearchType) {
      setSelectedPrevSearchType(selectedSearchType);
    }
    setSelectedSearchType(value);
    setDefault();
  };

  const handleReset = () => {
    setSelectedSearchType("invalid");
    setDefault();
  };

  return (
    <div>
      <SelectTitleWrapper>
        <UnderLineText>Select History Type</UnderLineText>
        <SelectWrapper>
          <Select
            className="customSelect"
            style={{
              width: window.screen.width < 350 ? "85%" : "100%",
            }}
            defaultValue="invalid"
            aria-label="Default select example"
            onChange={handleOnChange}
            value={selectedSearchType}
          >
            <Select.Option value="invalid">
              -- Select search type --
            </Select.Option>
            <Select.Option value="teamSpecific">Team Specific</Select.Option>
            <Select.Option value="userSpecific">User Specific</Select.Option>
          </Select>
          <IconWrapper>
            <CloseCircleFilled
              className={
                selectedSearchType && selectedSearchType !== "invalid"
                  ? "icon"
                  : "icon-disabled"
              }
              onClick={() => handleReset()}
            />
          </IconWrapper>
        </SelectWrapper>
      </SelectTitleWrapper>
    </div>
  );
};

export default TypeInput;
