import React, { useState, useEffect } from "react";
import lodash from "lodash";

import CmlIcon from "../../../common/icon/CmlIcon";
import { Button } from "antd";

const OvertimeTableAction = ({ actions, row, group }) => {
  const [isOwnTable, setIsOwnTable] = useState(true);

  useEffect(() => {
    if (actions.handleApprove && actions.handleReject) {
      setIsOwnTable(false);
    }
  }, [actions]);

  const renderButton = (action, onClick, disabled = false) => (
      <Button
          type="link"
          title={action.title}
          size="small"
          icon={<CmlIcon iconName={action.icon} />}
          onClick={onClick}
          disabled={disabled}
          key={action.name}
      />
  );

  const isEmployee = group.includes("employee");
  const status = lodash.toLower(row.status);

  const buttonActions = [
    {
      name: "handleCancel",
      title: "Delete this overtime request",
      icon: "delete",
      onClick: () => actions.handleCancel(row),
      show: isOwnTable && (status === "pending" || status === "modification" || status === "approved")
    },
    {
      name: "handleApprove",
      title: "Approve this overtime request",
      icon: "done",
      onClick: () => actions.handleApprove(row),
      show: !isOwnTable && !isEmployee && (status === "pending" || status === "modification")
    },
    {
      name: "handleReject",
      title: "Reject this overtime request",
      icon: "close",
      onClick: () => actions.handleReject(row),
      show: !isOwnTable && !isEmployee && (status === "pending" || status === "modification")
    },
  ];

  if (status === "rejected") {
    return null;
  }

  return (
      <>
        {buttonActions
            .filter(action => action.show)
            .map(action => renderButton(action, action.onClick))}
      </>
  );
};

export default OvertimeTableAction;
