import React, { useState, useContext, useEffect } from "react";
import { Checkbox } from "antd";
import lodash from "lodash";

const SandwichLeave = ({
  isEdit,
  onDisplayTenantConfig,
  setOnDisplayTenantConfig,
}) => {

  useEffect(() => {}, [onDisplayTenantConfig]);
  const handleCheckboxChChange = (e) => {
    const tenantConfig = lodash.clone(onDisplayTenantConfig);
    tenantConfig.leavePolicy.isSandwichLeave = e.target.checked;
    setOnDisplayTenantConfig(tenantConfig);
  };

  const getSandwichValue = () => {
    if (!onDisplayTenantConfig?.leavePolicy) {
      return false;
    }
    return onDisplayTenantConfig?.leavePolicy?.isSandwichLeave;
  };

  return (
    <Checkbox
      checked={getSandwichValue()}
      onChange={handleCheckboxChChange}
      disabled={!isEdit}
    ><span className="text">Count leave on holidays</span></Checkbox>
  );
};

export default SandwichLeave;
