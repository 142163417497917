import { checkBalances } from "../../leaveBalance/balanceChecker/balanceCheckerHelper/CheckerApply";
import { getStandardDates } from "../standardDateHandler/getStandardDate";
import { dateCountCrossYear } from "./helper/breakDownInCrossYear";
import { firstYearBreakDown } from "./helper/firstYear/firstYearLeaveBreakDown";
import { secondYearBreakDown } from "./helper/secondYear/secondYearLeaveBreakDown";

export const dateCountOnBehalf = (
  duration,
  type,
  start,
  end,
  userData,
  userLeaveDetails,
  expireDate,
  tenantConfig,
  firstYearWorkingDays,
  secondYearWorkingDays
) => {
  let leaveBreakDown = {
    firstConfig: {},
    secondConfig: {},
  };

  let hasBalance = checkBalances(
    end,
    start,
    userData,
    userLeaveDetails,
    expireDate,
    duration,
    type
  );
  const dates = getStandardDates(start, end);

  if (
    new Date(dates.end).toISOString() < new Date(expireDate[0]).toISOString()
  ) {
    const leaveTypes = Object.keys(
      tenantConfig[0]?.leavePolicy?.maxLeaveAndType
    );
    const remainingLeaveTypes = leaveTypes.filter(
      (leaveType) => leaveType !== type
    );
    return firstYearBreakDown(
      hasBalance,
      userData,
      type,
      firstYearWorkingDays,
      secondYearWorkingDays,
      leaveBreakDown,
      end,
      start,
      userLeaveDetails,
      expireDate,
      remainingLeaveTypes
    );
  } else if (
    new Date(expireDate[0]).toISOString() <=
      new Date(dates.start).toISOString() &&
    new Date(dates.end).toISOString() < new Date(expireDate[1]).toISOString()
  ) {
    const leaveTypes = Object.keys(
      tenantConfig[1]?.leavePolicy?.maxLeaveAndType
    );
    const remainingLeaveTypes = leaveTypes.filter(
      (leaveType) => leaveType !== type
    );
    return secondYearBreakDown(
      hasBalance,
      userData,
      type,
      firstYearWorkingDays,
      secondYearWorkingDays,
      leaveBreakDown,
      end,
      start,
      userLeaveDetails,
      expireDate,
      remainingLeaveTypes
    );
  } else {
    return dateCountCrossYear(
      dates,
      expireDate,
      hasBalance,
      userData,
      type,
      firstYearWorkingDays,
      secondYearWorkingDays,
      leaveBreakDown,
      end,
      start,
      userLeaveDetails,
      tenantConfig
    );
  }
};
