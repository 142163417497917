import React, { useState, useContext, useEffect, useRef } from "react";
import { Form, Button, Upload } from "antd";
import lodash from "lodash";

import secureLocalStorage from "react-secure-storage";
import { FILE_TYPE_SIZE } from "../../../../../constant";
import { GeneralContext } from "../../../../../contexts/general/Context";
import CmlIcon from "../icon/CmlIcon";

const FileUploader = ({
  fileName,
  fileType,
  fileDict,
  setFileDict,
  fileStatusDict,
  downloadFilesUrl,
}) => {
  const showAbleFileTypeList = ["png", "jpg", "jpeg"];
  const readableFileTypeList = ["pdf"];
  const [invalidMessage, setInvalidMessage] = useState("");
  const [isFileExist, setIsFileExist] = useState(false);
  const [filePrefix, setFilePrefix] = useState("");
  const [isFileRemoved, setIsFileRemoved] = useState(false);
  const [fileNameWithExtension, setFileNameWithExtension] = useState("");
  const { currentTheme } = useContext(GeneralContext);

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (isFileExist) {
      const authUser = JSON.parse(secureLocalStorage.getItem("authUser"));

      const payload = authUser.signInUserSession
        ? authUser?.signInUserSession?.idToken?.payload
        : authUser?.idToken?.payload;

      const prefix = lodash.startCase(payload?.name ?? payload?.group);
      setFilePrefix(prefix);
    }
  }, [isFileExist]);

  useEffect(() => {
    if (fileName && fileType) {
      setFileNameWithExtension(`${fileName}.${fileType}`);
    }
  }, [fileName, fileType]);

  const handleAlert = (message) => {
    setInvalidMessage(message);
    setTimeout(() => {
      setInvalidMessage("");
    }, 5000);
  };

  useEffect(() => {
    setIsFileExist(fileStatusDict[fileNameWithExtension] ?? false);
  }, [fileNameWithExtension, fileStatusDict, isFileExist]);

  useEffect(() => {
    if (!fileDict[fileNameWithExtension]) {
      setIsFileRemoved(false);
    }
  }, [isFileRemoved]);

  const handleFileChange = (event) => {
    const file = event.file ? event.file : event;
    if (!file) {
      setFileDict((prevState) => {
        const { [fileNameWithExtension]: deletedKey, ...rest } = prevState;
        return rest;
      });
    } else if (
      !(file.type in FILE_TYPE_SIZE) ||
      !file.type.includes(fileType)
    ) {
      handleAlert(
        `Unsupported file type. Allowed: ${lodash.upperCase(fileType)}`
      );
    } else if (file.size > FILE_TYPE_SIZE[file.type]) {
      handleAlert(
        `File size should be less than ${
          FILE_TYPE_SIZE[file.type] / (1024 * 1024)
        } MB`
      );
    } else {
      setFileDict((prevState) => ({
        ...prevState,
        [fileNameWithExtension]: file,
      }));
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = downloadFilesUrl[fileName];
    link.download = `${downloadFilesUrl[fileName]}.${fileType}`;
    link.click();
  };

  const handleRemove = () => {
    fileDict[fileNameWithExtension] = null;
    setIsFileRemoved(true);
    setFileDict(fileDict);
  };

  console.log(fileType);

  return (
    <div>
      <Form.Item
        name="file"
        valuePropName="file-list"
        rules={[
          {
            required: true,
            message: "Please select a file!",
          },
        ]}
      >
        <div className="cml_upload_control_container">
          <Upload
            accept={"." + fileType}
            maxCount={1}
            onChange={handleFileChange}
            beforeUpload={() => {
              return false;
            }}
            showUploadList={false}
            onRemove={handleRemove}
          >
            {fileDict[fileNameWithExtension] &&
              showAbleFileTypeList.includes(fileType) && (
                <>
                  <img
                    className="cml_upload_image"
                    src={URL.createObjectURL(fileDict[fileNameWithExtension])}
                    alt={fileNameWithExtension}
                  />

                  <div
                    className="cml_picture_thumbnail_button"
                    onClick={(event) => {
                      fileDict[fileNameWithExtension] = null;
                      event.stopPropagation();
                      setIsFileRemoved(true);
                      setFileDict(fileDict);
                    }}
                  >
                    <CmlIcon
                      _className={"thumbnail-icon-danger"}
                      iconName={"delete"}
                    />
                  </div>
                </>
              )}
            {fileDict[fileNameWithExtension] &&
              readableFileTypeList.includes(fileType) && (
                <div className="cml_upload_background">
                  <div style={{ textAlign: "center" }}>
                    <CmlIcon
                      iconName={
                        readableFileTypeList.includes(fileType)
                          ? "picture_as_pdf"
                          : "upload"
                      }
                      size={"40px"}
                      color={"white"}
                    />
                  </div>
                  <div className="cml_upload_document_wrapper">
                    <p>{fileNameWithExtension}</p>
                    <div
                      className="cml_pdf_action_button"
                      onClick={(event) => {
                        event.stopPropagation();
                        fileDict[fileNameWithExtension] = null;
                        setIsFileRemoved(true);
                        setFileDict(fileDict);
                      }}
                    >
                      <CmlIcon iconName={"delete"} color={"red"} />
                    </div>
                  </div>
                </div>
              )}
            {downloadFilesUrl[fileName] &&
              showAbleFileTypeList.includes(fileType) &&
              !fileDict[fileNameWithExtension] && (
                <>
                  <img
                    className="cml_upload_image"
                    src={downloadFilesUrl[fileName]}
                    alt={fileNameWithExtension}
                    style={{ width: "100%" }}
                  />
                  <div
                    className="cml_picture_thumbnail_button"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDownload();
                    }}
                  >
                    <CmlIcon iconName={"download"} />
                  </div>
                </>
              )}
            {downloadFilesUrl[fileName] &&
              readableFileTypeList.includes(fileType) &&
              !fileDict[fileNameWithExtension] && (
                <>
                  <div className="cml_upload_background">
                    <div style={{ textAlign: "center" }}>
                      <CmlIcon
                        iconName={
                          readableFileTypeList.includes(fileType)
                            ? "picture_as_pdf"
                            : "upload"
                        }
                        size={"40px"}
                        color={"white"}
                      />
                    </div>
                    <div className="cml_upload_document_wrapper">
                      <p>{fileNameWithExtension}</p>
                      <div
                        className="cml_pdf_action_button"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDownload();
                        }}
                      >
                        <CmlIcon iconName={"download"} />
                      </div>
                    </div>
                  </div>
                </>
              )}
            {!downloadFilesUrl[fileName] &&
              !fileDict[fileNameWithExtension] && (
                <div className="cml_upload_background">
                  <div className="cml_upload_content_wrapper">
                    <CmlIcon
                      size={"40px"}
                      color={"white"}
                      iconName={
                        readableFileTypeList.includes(fileType)
                          ? "picture_as_pdf"
                          : "upload"
                      }
                    />
                    <p>Upload {lodash.toUpper(fileType)}</p>
                    <p>
                      {lodash.toUpper(fileType)} must be less than{" "}
                      <span>3 MB</span> in size
                    </p>
                  </div>
                </div>
              )}
          </Upload>

          <Button
            className="cml_upload_control_button"
            onClick={() => fileInputRef.current.click()}
            type="primary"
          >
            <p>Upload</p>
          </Button>
          <input
            ref={fileInputRef}
            type="file"
            accept={"." + fileType}
            id="fileInput"
            style={{ display: "none" }}
            onChange={(e) => handleFileChange(e.target.files[0])}
          />
        </div>
      </Form.Item>

      {invalidMessage && <div style={{ color: "red" }}>{invalidMessage}</div>}
    </div>
  );
};

export default FileUploader;
