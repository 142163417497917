export const dateCountForSandwich = (dates, expireDate, setDuration) => {
  let firstYearDays = 0;
  let secondYearDays = 0;
  while (dates.start <= dates.end) {
    if (expireDate.length === 1) {
      if (dates.start < expireDate[0]) {
        firstYearDays++;
      }
    } else {
      if (dates.start < expireDate[0]) {
        firstYearDays++;
      }
      if (dates.start < expireDate[1] && dates.start >= expireDate[0]) {
        secondYearDays++;
      }
    }

    dates.start.setDate(dates.start.getDate() + 1);
  }
  const duration = firstYearDays + secondYearDays;
  setDuration(`${duration} ${duration > 1 ? "days" : "day"}`);
  return { firstYearDays, secondYearDays, duration };
};
