import React, { useState } from "react";
import { Radio } from "antd";

export const Tab = ({ onSelectedTab, tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].label);

  const handleTabClick = (event) => {
    const label = event.target.value;
    setActiveTab(label);
    onSelectedTab(label);
  };

  return (
    <Radio.Group value={activeTab} onChange={handleTabClick}>
      {tabs.map((tab, index) => (
        <Radio.Button key={index} value={tab.label}>
          <div className="cml-tab-button">
            {tab.icon}
            {tab.title}
          </div>
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};
