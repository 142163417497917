import React from "react";
import LeaveSkeleton from "./components/LeaveSkeleton";
import OvertimeSkeleton from "./components/OvertimeSkeleton";

const RequestSkeleton = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "50px",
      }}
    >
      <OvertimeSkeleton />
      <LeaveSkeleton />
      <OvertimeSkeleton />
    </div>
  );
};

export default RequestSkeleton;
