import { handleHistorySearch } from "../handleHistorySearch";
import { spawnErrorToast } from "../../../../util/toast/spawnToast";

export const handleTeamSpecificSearch = ({
  selectedTeam,
  selectedMember,
  selectedStatus,
  selectedRole,
  startDate,
  endDate,
  allDataFetchedIndicator,
  setAllDataFetchedIndicator,
  isLoading,
  setIsLoading,
  lastEvaluatedKey,
  setLastEvaluatedKey,
  searchType,
  authUser,
  adminHistory,
  setAdminHistory,
}) => {
  if (!selectedStatus || selectedStatus === "invalid") {
    spawnErrorToast("Please select a status");
  } else if (selectedStatus && (!selectedRole || selectedRole === "invalid")) {
    handleHistorySearch(
      selectedTeam,
      selectedMember,
      selectedStatus,
      selectedRole,
      startDate,
      endDate,
      allDataFetchedIndicator,
      setAllDataFetchedIndicator,
      isLoading,
      setIsLoading,
      lastEvaluatedKey,
      setLastEvaluatedKey,
      searchType,
      authUser,
      adminHistory,
      setAdminHistory
    );
  } else if (selectedRole && isNaN(startDate) && isNaN(endDate)) {
    spawnErrorToast("Please select start Date and end Date");
  } else if (selectedRole && isNaN(startDate)) {
    spawnErrorToast("Please select start Date");
  } else if (selectedRole && isNaN(endDate)) {
    spawnErrorToast("Please select end Date");
  } else {
    handleHistorySearch(
      selectedTeam,
      selectedMember,
      selectedStatus,
      selectedRole,
      startDate,
      endDate,
      allDataFetchedIndicator,
      setAllDataFetchedIndicator,
      isLoading,
      setIsLoading,
      lastEvaluatedKey,
      setLastEvaluatedKey,
      searchType,
      authUser,
      adminHistory,
      setAdminHistory
    );
  }
};
