export const tenantConfigMessage = {
  EMPTY_TENANT_CONFIG: [
    "Company Configuration yet to be set!",
    "Ask your administrator to configure.",
  ],
  ERROR_TENANT_CONFIG: [
    "Could not retrieve company configuration at this moment!",
  ],
  EMPTY_TEAM_MEMBERS: [
    "You do not have any team members assigned to your team",
    "Please ask Admin to assign members to your team",
  ],
  EMPTY_PENDING_REQUESTS: [
    "You do not have any pending leave requests at this moment",
  ],
};
