import styled from "@emotion/styled";

const TitleSearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.tabText};
  flex-direction: row;
  padding-bottom: 10px;
  text-align: center;
  font-weight: bold;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  p {
    font-size: 16px;
    margin-left: 20px;
  }
`;

const OverviewContainer = styled.div`
  margin-left: 20px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

const SubHeading = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: ${(props) => props.theme.text};
  margin: 0 0 0 0px;
`;

export { TitleSearchWrapper, OverviewContainer, SubHeading };
