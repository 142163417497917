import React, { useState, useContext, useEffect } from "react";
import lodash from "lodash";

import {
  spawnErrorToast,
  spawnSuccessToast,
} from "../../../../../../../../util/toast/spawnToast";

import { Form, Input, Tooltip, Button, Skeleton, Row, Col } from "antd";

import { Required } from "./ProfileInfo-styled";
import { GeneralContext } from "../../../../../../../../contexts/general/Context";
import FileUploader from "../../../../../common/fileUploader/FileUploader";

import { saveProfileInfo } from "../../../../../../../../controller/common/saveProfileInfo";
import { getProfileInfo } from "../../../../../../../../controller/common/getProfileInfo";
import { getMemberSettings } from "../../../../../../../../controller/common/getMemberSettings";
import { uploadFile } from "../../../../../../../../controller/common/uploadFile";
import { CustomCard, SubHeading } from "../../../../../common/Common-styled";
import CmlIcon from "../../../../../common/icon/CmlIcon";
import { getDownloadUrl } from "../../../../../../../../controller/common/getDownloadUrl";
import SaveButton from "../../../../../common/buttons/SaveButton";
import CancelButton from "../../../../../common/buttons/CancelButton";

const ProfileInfo = ({ authUser }) => {
  const { currentTheme } = useContext(GeneralContext);
  const [isEdit, setIsEdit] = useState(true);
  const [inputFields, setInputFields] = useState([]);
  const [inputs, setInputs] = useState({});
  const [previousInputs, setPreviousInputs] = useState({});
  const [fileDict, setFileDict] = useState({});
  const [fileStatusDict, setFileStatusDict] = useState({});
  const [inProgress, setInProgress] = useState(false);
  const [missingFileMessage, setMissingFileMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [downloadFilesUrl, setDownloadedFilesUrl] = useState({});

  const showAbleFileTypeList = ["png", "jpg", "jpeg"];
  const readableFileTypeList = ["pdf"];

  useEffect(() => {
    const fetchMemberSettings = async (authUser) => {
      try {
        const response = await getMemberSettings(authUser);
        const config = JSON.parse(response.data?.data);
        if (Object.keys(config).length > 0) {
          setInputFields(config);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    fetchMemberSettings(authUser);
  }, []);

  useEffect(() => {
    const data = { ...inputs };
    data.fileNameList = [];
    for (const fileName in fileDict) data.fileNameList.push(fileName);

    setInputs(data);
  }, [fileDict]);

  const isFileMissing = () => {
    const fileFields = inputFields.filter((input) =>
      ["pdf", "png", "jpeg"].includes(input.type)
    );
    const missingFiles = fileFields.filter(
      (field) =>
        !fileStatusDict[`${field.name}.${field.type}`] &&
        !fileDict[`${field.name}.${field.type}`]
    );

    if (missingFiles.length > 0) {
      const missingFileNames = missingFiles.map((field) =>
        lodash.startCase(field.name)
      );
      const message = `${missingFileNames.join(", ")} is Required`;
      setMissingFileMessage(message);
      setTimeout(() => {
        setMissingFileMessage("");
      }, 5000);
      return true;
    }

    return false;
  };

  useEffect(() => {
    const fetchProfileInfo = async (authUser) => {
      setLoading(true);
      try {
        const response = await getProfileInfo(authUser);
        const info = response.data?.data;
        setFileStatusDict(info?.fileStatusDict ?? {});
        delete info?.fileStatusDict;
        if (Object.keys(info).length > 0) {
          setInputs(info);
          setPreviousInputs(info);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    fetchProfileInfo(authUser);
  }, []);

  useEffect(() => {
    if (inputFields.length) {
      handleDownload();
    }
  }, [inputFields]);

  const handleFormChange = (event) => {
    let data = { ...inputs };
    data[event.target.name] = event.target.value;
    setInputs(data);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setInputs(lodash.cloneDeep(previousInputs));
    resetFileInputs();
  };

  const resetFileInputs = () =>
    document
      .querySelectorAll('[id^="formFile"]')
      .forEach((element) => (element.value = null));

  const handleSave = (e) => {
    e.preventDefault();
    if (!isFileMissing()) {
      callApiToSaveProfileInfo();
    }
  };

  const saveFiles = async (signedUrlDict) => {
    try {
      const uploadPromises = Object.entries(signedUrlDict).map(
        ([fileName, signedUrl]) => uploadFile(signedUrl, fileDict[fileName])
      );
      await Promise.all(uploadPromises);
    } catch (error) {
      console.log(`Failed uploading files! ${error}`);
    }
  };

  const handleDownload = () => {
    try {
      inputFields?.map(async (inputField) => {
        if (
          showAbleFileTypeList.includes(inputField?.type) ||
          readableFileTypeList.includes(inputField?.type)
        ) {
          const resp = await getDownloadUrl(
            authUser,
            `${inputField?.name}.${inputField?.type}`
          );
          setDownloadedFilesUrl((prev) => {
            const newState = { ...prev };
            newState[inputField?.name] = resp?.data?.data;
            return newState;
          });
        }
      });
    } catch (error) {
      spawnErrorToast("Failed downloading file!");
    }
  };

  const callApiToSaveProfileInfo = async () => {
    try {
      setInProgress(true);
      const response = await saveProfileInfo(authUser, inputs);
      const signedUrlDict = response?.data?.data ?? {};
      await saveFiles(signedUrlDict);

      spawnSuccessToast("Successfully Saved!");
      setPreviousInputs({ ...inputs });
    } catch (error) {
      spawnErrorToast("Failed to Save!");
    } finally {
      setInProgress(false);
    }
  };

  return (
    <div>
      <div className="mb-5">Profile Information</div>
      {loading ? (
        <Skeleton active />
      ) : (
        <div className="cml-card">
          {inputFields.length === 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Not available</p>
              <Tooltip title="profile information fields have not been set yet">
                <div className="icon-holder-button-div">
                  <CmlIcon _className={"medium-icon-style"} iconName={"info"} />
                </div>
              </Tooltip>
            </div>
          )}
          <Form layout="vertical">
            {inputFields.map((input, index) => (
              <div key={input.name}>
                <Form.Item
                  colon={false}
                  label={
                    <div
                      style={{
                        textAlign: "left",
                        color: currentTheme.text,
                        minWidth: "90px",
                      }}
                    >
                      {input.label}
                    </div>
                  }
                  style={{ flex: 1 }}
                >
                  {["text", "textarea"].includes(input.type) && (
                    <div>
                      {input.type === "textarea" ? (
                        <Input.TextArea
                          className="customInput"
                          disabled={!isEdit}
                          style={{
                            height: 120,
                            color: "black",
                          }}
                          name={input.name}
                          placeholder={input.label}
                          value={inputs[input.name] || ""}
                          onChange={(event) => handleFormChange(event)}
                        />
                      ) : (
                        <Input
                          disabled={!isEdit}
                          className="customInput"
                          style={{
                            height: 50,
                            color: currentTheme.text,
                          }}
                          name={input.name}
                          placeholder={input.label}
                          value={inputs[input.name] || ""}
                          onChange={(event) => handleFormChange(event)}
                        />
                      )}
                    </div>
                  )}

                  {["pdf", "png", "jpeg"].includes(input.type) && (
                    <FileUploader
                      isEdit={isEdit}
                      fileName={input.name}
                      fileType={input.type}
                      setFileDict={setFileDict}
                      fileDict={fileDict}
                      fileStatusDict={fileStatusDict}
                      downloadFilesUrl={downloadFilesUrl}
                    />
                  )}
                </Form.Item>
              </div>
            ))}

            {isEdit && !lodash.isEmpty(missingFileMessage) && (
              <Required>{missingFileMessage}</Required>
            )}

            <div className="left-aligned-buttons">
              <Button
                type="primary"
                onClick={handleSave}
                loading={inProgress}
                icon={<CmlIcon iconName={"hard_drive"} />}
              >
                Save
              </Button>
              <Button
                onClick={handleCancel}
                icon={<CmlIcon iconName={"cancel"} />}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default ProfileInfo;
