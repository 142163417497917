import { Alert, Button, DatePicker, Form, Input, Select } from "antd";
import React from "react";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import lodash from "lodash";
import dayjs from "dayjs";
import CmlIcon from "../../../common/icon/CmlIcon";

const FormComponent = ({
  ownApply,
  focused,
  form,
  onFinish,
  duration,
  leaveType,
  tenantConfig,
  startDate,
  endDate,
  description,
  teamConfig,
  emailList,
  userData,
  validateForm,
  inProgress,
  formValidateMessage,
  setEmails,
  handleLeaveTypeChange,
  handleStartDateChange,
  handleEndDateChange,
  handleDisableDate,
  handleCellRender,
  validateDateRange,
  handleDescription,
  showingInModal,
}) => {
  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
    >
      {!ownApply && (
        <Form.Item>
          <div className="cml-card">
            <div className="neutral-text">Employee Email:</div>
            <div>{userData?.email}</div>
          </div>
        </Form.Item>
      )}
      <Form.Item>
          <div className="neutral-text">Duration:</div>
          <div>{duration}</div>
      </Form.Item>
      <Form.Item
        label={<div className="neutral-text">Leave Type</div>}
        name="leaveType"
      >
        <Select
          value={leaveType}
          onChange={(value) => handleLeaveTypeChange(value)}
        >
          {tenantConfig[0]?.leavePolicy?.maxLeaveAndType &&
            Object.keys(tenantConfig[0]?.leavePolicy?.maxLeaveAndType).map(
              (item, i) => (
                <Select.Option key={i} value={item}>
                  {lodash.startCase(item)}
                </Select.Option>
              )
            )}
        </Select>
      </Form.Item>

      <Form.Item
        label={<div className="neutral-text">Start Date</div>}
        rules={[{ required: true, message: "Please select start date" }]}
        hasFeedback={true}
        name="startDate"
      >
        <DatePicker
          className="full-width"
          onChange={handleStartDateChange}
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode;
          }}
          value={dayjs(startDate)}
          showToday={false}
          disabledDate={(current) =>
            handleDisableDate(current.toDate(), "startDate")
          }
          cellRender={(current) => handleCellRender(current)}
          allowClear={false}
          disabled={!focused ? true : false}
        />
      </Form.Item>

      <Form.Item
        label={<div className="neutral-text">End Date</div>}
        name={"endDate"}
        rules={[
          { validator: validateDateRange },
          { required: true, message: "Please select end date" },
        ]}
        hasFeedback={true}
      >
        <DatePicker
          className="full-width"
          onChange={handleEndDateChange}
          getPopupContainer={(triggerNode) => {
            return triggerNode.parentNode;
          }}
          value={dayjs(endDate)}
          showToday={false}
          disabledDate={(current) =>
            handleDisableDate(current.toDate(), "endDate")
          }
          cellRender={(current) => handleCellRender(current)}
          allowClear={false}
          disabled={!focused ? true : false}
        />
      </Form.Item>

      <Form.Item
        label={
          <div className="neutral-text">Description</div>
        }
        name="description"
        rules={[
          { required: true, message: "Please enter a description" },
          {
            max: 200,
            message: "Description must not be larger than 200 characters",
          },
        ]}
      >
        <Input.TextArea
          value={description}
          onChange={(e) => handleDescription(e)}
          autoSize={{ minRows: 3, maxRows: 5 }}
          showCount
          maxLength={200}
        />
      </Form.Item>

      <Form.Item
        label={<div className="neutral-text">Mail List</div>}
        name="mailList"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <ReactMultiEmail
            placeholder={"Emails here"}
            emails={[
              ...(tenantConfig[0]?.emailList ?? []),
              ...(teamConfig?.emailList ?? []),
            ]}
            className="multi-email-disabled"
            getLabel={(email, index) => {
              return (
                <div data-tag key={index}>
                  <div data-tag-item>{email}</div>
                </div>
              );
            }}
          />
          {/* TODO: Is this control really necessary? Not seeing any label for this control */}
          <ReactMultiEmail
            placeholder={"john@xyz.com, doe@xyz.com"}
            emails={emailList}
            onChange={(_emails) => {
              setEmails(_emails);
            }}
            getLabel={(email, index, removeEmail) => {
              return (
                <div data-tag key={index}>
                  <div data-tag-item>{email}</div>
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />
        </div>
      </Form.Item>
      <Form.Item>
        <div className="mt-15">
          {!validateForm && (
            <Alert
              className="apply-alert"
              message="Warning"
              description={formValidateMessage}
              type="warning"
              showIcon
            />
          )}
        </div>
        <div className={showingInModal ? "right-aligned-buttons" : ""}>
          <Button
            type="primary"
            htmlType="submit"
            loading={inProgress}
            icon={<CmlIcon iconName={"send"}></CmlIcon>}
          >
            Apply
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default FormComponent;
