import React, { useEffect, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { Button, Form, Modal } from "antd";
import SelectInput from "../selectInput/SelectInput";
import SelectGroupInput from "../selectInput/SelectGroupInput";
import SelectTeamInput from "../selectInput/SelectTeamInput";
import { getTeamSuperAdminHandler } from "../../../../../../core/assignUnassignSuperAdmin/getTeamsHandler";
import { assignSuperAdminHandler } from "../../../../../../core/assignUnassignSuperAdmin/assignSuperAdminHandler";
import {
  authUserAtom,
  selectedSuperAdminAtom,
  selectedSuperAdminGroupAtom,
  selectedSuperAdminTeamAtom,
  selectedTenantAtom,
  showModalsAtom,
  superAdminsAtom,
} from "../../../../../../contexts/generalStore/store";
import { spawnErrorToast } from "../../../../../../util/toast/spawnToast";
import { getTenant } from "../../../../../../controller/superadmin/getTenant";
import CmlIcon from "../../../common/icon/CmlIcon";

const AssignModal = () => {
  const [showModal, setShowModal] = useAtom(showModalsAtom);
  const [selectedSuperAdmin, setSelectedSuperAdmin] = useAtom(
    selectedSuperAdminAtom
  );
  const [selectedTenantValue, setSelectedTenantValue] =
    useAtom(selectedTenantAtom);
  const [selectedGroupValue, setSelectedGroupValue] = useAtom(
    selectedSuperAdminGroupAtom
  );
  const [selectedSuperAdminTeam, setSelectedSuperAdminTeam] = useAtom(
    selectedSuperAdminTeamAtom
  );
  const authUser = useAtomValue(authUserAtom);

  const setSuperAdmins = useSetAtom(superAdminsAtom);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTenantFetch, setIsLoadingTenantFetch] = useState(false);
  const [tenantTeams, setTenantTeams] = useState([]);
  const [tenantDomain, setTenantDomain] = useState([]);
  const [tenants, setTenants] = useState([]);

  const teamSensitiveGroups = ["teamLead", "employee"];

  const getStateArguments = () => {
    return {
      authUser: authUser,
      selectedTenantValue: selectedTenantValue,
      setTenantTeams: setTenantTeams,
      selectedSuperAdmin: selectedSuperAdmin,
      selectedGroupValue: selectedGroupValue,
      selectedSuperAdminTeam: selectedSuperAdminTeam,
      teamSensitiveGroups: teamSensitiveGroups,
      setIsLoading: setIsLoading,
      setSuperAdmins: setSuperAdmins,
      handleModalClose: handleModalClose,
    };
  };

  const retrieveTenants = async (token = "") => {
    setIsLoadingTenantFetch(true);
    try {
      const response = await getTenant(authUser, token);
      if (response?.data?.ContinuationToken) {
        retrieveTenants(response.data.ContinuationToken);
      }
      if (token && token !== "") {
        setTenants((prev) => [...prev, ...response?.data?.Items]);
      } else {
        setTenants(response?.data?.Items);
      }
    } catch (err) {
      spawnErrorToast("Couldn't retrieve tenants");
    }
    finally {
      setIsLoadingTenantFetch(false);
    }
  };

  useEffect(() => {
    retrieveTenants();
  }, []);

  useEffect(() => {
    tenants.forEach((tenant) => {
      setTenantDomain((prev) => [...prev, tenant.domainName]);
    });
  }, [tenants]);



  useEffect(() => {
    if (
      selectedTenantValue !== "invalid" &&
      selectedTenantValue &&
      teamSensitiveGroups.includes(selectedGroupValue)
    ) {
      setTenantTeams([]);
      const args = getStateArguments();
      getTeamSuperAdminHandler(args);
    }
  }, [selectedTenantValue, selectedGroupValue]);

  const handleModalClose = () => {
    setShowModal({ ...showModal, showSuperAdminAssignModal: false });
    setSelectedSuperAdmin();
    setSelectedGroupValue("tenantAdmin");
    setSelectedTenantValue();
    setSelectedSuperAdminTeam("invalid");
    setTenantTeams([]);
    setTenants([]);
  };

  const handleSubmit = async () => {
    const args = getStateArguments();
    assignSuperAdminHandler(args);
  };

  const tenantSelectionValidator = () => {
    if (selectedTenantValue === "invalid" ||
      !selectedTenantValue) {
      return Promise.reject("Select a tenant");
    }
    return Promise.resolve();
  };

  const groupSelectionValidator = () => {
    if (!selectedGroupValue ||
      selectedGroupValue === "invalid") {
      return Promise.reject("Select a tenant's group");
    }
    return Promise.resolve();
  };

  const teamSelectionValidator = () => {
    if (selectedSuperAdminTeam === "invalid" ||
      !selectedSuperAdminTeam) {
      return Promise.reject("Select a tenant's team");
    }
    return Promise.resolve();
  };

  return (
    <>
      <Modal
        title="Assign super admin to a Tenant role"
        open={showModal.showSuperAdminAssignModal}
        onCancel={handleModalClose}
        okButtonProps={{
          style: { display: "none" },
        }}
        cancelButtonProps={{
          style: { display: "none" },
        }}
        confirmLoading={isLoading}
        mask={true}
        maskClosable={false}
      >

        <Form
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item>
            <div className="cml-card mt-20">
              <div className="neutral-text">Super Admin Email:</div>
              <div>{selectedSuperAdmin?.email}</div>
            </div>
          </Form.Item>
          <Form.Item
            label={<div className="neutral-text">Tenant</div>}
            className="mt-20"
            required
            rules={[{ validator: tenantSelectionValidator }]}
            hasFeedback={true}
            name="tenant"
          >
            <SelectInput members={tenantDomain} loading={isLoadingTenantFetch} />
          </Form.Item>
          <Form.Item
            label={<div className="neutral-text">Group</div>}
            required
            rules={[{ validator: groupSelectionValidator }]}
            hasFeedback={true}
            name="group"
          >
            <SelectGroupInput />
          </Form.Item>
          {teamSensitiveGroups.includes(selectedGroupValue) &&
            selectedTenantValue !== "invalid" && (
              <Form.Item
                label={<div className="neutral-text">Team:</div>}
                required
                rules={[{ validator: teamSelectionValidator }]}
                hasFeedback={true}
                name="teamName"
              >
                <SelectTeamInput teams={tenantTeams} />
              </Form.Item>
            )}

          <Form.Item>
            <div className="right-aligned-buttons">
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                className="mt-20"
                icon={<CmlIcon iconName="redo" />}
              >
                Assign
              </Button>
            </div>
          </Form.Item>
        </Form>

      </Modal>
    </>

  );
};

export default AssignModal;
