import { checkBalances } from "../../../leaveBalance/balanceChecker/balanceCheckerHelper/CheckerApply";

export const breakDownSingleYear = (
  userData,
  hasBalance,
  leaveBreakDown,
  end,
  start,
  userLeaveDetails,
  expireDate,
  remainingLeaveTypes,
  durationToCheck,
  config,
  workingDays
) => {
  for (const leaveType of remainingLeaveTypes) {
    hasBalance = checkBalances(
      end,
      start,
      userData,
      userLeaveDetails,
      expireDate,
      durationToCheck,
      leaveType
    );
    if (hasBalance) {
      leaveBreakDown[config][leaveType] = durationToCheck[workingDays];
      durationToCheck = 0;
    } else {
      leaveBreakDown[config][leaveType] =
        userData?.leaveBalance?.[config][leaveType];
      durationToCheck[workingDays] =
        durationToCheck[workingDays] -
        userData?.leaveBalance?.[config][leaveType];
    }
  }
  if (durationToCheck[workingDays] > 0) {
    leaveBreakDown[config]["extraDay"] = durationToCheck[workingDays];
  }
  return leaveBreakDown;
};
