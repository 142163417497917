import React, { useContext, useEffect } from "react";
import { Button, message, Table, InputNumber } from "antd";
import PlusButton from "../../../../../../../common/buttons/PlusButton";
import CmlIcon from "../../../../../../../common/icon/CmlIcon";

const NoticePeriod = ({
  isEdit,
  isNewConfig,
  noticePeriodList,
  setNoticePeriodList,
}) => {

  useEffect(() => {}, [noticePeriodList]);

  const isNewRow = (row) => {
    return row?.isNewRow === true;
  };

  const handleDelete = (row, index) => {
    const updatedNoticePeriodList = [...noticePeriodList];
    updatedNoticePeriodList.splice(index, 1);
    setNoticePeriodList(updatedNoticePeriodList);
  };

  const actionCell = (cell, row, rowIndex, colIndex) => {
    return (
      <Button
        type="link"
        size="small"
        title="Delete this notice period"
        icon={<CmlIcon iconName="delete" />}
        onClick={() => { handleDelete(row, rowIndex); }}
      />
    );
  };

  const getLeaveDaysCol = (cell, row, rowIndex, colIndex) => {
    if (!isNewRow(row)) {
      return row?.day;
    }
    return (
      <InputNumber
        disabled={!isEdit && !isNewConfig}
        value={row?.day}
        bordered={false}
        min={1}
        onChange={(newValue) => {
          handleNoticePeriodDaysChange(newValue, rowIndex);
        }}
      />
    );
  };

  const getNoticeValueCol = (cell, row, rowIndex, colIndex) => {
    if (!row?.isNewRow) {
      return <div style={{ fontSize: 16 }}>{row?.notice}</div>;
    }
    return (
      <InputNumber
        disabled={!isEdit && !isNewConfig}
        value={row?.notice}
        bordered={false}
        min={1}
        onChange={(newValue) => {
          handleNoticePeriodValueChange(newValue, rowIndex);
        }}
      />
    );
  };

  const tableColumn = [
    {
      title: "Leave Days",
      dataIndex: "day",
      key: "day",
      render: getLeaveDaysCol,
    },
    {
      title: "Notice Period",
      dataIndex: "notice",
      key: "notice",
      render: getNoticeValueCol,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      render: actionCell,
    },
  ];

  const handleNoticePeriodDaysChange = (newValue, index) => {
    if (noticePeriodList.some((item) => item.day === newValue)) {
      message.warning(`Notice period for ${newValue} days is already added`);
      return;
    }

    const updatedNoticePeriodList = [...noticePeriodList];
    updatedNoticePeriodList[index].day = newValue;
    setNoticePeriodList(updatedNoticePeriodList);
  };

  const handleNoticePeriodValueChange = (newValue, index) => {
    const updatedNoticePeriodList = [...noticePeriodList];
    updatedNoticePeriodList[index].notice = newValue;
    setNoticePeriodList(updatedNoticePeriodList);
  };

  function findHighestKey(map) {
    let highestKey = null;
    for (let key in map) {
      if (
        highestKey === null ||
        parseInt(map[key]?.day) > parseInt(highestKey)
      ) {
        highestKey = map[key]?.day;
      }
    }
    return highestKey;
  }

  const addNoticePeriod = () => {
    const day = parseInt(findHighestKey(noticePeriodList)) + 1;
    const newNoticePeriod = {
      day: day,
      notice: 1,
      isNewRow: true,
      index: day,
      key: day,
    };
    const updatedNoticePeriodList = [...noticePeriodList, newNoticePeriod];
    setNoticePeriodList(updatedNoticePeriodList);
  };

  return (
    <>
      <div className="mb-10">
        <span className="neutral-text">Notice Periods</span>
        <span className="ml-10">
          <PlusButton
            isEdit={isEdit}
            isNewConfig={isNewConfig}
            onClick={addNoticePeriod}
          />
        </span>
      </div>

      <Table
        size="small"
        dataSource={noticePeriodList}
        columns={tableColumn}
        pagination={false}
      />
    </>
  );
};

export default NoticePeriod;
