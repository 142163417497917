import lodash from "lodash";

import { firstYearBreakDown } from "./firstYear/firstYearLeaveBreakDown";
import { secondYearBreakDown } from "./secondYear/secondYearLeaveBreakDown";

export const dateCountCrossYear = (
  dates,
  expireDate,
  hasBalance,
  userData,
  type,
  firstYearWorkingDays,
  secondYearWorkingDays,
  leaveBreakDown,
  end,
  start,
  userLeaveDetails,
  tenantConfig
) => {
  let firstYearLeaveBreakDown;
  let secondYearLeaveBreakDown;
  const startDate = lodash.clone(new Date(dates.start));
  const endDate = lodash.clone(new Date(dates.end));
  while (startDate <= endDate) {
    if (
      new Date(startDate).toISOString() < new Date(expireDate[0]).toISOString()
    ) {
      const leaveTypes = Object.keys(
        tenantConfig[0]?.leavePolicy?.maxLeaveAndType
      );
      const remainingLeaveTypes = leaveTypes.filter(
        (leaveType) => leaveType !== type
      );
      firstYearLeaveBreakDown = firstYearBreakDown(
        hasBalance,
        userData,
        type,
        firstYearWorkingDays,
        secondYearWorkingDays,
        leaveBreakDown,
        end,
        start,
        userLeaveDetails,
        expireDate,
        remainingLeaveTypes
      );
    }
    if (
      new Date(startDate).toISOString() <
        new Date(expireDate[1]).toISOString() &&
      new Date(startDate).toISOString() >= new Date(expireDate[0]).toISOString()
    ) {
      const leaveTypes = Object.keys(
        tenantConfig[1]?.leavePolicy?.maxLeaveAndType
      );
      const remainingLeaveTypes = leaveTypes.filter(
        (leaveType) => leaveType !== type
      );
      secondYearLeaveBreakDown = secondYearBreakDown(
        hasBalance,
        userData,
        type,
        firstYearWorkingDays,
        secondYearWorkingDays,
        leaveBreakDown,
        end,
        start,
        userLeaveDetails,
        expireDate,
        remainingLeaveTypes
      );
    }

    startDate.setDate(startDate.getDate() + 1);
  }

  leaveBreakDown = {
    firstConfig: firstYearLeaveBreakDown.firstConfig,
    secondConfig: secondYearLeaveBreakDown.secondConfig,
  };
  return leaveBreakDown;
};
