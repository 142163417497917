import React, { useContext, useEffect, useState } from "react";
import lodash from "lodash";
import { Layout, Menu, Skeleton } from "antd";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import ApplyLeave from "../components/apply/Apply";
import CalendarView from "../components/calendarView/CalendarView";
import OwnLeaveRequest from "../components/requests/OwnRequest";
import {
  adminHistoryAtom,
  adminHistoryEndDate,
  adminHistoryStartDate,
  apiCallAtom,
  apiCallDataFetchedAtom,
  authUserAtom,
  groupAtom,
  multiGroupSelectedAtom,
  overTimeListAtom,
  selectedMemberAtom,
  selectedRoleAtom,
  selectedSearchTypeAtom,
  selectedStatusAtom,
  selectedTeamAtom,
  selectedTeamForMembersRequestsAtom,
  selectedTeamForOvertimeAtom,
  selectedTeamForRequestsAtom,
  superAdminsAtom,
  teamBalanceListAtom,
  teamConfigAtom,
  teamListAtom,
  teamMembersAtom,
  tenantConfigAtom,
  tenantsAtom,
  unassignedListAtom,
  userLeaveDetailsAtom,
} from "../../../contexts/generalStore/store";
import NavDropdown from "../../dropdown/whiteLabelingDropDown/NavDropdown";
import appLogo from "../../../img/appIcon.svg";
import AdministrationView from "../components/administration/AdministrationView";
import { GeneralContext } from "../../../contexts/general/Context";
import LeaveRequestApproval from "../components/approval/LeaveRequestApproval";
import { apiCalls } from "../../../util/apiCalls/apiCalls";
import { PERMISSIONS } from "../../../util/constants/permission";
import { spawnErrorToast } from "../../../util/toast/spawnToast";
import Tenants from "../components/tenants/tenantsList/Tenants";
import SuperAdminList from "../components/superAdminList/SuperAdminList";
import Help from "../components/help/Help";
import MessageContainer from "../components/messageContainer/MessageContainer";
import { tenantConfigMessage } from "../../../util/constants/tenantConfigMessage";
import ConfigSetting from "../components/administration/components/config/ConfigSettings";
import TeamMembers from "../components/teamMembers/TeamMembers";
import ApplyLeaveSkeleton from "../components/skeleton/applyLeave/ApplyLeaveSkeleton";
import ApprovalSkeleton from "../components/skeleton/approval/ApprovalSkeleton";
import EntitlementSkeleton from "../components/skeleton/entitlement/EntitlementSkeleton";
import RequestSkeleton from "../components/skeleton/requests/RequestSkeleton";
import AdministrationSkeleton from "../components/skeleton/administration/AdministrationSkeleton";
import SettingsView from "../components/settings/Settings";
import CmlIcon from "../components/common/icon/CmlIcon";
import EntitlementView from "../components/entitlement/Entitlement";

const { Header, Sider, Content } = Layout;

const LeaveCalender = ({ inProgress, tenantConfig, parsedSelectedGroup }) => {
  return (
    <>
      <CalendarView
        inProgress={inProgress}
        tenantConfig={tenantConfig}
        parsedSelectedGroup={parsedSelectedGroup}
      />
    </>
  );
};
const Apply = ({
  status = true,
  ownApply = true,
  userData = null,
  inProgress,
  hasRefreshToken,
  tenantConfig,
  parsedSelectedGroup,
}) => {
  return (
    <>
      <h1>Apply</h1>
      <div className="page-container">
        {!inProgress && tenantConfig?.length ? (
          <>
            <ApplyLeave
              status={status}
              ownApply={ownApply}
              userData={userData}
              hasRefreshToken={hasRefreshToken}
            />
          </>
        ) : !inProgress &&
          !tenantConfig?.length &&
          !PERMISSIONS.view.menu.noTenantConfig_NoRefreshTokenAllowedGroups.some(
            (value) => parsedSelectedGroup?.includes(value)
          ) ? (
          <>
            <MessageContainer
              messages={tenantConfigMessage.EMPTY_TENANT_CONFIG}
            />
          </>
        ) : (
          <>
            <ApplyLeaveSkeleton />
          </>
        )}
      </div>
    </>
  );
};
const Request = ({ inProgress, tenantConfig, parsedSelectedGroup }) => {
  return (
    <>
      <h1>My Request</h1>
      <div className="page-container">
        {!inProgress && tenantConfig?.length ? (
          <OwnLeaveRequest />
        ) : !inProgress &&
          !tenantConfig?.length &&
          !PERMISSIONS.view.menu.noTenantConfig_NoRefreshTokenAllowedGroups.some(
            (value) => parsedSelectedGroup?.includes(value)
          ) ? (
          <>
            <MessageContainer
              messages={tenantConfigMessage.EMPTY_TENANT_CONFIG}
            />
          </>
        ) : (
          <RequestSkeleton />
        )}
      </div>
    </>
  );
};
const Members = ({ inProgress, tenantConfig, parsedSelectedGroup }) => {
  return (
    <>
      <h1>Team Members</h1>
      <div className="page-container">
        {!inProgress && tenantConfig?.length ? (
          <TeamMembers />
        ) : !inProgress &&
          !tenantConfig?.length &&
          !PERMISSIONS.view.menu.noTenantConfig_NoRefreshTokenAllowedGroups.some(
            (value) => parsedSelectedGroup?.includes(value)
          ) ? (
          <>
            <MessageContainer
              messages={tenantConfigMessage.EMPTY_TENANT_CONFIG}
            />
          </>
        ) : (
          <Skeleton loading={inProgress} active avatar></Skeleton>
        )}
      </div>
    </>
  );
};
const Approval = ({ inProgress, tenantConfig, parsedSelectedGroup }) => {
  return (
    <>
      {!inProgress && tenantConfig?.length ? (
        <LeaveRequestApproval />
      ) : !inProgress &&
        !tenantConfig?.length &&
        !PERMISSIONS.view.menu.noTenantConfig_NoRefreshTokenAllowedGroups.some(
          (value) => parsedSelectedGroup?.includes(value)
        ) ? (
        <>
          <MessageContainer
            messages={tenantConfigMessage.EMPTY_TENANT_CONFIG}
          />
        </>
      ) : (
        <>
          <h1>Approval</h1>
          <ApprovalSkeleton />
        </>
      )}
    </>
  );
};
const EntitleMent = ({ inProgress, tenantConfig, parsedSelectedGroup }) => {
  return (
    <>
      <h1>Entitlement</h1>
      <div className="page-container">
        {!inProgress && tenantConfig?.length ? (
          <EntitlementView />
        ) : !inProgress &&
          !tenantConfig?.length &&
          !PERMISSIONS.view.menu.noTenantConfig_NoRefreshTokenAllowedGroups.some(
            (value) => parsedSelectedGroup?.includes(value)
          ) ? (
          <>
            <MessageContainer
              messages={tenantConfigMessage.EMPTY_TENANT_CONFIG}
            />
          </>
        ) : (
          <EntitlementSkeleton />
        )}
      </div>
    </>
  );
};
const Administration = ({ inProgress, tenantConfig, parsedSelectedGroup }) => {
  return (
    <>
      {!inProgress && tenantConfig?.length ? (
        <AdministrationView />
      ) : !inProgress &&
        !tenantConfig?.length &&
        PERMISSIONS.view.card.policyAllowedGroups.some((value) =>
          parsedSelectedGroup?.includes(value)
        ) ? (
        <>
          <ConfigSetting />
        </>
      ) : !inProgress &&
        !tenantConfig?.length &&
        PERMISSIONS.view.menu.applyItemAllowedGroups.some((value) =>
          parsedSelectedGroup?.includes(value)
        ) ? (
        <>
          <MessageContainer
            messages={tenantConfigMessage.EMPTY_TENANT_CONFIG}
          />
        </>
      ) : (
        <>
          <h1>Administration</h1>
          <AdministrationSkeleton />
        </>
      )}
    </>
  );
};
const Settings = ({ inProgress, tenantConfig, parsedSelectedGroup }) => {
  return (
    <>
      {!inProgress && tenantConfig?.length ? (
        <SettingsView />
      ) : !inProgress &&
        !tenantConfig?.length &&
        PERMISSIONS.view.menu.applyItemAllowedGroups.some((value) =>
          parsedSelectedGroup?.includes(value)
        ) ? (
        <>
          <MessageContainer
            messages={tenantConfigMessage.EMPTY_TENANT_CONFIG}
          />
        </>
      ) : (
        <>
          <h1>Settings</h1>
          <AdministrationSkeleton />
        </>
      )}
    </>
  );
};
const Reports = () => <>reports</>;
const TenantsList = () => {
  return (
    <>
      <h1>Registered Tenants</h1>
      <div className="mt-30">
        <Tenants />
      </div>
    </>
  );
};
const SuperAdmins = () => {
  return (
    <>
      <h1>System SuperAdmins</h1>
      <div className="mt-30">
        <SuperAdminList />
      </div>
    </>
  );
};
const HelpUser = ({ inProgress }) => {
  return (
    <>
      <h1>Help</h1>
      {!inProgress ? <Help /> : <Skeleton active />}
    </>
  );
};
const WaitingSkeleton = () => <Skeleton active />;

const renderContent = (
  selectedKey,
  inProgress,
  hasRefreshToken,
  tenantConfig,
  parsedSelectedGroup
) => {
  switch (selectedKey) {
    case "leaveCalendar":
      return (
        <LeaveCalender
          inProgress={inProgress}
          tenantConfig={tenantConfig}
          parsedSelectedGroup={parsedSelectedGroup}
        />
      );
    case "apply":
      return (
        <Apply
          status={true}
          ownApply={true}
          userData={null}
          inProgress={inProgress}
          hasRefreshToken={hasRefreshToken}
          tenantConfig={tenantConfig}
        />
      );
    case "request":
      return (
        <Request
          inProgress={inProgress}
          tenantConfig={tenantConfig}
          parsedSelectedGroup={parsedSelectedGroup}
        />
      );
    case "approval":
      return (
        <Approval
          inProgress={inProgress}
          tenantConfig={tenantConfig}
          parsedSelectedGroup={parsedSelectedGroup}
        />
      );
    case "members":
      return (
        <Members
          inProgress={inProgress}
          tenantConfig={tenantConfig}
          parsedSelectedGroup={parsedSelectedGroup}
        />
      );
    case "entitlement":
      return (
        <EntitleMent
          inProgress={inProgress}
          tenantConfig={tenantConfig}
          parsedSelectedGroup={parsedSelectedGroup}
        />
      );
    case "administration":
      return (
        <Administration
          inProgress={inProgress}
          tenantConfig={tenantConfig}
          parsedSelectedGroup={parsedSelectedGroup}
        />
      );
    case "settings":
      return (
        <Settings
          inProgress={inProgress}
          tenantConfig={tenantConfig}
          parsedSelectedGroup={parsedSelectedGroup}
        />
      );
    case "reports":
      return <Reports inProgress={inProgress} />;
    case "tenantsList":
      return <TenantsList inProgress={inProgress} />;
    case "superAdmins":
      return <SuperAdmins inProgress={inProgress} />;
    case "help":
      return <HelpUser inProgress={inProgress} />;
    default:
      return <WaitingSkeleton />;
  }
};

const DesktopViewDashboard = () => {
  const { currentTheme } = useContext(GeneralContext);
  const tabInfo = JSON.parse(localStorage.getItem("selectedTabInfo"));
  const [selectedKey, setSelectedKey] = useState();
  const [hasRefreshToken, setHasRefreshToken] = useState(false);
  const [inProgress, setInProgress] = useState(true);

  const setTeamList = useSetAtom(teamListAtom);
  const setUserLeaveDetails = useSetAtom(userLeaveDetailsAtom);
  const setGroup = useSetAtom(groupAtom);
  const setSelectedTeamForRequest = useSetAtom(selectedTeamForRequestsAtom);
  const setSelectedTeam = useSetAtom(selectedTeamAtom);
  const setUnassignedList = useSetAtom(unassignedListAtom);
  const setSelectedTeamForHistory = useSetAtom(
    selectedTeamForMembersRequestsAtom
  );
  const setSelectedMember = useSetAtom(selectedMemberAtom);
  const setSelectedStatus = useSetAtom(selectedStatusAtom);
  const setSelectedRole = useSetAtom(selectedRoleAtom);
  const setSearchType = useSetAtom(selectedSearchTypeAtom);
  const setStartDateForHistory = useSetAtom(adminHistoryStartDate);
  const setEndDateForHistory = useSetAtom(adminHistoryEndDate);
  const setAdminHistory = useSetAtom(adminHistoryAtom);
  const setTeamBalanceList = useSetAtom(teamBalanceListAtom);
  const setOverTimeList = useSetAtom(overTimeListAtom);
  const setSelectedTeamForOvertime = useSetAtom(selectedTeamForOvertimeAtom);
  const setTeamMembers = useSetAtom(teamMembersAtom);
  const setTenants = useSetAtom(tenantsAtom);
  const setSuperAdmins = useSetAtom(superAdminsAtom);
  const setTeamConfig = useSetAtom(teamConfigAtom);

  const [allDataFetchedIndicator, setAllDataFetchedIndicator] = useAtom(
    apiCallDataFetchedAtom
  );
  const [isLoading, setIsLoading] = useAtom(apiCallAtom);
  const [tenantConfig, setTenantConfig] = useAtom(tenantConfigAtom);

  const authUser = useAtomValue(authUserAtom);
  const multiGroupSelected = useAtomValue(multiGroupSelectedAtom);

  const stringifyGroup = authUser?.signInUserSession
    ? authUser?.signInUserSession?.idToken?.payload?.group
    : authUser?.idToken?.payload?.group;

  let stringifyGroupArray = [];
  let parsedSelectedGroup = [];

  try {
    parsedSelectedGroup = JSON.parse(localStorage.getItem("selectedGroup"));
    stringifyGroupArray = JSON.parse(stringifyGroup);
    if (stringifyGroup) {
      setGroup((prevGroup) =>
        lodash.camelCase(parsedSelectedGroup ?? prevGroup)
      );
    }
  } catch (error) {
    console.error(error);
  }

  const bootstrap = async () => {
    setInProgress(true);
    try {
      const { tenantConfig, teamConfig, userDetails, teams } = await apiCalls(
        parsedSelectedGroup,
        authUser
      );
      setTenantConfig(tenantConfig?.data?.Items);
      setTeamConfig(teamConfig?.data?.Items[0]);
      setHasRefreshToken(userDetails?.data?.Items[0]?.hasRefreshToken);
      setUserLeaveDetails(userDetails?.data?.Items[0]?.leaveBalance);
      setTeamList(
        teams?.data?.Items[0]?.teamList ? teams?.data?.Items[0]?.teamList : []
      );
    } catch (error) {
      spawnErrorToast("Error while fetching data");
    } finally {
      setInProgress(false);
    }
  };

  const handleBootStrapSelectedKeys = () => {
    if (
      PERMISSIONS.view.menu.unassignedUserHome.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("help"));
      setSelectedKey("help");
    } else if (
      PERMISSIONS.view.menu.calenderItemAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("leaveCalendar"));
      setSelectedKey("leaveCalendar");
    } else if (
      PERMISSIONS.view.menu.applyItemAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("apply"));
      setSelectedKey("apply");
    } else if (
      PERMISSIONS.view.menu.ownLeaveRequestAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("request"));
      setSelectedKey("request");
    } else if (
      PERMISSIONS.view.menu.approveLeaveRequestAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("approval"));
      setSelectedKey("approval");
    } else if (
      PERMISSIONS.view.menu.approveLeaveRequestAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("members"));
      setSelectedKey("members");
    } else if (
      PERMISSIONS.view.menu.entitlementsAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("entitlement"));
      setSelectedKey("entitlement");
    } else if (
      PERMISSIONS.view.menu.administrationAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("administration"));
      setSelectedKey("administration");
    } else if (
      PERMISSIONS.view.menu.settingsTabAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("settings"));
      setSelectedKey("settings");
    } else if (
      PERMISSIONS.view.menu.reportAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("reports"));
      setSelectedKey("reports");
    } else if (
      PERMISSIONS.view.menu.tenantsListAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("tenantsList"));
      setSelectedKey("tenantsList");
    } else if (
      PERMISSIONS.view.menu.superAdminsListAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      localStorage.setItem("selectedTabInfo", JSON.stringify("superAdmins"));
      setSelectedKey("superAdmins");
    }
  };

  useEffect(() => {
    if (stringifyGroupArray.length === 1 && parsedSelectedGroup) {
      bootstrap();
      if (!tabInfo) {
        handleBootStrapSelectedKeys();
      } else {
        setSelectedKey(tabInfo);
      }
    }
  }, []);

  useEffect(() => {
    if (JSON.parse(stringifyGroup).length > 1 && parsedSelectedGroup) {
      bootstrap();
      handleBootStrapSelectedKeys();
    }
  }, [multiGroupSelected]);

  const getMenuItems = () => {
    const menuItems = [];

    if (
      PERMISSIONS.view.menu.unassignedUserHome.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "help",
        icon: <CmlIcon _className={"menu-icon"} iconName={"help"} />,
        label: <div style={{ fontSize: "16px" }}>Help</div>,
      });
    }
    if (
      PERMISSIONS.view.menu.tenantsListAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "tenantsList",
        icon: <CmlIcon _className={"menu-icon"} iconName={"apartment"} />,
        label: <div style={{ fontSize: "16px" }}>Tenants List</div>,
      });
    }
    if (
      PERMISSIONS.view.menu.superAdminsListAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "superAdmins",
        icon: <CmlIcon _className={"menu-icon"} iconName={"groups"} />,
        label: <div style={{ fontSize: "16px" }}>Super admins</div>,
      });
    }
    if (
      PERMISSIONS.view.menu.calenderItemAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "leaveCalendar",
        icon: <CmlIcon _className={"menu-icon"} iconName={"calendar_month"} />,
        label: "Leave Calendar",
      });
    }
    if (
      PERMISSIONS.view.menu.applyItemAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "apply",
        icon: <CmlIcon _className={"menu-icon"} iconName={"edit_square"} />,
        label: "Apply",
      });
    }
    if (
      PERMISSIONS.view.menu.ownLeaveRequestAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "request",
        icon: <CmlIcon _className={"menu-icon"} iconName={"text_snippet"} />,
        label: "My Request",
      });
    }
    if (
      PERMISSIONS.view.menu.approveLeaveRequestAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "approval",
        icon: <CmlIcon _className={"menu-icon"} iconName={"approval"} />,
        label: "Approval",
      });
    }
    if (
      PERMISSIONS.view.menu.teamMembersTabAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "members",
        icon: <CmlIcon _className={"menu-icon"} iconName={"groups"} />,
        label: "Team Members",
      });
    }
    if (
      PERMISSIONS.view.menu.entitlementsAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "entitlement",
        icon: (
          <CmlIcon _className={"menu-icon"} iconName={"bar_chart_4_bars"} />
        ),
        label: "Entitlement",
      });
    }
    if (
      PERMISSIONS.view.menu.administrationAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      ) ||
      PERMISSIONS.view.menu.settingsTabAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        type: "divider",
      });
    }
    if (
      PERMISSIONS.view.menu.settingsTabAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "settings",
        icon: <CmlIcon _className={"menu-icon"} iconName={"settings"} />,
        label: "Settings",
      });
    }
    if (
      PERMISSIONS.view.menu.administrationAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "administration",
        icon: <CmlIcon _className={"menu-icon"} iconName={"settings"} />,
        label: "Administration",
      });
    }
    if (
      PERMISSIONS.view.menu.reportAllowedGroups.some((value) =>
        parsedSelectedGroup?.includes(value)
      )
    ) {
      menuItems.push({
        key: "reports",
        icon: <CmlIcon _className={"menu-icon"} iconName={"monitoring"} />,
        label: "Reports",
      });
    }
    return menuItems;
  };

  const handleSelectedKey = (key) => {
    localStorage.setItem("selectedTabInfo", JSON.stringify(key));
    setSelectedKey(key);
    setSelectedTeamForRequest("invalid");
    setSelectedTeam("invalid");
    setUnassignedList([]);
    setSelectedTeamForHistory("invalid");
    setSelectedMember("invalid");
    setSelectedStatus("invalid");
    setSelectedRole("invalid");
    setSearchType("invalid");
    setSelectedTeamForOvertime("invalid");
    setStartDateForHistory(null);
    setEndDateForHistory(null);
    setAdminHistory([]);
    setTeamBalanceList([]);
    setOverTimeList([]);
    setTeamMembers([]);
    setTenants([]);
    setSuperAdmins([]);
    setAllDataFetchedIndicator({
      ...allDataFetchedIndicator,
      isAllDataFetched: true,
    });
    setIsLoading({ ...isLoading, inProgress: false });
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Header>
        <div className="header-wrapper">
          <img src={appLogo} alt="Cempal Logo" referrerPolicy="no-referrer" />

          <NavDropdown
            role={parsedSelectedGroup}
            user={
              authUser?.attributes && authUser?.attributes?.name
                ? authUser?.attributes?.name
                : authUser?.attributes?.email
                ? authUser?.attributes?.email
                : authUser?.idToken?.payload?.name
                ? authUser?.idToken?.payload?.name
                : authUser?.idToken?.payload?.email
            }
            teamName={
              authUser?.signInUserSession
                ? authUser?.signInUserSession?.idToken?.payload?.teamName
                : authUser?.idToken?.payload?.teamName
            }
            tenantDomain={
              authUser?.signInUserSession
                ? authUser?.signInUserSession?.idToken?.payload?.tenantDomain
                : authUser?.idToken?.payload?.tenantDomain
            }
            imageSource={
              authUser?.attributes
                ? authUser?.attributes?.picture
                : authUser?.idToken?.payload?.picture
            }
          />
        </div>
      </Header>
      <Layout>
        <Sider width={210}>
          <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            onSelect={({ key }) => handleSelectedKey(key)}
            items={getMenuItems()}
          />
        </Sider>
        <Content className="content-container">
          {renderContent(
            selectedKey,
            inProgress,
            hasRefreshToken,
            tenantConfig,
            parsedSelectedGroup
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default DesktopViewDashboard;
