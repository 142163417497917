import React from "react";

import ProfileInfo from "../../../administration/components/config/component/profileInfo/ProfileInfo";
import { useAtomValue } from "jotai";
import { authUserAtom } from "../../../../../../contexts/generalStore/store";
import { Col, Row } from "antd";

const ProfileSettings = () => {
  const authUser = useAtomValue(authUserAtom);
  return (
    <div className="page-container">
      <Row>
        <Col sm={24} md={10}>
          <ProfileInfo authUser={authUser} />
        </Col>
      </Row>
    </div>
  );
};

export default ProfileSettings;
