import lodash from "lodash";

import { assignSuperAdmin } from "../../controller/superadmin/assignSuperAdmin";
import {
  spawnErrorToast,
  spawnSuccessToast,
} from "../../util/toast/spawnToast";

const handleSuperAdminListChange = (
  selectedSuperAdmin,
  selectedGroupValue,
  selectedTenantValue,
  selectedSuperAdminTeam,
  teamSensitiveGroups,
  setSuperAdmins
) => {
  let group = lodash.clone(selectedSuperAdmin.group);
  group.push(selectedGroupValue);
  setSuperAdmins((prev) => {
    const newSuperAdmins = prev.map((superAdmin) => {
      if (superAdmin.email === selectedSuperAdmin.email) {
        return {
          ...superAdmin,
          group: group,
          tenant: selectedTenantValue,
          team: teamSensitiveGroups.includes(selectedGroupValue)
            ? selectedSuperAdminTeam
            : null,
        };
      }
      return superAdmin;
    });
    return newSuperAdmins;
  });
};

const generatePayload = (
  selectedSuperAdmin,
  selectedGroupValue,
  selectedTenantValue,
  selectedSuperAdminTeam,
  teamSensitiveGroups
) => {
  if (teamSensitiveGroups.includes(selectedGroupValue)) {
    return {
      superAdminEmail: selectedSuperAdmin.email,
      group: selectedGroupValue,
      tenant: selectedTenantValue,
      team: selectedSuperAdminTeam,
    };
  }
  return {
    superAdminEmail: selectedSuperAdmin.email,
    group: selectedGroupValue,
    tenant: selectedTenantValue,
  };
};

export const assignSuperAdminHandler = async ({
  authUser,
  selectedSuperAdmin,
  selectedGroupValue,
  selectedTenantValue,
  selectedSuperAdminTeam,
  teamSensitiveGroups,
  setIsLoading,
  setSuperAdmins,
  handleModalClose,
}) => {
  setIsLoading(true);
  const payload = generatePayload(
    selectedSuperAdmin,
    selectedGroupValue,
    selectedTenantValue,
    selectedSuperAdminTeam,
    teamSensitiveGroups
  );
  try {
    await assignSuperAdmin(payload, authUser);
    spawnSuccessToast(
      `${selectedSuperAdmin.email} assigned as ${selectedGroupValue} at tenant ${selectedTenantValue} Successfully`
    );
    handleSuperAdminListChange(
      selectedSuperAdmin,
      selectedGroupValue,
      selectedTenantValue,
      selectedSuperAdminTeam,
      teamSensitiveGroups,
      setSuperAdmins
    );
  } catch (error) {
    spawnErrorToast("Couldn't assign at this moment");
  } finally {
    setIsLoading(false);
    handleModalClose();
  }
};
