import React, { useEffect, useState } from "react";
import lodash from "lodash";
import { useAtomValue } from "jotai";

import BalanceDrawer from "./BalanceDrawer";
import {
  tenantConfigAtom,
  userLeaveDetailsAtom,
} from "../../../../contexts/generalStore/store";
import CmlProgress from "../common/progress/CmlProgress";
import { Col, Row } from "antd";

const EntitlementView = () => {
  const [firstConfigLeaveTypes, setFirstConfigLeaveTypes] = useState([]);
  const [firstConfigLeaveTypeValues, setFirstConfigLeaveTypeValues] = useState(
    []
  );
  const [secondConfigLeaveTypes, setSecondConfigLeaveTypes] = useState([]);
  const [secondConfigLeaveTypeValues, setSecondConfigLeaveTypeValues] =
    useState([]);
  const [showBalanceDrawer, setShowBalanceDrawer] = useState(false);
  const [selectedLeaveType, setSelectedLeaveType] = useState();
  const [selectedMaxLeaveAndTypeArray, setSelectedMaxLeaveAndTypeArray] =
    useState();
  const [selectedLeaveBalance, setSelectedLeaveBalance] = useState();

  const tenantConfig = useAtomValue(tenantConfigAtom);
  const userBalanceDetails = useAtomValue(userLeaveDetailsAtom);

  useEffect(() => {
    Object.keys(userBalanceDetails).forEach((configKey) => {
      const config = userBalanceDetails[configKey];
      Object.keys(config).forEach((leaveType) => {
        if (
          configKey === "firstConfig" &&
          !firstConfigLeaveTypes.includes(leaveType)
        ) {
          setFirstConfigLeaveTypes((prevLeaveTypes) => [
            ...prevLeaveTypes,
            leaveType,
          ]);
          setFirstConfigLeaveTypeValues((prevLeaveValues) => [
            ...prevLeaveValues,
            config[leaveType],
          ]);
        } else if (
          configKey === "secondConfig" &&
          !secondConfigLeaveTypes.includes(leaveType)
        ) {
          setSecondConfigLeaveTypes((prevLeaveTypes) => [
            ...prevLeaveTypes,
            leaveType,
          ]);
          setSecondConfigLeaveTypeValues((prevLeaveValues) => [
            ...prevLeaveValues,
            config[leaveType],
          ]);
        }
      });
    });
  }, [userBalanceDetails, showBalanceDrawer]);

  const maxLeaveAndTypeArray = Object.entries(
    tenantConfig[0]?.leavePolicy?.maxLeaveAndType
  )?.map(([key, value]) => ({
    leaveType: key,
    maxLeave: value,
  }));

  const handleProgressClick = (index) => {
    setSelectedLeaveType(firstConfigLeaveTypes[index]);
    setSelectedLeaveBalance(firstConfigLeaveTypeValues[index]);
    setSelectedMaxLeaveAndTypeArray(maxLeaveAndTypeArray[index]);
    setShowBalanceDrawer(true);
  };

  return (
    <div className="cml-card">
      <BalanceDrawer
        showBalanceDrawer={showBalanceDrawer}
        selectedLeaveType={selectedLeaveType}
        selectedMaxLeaveAndTypeArray={selectedMaxLeaveAndTypeArray}
        selectedLeaveBalance={selectedLeaveBalance}
        setShowBalanceDrawer={setShowBalanceDrawer}
      />
      {maxLeaveAndTypeArray.map((leaveTypeObj, index) => {
        const isLastElement = (maxLeaveAndTypeArray.length - 1) === index;
        const { leaveType, maxLeave } = leaveTypeObj;
        const available = firstConfigLeaveTypeValues[index];
        const percentage = (available / maxLeave) * 100;
        return (
          <Row key={index} className={isLastElement ? "" : "mb-20"}>
            <Col sm={24} md={4}>
              <div className="mt-5">{lodash.startCase(leaveType)}</div>
            </Col>
            <Col sm={24} md={20}>
              <CmlProgress
                remaining={available}
                max={maxLeave}
                percentage={percentage}
                remainingTitle={`Remaining balance - ${available}`}
                consumedTitle={`consumed balance - ${maxLeave - available}`}
                tooltipPlacement={"top"}
                handleProgressClick={() => handleProgressClick(index)}
              />
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default EntitlementView;
