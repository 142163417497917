import React from "react";
import SaaSIcon from "../../../../img/saas_256.png";
import MultiRoleIcon from "../../../../img/multiRole_256.png";
import PublicShareIcon from "../../../../img/publicShare_256.png";
import ApplyLeaveIcon from "../../../../img/applyLeave_256.png";
import EmailNotificationIcon from "../../../../img/email_256.png";

export default function ServiceBox({ icon, title, subtitle }) {
  const img = { src: String, alt: String };

  switch (icon) {
    case "SaaS":
      img.src = SaaSIcon;
      img.alt = "Saas app icon";
      break;
    case "multiRole":
      img.src = MultiRoleIcon;
      img.alt = "Multi Role leave management"
      break;
    case "publicShare":
      img.src = PublicShareIcon;
      img.alt = "Share team balance publicly";
      break;
    case "ApplyLeave":
      img.src = ApplyLeaveIcon;
      img.alt = "Apply leave icon";
      break;
    case "Notification":
      img.src = EmailNotificationIcon;
      img.alt = "Email notification icon";
      break;
    default:
      img.src = SaaSIcon;
      img.alt = "Saas app icon";
      break;
  }

  return (
    <>
      <img src={img.src} alt={img.alt} className="mb-20" style={{ height: "200px" }} />
      <h3 className="mb-20">{title}</h3>
      <p className="gfs glh" style={{textAlign: "left"}}>{subtitle}</p>
    </>
  );
}
