import { config } from "@fortawesome/fontawesome-svg-core";

export const determineCurrentTenantConfig = (tenantConfigList) => {
  const today = new Date();
  let closestTenantConfig = null;
  tenantConfigList.forEach((tenantConfig) => {
    const expireAt = new Date(tenantConfig.expiredAt);
    if (
      expireAt > today &&
      (closestTenantConfig === null ||
        expireAt < new Date(closestTenantConfig.expireAt))
    ) {
      closestTenantConfig = tenantConfig;
    }
  });
  return closestTenantConfig === null ? {} : closestTenantConfig;
};

export const getTenantConfigByExpireDate = (tenantConfigList, expiredAt) => {
  let tenantConfig;
  tenantConfigList.forEach((item) => {
    if (item?.expiredAt === expiredAt) {
      tenantConfig = item;
    }
  });
  return tenantConfig ? tenantConfig : {};
};
