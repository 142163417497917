import { dateCountForNoticePeriod } from "../../datetime/dateCountHandler/helper/dateCount";
import { calculateNoticePeriod } from "./calculateNoticePeriod";

export const handleNoticePeriod = (
  start,
  end,
  getStandardDates,
  dateCount,
  tenantConfig,
  expireDate,
  setFormValidateMessage,
  setValidateForm,
  callApplyLeaveApi,
  newEvent,
  leave,
  bridgeLeavePrevDate,
  bridgeLeaveCount,
  firstYearDurationBeforeEdit,
  secondYearDurationBeforeEdit,
  fromEdit
) => {
  const dates = getStandardDates(end, start);
  const consumedDays = dateCount(dates.start, dates.end);
  let dayCountForNotice = 0;
  let datesForNotice = {};
  if (bridgeLeaveCount > 0) {
    datesForNotice = getStandardDates(bridgeLeavePrevDate, new Date());
    dayCountForNotice = dateCountForNoticePeriod(datesForNotice);
  } else {
    datesForNotice = getStandardDates(start, new Date());
    dayCountForNotice = dateCountForNoticePeriod(datesForNotice);
  }
  if (dates.start < expireDate[0]) {
    const noticePeriodKeyList = Object.keys(
      tenantConfig[0]?.leavePolicy?.noticePeriodOfLeave
    );
    const noticePeriodValueList = Object.values(
      tenantConfig[0]?.leavePolicy?.noticePeriodOfLeave
    );
    if (tenantConfig[0]?.leavePolicy?.appliedNotice?.[leave]) {
      calculateNoticePeriod(
        noticePeriodKeyList,
        noticePeriodValueList,
        consumedDays,
        setFormValidateMessage,
        setValidateForm,
        dayCountForNotice,
        callApplyLeaveApi,
        newEvent,
        firstYearDurationBeforeEdit,
        secondYearDurationBeforeEdit,
        fromEdit,
        bridgeLeaveCount
      );
    } else {
      if (fromEdit) {
        callApplyLeaveApi(
          newEvent,
          firstYearDurationBeforeEdit,
          secondYearDurationBeforeEdit
        );
      } else {
        callApplyLeaveApi(newEvent);
      }
    }
  } else if (dates.start >= expireDate[0]) {
    const noticePeriodKeyList = Object.keys(
      tenantConfig[tenantConfig?.length - 1]?.leavePolicy?.noticePeriodOfLeave
    );
    const noticePeriodValueList = Object.values(
      tenantConfig[tenantConfig?.length - 1]?.leavePolicy?.noticePeriodOfLeave
    );
    if (
      tenantConfig[tenantConfig?.length - 1]?.leavePolicy?.appliedNotice?.[
        leave
      ]
    ) {
      calculateNoticePeriod(
        noticePeriodKeyList,
        noticePeriodValueList,
        consumedDays,
        setFormValidateMessage,
        setValidateForm,
        dayCountForNotice,
        callApplyLeaveApi,
        newEvent,
        firstYearDurationBeforeEdit,
        secondYearDurationBeforeEdit,
        fromEdit,
        bridgeLeaveCount
      );
    } else {
      if (fromEdit) {
        callApplyLeaveApi(
          newEvent,
          firstYearDurationBeforeEdit,
          secondYearDurationBeforeEdit
        );
      } else {
        callApplyLeaveApi(newEvent);
      }
    }
  }
};
