import React, { useState } from "react";
import { useAtom, useAtomValue } from "jotai";

import { authUserAtom, groupedOvertimeItemsAtom } from "../../../../../contexts/generalStore/store";
import { actionOvertime } from "../../../../../controller/common/actionOverTime";
import { spawnErrorToast, spawnSuccessToast } from "../../../../../util/toast/spawnToast";
import OvertimeActionModal from "./actionModal/OvertimeActionModal";
import { reCalculateOvertimeOverviewWhenItemRemoved } from "../../../../../util/transformers/overtimeGroupItems";

const RejectOvertimeModal = ({ isModalOpen, onModalClose, tableRow }) => {
  const [inProgress, setInProgress] = useState(false);
  const [groupedItems, setGroupedItems] = useAtom(groupedOvertimeItemsAtom);

  const authUser = useAtomValue(authUserAtom);

  const updateTableData = (changedLeaveReq) => {
    const updatedList = reCalculateOvertimeOverviewWhenItemRemoved(groupedItems, changedLeaveReq.reqId)
    setGroupedItems(updatedList);
  };
  
  const handleSubmit = async (comment) => {
    const payload = {
      authorEmail: tableRow.authorEmail,
      workDate: tableRow?.details?.workDate,
      action: "REJECT",
      reqId: tableRow?.reqId,
      comment: comment
    };
    setInProgress(true);
    try {
      await actionOvertime(authUser, payload);
      updateTableData(payload);
      spawnSuccessToast("Application rejected successfully");
      onModalClose();
    } catch (err) {
      spawnErrorToast("Couldn't rejected overtime request at this moment");
    } finally {
      setInProgress(false);
    }
  };

  return (
    <>
      <OvertimeActionModal
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        tableRow={tableRow}
        title={"Reject overtime request"}
        inProgress={inProgress}
        confirmButtonLabel={"Reject"}
        handleSubmit={handleSubmit}
        confirmAction={"reject"}
      />
    </>

  );
};

export default RejectOvertimeModal;
