import { post } from "../../lib/fetch";
import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";

export const createNewTeam = async (teamName, authUser) => {
  const url = endpoints.TEAM_LIST;
  const payload = {
    teamName: teamName,
  };
  const headers = await getHeaders(authUser);

  await post({ url, payload, headers });
};
