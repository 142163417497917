import React from "react";
import { CardDiv, ShimmerDiv } from "../../../common/Common-styled";

const LeaveApprovalSkeleton = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: window.innerWidth > 768 ? "row" : "column",
          justifyContent: "space-between",
          gap: "15px",
          marginBottom: "10px",
        }}
      >
        <ShimmerDiv
          height={"40px"}
          width={window.innerWidth > 768 ? "15%" : "50%"}
        />
        <ShimmerDiv
          height={"40px"}
          width={window.innerWidth > 768 ? "22%" : "100%"}
        />
      </div>
      <CardDiv style={{ width: "100%" }} padding={"20px"}>
        <ShimmerDiv height={"200px"} width={"100%"} />
      </CardDiv>
    </div>
  );
};

export default LeaveApprovalSkeleton;
