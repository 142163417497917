import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import lodash from "lodash";
import dayjs from "dayjs";
import { Row, Col } from "antd";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import ApplyForm from "../forms/applyForm/ApplyForm";
import { getOwnLeaveRequest } from "../../../../controller/employee/getOwnLeaveRequest";
import {
  getConvertedDate,
  getStandardDate,
  getStandardDates,
  getTimeManipulatedDates,
} from "../../../../core/datetime/standardDateHandler/getStandardDate";
import { hasBalanceCheckerApply } from "../../../../core/leaveBalance/balanceChecker/balanceCheckerApply";
import { dateCount } from "../../../../core/datetime/dateCountHandler/dateCountHandler";
import { applyLeave } from "../../../../controller/employee/applyLeave";
import {
  spawnErrorToast,
  spawnSuccessToast,
} from "../../../../util/toast/spawnToast";
import { HttpStatusCode } from "../../../../enum";
import { handleNoticePeriod } from "../../../../core/leaveBalance/noticePeriodHandler/noticePeriodHandler";
import {
  overTimeListAtom,
  showModalsAtom,
  teamBalanceListAtom,
  teamConfigAtom,
  teamMembersAtom,
  tenantConfigAtom,
  userLeaveDetailsAtom,
} from "../../../../contexts/generalStore/store";
import { bridgeLeaveChecker } from "../../../../core/leaveBalance/bridgeLeaveChecker/bridgeLeaveChecker";
import { overlapChecker } from "../../../../core/leaveBalance/overlapChecker/overlapChecker";
import { getOverTimeList } from "../../../../controller/common/getOverTimeList";
import OvertimeForm from "../forms/applyOvertimeForm/OvertimeForm";
import { dateCountOnBehalf } from "../../../../core/datetime/dateCountForOnBehalf/dateCountOnBehalf";
import MessageContainer from "../messageContainer/MessageContainer";
import { tenantConfigMessage } from "../../../../util/constants/tenantConfigMessage";
import NoCalendarAccess from "../noCalendarAccess/NoCalendarAccess";
import { PERMISSIONS } from "../../../../util/constants/permission";
import ApplyLeaveSkeleton from "../skeleton/applyLeave/ApplyLeaveSkeleton";

import { Tab } from "../common/tabs/Tab";
import CmlIcon from "../common/icon/CmlIcon";

const ApplyLeave = ({
  status,
  ownApply,
  userData,
  fromAdmin,
  hasRefreshToken,
  showingInModal,
}) => {
  const selectedGroupJSON = localStorage.getItem("selectedGroup");
  let parsedSelectedGroup = null;

  if (selectedGroupJSON) {
    const parsedSelectedGroupArray = JSON.parse(selectedGroupJSON);
    if (
      Array.isArray(parsedSelectedGroupArray) &&
      parsedSelectedGroupArray.length > 0
    ) {
      parsedSelectedGroup = parsedSelectedGroupArray[0];
    }
  }

  const tenantConfig = useAtomValue(tenantConfigAtom);
  const teamConfig = useAtomValue(teamConfigAtom);
  const [teamMembers, setTeamMembers] = useAtom(teamMembersAtom);
  const [userLeaveDetails, setUserLeaveDetails] = useAtom(userLeaveDetailsAtom);
  const [showApplyModal, setShowApplyModal] = useAtom(showModalsAtom);
  const [teamBalanceList, setTeamBalanceList] = useAtom(teamBalanceListAtom);

  const setOverTimeList = useSetAtom(overTimeListAtom);

  const user = JSON.parse(secureLocalStorage.getItem("authUser"));
  const leadEmail = user?.attributes
    ? user?.attributes?.email
    : user?.idToken?.payload?.email;

  const [leave, setLeaveType] = useState(
    Object.keys(tenantConfig[0]?.leavePolicy?.maxLeaveAndType)[0]
  );

  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [nextDefaultDate, setNextDefaultDate] = useState();
  const [defaultDate, setDefaultDate] = useState();
  const [validateForm, setValidateForm] = useState(true);
  const [inProgress, setInProgress] = useState();
  const [formValidateMessage, setFormValidateMessage] = useState();
  const [sickLeaveMinimumDate, setSickLeaveMinimumDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [duration, setDuration] = useState("1 day");
  const [weekendFirst] = useState([]);
  const [weekendSecond] = useState([]);
  const [holidays, setHoliday] = useState([]);
  const [expireDate, setExpireDate] = useState([]);
  const [emailList, setEmailList] = useState([]);
  const [focused, setFocused] = useState(true);
  const [takenLeaveDates] = useState([]);
  const [datesApproved] = useState([]);
  const [datesRejected] = useState([]);
  const [datesPending] = useState([]);
  const [datesModify] = useState([]);
  const [updateLeaveArray, setUpdateLeaveArray] = useState(false);
  const [selectedTab, setSelectedTab] = useState("leaveApply");

  const getDatesInRange = (startDate, endDate, status) => {
    const date = new Date(startDate.getTime());
    while (date <= endDate) {
      if (status === "approved") {
        if (!isInArray(datesApproved, new Date(date))) {
          datesApproved?.push(new Date(date));
        }
        if (!isInArray(takenLeaveDates, new Date(date))) {
          takenLeaveDates?.push(new Date(date));
        }
      } else if (status === "modification") {
        if (!isInArray(datesModify, new Date(date))) {
          datesModify?.push(new Date(date));
        }
        if (!isInArray(takenLeaveDates, new Date(date))) {
          takenLeaveDates?.push(new Date(date));
        }
      } else if (status === "pending") {
        if (!isInArray(datesPending, new Date(date))) {
          datesPending?.push(new Date(date));
        }
        if (!isInArray(takenLeaveDates, new Date(date))) {
          takenLeaveDates?.push(new Date(date));
        }
      } else {
        if (!isInArray(datesRejected, new Date(date))) {
          datesRejected?.push(new Date(date));
        }
      }

      date.setDate(date.getDate() + 1);
    }
  };

  const isInArray = (array, DateValue) => {
    const dateValueStr = new Date(DateValue).toDateString();
    return array.some((date) => new Date(date).toDateString() === dateValueStr);
  };

  const isInHolidayArray = (array, DateValue) => {
    const dateValueStr = new Date(DateValue).toDateString();
    return array.some(
      (item) => new Date(item.date).toDateString() === dateValueStr
    );
  };

  const calculateDefaultDate = (defaultDate) => {
    if (status === true && ownApply === true) {
      while (
        weekendFirst.includes(defaultDate.getDay()) ||
        isInHolidayArray(holidays, defaultDate) ||
        isInArray(takenLeaveDates, defaultDate)
      ) {
        defaultDate.setDate(defaultDate.getDate() + 1);
      }
      setStartDate(defaultDate);
      setEndDate(defaultDate);
      setNextDefaultDate(defaultDate);
      setDefaultDate(defaultDate);
    } else {
      while (
        weekendFirst.includes(defaultDate.getDay()) ||
        isInArray(holidays, defaultDate)
      ) {
        defaultDate.setDate(defaultDate.getDate() + 1);
      }
      setStartDate(defaultDate);
      setEndDate(defaultDate);
    }
    setUpdateLeaveArray(false);
  };

  const getLeaveRequest = async () => {
    setFocused(false);
    const firstYearStart = tenantConfig[0]?.expiredAt;
    const currentYear = new Date(firstYearStart).getFullYear();
    const twoYearsBeforeExpirationYear = currentYear - 1;
    const twoYearsBeforeExpirationDate = new Date(firstYearStart).setFullYear(
      twoYearsBeforeExpirationYear
    );
    const fromDate = new Date(twoYearsBeforeExpirationDate).toISOString();
    const toDate = new Date(
      tenantConfig[tenantConfig.length - 1]?.expiredAt
    ).toISOString();
    const takenLeavesList = await getOwnLeaveRequest(user, fromDate, toDate);
    takenLeavesList?.data?.Items.forEach((item) => {
      getDatesInRange(
        new Date(item.details.startDate),
        new Date(item.details.endDate),
        item.status
      );
    });
    const defaultDateTime = getConvertedDate(dayjs().toDate());
    const defaultDate = dayjs(defaultDateTime[0]).toDate();
    calculateDefaultDate(defaultDate);
    setUpdateLeaveArray(true);
  };

  const getOverTime = async (token) => {
    const minDate = new Date(tenantConfig[0]?.startYear);
    const maxDate = new Date(tenantConfig[tenantConfig.length - 1]?.expiredAt);
    const queryParams = {
      from: minDate.toISOString(),
      to: maxDate.toISOString(),
      to: maxDate.toISOString(),
    };
    try {
      const response = await getOverTimeList(user, queryParams, token);
      setOverTimeList(response?.data?.Items);
    } catch (error) {
      spawnErrorToast("Error while fetching over time list");
    } finally {
      setInProgress({ ...inProgress, inProgressOwnOverTime: false });
    }
  };

  const calculateSickLeaveMinDate = (maxDate) => {
    const firstYearExp = tenantConfig[0]?.expiredAt;
    if (new Date() < new Date(firstYearExp) && tenantConfig.length > 1) {
      const currentYear = new Date(maxDate).getFullYear();
      const twoYearsBeforeExpirationYear = currentYear - 2;
      const twoYearsBeforeExpirationDate = new Date(maxDate).setFullYear(
        twoYearsBeforeExpirationYear
      );
      const sickLeaveMinDate = new Date(twoYearsBeforeExpirationDate);
      setSickLeaveMinimumDate(sickLeaveMinDate);
    } else {
      const currentYear = new Date(maxDate).getFullYear();
      const twoYearsBeforeExpirationYear = currentYear - 1;
      const oneYearsBeforeExpirationDate = new Date(maxDate).setFullYear(
        twoYearsBeforeExpirationYear
      );
      const sickLeaveMinDate = new Date(oneYearsBeforeExpirationDate);
      setSickLeaveMinimumDate(sickLeaveMinDate);
    }
  };

  useEffect(() => {
    const defaultDateTime = getConvertedDate(dayjs().toDate());
    const defaultDate = dayjs(defaultDateTime[0]).toDate();
    if (expireDate.length) {
      if (!hasBalanceChecker(defaultDate, defaultDate)) {
        setValidateForm(false);
        setFormValidateMessage(
          `Not enough leave balance for "${lodash.startCase(leave)}"`
        );
        return;
      } else {
        setValidateForm(true);
      }
    }
  }, [expireDate]);

  const bootStrappingApply = () => {
    if (updateLeaveArray === false && ownApply) {
      getLeaveRequest();
      getOverTime();
    } else {
      const defaultDateTime = getConvertedDate(dayjs().toDate());
      const defaultDate = dayjs(defaultDateTime[0]).toDate();
      calculateDefaultDate(defaultDate);
    }
    const maxDate = new Date(
      tenantConfig[tenantConfig.length - 1]?.expiredAt
    ).setDate(
      new Date(tenantConfig[tenantConfig.length - 1]?.expiredAt).getDate() - 1
    );
    calculateSickLeaveMinDate(maxDate);
    setMaxDate(new Date(maxDate));
    tenantConfig?.forEach((value, index) => {
      setExpireDate((prevState) => [...prevState, new Date(value?.expiredAt)]);
      const holidayArray = Object.keys(value?.holiday).map((date) => {
        return {
          date: new Date(date),
          title: value?.holiday[date].title,
        };
      });
      Object.keys(value?.weekend)?.map((item) => {
        if (index === 0) {
          if (!weekendFirst.includes(parseInt(item))) {
            weekendFirst?.push(parseInt(item));
          }
        }
        if (index === 1) {
          if (!weekendSecond.includes(parseInt(item))) {
            weekendSecond.push(parseInt(item));
          }
        }
      });
      setHoliday((prevState) => [...prevState, ...holidayArray]);
    });
  };

  useEffect(() => {
    if ((tenantConfig.length > 0 && hasRefreshToken) || !ownApply) {
      // TODO: This can cause unnecessary re-rendering of the component, need to fix
      bootStrappingApply();
    }
  }, []);

  useEffect(() => {
    if (updateLeaveArray && expireDate.length) {
      setFocused(true);
    }
  }, [updateLeaveArray]);

  const setDefaultValue = () => {
    if (ownApply) {
      calculateDefaultDate(nextDefaultDate);
    } else {
      calculateDefaultDate(dayjs().toDate());
    }
    setDescription("");
    setDuration("1 day");
    setValidateForm(true);
    setFormValidateMessage("");
    setLeaveType(Object.keys(tenantConfig[0]?.leavePolicy?.maxLeaveAndType)[0]);
    setEmailList([]);
  };

  const hasBalanceChecker = (start, end) => {
    const balanceChecker = hasBalanceCheckerApply(
      start,
      end,
      leave,
      tenantConfig,
      expireDate,
      userData,
      userLeaveDetails,
      calculateConsumedDays,
      ownApply
    );
    return balanceChecker;
  };

  const dateChecker = (endDate, startDate) => {
    const date = getStandardDate(endDate, startDate);
    const isOverLapped = overlapChecker(takenLeaveDates, date);
    const hasBalance = hasBalanceChecker(date.start, date.end);
    if (isOverLapped) {
      setValidateForm(false);
      setFormValidateMessage("Leave date is overlapped");
      return;
    }
    if (!hasBalance) {
      setValidateForm(false);
      setFormValidateMessage(
        `Not enough leave balance for "${lodash.startCase(leave)}"`
      );
      return;
    }
    setValidateForm(true);
  };

  const updateMembersLeaveBalance = (member, durationBreakDown) => {
    if (member?.leaveBalance?.firstConfig && durationBreakDown?.firstConfig) {
      const leaveTypes = Object.keys(member?.leaveBalance?.firstConfig);
      leaveTypes.forEach((leaveType) => {
        if (durationBreakDown.firstConfig[leaveType]) {
          member.leaveBalance.firstConfig[leaveType] -=
            durationBreakDown.firstConfig[leaveType];
        }
      });
    }
    if (member?.leaveBalance?.secondConfig && durationBreakDown?.secondConfig) {
      const secondConfigLeaveTypes = Object.keys(
        member?.leaveBalance?.secondConfig
      );
      secondConfigLeaveTypes.forEach((leaveType) => {
        if (durationBreakDown.secondConfig[leaveType]) {
          member.leaveBalance.secondConfig[leaveType] -=
            durationBreakDown.secondConfig[leaveType];
        }
      });
    }
  };

  const handleMemberListChange = () => {
    const type = leave
      ? leave
      : Object.keys(tenantConfig[0]?.leavePolicy?.maxLeaveAndType)[0];
    const duration = calculateConsumedDays(startDate, endDate);
    const memberList = fromAdmin ? teamBalanceList : teamMembers;
    if (userData?.email) {
      const durationBreakDown = dateCountOnBehalf(
        duration,
        type,
        startDate,
        endDate,
        userData,
        userData.leaveBalance,
        expireDate,
        tenantConfig,
        duration.firstYearWorkingDays,
        duration.secondYearWorkingDays
      );
      memberList.forEach((member) => {
        if (userData?.email === member.email) {
          updateMembersLeaveBalance(member, durationBreakDown);
        }
      });
      setTeamMembers([...memberList]);
      setTeamBalanceList([...memberList]);
    } else {
      const userLeaveDetailsClone = lodash.clone(userLeaveDetails);
      userLeaveDetailsClone.firstConfig[type] =
        userLeaveDetailsClone?.firstConfig[type] -
        duration.firstYearWorkingDays;
      if (userLeaveDetailsClone?.secondConfig) {
        userLeaveDetailsClone.secondConfig[type] =
          userLeaveDetailsClone?.secondConfig[type] -
          duration.secondYearWorkingDays;
      }
      setUserLeaveDetails(userLeaveDetailsClone);
    }
  };

  const calculateConsumedDays = (start, end) => {
    const consumedDays = dateCount(
      start,
      end,
      tenantConfig,
      getStandardDate,
      holidays,
      expireDate,
      setDuration,
      weekendFirst,
      weekendSecond
    );
    const firstYearWorkingDays = consumedDays.firstYearWorkingDays;
    const secondYearWorkingDays = consumedDays.secondYearWorkingDays;
    const duration = consumedDays.duration;
    return { firstYearWorkingDays, secondYearWorkingDays, duration };
  };

  function getDateArray(startDate, endDate) {
    const current = new Date(startDate);
    const end = new Date(endDate);

    while (current <= end) {
      takenLeaveDates.push(new Date(current));
      datesPending.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
    setFocused(true);
  }

  const callApplyLeaveApi = async (newEvent) => {
    setInProgress(true);
    try {
      await applyLeave(newEvent, user);
      if (ownApply) {
        getDateArray(newEvent.startDate, newEvent.endDate);
        getDatesInRange(
          new Date(newEvent.startDate),
          new Date(newEvent.endDate),
          newEvent.leaveType
        );
      }
      spawnSuccessToast("Application submitted");
      setDefaultValue();
      handleMemberListChange();
      setShowApplyModal({ ...showApplyModal, showApplyOnBehalfModal: false });
    } catch (error) {
      if (error?.response?.status === HttpStatusCode.UNAUTHORIZED) {
        spawnErrorToast(error?.response?.data?.message);
      } else {
        spawnErrorToast(
          error?.response?.data && error?.response?.data !== ""
            ? error.response.data
            : error?.message
        );
      }
    } finally {
      setInProgress(false);
    }
  };

  const generatePayload = (type, start, end) => {
    const creationDate = getStandardDates(new Date());
    const dates = getTimeManipulatedDates(start, end);
    const newEvent = {
      startDate: dates.start,
      endDate: dates.end,
      employeeEmail: userData?.email ? userData?.email : leadEmail,
      employeeEmail: userData?.email ? userData?.email : leadEmail,
      leadEmail: userData?.email || fromAdmin ? leadEmail : "",
      leaveType: type,
      title: userData?.email
        ? userData?.email
        : JSON.parse(secureLocalStorage.getItem("authUser"))?.attributes?.email,
      description: description,
      status: fromAdmin ? "approved" : "pending",
      creationDate: creationDate.start,
      emailList: emailList,
      selectedTeam: fromAdmin ? userData?.teamName : null,
      fromAdmin: fromAdmin ? true : false,
    };
    return newEvent;
  };

  const handleAddEvent = async () => {
    const type = leave
      ? leave
      : Object.keys(tenantConfig[0]?.leavePolicy?.maxLeaveAndType)[0];
    const date = getStandardDate(endDate, startDate);
    const newEvent = generatePayload(type, date.start, date.end);

    if (ownApply && !hasBalanceChecker(date.start, date.end)) {
      setValidateForm(false);
      setInProgress(false);
      setFormValidateMessage(
        `Not enough leave balance for "${lodash.startCase(type)}"`
      );
      return;
    } else {
      if (ownApply) {
        const bridgeLeave = bridgeLeaveChecker(
          takenLeaveDates,
          holidays,
          weekendFirst,
          weekendSecond,
          date,
          expireDate,
          tenantConfig
        );
        handleNoticePeriod(
          date.start,
          date.end,
          getStandardDate,
          calculateConsumedDays,
          tenantConfig,
          expireDate,
          setFormValidateMessage,
          setValidateForm,
          callApplyLeaveApi,
          newEvent,
          leave,
          bridgeLeave?.prevLeaveDate,
          bridgeLeave?.bridgeLeave
        );
      } else {
        callApplyLeaveApi(newEvent);
      }
    }
  };

  const handleLeaveTypeChange = (value) => {
    setLeaveType(value);
    setStartDate(dayjs(defaultDate).toDate());
    setEndDate(dayjs(defaultDate).toDate());
    dateChecker(dayjs(defaultDate).toDate(), dayjs(defaultDate).toDate());
  };

  const handleStartDateChange = (start) => {
    setValidateForm(true);
    setFormValidateMessage("");
    setStartDate(dayjs(start).toDate());
    handleStartDateChangeEffect(dayjs(start).toDate());
  };

  const handleStartDateChangeEffect = (start) => {
    setEndDate(dayjs(start).toDate());
    dateChecker(dayjs(start).toDate(), dayjs(start).toDate());
  };

  const handleEndDateChange = (end) => {
    setValidateForm(true);
    setFormValidateMessage("");
    setEndDate(dayjs(end).toDate());
    setNextDefaultDate(dayjs(end).toDate());
    dateChecker(dayjs(end).toDate(), startDate);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const onFinish = () => {
    handleAddEvent();
  };

  const onSelectedTab = (option) => {
    setSelectedTab(option);
  };

  const getTabs = () => {
    let tabs = [
      {
        title: "Leave",
        label: "leaveApply",
        icon: <CmlIcon _className={"tab-icon"} iconName={"potted_plant"} />,
      },
      {
        title: "Overtime",
        label: "overtimeApply",
        icon: <CmlIcon _className={"tab-icon"} iconName={"schedule"} />,
      },
    ];
    return tabs;
  };

  return (
    <>
      {ownApply &&
      !tenantConfig.length &&
      !PERMISSIONS.view.menu.noTenantConfig_NoRefreshTokenAllowedGroups.some(
        (value) => parsedSelectedGroup?.includes(value)
      ) ? (
        <>
          <MessageContainer
            messages={tenantConfigMessage.EMPTY_TENANT_CONFIG}
          />
        </>
      ) : ownApply &&
        !hasRefreshToken &&
        !PERMISSIONS.view.menu.noTenantConfig_NoRefreshTokenAllowedGroups.some(
          (value) => parsedSelectedGroup?.includes(value)
        ) ? (
        <>
          <NoCalendarAccess />
        </>
      ) : (
        <>
          {(focused && expireDate.length) || !ownApply ? (
            <>
              {ownApply && (
                <Tab onSelectedTab={onSelectedTab} tabs={getTabs()} />
              )}
              <Row>
                <Col xs={24} md={ownApply ? 8 : 24}>
                  <div className={ownApply ? "cml-card mt-30" : ""}>
                    {selectedTab === "leaveApply" ? (
                      <ApplyForm
                        startDate={startDate}
                        endDate={endDate}
                        leaveType={leave}
                        description={description}
                        emailList={emailList}
                        duration={duration === "0 day" ? "1 day" : duration}
                        tenantConfig={tenantConfig}
                        teamConfig={teamConfig}
                        updateLeaveArray={updateLeaveArray}
                        weekendFirst={weekendFirst}
                        weekendSecond={weekendSecond}
                        holidays={holidays}
                        expireDate={expireDate}
                        approved={datesApproved}
                        focused={focused}
                        pending={datesPending}
                        modification={datesModify}
                        inProgress={inProgress}
                        onFinish={onFinish}
                        handleLeaveTypeChange={handleLeaveTypeChange}
                        handleStartDateChange={handleStartDateChange}
                        handleEndDateChange={handleEndDateChange}
                        handleDescription={handleDescription}
                        setEmails={setEmailList}
                        sickLeaveMinimumDate={sickLeaveMinimumDate}
                        validateForm={validateForm}
                        formValidateMessage={formValidateMessage}
                        ownApply={ownApply}
                        fromAdmin={fromAdmin}
                        userData={userData}
                        showingInModal={showingInModal}
                      />
                    ) : (
                      <OvertimeForm />
                    )}
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <ApplyLeaveSkeleton />
          )}
        </>
      )}
    </>
  );
};

export default ApplyLeave;
