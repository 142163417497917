import { get } from "../../lib/fetch";
import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";

export const getMembersLeaveHistory = async (
  authUser,
  searchType,
  selectedTeam,
  selectedMember,
  selectedStatus,
  selectedRole,
  startDate,
  endDate,
  continueToken
) => {
  let url = "";
  if (searchType === "teamSpecific") {
    if (
      selectedTeam !== "invalid" &&
      selectedStatus !== "invalid" &&
      selectedRole === "invalid" &&
      !startDate &&
      !endDate
    ) {
      url = `${endpoints.LEAVE_HISTORY}?teamName=${selectedTeam}&searchType=${searchType}&leaveStatus=${selectedStatus}`;
    } else {
      url = `${endpoints.LEAVE_HISTORY}?teamName=${selectedTeam}&searchType=${searchType}&leaveStatus=${selectedStatus}&applicationType=${selectedRole}&startDate=${startDate}&endDate=${endDate}`;
    }
  } else if (searchType === "userSpecific") {
    if (
      selectedTeam !== "invalid" &&
      selectedMember !== "invalid" &&
      selectedStatus === "invalid" &&
      selectedRole === "invalid" &&
      isNaN(Date.parse(startDate)) &&
      isNaN(Date.parse(endDate))
    ) {
      url = `${endpoints.LEAVE_HISTORY}?employeeEmail=${selectedMember}&searchType=${searchType}&teamName=${selectedTeam}`;
    } else if (
      selectedTeam !== "invalid" &&
      selectedMember !== "invalid" &&
      selectedStatus !== "invalid" &&
      selectedRole === "invalid" &&
      isNaN(Date.parse(startDate)) &&
      isNaN(Date.parse(endDate))
    ) {
      url = `${endpoints.LEAVE_HISTORY}?employeeEmail=${selectedMember}&searchType=${searchType}&teamName=${selectedTeam}&leaveStatus=${selectedStatus}`;
    } else if (
      selectedTeam !== "invalid" &&
      selectedMember !== "invalid" &&
      selectedStatus !== "invalid" &&
      selectedRole === "invalid" &&
      !isNaN(Date.parse(startDate)) &&
      !isNaN(Date.parse(endDate))
    ) {
      url = `${endpoints.LEAVE_HISTORY}?employeeEmail=${selectedMember}&searchType=${searchType}&teamName=${selectedTeam}&leaveStatus=${selectedStatus}&startDate=${startDate}&endDate=${endDate}`;
    } else {
      url = `${endpoints.LEAVE_HISTORY}?employeeEmail=${selectedMember}&searchType=${searchType}&teamName=${selectedTeam}&applicationType=${selectedRole}&leaveStatus=${selectedStatus}&startDate=${startDate}&endDate=${endDate}`;
    }
  }
  if (continueToken !== "" && continueToken) {
    url = `${url}&lastEvaluatedKey=${continueToken}`;
  }
  const headers = await getHeaders(authUser);
  return await get({ url, headers });
};
