import { Button, DatePicker, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useAtom, useAtomValue } from "jotai";
import dayjs from "dayjs";
import {
    getTimeManipulatedDates,
} from "../../../../../core/datetime/standardDateHandler/getStandardDate";
import { getUpcomingDay } from "../../../../../core/datetime/defaultDate/getUpcomingHoliday";
import {
    spawnErrorToast,
    spawnSuccessToast,
} from "../../../../../util/toast/spawnToast";
import { postOvertime } from "../../../../../controller/common/postOvertime";
import {
    authUserAtom,
    overTimeListAtom,
    showModalsAtom,
    tenantConfigAtom,
} from "../../../../../contexts/generalStore/store";
import CmlIcon from "../../common/icon/CmlIcon";
import OvertimeInput from "./OvertimeInput";

const OvertimeForm = () => {
  const [form] = Form.useForm();

  const authUser = useAtomValue(authUserAtom);
  const tenantConfig = useAtomValue(tenantConfigAtom);

  const [showModal, setShowModal] = useAtom(showModalsAtom);
  const [overTimeList, setOverTimeList] = useAtom(overTimeListAtom);

  const [dateStr, setDateStr] = useState("");
  const [description, setDescription] = useState("");
  const [hours, setHours] = useState(8);
  const [inProgress, setInprogress] = useState(false);
  const [holidays, setHoliday] = useState([]);
  const [weekendFirst, setWeekendFirst] = useState([]);
  const [minDate, maxDate] = [
        new Date(tenantConfig[0]?.startYear),
        new Date(tenantConfig[0]?.expiredAt).setDate(
            new Date(tenantConfig[0]?.expiredAt).getDate() - 1
        ),
  ];
  const shouldCalcOTHourly = tenantConfig[0]?.shouldCalculateOvertimeHourly || false;

  const checkIfDateExists = (array, dateToCheck) => {
      const { start } = getTimeManipulatedDates(new Date(dateToCheck));
      return array.some((item) => {
          const compareDate = getTimeManipulatedDates(new Date(item.date));
          return compareDate.start === start;
      });
  };

  useEffect(() => {
      tenantConfig.forEach((value, index) => {
            Object.entries(value.holiday).forEach(([date, { title }]) => {
                if (!checkIfDateExists(holidays, new Date(date))) {
                    setHoliday((prev) => [...prev, { date: new Date(date), title }]);
                }
            });
            Object.keys(value.weekend).forEach((day) => {
                if (!weekendFirst.includes(parseInt(day))) {
                    setWeekendFirst((prev) => [...prev, parseInt(day)]);
                }
            });
        });

        const upcomingHoliday = getUpcomingDay(holidays, overTimeList, weekendFirst);
        form.setFieldsValue({
            workDate: dayjs(upcomingHoliday),
            description,
        });
        setDateStr(upcomingHoliday);
      }, [overTimeList, holidays, form, weekendFirst]);

    const handleDescription = (e) => setDescription(e.target.value);
    const handleHours = (value) => {
        setHours(value);
    };

  const handleClose = () => {
        setShowModal((prev) => ({ ...prev, showExtraDaySubmissionModal: false }));
        setDescription("");
        setDateStr("");
        setInprogress(false);
    };

    const handleSubmit = async () => {
        setInprogress(true);
        const payload = { workDate: dateStr, description, hours };
        try {
            const response = await postOvertime({ authUser, payload });
            spawnSuccessToast(response?.data?.message);
            setOverTimeList((prevState) => [
                ...prevState,
                {
                    authorEmail: response?.data?.data?.authorEmail,
                    reqId: response?.data?.data?.reqId,
                    status: response?.data?.data?.status,
                    details: response?.data?.data,
                },
            ]);
        } catch (error) {
            spawnErrorToast(error.response.data.message);
        } finally {
            handleClose();
        }
    };

    const handleDisableDate = (current) => {
        const isHolidayOrWeekend =
            checkIfDateExists(holidays, current) ||
            weekendFirst.includes(current.getDay());
        const isOvertimeApplied = overTimeList.some(
            ({ details, status }) =>
                ["approved", "pending", "modification"].includes(status) &&
                new Date(details.workDate).toDateString() === current.toDateString()
        );
        return (
            current < minDate ||
            current > maxDate ||
            !(isHolidayOrWeekend && !isOvertimeApplied)
        );
    };

    const handleCellRender = (current) => {
        const currentDate = getTimeManipulatedDates(current.toDate()).start;
        const isHoliday = checkIfDateExists(holidays, currentDate);
        const isWeekend = weekendFirst.includes(new Date(currentDate).getDay());
        const isOvertimeApplied = overTimeList.some(
            ({ details, status }) =>
                ["approved", "pending", "modification"].includes(status) &&
                new Date(details.workDate).toDateString() === new Date(currentDate).toDateString()
        );
        if (isHoliday || isWeekend) {
            return (
                <div
                    className="ant-picker-cell-inner ant-picker-cell-inner-holiday"
                    title={isOvertimeApplied ? "Already applied" : isHoliday ? "Holiday" : "Weekend"}
                >
                    {current.date()}
                </div>
            );
        }
        return (
            <div className="ant-picker-cell-inner">
                {current.date()}
            </div>
        );
    };

    const onDateChange = (date) => {
        setDateStr(date.toISOString());
    };

    return (
        <Form form={form} onFinish={handleSubmit} layout="vertical">
            <OvertimeInput initialHour={hours} flag={shouldCalcOTHourly} handleHoursFunc={handleHours} duration={1} />

            <Form.Item
                label={<div className="neutral-text">Date</div>}
                name="workDate"
                rules={[{ required: true, message: "Please select date" }]}
            >
                <DatePicker
                    className="full-width"
                    onChange={onDateChange}
                    showToday={false}
                    disabledDate={(current) => handleDisableDate(current.toDate())}
                    cellRender={handleCellRender}
                    allowClear={false}
                    inputReadOnly={true}
                />
            </Form.Item>
            <Form.Item
                label={<div className="neutral-text">Description</div>}
                name="description"
            >
                <Input.TextArea
                    value={description}
                    onChange={handleDescription}
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    showCount
                    maxLength={200}
                />
            </Form.Item>
            <Form.Item>
                <Button
                    type="primary"
                    className="mt-20"
                    htmlType="submit"
                    loading={inProgress}
                    icon={<CmlIcon iconName={"send"} />}
                >
                    Apply
                </Button>
            </Form.Item>
        </Form>
    );
};

export default OvertimeForm;
