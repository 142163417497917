import { Modal } from "antd";
import React, { useContext, useState } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import {
  authUserAtom,
  teamBalanceListAtom,
} from "../../../../../../../../../contexts/generalStore/store";
import { handleUnassignFromTeam } from "../../../../../../../../../core/team/teamAssignment/unassignFromTeamHandler";
import CmlIcon from "../../../../../../common/icon/CmlIcon";

const UnassignMember = ({ open, setOpen, member }) => {

  const [confirmLoading, setConfirmLoading] = useState(false);

  const authUser = useAtomValue(authUserAtom);

  const setTeamBalanceList = useSetAtom(teamBalanceListAtom);

  const removeEmployeeFromList = (email) => {
    setTeamBalanceList((oldValues) => {
      return oldValues.filter((oldEmployee) => oldEmployee.email !== email);
    });
  };

  const getStateArguments = () => {
    return {
      employee: member,
      authUser: authUser,
      setIsLoading: setConfirmLoading,
      handleClose: handleCancel,
      removeEmployeeFromList: removeEmployeeFromList,
    };
  };

  const handleSubmit = async () => {
    const args = getStateArguments();
    handleUnassignFromTeam(args);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        title="Unassign Member"
        open={open}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okText="Unassign"
        cancelText="Close"
        okButtonProps={{
          icon: <CmlIcon iconName="task_alt" />
        }}
        cancelButtonProps={{
          icon: <CmlIcon iconName="cancel" />
        }}
        confirmLoading={confirmLoading}
        maskClosable={false}
      >
        <p>
          Are you sure you want to remove <b>{member?.employeeName}</b> from{" "}
          <b>{member?.teamName}</b>?
        </p>
      </Modal>
    </div>
  );
};

export default UnassignMember;
