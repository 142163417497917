const hoursToDays = (hours) => {
    const days = Math.floor(hours / 8);
    const remainingHours = hours % 8;
    if (days > 0 && remainingHours > 0) {
        return `${days} days and ${remainingHours} hours`;
    } else if (days > 0) {
        return `${days} days`;
    } else {
        return `${remainingHours} hours`;
    }
};

export const transformToOvertimeOverviewResp = (data) => {
    const groupedData = data.Items.reduce((acc, item) => {
        const email = item.authorEmail;
        if (!acc[email]) {
            acc[email] = {
                items: [],
                totalRequestedOvertimeInHours: 0,
                totalApprovedOvertimeInHours: 0
            };
        }
        acc[email].items.push(item);

        // Use default of 8 hours if 'hours' is missing
        const hours = item.details.hours ? parseFloat(item.details.hours) : 8;

        acc[email].totalRequestedOvertimeInHours += hours;
        if (item.status === "approved") {
            acc[email].totalApprovedOvertimeInHours += hours;
        }

        return acc;
    }, {});

    const transformedData = Object.keys(groupedData).map(email => {
        const userOverview = groupedData[email];
        return {
            authorEmail: email,
            items: userOverview.items,
            overview: {
                totalRequestedOvertimeInHours: userOverview.totalRequestedOvertimeInHours,
                totalApprovedOvertimeInHours: userOverview.totalApprovedOvertimeInHours,
                totalRequestedOvertimeInDays: hoursToDays(userOverview.totalRequestedOvertimeInHours),
                totalApprovedOvertimeInDays: hoursToDays(userOverview.totalApprovedOvertimeInHours)
            }
        };
    });

    return {
        ...data,
        groupedItems: transformedData
    };
};

export const reCalculateOvertimeOverviewWhenItemRemoved = (data, targetId) => {
    for (let i = 0; i < data.length; i++) {
        data[i].items = data[i].items.filter(item => item.reqId !== targetId);

        let totalRequestedHours = 0;
        let totalApprovedHours = 0;
        data[i].items.forEach(item => {
            const hours = Math.max(0, parseFloat(item?.details?.hours || 8)); // Use 8 if hours is missing
            if (hours >= 0) { // Ignore negative values
                totalRequestedHours += hours;
                if (item.status === "approved") {
                    totalApprovedHours += hours;
                }
            }
        });

        data[i].overview = {
            totalRequestedOvertimeInHours: totalRequestedHours,
            totalApprovedOvertimeInHours: totalApprovedHours,
            totalRequestedOvertimeInDays: hoursToDays(totalRequestedHours),
            totalApprovedOvertimeInDays: hoursToDays(totalApprovedHours)
        };
    }
    return data;
};

export const updateItemStatusByReqId = (data, reqId, newStatus) => {
    return data.map(author => {
        const updatedItems = author.items.map(item => {
            if (item.reqId === reqId) {
                return {
                    ...item,
                    status: newStatus
                };
            }
            return item;
        });
        return {
            ...author,
            items: updatedItems
        };
    });
};
