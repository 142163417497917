import {
  getTimeManipulatedDates,
} from "../standardDateHandler/getStandardDate";

const isAlreadyExist = (currentDate, overTimeDates) => {
  const date = getTimeManipulatedDates(currentDate);
  const isExistOvertime = overTimeDates.some((overTimeDate) => {
    if (
      new Date(date.start).toISOString() ===
      new Date(overTimeDate.workDate).toISOString()
    ) {
      return true;
    }
    return false;
  });
  return isExistOvertime;
};

const getUpcomingHoliday = (holidayList, currentDate, overTimeDates) => {
  const includedStatus = ["approved", "pending", "modification"];
  const holidays = holidayList.map(({ date }) => new Date(date));
  const upcomingHolidays = holidays
    .filter((holiday) => new Date(holiday) > currentDate)
    .sort((afterValue, beforeValue) => afterValue - beforeValue);

  const upcomingHoliday = upcomingHolidays.find((holiday) =>
    overTimeDates.every((overTimeDate) => {
      if (includedStatus.includes(overTimeDate.status)) {
        return (
          new Date(overTimeDate.workDate).toDateString() !==
          new Date(holiday).toDateString()
        );
      }
      return true;
    })
  );
  return upcomingHoliday;
};

export const getUpcomingDay = (holidayList, overTimeList, weekend) => {
  const currentDate = new Date();

  const overTimeDates = overTimeList.map(({ details, status }) => ({
    workDate: details.workDate,
    status,
  }));
  const upcomingHoliday = getUpcomingHoliday(
    holidayList,
    currentDate,
    overTimeDates
  );

  while (currentDate < upcomingHoliday) {
    if (
      weekend.includes(currentDate.getDay()) &&
      !isAlreadyExist(currentDate, overTimeDates)
    ) {
      return currentDate.toISOString();
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  const formattedUpcomingHoliday = upcomingHoliday
    ? upcomingHoliday.toISOString()
    : null;
  return formattedUpcomingHoliday;
};

