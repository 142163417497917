const getConvertedUTCISODate = (date) => {
  if (!isNaN(Date.parse(date))) {
    return new Date(date).toISOString();
  } else {
    return null;
  }
};

export const getStandardDates = (startDate, endDate) => {
  const dateStart = getConvertedUTCISODate(startDate);
  if (!endDate) return { start: dateStart };
  const dateEnd = getConvertedUTCISODate(endDate);
  return {
    start: dateStart,
    end: dateEnd,
  };
};

export const getTimeManipulatedDates = (start, end) => {
  const startYear = start.getFullYear();
  const startMonth = start.getMonth();
  const onlyDateStart = start.getDate();
  const dateStart = new Date(startYear, startMonth, onlyDateStart, 0, 0, 0, 0);

  if (!end) return getStandardDates(dateStart);

  const endYear = end.getFullYear();
  const endMonth = end.getMonth();
  const onlyDateEnd = end.getDate();
  const dateEnd = new Date(endYear, endMonth, onlyDateEnd, 23, 59, 59, 0);
  const isoDate = getStandardDates(dateStart, dateEnd);
  return {
    start: isoDate.start,
    end: isoDate.end,
  };
};

export const getConvertedDate = (date) => {
  const multiplicationFactorInMillisecond = 60000;
  const dateLocal = new Date(date);
  const offset =
    dateLocal.getTimezoneOffset() * multiplicationFactorInMillisecond;
  const newLocalDateIso = new Date(dateLocal - offset).toISOString();
  return newLocalDateIso.split("T");
};

export const getStandardDate = (endDate, startDate) => {
  const dateStart = getConvertedDate(startDate);
  if (!endDate) {
    return {
      start: new Date(dateStart[0]),
    };
  }
  const dateEnd = getConvertedDate(endDate);
  return {
    start: new Date(dateStart[0]),
    end: new Date(dateEnd[0]),
  };
};
