import { Select } from "antd";
import { useAtom } from "jotai";

import { selectedGroupAtom } from "../../../../../contexts/generalStore/store";

const ModalGroupSelect = () => {
  const [selectedGroup, setSelectedGroup] = useAtom(selectedGroupAtom);
  const groups = [
    {
      name: "Employee",
      value: "employee",
    },
    {
      name: "Team Lead",
      value: "teamLead",
    },
  ];

  const handleTeamSelection = (value) => {
    setSelectedGroup(value);
  };
  return (
    <Select
      aria-label="Default select example"
      defaultValue="employee"
      value={selectedGroup}
      onChange={(e) => handleTeamSelection(e)}
    >
      {groups &&
        groups.map((group, index) => {
          return (
            <Select.Option key={index} value={group?.value}>
              {group?.name}
            </Select.Option>
          );
        })}
    </Select>
  );
};

export default ModalGroupSelect;
