import styled from "@emotion/styled";

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-width: 100%;
`;

const LogoutButtonWrapper = styled.div`
justify-self: flex-end;
  display: flex;
  align-items: center;
  margin: 0 15px 0 0;
`;

export { HeaderWrapper, LogoutButtonWrapper };
