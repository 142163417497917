import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { Select } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";

import {
  adminHistoryEndDate,
  adminHistoryStartDate,
  continueTokenAtom,
  selectedMemberAtom,
  selectedRoleAtom,
  selectedSearchTypeAtom,
  selectedStatusAtom,
  selectedTeamForMembersRequestsAtom,
} from "../../../../../../../../contexts/generalStore/store";
import {
  IconWrapper,
  SelectTitleWrapper,
  SelectWrapper,
  UnderLineText,
} from "../../history-styled";

const StatusSelectInput = () => {
  const setSelectedStatus = useSetAtom(selectedStatusAtom);
  const setStartDate = useSetAtom(adminHistoryStartDate);
  const setEndDate = useSetAtom(adminHistoryEndDate);
  const setRole = useSetAtom(selectedRoleAtom);
  const setLastEvaluatedKey = useSetAtom(continueTokenAtom);
  const searchType = useAtomValue(selectedSearchTypeAtom);
  const selectedTeam = useAtomValue(selectedTeamForMembersRequestsAtom);
  const selectedStatus = useAtomValue(selectedStatusAtom);
  const selectedMember = useAtomValue(selectedMemberAtom);

  const handleOnChange = (value) => {
    setSelectedStatus(value);
    setStartDate();
    setEndDate();
    setRole("invalid");
    setLastEvaluatedKey("");
  };

  const handleReset = () => {
    setSelectedStatus("invalid");
    setStartDate();
    setEndDate();
    setRole("invalid");
    setLastEvaluatedKey("");
  };

  const handleDisable = () => {
    if (searchType === "teamSpecific" && selectedTeam !== "invalid") {
      return false;
    }
    if (
      searchType === "userSpecific" &&
      selectedTeam !== "invalid" &&
      selectedMember !== "invalid"
    ) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <SelectTitleWrapper>
        <UnderLineText>Select Status</UnderLineText>
        <SelectWrapper>
          <Select
            aria-label="Default select example"
            className="customSelect"
            style={{
              width: "100%",
            }}
            defaultValue={"invalid"}
            onChange={handleOnChange}
            value={selectedStatus}
            disabled={handleDisable()}
          >
            <Select.Option value="invalid">-- Select status --</Select.Option>
            <>
              <Select.Option value="pending">Pending</Select.Option>
              <Select.Option value="approved">Approved</Select.Option>
              <Select.Option value="modification">Modification</Select.Option>
              <Select.Option value="rejected">Rejected</Select.Option>
              <Select.Option value="canceled">Canceled</Select.Option>
            </>
          </Select>
          <IconWrapper>
            <CloseCircleFilled
              className={
                selectedStatus && selectedStatus !== "invalid"
                  ? "icon"
                  : "icon-disabled"
              }
              onClick={() => handleReset()}
            />
          </IconWrapper>
        </SelectWrapper>
      </SelectTitleWrapper>
    </div>
  );
};

export default StatusSelectInput;
