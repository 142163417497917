import React, { useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { Modal } from "antd";

import { defaultSelectionInTeamAssign } from "../../../../../../../../../../util/constants/modalSelectionDefaults";
import { handleAssignToTeam } from "../../../../../../../../../../core/team/teamAssignment/assignToTeamHandler";
import {
  authUserAtom,
  selectedGroupAtom,
  selectedTeamForAssignAtom,
  unassignedListAtom,
} from "../../../../../../../../../../contexts/generalStore/store";
import AssignMemberForm from "./components/AssignMemberForm";

const AssignMember = ({ open, setOpen, user }) => {

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const authUser = useAtomValue(authUserAtom);

  const [selectedTeamForAssign, setSelectedTeamForAssign] = useAtom(
    selectedTeamForAssignAtom
  );
  const [selectedGroupForAssign, setSelectedGroupForAssign] =
    useAtom(selectedGroupAtom);

  const setUnassignedList = useSetAtom(unassignedListAtom);

  const removeEmployeeFromList = (email) => {
    setUnassignedList((oldValues) => {
      return oldValues.filter((oldEmployee) => oldEmployee.email !== email);
    });
  };

  const getStateArguments = () => {
    return {
      authUser: authUser,
      employee: user,
      selectedDate: selectedDate,
      selectedTeam: selectedTeamForAssign,
      selectedGroup: selectedGroupForAssign,
      setIsLoading: setIsLoading,
      removeEmployeeFromList: removeEmployeeFromList,
      handleModalClose: handleModalClose,
      setSelectedTeam: setSelectedTeamForAssign,
      setSelectedGroup: setSelectedGroupForAssign,
    };
  };

  const handleSubmit = () => {
    const args = getStateArguments();
    handleAssignToTeam(args);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e);
  };

  const handleModalClose = () => {
    setOpen(false);
    setSelectedTeamForAssign(
      defaultSelectionInTeamAssign.DEFAULT_TEAM_SELECTION
    );
    setSelectedGroupForAssign(
      defaultSelectionInTeamAssign.DEFAULT_ASSIGN_GROUP
    );
    setSelectedDate(new Date());
  };

  const validateTeamSelect = () => {
    if (
      !selectedTeamForAssign ||
      selectedTeamForAssign ===
        defaultSelectionInTeamAssign.DEFAULT_TEAM_SELECTION
    ) {
      return Promise.reject(new Error("Select a team"));
    }
    return Promise.resolve();
  };

  return (
    <div>
      <Modal
        title="Assign User"
        open={open}
        onCancel={handleModalClose}
        okButtonProps={{
          style: { display: "none" },
        }}
        cancelButtonProps={{
          style: { display: "none" },
        }}
        confirmLoading={isLoading}
        mask={true}
        maskClosable={false}
      >
        <AssignMemberForm
          user={user}
          handleSubmit={handleSubmit}
          handleDateChange={handleDateChange}
          selectedDate={selectedDate}
          validateTeamSelect={validateTeamSelect}
          isLoading={isLoading}
        />
      </Modal>
    </div>
  );
};

export default AssignMember;
