import React from "react";
import { ShimmerDiv } from "../../common/Common-styled";

const AdministrationSkeleton = () => {
  return (
    <div className="page-container">
      <div className="flex-container">
        <ShimmerDiv height="140px" width="175px" />

        <ShimmerDiv height="140px" width="175px" />

        <ShimmerDiv height="140px" width="175px" />

        <ShimmerDiv height="140px" width="175px" />

        <ShimmerDiv height="140px" width="175px" />
      </div>
    </div>
  );
};

export default AdministrationSkeleton;
