import React, { useContext, useEffect } from "react";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import lodash from "lodash";
import { GeneralContext } from "../../../../../../../../../../contexts/general/Context";
import { CardDiv } from "../../../../TenantConfig-Styled";

const EmailList = ({
  isEdit,
  isNewConfig,
  onDisplayTenantConfig,
  setOnDisplayTenantConfig,
}) => {
  useEffect(() => {}, [onDisplayTenantConfig]);
  const handleEmailChange = (emailList) => {
    const tenantConfig = lodash.clone(onDisplayTenantConfig);
    tenantConfig.emailList = emailList;
    setOnDisplayTenantConfig(tenantConfig);
  };

  return (
    <div>
      <div className="neutral-text">Email List</div>
      <div>
        <ReactMultiEmail
          placeholder={"Emails here"}
          emails={onDisplayTenantConfig?.emailList}
          className={isEdit || isNewConfig ? "" : "multi-email-disabled"}
          onChange={handleEmailChange}
          getLabel={(email, index, removeEmail) => {
            return (
              <div key={index}>
                {!isEdit ? (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                  </div>
                ) : (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      x
                    </span>
                  </div>
                )}
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default EmailList;
