import lodash from "lodash";

import { isInArray } from "../../../../util/inArrayCheck/isInArray";
import {
  getDateForCheck,
  handleDateValueChange,
  handleIsInWeekend,
  handleIsSandwich,
} from "../helper/dateTimeHelper";

export const handleCheck = (
  takenLeaves,
  holidays,
  weekendFirst,
  weekendSecond,
  expireDate,
  dates,
  isSandwichFirstYear,
  isSandwichSecondYear,
  isBackward
) => {
  let hasLeave = true;
  let bridgeLeaveCount = 0;
  let inSandwichCount = 0;
  let prevLastLeaveDate = lodash.clone(dates.start);
  let dateForCheck = getDateForCheck(dates, isBackward);
  while (hasLeave) {
    const isSandwich = handleIsSandwich(
      isSandwichFirstYear,
      isSandwichSecondYear,
      dateForCheck,
      expireDate
    );
    const isWeekend = handleIsInWeekend(
      expireDate,
      weekendFirst,
      weekendSecond,
      dateForCheck
    );
    if (!isInArray(holidays, dateForCheck)) {
      if (!isWeekend) {
        if (isInArray(takenLeaves, dateForCheck)) {
          bridgeLeaveCount++;
          if (isSandwich) {
            bridgeLeaveCount = inSandwichCount + bridgeLeaveCount;
          }
          if (isBackward) {
            prevLastLeaveDate = lodash.clone(dateForCheck);
          }
        } else {
          hasLeave = false;
        }
      } else {
        if (isSandwich) {
          inSandwichCount++;
        }
      }
    } else {
      if (isSandwich) {
        inSandwichCount++;
      }
    }
    handleDateValueChange(dateForCheck, isBackward);
  }
  return { bridgeLeaveCount, prevLastLeaveDate };
};
