import { get } from "../../lib/fetch";
import secureLocalStorage from "react-secure-storage";

import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";

export const getTeamConfig = async () => {
  const authUser = JSON.parse(secureLocalStorage.getItem("authUser"));
  const headers = await getHeaders(authUser);
  const url = `${endpoints.TEAM_CONFIG}`;
  const response = await get({ url, headers });
  return response;
};
