import React, { useState, useEffect } from "react";


const MessageContainer = ({ messages }) => {
  const [messageList, setMessageList] = useState([]);

  useEffect(() => {
    setMessageList(messages);
  }, [messages]);

  return (
    <>
      {messageList.length > 0 && (
        <div className="cml-card">
          <div className="p-40 ta-c">
            <h1>{messageList[0]}</h1>
            {messageList.map((item, index) => (
              <div key={index} className="mt-10">
                {index !== 0 && <h5>{item}</h5>}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default MessageContainer;
