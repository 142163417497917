import { put } from "../../lib/fetch";
import { endpoints } from "../../util/url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";

export const unassignEmployeeFromTeam = async (authUser, payload) => {
  const url = endpoints.UNASSIGNED_EMPLOYEE_LIST;
  const headers = await getHeaders(authUser);

  await put({ url, payload, headers });
};
