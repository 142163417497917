import React, { useEffect } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import lodash from "lodash";

const StartYear = ({
  isEdit,
  isNewConfig,
  onDisplayTenantConfig,
  setOnDisplayTenantConfig,
}) => {
  useEffect(() => {}, [onDisplayTenantConfig]);

  const getStartYear = () => {
    return dayjs(onDisplayTenantConfig?.startYear);
  };

  const handleDateChange = (date) => {
    if (!date?.$d) {
      return;
    }
    const isoDate = new Date(date.$d);
    const tenantConfig = lodash.clone(onDisplayTenantConfig);
    tenantConfig.startYear = isoDate.toISOString();
    setOnDisplayTenantConfig(tenantConfig);
  };

  return (
    <div>
      <div className="neutral-text">Start of Year</div>
      <DatePicker
        className="customDatePicker"
        format="YYYY-MM-DD"
        disabled={
          isEdit && !isNewConfig ? true : isNewConfig && !isEdit ? true : false
        }
        value={getStartYear()}
        onChange={handleDateChange}
        showToday={false}
        clearIcon={false}
      />
    </div>
  );
};

export default StartYear;
