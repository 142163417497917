import React, { useEffect, useState } from "react";
import { useAtomValue } from "jotai";

import { getOnBehalfLeaves } from "../../../../controller/teamLead/getOnBehalfLeaves";
import OwnRequestTable from "../../../tables/ownRequest/OwnRequestTable";
import { authUserAtom } from "../../../../contexts/generalStore/store";
import EditModal from "../../../modals/requestEditModal/EditModal";
import { TitleSearchWrapper } from "../../../tables/dynamicTable/DynamicTable-styled";
import { Input } from "antd";
import { toLocalDateString } from "../../../../util/formatter/getDateInTextFormat";

const OnBehalfRequests = ({
  startDate,
  endDate,
  isSearchedClicked,
  setIsSearchedClicked,
}) => {
  const [userLeaveData, setUserLeaveData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState();
  const [inProgress, setInProgress] = useState(true);
  const [paginationToken, setPaginationToken] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isAllRequestFetched, setIsAllRequestFetched] = useState(true);
  const [inProgressEdit, setInProgressEdit] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  const authUser = useAtomValue(authUserAtom);

  const callOnBehalfLeaveRequestApi = async (token = "") => {
    try {
      const response = await getOnBehalfLeaves(authUser);
      setPaginationToken(
        response?.data?.ContinuationToken ? response.data.ContinuationToken : ""
      );
      setIsAllRequestFetched(response?.data?.ContinuationToken ? false : true);
      if (response?.data?.Items) {
        return response?.data?.Items;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };
  const loadMoreLeaves = async () => {
    setInProgress(true);
    if (isAllRequestFetched) return;

    const leaves = await callOnBehalfLeaveRequestApi(paginationToken);
    setUserLeaveData([...userLeaveData, ...leaves]);
    setInProgress(false);
  };
  const getLeaveList = async () => {
    setPaginationToken("");
    setInProgress(true);
    const leaves = await callOnBehalfLeaveRequestApi();
    setUserLeaveData([...leaves]);
    setInProgress(false);
  };
  const handleEdit = (leaveRequest, event) => {
    setSelectedLeaveRequest(leaveRequest);
    setShowModal(true);
    event.stopPropagation();
  };
  const actions = {
    handleEdit: handleEdit,
  };
  const updateLeaveRequest = (duration, leaveRequest) => {
    const newRequestList = userLeaveData.filter(function (request) {
      return request.reqId !== leaveRequest?.reqId;
    });
    setUserLeaveData(newRequestList);
  };
  const handleTableSearch = (value) => {
    setSearchText(value);
  };

  useEffect(() => {
    if (startDate && endDate && isSearchedClicked && hasLoaded) {
      getLeaveList();
    }
    return () => {
      setIsSearchedClicked(false);
    };
  }, [startDate, endDate]);
  useEffect(() => {
    if (startDate && endDate && !hasLoaded && !isSearchedClicked) {
      getLeaveList();
      setHasLoaded(true);
    }
  }, [startDate, endDate, hasLoaded]);

  return (
    <div>
      <div className="cml-card">
        {userLeaveData.length > 0 && (
          <TitleSearchWrapper>
            <Input.Search
              placeholder="Start typing to search"
              onChange={(e) => handleTableSearch(e.target.value)}
              style={{
                width: window.screen.width > 500 ? "300px" : "100%",
              }}
              enterButton
            />
            <p>
              <span style={{ marginRight: "10px" }}>Results shown from</span>
              <span>
                {`${toLocalDateString(
                  startDate.toISOString()
                )} - ${toLocalDateString(endDate.toISOString())}`}
              </span>
            </p>
          </TitleSearchWrapper>
        )}

        <OwnRequestTable
          userLeaveData={userLeaveData}
          actions={actions}
          inProgress={inProgress}
          loadMoreLeaves={loadMoreLeaves}
          isAllRequestFetched={isAllRequestFetched}
          searchText={searchText}
        />
      </div>
      {showModal && (
        <EditModal
          open={showModal}
          setOpen={setShowModal}
          confirmLoading={inProgressEdit}
          setConfirmLoading={setInProgressEdit}
          leaveRequest={selectedLeaveRequest}
          userLeaveData={userLeaveData}
          setUserLeaveData={setUserLeaveData}
          updateLeaveRequest={updateLeaveRequest}
          fromOnBehalf={true}
        />
      )}
    </div>
  );
};

export default OnBehalfRequests;
