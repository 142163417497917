import lodash from "lodash";

import { filterLeavesByDateRange } from "../filterTakenLeaves/filterTakenLeaves";
import { handleCheck } from "./handleCheck/handleCheck";

export const bridgeLeaveChecker = (
  takenLeaves,
  holidays,
  weekendFirst,
  weekendSecond,
  dates,
  expireDate,
  tenantConfig,
  fromEdit,
  startDateBeforeEdit,
  endDateBeforeEdit
) => {
  let takenLeaveDatesCopy = lodash.clone(takenLeaves);
  if (fromEdit) {
    takenLeaveDatesCopy = filterLeavesByDateRange(
      takenLeaves,
      startDateBeforeEdit,
      endDateBeforeEdit
    );
  }
  const isSandwichFirstYear = tenantConfig[0]?.leavePolicy?.isSandwichLeave;
  const isSandwichSecondYear = tenantConfig[1]?.leavePolicy?.isSandwichLeave;
  let bridgeLeave = 0;
  let prevLeaveDate = new Date(dates.start);
  const isBackward = true;
  const isForward = false;
  const bridgeLeavePrev = handleCheck(
    takenLeaveDatesCopy,
    holidays,
    weekendFirst,
    weekendSecond,
    expireDate,
    dates,
    isSandwichFirstYear,
    isSandwichSecondYear,
    isBackward
  );
  const bridgeLeaveForward = handleCheck(
    takenLeaveDatesCopy,
    holidays,
    weekendFirst,
    weekendSecond,
    expireDate,
    dates,
    isSandwichFirstYear,
    isSandwichSecondYear,
    isForward
  );
  bridgeLeave =
    bridgeLeavePrev.bridgeLeaveCount + bridgeLeaveForward.bridgeLeaveCount;
  prevLeaveDate = bridgeLeavePrev.prevLastLeaveDate;
  return { bridgeLeave, prevLeaveDate };
};
