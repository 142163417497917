import {Form, InputNumber} from "antd";
import React from "react";

const OvertimeInput = ({ initialHour, flag, handleHoursFunc, duration }) => {
    if (!flag) {
        return  <Form.Item>
            <div>
                <div className="neutral-text">Duration: </div>
                <div>{duration} day</div>
            </div>
        </Form.Item>;
    }

    return (
        <Form.Item
            label={<div className="neutral-text">Hours</div>}
            name="hours"
        >
            <InputNumber
                size="large"
                min={0}
                max={24}
                step={0.5}
                defaultValue={initialHour}
                onChange={handleHoursFunc} />
        </Form.Item>
    );
};

export default OvertimeInput;
