import React, { useState } from "react";
import { useAtom, useAtomValue } from "jotai";

import OvertimeActionModal from "./actionModal/OvertimeActionModal";
import { spawnErrorToast, spawnSuccessToast } from "../../../../../util/toast/spawnToast";
import { cancelOvertime } from "../../../../../controller/common/cancelOvertime";
import { authUserAtom, groupedOvertimeItemsAtom } from "../../../../../contexts/generalStore/store";
import { reCalculateOvertimeOverviewWhenItemRemoved } from "../../../../../util/transformers/overtimeGroupItems";

const CancelExtraLeaveBalance = ({
  isModalOpen,
  onModalClose,
  tableRow,
  updateBalanceUponOvertime
}) => {
  const [inProgress, setInProgress] = useState(false);
  const [groupedItems, setGroupedItems] = useAtom(groupedOvertimeItemsAtom);

  const authUser = useAtomValue(authUserAtom);

  const updateTableData = (changedLeaveReq) => {
    const updatedList = reCalculateOvertimeOverviewWhenItemRemoved(groupedItems, changedLeaveReq.reqId)
    setGroupedItems(updatedList);
  };

  const handleSubmit = async (comment) => {
    const payload = {
      workDate: tableRow?.details?.workDate,
      action: "CANCEL",
      reqId: tableRow?.reqId,
      comment: comment
    };
    try {
      setInProgress(true);
      await cancelOvertime(authUser, payload);
      if (tableRow?.status === "approved") {
        updateBalanceUponOvertime(tableRow?.details?.configFlag);
      }
      updateTableData(payload);
      spawnSuccessToast("Application canceled successfully");
      onModalClose();
    } catch (err) {
      spawnErrorToast("Couldn't delete overtime request at this moment");
    } finally {
      setInProgress(false);
    }
  };

  return (
    <>
      <OvertimeActionModal
        isModalOpen={isModalOpen}
        onModalClose={onModalClose}
        tableRow={tableRow}
        title={"Cancel overtime request"}
        inProgress={inProgress}
        confirmButtonLabel={"Cancel"}
        handleSubmit={handleSubmit}
        confirmAction={"cancel"}
      />
    </>
  );
};

export default CancelExtraLeaveBalance;
