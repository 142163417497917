import { useEffect, useContext, useState } from "react";
import { Amplify, Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { Global } from "@emotion/react";
import { useSetAtom } from "jotai";
import secureLocalStorage from "react-secure-storage";
import "@aws-amplify/ui-react/styles.css";
import "./App.css";

import amplifyConfig from "./amplifyConfigOverrides";
import { route } from "./constant";
import GeneralState from "./contexts/general/State";
import { GeneralContext } from "./contexts/general/Context";
import { getGlobalStyles } from "./util/theme/globalStyles";
import { endpointsAtom, isInMobileAtom } from "./contexts/generalStore/store";
import { retrieveAPIEndpoints } from "./util/url/retrieveApiEndpoints";

import SecureRoute from "./pages/secureRoute/SecureRoute";
import Login from "./pages/auth/Login";
import { RequireAuth } from "./pages/auth/RequireAuth";
import Error from "./pages/errorPage/Error";
import PublicShare from "./pages/publicShare/PublicShare";
import Loading from "./pages/LoadingPage/Loading";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";

Amplify.configure(amplifyConfig);
Auth.configure({ storage: secureLocalStorage });

function EmpalRoutes(currentURL) {
  const setEndpoints = useSetAtom(endpointsAtom);
  const { currentTheme } = useContext(GeneralContext);
  const GlobalStyles = getGlobalStyles(currentTheme);

  const getStateArgument = () => {
    return { setEndpoints };
  };

  useEffect(() => {
    const args = getStateArgument();
    retrieveAPIEndpoints(args);
  }, []);

  return (
    <ThemeProvider theme={currentTheme}>
      <Global styles={GlobalStyles} />
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<SecureRoute />} />
          <Route exact path="/public" element={<PublicShare />} />
          <Route exact path="/policy" element={<PrivacyPolicy />} />
          <Route
            exact
            path={route.LOGIN}
            element={<Login currentURL={currentURL?.currentURL} />}
          />
          <Route
            exact
            path={route.DASHBOARD}
            element={
              <RequireAuth>
                <SecureRoute />
              </RequireAuth>
            }
          >
            <Route path={route.SETTINGS} element={<SecureRoute />} />
            <Route path={route.POLICY} element={<SecureRoute />} />
          </Route>
          <Route exact path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

function App(currentURL) {
  const [isLoading, setIsLoading] = useState(true);
  const setIsInMobile = useSetAtom(isInMobileAtom);

  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);

  useEffect(() => {
    setIsLoading(false);
    setIsInMobile(isMobileDevice);
  }, []);
  return (
    <Authenticator.Provider>
      <GeneralState>
        {isLoading ? (
          <Loading />
        ) : (
          <EmpalRoutes currentURL={currentURL?.currentURL} />
        )}
      </GeneralState>
    </Authenticator.Provider>
  );
}

export default App;
