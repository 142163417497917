import React from "react";
import UserList from "../administration/components/people/component/userList/UserList";

/**
 * @deprecated Use <UserList /> Instead
 */
const TeamMembers = () => {
  return (
    <div>
      <div>
        <UserList />
      </div>
    </div>
  );
};

export default TeamMembers;
