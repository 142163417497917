export const removeRequest = (
  userLeaveData,
  leaveRequest,
  setUserLeaveData
) => {
  const newRequestList = userLeaveData.filter(function (request) {
    return request.reqId !== leaveRequest?.reqId;
  });
  setUserLeaveData(newRequestList);
};
