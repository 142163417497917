import React, { useState } from "react";
import { Modal } from "antd";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import {
  authUserAtom,
  selectedSuperAdminAtom,
  showModalsAtom,
  superAdminsAtom,
} from "../../../../../../contexts/generalStore/store";
import { unassignSuperAdminHandler } from "../../../../../../core/assignUnassignSuperAdmin/unassignSuperAdminHandler";
import CmlIcon from "../../../common/icon/CmlIcon";

const UnassignModal = () => {

  const [showModal, setShowModal] = useAtom(showModalsAtom);
  const [selectedSuperAdmin, setSelectedSuperAdmin] = useAtom(
    selectedSuperAdminAtom
  );

  const authUser = useAtomValue(authUserAtom);

  const setSuperAdmins = useSetAtom(superAdminsAtom);

  const [isLoading, setIsLoading] = useState(false);

  const teamSensitiveGroups = ["teamLead", "employee"];
  const teamNonSensitiveGroups = ["tenantAdmin", "unassignedEmployee"];

  const handleModalClose = () => {
    setSelectedSuperAdmin();
    setShowModal((prev) => ({ ...prev, showSuperAdminUnAssignModal: false }));
  };

  const getStateArguments = () => {
    return {
      authUser: authUser,
      selectedSuperAdmin: selectedSuperAdmin,
      teamSensitiveGroups: teamSensitiveGroups,
      teamNonSensitiveGroups: teamNonSensitiveGroups,
      setSuperAdmins: setSuperAdmins,
      handleModalClose: handleModalClose,
      setIsLoading: setIsLoading,
    };
  };

  const handleSubmit = async () => {
    const args = getStateArguments();
    unassignSuperAdminHandler(args);
  };

  return (
    <Modal
      title="Unassign super admin from tenant role"
      open={showModal.showSuperAdminUnAssignModal}
      onOk={handleSubmit}
      onCancel={handleModalClose}
      okText="Unassign"
      cancelText="Close"
      okButtonProps={{
        type: "primary",
        icon: <CmlIcon iconName={"undo"} />
      }}
      cancelButtonProps={{
        type: "default",
        icon: <CmlIcon iconName={"cancel"} />
      }}
      confirmLoading={isLoading}
      maskClosable={false}
    >
      <p>
        Are you sure you want to remove <b>{selectedSuperAdmin?.email}</b>{" "}
        from{" "}
        <b>
          {selectedSuperAdmin?.tenant
            ? selectedSuperAdmin?.tenant
            : selectedSuperAdmin?.domainName}
        </b>{" "}
        ?
      </p>
    </Modal >
  );
};

export default UnassignModal;
