import { put } from "../../lib/fetch";
import { endpoints } from "../../util//url/endpoints";
import { getHeaders } from "../../util/session/getHeaders";
import secureLocalStorage from "react-secure-storage";

export const changeStatusLeaveRequestTeamLead = async (payload) => {
  const authUser = JSON.parse(secureLocalStorage.getItem("authUser"));
  const headers = await getHeaders(authUser);
  const url = `${endpoints.TEAM_MEMBER_APPLICATION}`;

  return await put({ url, payload, headers });
};
