import React from "react";
import { useAtomValue } from "jotai";
import { Col, Row } from "antd";

import ProfileInfo from "./component/profileInfo/ProfileInfo";
import { authUserAtom } from "../../../../../../contexts/generalStore/store";
import MandatoryFields from "./component/mandatoryFields/MandatoryFields";

const ConfigSetting = () => {
  const authUser = useAtomValue(authUserAtom);

  return (
    <div className="page-container">
      <Row>
        <Col sm={24} md={10}>
          <MandatoryFields authUser={authUser} />
        </Col>
        <Col sm={24} md={10}>
          <div className="ml-30">
            <ProfileInfo authUser={authUser} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ConfigSetting;
