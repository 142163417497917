import React, { useEffect } from "react";
import { Form } from "antd";
import dayjs from "dayjs";
import lodash from "lodash";

import {
  getStandardDate,
  getTimeManipulatedDates,
} from "../../../../../core/datetime/standardDateHandler/getStandardDate";
import FormComponent from "./components/FormComponent";

const ApplyForm = ({
  startDate,
  endDate,
  leaveType,
  description,
  emailList,
  duration = "1 day",
  tenantConfig,
  teamConfig,
  updateLeaveArray,
  weekendFirst,
  weekendSecond,
  holidays,
  expireDate,
  approved,
  focused,
  pending,
  modification,
  sickLeaveMinimumDate,
  userData,
  inProgress,
  onFinish,
  handleLeaveTypeChange,
  handleStartDateChange,
  handleEndDateChange,
  handleDescription,
  setEmails,
  validateForm,
  formValidateMessage,
  ownApply,
  fromAdmin,
  showingInModal,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      leaveType: leaveType,
      description: description,
      emailList: emailList,
    });
  }, [startDate, endDate, leaveType, emailList, updateLeaveArray]);

  const validateDateRange = () => {
    const start = dayjs(startDate).toDate();
    const end = dayjs(endDate).toDate();
    if (start && end && start > end) {
      return Promise.reject("Start date must be before end date");
    }
    return Promise.resolve();
  };

  const isInArray = (array, DateValue) => {
    const dateValueStr = new Date(DateValue).toDateString();
    return array.some((date) => new Date(date).toDateString() === dateValueStr);
  };

  const isInHolidayArray = (array, DateValue) => {
    const dateValueStr = new Date(DateValue).toDateString();
    return array.some(
      (item) => new Date(item.date).toDateString() === dateValueStr
    );
  };

  const renderHolidayTooltip = (date) => {
    if (isInHolidayArray(holidays, date)) {
      const holiday = holidays.find(
        (item) =>
          new Date(item.date).toDateString() === new Date(date).toDateString()
      );
      return `${holiday.title}`;
    } else {
      return "";
    }
  };

  const handleMinDate = (pickerLabel) => {
    let minDate = lodash.clone(sickLeaveMinimumDate);
    if (pickerLabel === "endDate") {
      return new Date(startDate);
    }
    if (!tenantConfig[0]?.leavePolicy?.appliedNotice[leaveType]) {
      // For Own Leave Application
      return minDate.setDate(minDate.getDate() + 1);
    } else if (!ownApply) {
      // For On-Behalf leave application
      return minDate.setDate(minDate.getDate() + 1);
    } else {
      return new Date();
    }
  };

  const handleDisableDate = (current, pickerLabel) => {
    current = dayjs(current).toDate();
    const minDate = new Date(handleMinDate(pickerLabel));
    const maxDate = new Date(tenantConfig[tenantConfig.length - 1]?.expiredAt);
    maxDate.setDate(maxDate.getDate() - 1);
    const modifiedMin = getStandardDate(minDate, minDate);
    const modifiedCurrentDate = getStandardDate(current, current);
    if (current < expireDate[0]) {
      return (
        modifiedCurrentDate.start &&
        (modifiedCurrentDate.start < modifiedMin.start ||
          modifiedCurrentDate.start > maxDate ||
          isInHolidayArray(holidays, current) ||
          weekendFirst.includes(current.getDay()) ||
          isInArray(approved, current) ||
          isInArray(pending, current) ||
          isInArray(modification, current))
      );
    } else {
      return (
        modifiedCurrentDate.start &&
        (modifiedCurrentDate.start < modifiedMin.start ||
          modifiedCurrentDate.start > maxDate ||
          isInHolidayArray(holidays, current) ||
          weekendSecond.includes(current.getDay()) ||
          isInArray(approved, current) ||
          isInArray(pending, current) ||
          isInArray(modification, current))
      );
    }
  };

  const handleCellRender = (current) => {
    const currentDate = getTimeManipulatedDates(dayjs(current).toDate());
    if (isInArray(approved, currentDate.start)) {
      return (
        <div
          className="ant-picker-cell-inner ant-picker-cell-inner-approved"
          title="approved leave"
        >
          {current.date()}
        </div>
      );
    }
    if (isInArray(pending, currentDate.start)) {
      return (
        <div
          className="ant-picker-cell-inner ant-picker-cell-inner-pending"
          title="pending leave"
        >
          {current.date()}
        </div>
      );
    }
    if (isInArray(modification, currentDate.start)) {
      return (
        <div
          className="ant-picker-cell-inner ant-picker-cell-inner-modification"
          title="need modification"
        >
          {current.date()}
        </div>
      );
    }
    if (isInHolidayArray(holidays, currentDate.start)) {
      return (
        <div
          className="ant-picker-cell-inner ant-picker-cell-inner-holiday"
          title={renderHolidayTooltip(currentDate.start)}
        >
          {current.date()}
        </div>
      );
    }
    return (
      <div className="ant-picker-cell-inner" title="">
        {current.date()}
      </div>
    );
  };

  return (
    <>
      <FormComponent
        ownApply={ownApply}
        focused={focused}
        form={form}
        onFinish={onFinish}
        duration={duration}
        leaveType={leaveType}
        tenantConfig={tenantConfig}
        startDate={startDate}
        endDate={endDate}
        description={description}
        teamConfig={teamConfig}
        emailList={emailList}
        validateForm={validateForm}
        inProgress={inProgress}
        formValidateMessage={formValidateMessage}
        setEmails={setEmails}
        userData={userData}
        handleLeaveTypeChange={handleLeaveTypeChange}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        handleDisableDate={handleDisableDate}
        handleCellRender={handleCellRender}
        validateDateRange={validateDateRange}
        handleDescription={handleDescription}
        showingInModal={showingInModal}
      />
    </>
  );
};

export default ApplyForm;
