export const PERMISSIONS = {
  view: {
    card: {
      peopleAllowedGroups: ["tenantAdmin", "teamLead"],
      designationAllowedGroups: ["tenantAdmin"],
      historyAllowedGroups: ["tenantAdmin"],
      teamsAllowedGroups: ["tenantAdmin"],
      tenantConfig: ["tenantAdmin"],
      policyAllowedGroups: ["tenantAdmin"],
      leaveCalendarAllowedGroups: ["teamLead", "employee"],
      applyAllowedGroups: ["teamLead", "employee"],
      reportsAllowedGroups: ["tenantAdmin"],
    },
    settingsCard: {
      profileSettingsAllowedGroups: ["teamLead", "employee"],
    },
    teamConfig: {
      teamConfigAllowedGroups: ["teamLead"],
    },
    menu: {
      calenderItemAllowedGroups: ["teamLead", "employee"],
      applyItemAllowedGroups: ["teamLead", "employee"],
      ownLeaveRequestAllowedGroups: ["teamLead", "employee"],
      ownOvertimeRequestAllowedGroups: ["teamLead", "employee"],
      approveLeaveRequestAllowedGroups: ["teamLead", "tenantAdmin"],
      approveOvertimeAllowedGroups: ["teamLead", "tenantAdmin"],
      administrationAllowedGroups: ["tenantAdmin"],
      reportAllowedGroups: ["notApplied"],
      entitlementsAllowedGroups: ["teamLead", "employee"],
      tenantsListAllowedGroups: ["superAdmin"],
      tenantsUsersListAllowedGroups: ["superAdmin"],
      superAdminsListAllowedGroups: ["superAdmin"],
      unassignedUserHome: ["unassignedEmployee"],
      noTenantConfig_NoRefreshTokenAllowedGroups: ["superAdmin"],
      teamMembersTabAllowedGroups: ["teamLead"],
      settingsTabAllowedGroups: ["teamLead", "employee"],
    },
    components: {
      onBehalfTableAllowedGroups: ["teamLead"],
    },
  },
  apis: {
    teamMembersAllowedGroups: ["teamLead"],
    allMembersAllowedGroups: ["tenantAdmin"],
    unassignedMemberAllowedGroups: ["tenantAdmin"],
    assignedMemberAllowedGroups: ["tenantAdmin"],
    applyOnBehalfMembersAllowedGroups: ["teamLead"],
    applyOnBehalfLeadAllowedGroups: ["tenantAdmin"],
    applyOwnRequestAllowedGroups: ["teamLead", "employee"],
    tenantConfigAllowedGroup: ["tenantAdmin", "teamLead", "employee"],
    teamConfigAllowedGroup: ["teamLead"],
    userDetailsAllowedGroups: ["teamLead", "employee"],
    createTeamAllowedGroups: ["tenantAdmin"],
    teamBalanceAllowedGroups: ["tenantAdmin"],
    requestApprovalAllowedGroups: ["tenantAdmin", "teamLead"],
    applyOvertimeAllowedGroups: ["teamLead", "employee"],
    approveOvertimeAllowedGroups: ["teamLead", "tenantAdmin"],
    configurePolicyAllowedGroups: ["tenantAdmin"],
    fetchAllTeamsAllowedGroups: ["tenantAdmin"],
    approveLeaveRequestAllowedGroups: {
      selectTeam: ["tenantAdmin"],
      default: ["teamLead"],
    },
    bootstrapApiCallAllowedGroup: ["tenantAdmin", "employee", "teamLead"],
  },
};
