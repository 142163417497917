import { getAPIEndpoints } from "../../controller/publicEndpoint/getAPIEndpoints";

export const retrieveAPIEndpoints = async ({ setEndpoints }) => {
  const response = await getAPIEndpoints();
  if (response?.data?.data?.links) {
    localStorage.setItem(
      "endpoints",
      JSON.stringify(response?.data?.data?.links)
    );
    setEndpoints(response?.data?.data?.links);
  } else {
    localStorage.setItem("endpoints", JSON.stringify({}));
  }
};
