import { Alert, Button, DatePicker, Form, Input, Modal } from "antd";
import { useAtom, useAtomValue } from "jotai";
import { useContext, useEffect, useState } from "react";
import lodash from "lodash";
import dayjs from "dayjs";
import { SendOutlined } from "@ant-design/icons";

import {
  authUserAtom,
  tenantConfigAtom,
  userLeaveDetailsAtom,
} from "../../../contexts/generalStore/store";
import {
  getStandardDate,
  getTimeManipulatedDates,
} from "../../../core/datetime/standardDateHandler/getStandardDate";
import { bridgeLeaveChecker } from "../../../core/leaveBalance/bridgeLeaveChecker/bridgeLeaveChecker";
import { handleNoticePeriod } from "../../../core/leaveBalance/noticePeriodHandler/noticePeriodHandler";
import { dateCount } from "../../../core/datetime/dateCountHandler/dateCountHandler";
import { hasBalanceCheckerEdit } from "../../../core/leaveBalance/balanceChecker/balanceCheckerEdit";
import { overlapChecker } from "../../../core/leaveBalance/overlapChecker/overlapChecker";
import { GeneralContext } from "../../../contexts/general/Context";
import {
  getDatesInRange,
  getLeaveRequest,
} from "../../../core/leaveRequest/getUserLeaves";
import { setHolidayWeekend } from "../../../core/config/setHolidaysWeekend";
import { editOwnLeaveRequest } from "../../../controller/employee/editOwnLeaveRequest";
import {
  spawnErrorToast,
  spawnSuccessToast,
} from "../../../util/toast/spawnToast";
import { updateBalanceState } from "../../../core/leaveBalance/updateBalance/updateBalanceUponEdit";
import { filterLeavesByDateRange } from "../../../core/leaveBalance/filterTakenLeaves/filterTakenLeaves";

const EditModal = ({
  open,
  setOpen,
  confirmLoading,
  setConfirmLoading,
  leaveRequest,
  userLeaveData,
  setUserLeaveData,
  updateLeaveRequest,
  fromOnBehalf,
  datesApproved,
  datesModify,
  datesPending,
  takenLeaveDates,
  updateLeaveArray,
  setUpdateLeaveArray,
}) => {
  const [form] = Form.useForm();
  const { currentTheme } = useContext(GeneralContext);
  const tenantConfig = useAtomValue(tenantConfigAtom);
  const authUser = useAtomValue(authUserAtom);
  const [userLeaveDetails, setUserLeaveDetails] = useAtom(userLeaveDetailsAtom);

  const maxDate = new Date(
    tenantConfig[tenantConfig.length - 1]?.expiredAt
  ).setDate(
    new Date(tenantConfig[tenantConfig.length - 1]?.expiredAt).getDate() - 1
  );
  const currentYear = new Date(maxDate).getFullYear();
  const twoYearsBeforeExpirationYear = currentYear - 2;
  const twoYearsBeforeExpirationDate = new Date(maxDate).setFullYear(
    twoYearsBeforeExpirationYear
  );
  const sickLeaveMinDate = new Date(twoYearsBeforeExpirationDate);

  const [employeeEmail, setEmployeeEmail] = useState("");
  const [fromWhom, setFromWhom] = useState("");
  const [leaveType, setLeaveType] = useState();
  const [description, setDescription] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDateBeforeEdit, setEndDateBeforeEdit] = useState();
  const [startDateBeforeEdit, setStartDateBeforeEdit] = useState();
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState();
  const [formValidateMessage, setFormValidateMessage] = useState();
  const [inProgress, setInProgress] = useState(false);
  const [duration, setDuration] = useState("1 day");
  const [firstYearConsumedDays, setFirstYearConsumedDays] = useState(
    leaveRequest?.details?.firstConfigDuration
  );
  const [firstConfigDuration, setfirstConfigDuration] = useState();
  const [secondConfigDuration, setSecondConfigDuration] = useState();
  const [secondYearConsumedDays, setSecondYearConsumedDays] = useState(
    leaveRequest?.details?.secondConfigDuration
  );
  const [validateForm, setValidateForm] = useState(true);
  const [durationBeforeEdit, setDurationBeforeEdit] = useState();
  const [sickLeaveMinimumDate, setSickLeaveMinimumDate] =
    useState(sickLeaveMinDate);
  const [weekendFirst, setWeekendFirst] = useState([]);
  const [weekendSecond, setWeekendSecond] = useState([]);
  const [holidays, setHoliday] = useState([]);
  const [expireDate, setExpireDate] = useState([]);
  const [excludedDates, setExcludedDates] = useState([]);
  const [ownApply, setOwnApply] = useState(true);
  const [isOverLapped, setIsOverLapped] = useState(false);
  const fromEdit = true;

  const clearLeaveArrays = () => {
    while (datesApproved.length) {
      datesApproved.pop();
    }
    while (datesModify.length) {
      datesModify.pop();
    }
    while (datesPending.length) {
      datesPending.pop();
    }
    while (takenLeaveDates.length) {
      takenLeaveDates.pop();
    }
  };

  const handleClose = () => {
    setIsOverLapped(false);
    setOpen(false);
    setFormValidateMessage();
    setValidateForm(true);
    if (!fromOnBehalf) clearLeaveArrays();
    if (leaveRequest.details?.duration > 1) {
      setDuration(`${leaveRequest.details?.duration} days`);
    } else {
      setDuration(`${leaveRequest.details?.duration} day`);
    }
    if (!fromOnBehalf) setUpdateLeaveArray(false);
  };

  useEffect(() => {
    if (leaveRequest?.details?.configFlag === "overlap") {
      setFirstYearConsumedDays(leaveRequest?.details?.firstConfigDuration);
      setSecondYearConsumedDays(leaveRequest?.details?.secondConfigDuration);
    } else if (leaveRequest?.details?.configFlag === "first") {
      setFirstYearConsumedDays(leaveRequest?.details?.duration);
      setSecondYearConsumedDays(0);
    } else {
      setFirstYearConsumedDays(0);
      setSecondYearConsumedDays(leaveRequest?.details?.duration);
    }

    setHolidayWeekend(
      tenantConfig,
      expireDate,
      weekendFirst,
      weekendSecond,
      setHoliday
    );
  }, []);

  useEffect(() => {
    if (open === true && Object.keys(leaveRequest).length > 0) {
      form.setFieldsValue({
        startDate: dayjs(leaveRequest?.details?.startDate),
        endDate: dayjs(leaveRequest?.details?.endDate),
        description: leaveRequest?.details?.description,
      });

      if (updateLeaveArray === false) {
        getLeaveRequest(
          tenantConfig,
          authUser,
          datesApproved,
          datesModify,
          datesPending,
          excludedDates,
          takenLeaveDates,
          fromEdit,
          setUpdateLeaveArray
        );
      }

      const leaveStart = new Date(leaveRequest?.details?.startDate);
      const leaveEnd = new Date(leaveRequest?.details?.endDate);
      setDurationBeforeEdit(leaveRequest?.details?.duration);
      setSelectedLeaveRequest(leaveRequest);
      setEmployeeEmail(leaveRequest.employeeEmail);
      setLeaveType(leaveRequest?.details?.leaveType);
      setDescription(leaveRequest?.details?.description);
      setFromWhom(leaveRequest.fromWhom);
      setStartDate(leaveStart);
      setEndDate(leaveEnd);
      setStartDateBeforeEdit(leaveStart);
      setEndDateBeforeEdit(leaveEnd);
      if (leaveRequest?.details?.configFlag === "overlap") {
        setFirstYearConsumedDays(leaveRequest?.details?.firstConfigDuration);
        setSecondYearConsumedDays(leaveRequest?.details?.secondConfigDuration);
        setfirstConfigDuration(leaveRequest?.details?.firstConfigDuration);
        setSecondConfigDuration(leaveRequest?.details?.secondConfigDuration);
      } else if (leaveRequest?.details?.configFlag === "first") {
        setfirstConfigDuration(leaveRequest?.details?.duration);
        setFirstYearConsumedDays(leaveRequest?.details?.duration);
        setSecondYearConsumedDays(0);
        setSecondConfigDuration(0);
      } else {
        setfirstConfigDuration(0);
        setFirstYearConsumedDays(0);
        setSecondYearConsumedDays(leaveRequest?.details?.duration);
        setSecondConfigDuration(leaveRequest?.details?.duration);
      }

      if (leaveRequest?.details?.duration > 1) {
        setDuration(`${leaveRequest?.details?.duration} days`);
      } else {
        setDuration(`${leaveRequest?.details?.duration} day`);
      }
    }
  }, [open]);

  useEffect(() => {
    form.setFieldsValue({
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      description: description,
    });
  }, [startDate, endDate, description]);

  const callApi = async (
    leaveRequest,
    firstYearDurationBeforeEdit,
    secondYearDurationBeforeEdit
  ) => {
    setInProgress(true);
    setConfirmLoading(true);
    try {
      await editOwnLeaveRequest(authUser, leaveRequest);
      if (!fromOnBehalf) {
        updateBalanceState(
          expireDate,
          startDateBeforeEdit,
          endDateBeforeEdit,
          leaveRequest.leaveType,
          parseInt(duration.split(" ")[0]),
          parseInt(durationBeforeEdit),
          parseInt(firstYearDurationBeforeEdit),
          parseInt(firstYearConsumedDays),
          parseInt(secondYearDurationBeforeEdit),
          parseInt(secondYearConsumedDays),
          userLeaveDetails,
          setUserLeaveDetails
        );
      }
      updateLeaveRequest(duration.split(" ")[0], leaveRequest);
      spawnSuccessToast("Leave request updated successfully!");
      handleClose();
    } catch (error) {
      spawnErrorToast(error.response?.data);
    } finally {
      setInProgress(false);
      setConfirmLoading(true);
    }
  };

  const generatePayload = () => {
    const dates = getTimeManipulatedDates(startDate, endDate);
    const leaveRequest = {
      employeeEmail: employeeEmail,
      startDate: dates.start,
      endDate: dates.end,
      leaveType: leaveType,
      description: description,
      creationDate: selectedLeaveRequest?.creationDate,
      reqId: selectedLeaveRequest.reqId,
      firstConfigDuration: firstYearConsumedDays,
      secondConfigDuration: secondYearConsumedDays,
      fromWhom: fromWhom,
    };
    return leaveRequest;
  };

  const handleSave = async () => {
    const leaveRequest = generatePayload();
    const firstYearDurationBeforeEdit = firstConfigDuration;
    const secondYearDurationBeforeEdit = secondConfigDuration;
    if (description.trim().length === 0) {
      setValidateForm(false);
      setFormValidateMessage("Description is required");
      setInProgress(false);
      return;
    } else {
      if (!fromOnBehalf) {
        const date = {
          start: startDate,
          end: endDate,
        };
        const bridgeLeave = bridgeLeaveChecker(
          takenLeaveDates,
          holidays,
          weekendFirst,
          weekendSecond,
          date,
          expireDate,
          tenantConfig,
          fromEdit,
          startDateBeforeEdit,
          endDateBeforeEdit
        );
        handleNoticePeriod(
          startDate,
          endDate,
          getStandardDate,
          calculateConsumedDays,
          tenantConfig,
          expireDate,
          setFormValidateMessage,
          setValidateForm,
          callApi,
          leaveRequest,
          leaveType,
          bridgeLeave?.prevLeaveDate,
          bridgeLeave?.bridgeLeave,
          firstYearDurationBeforeEdit,
          secondYearDurationBeforeEdit,
          fromEdit
        );
      } else {
        callApi(
          leaveRequest,
          firstYearDurationBeforeEdit,
          secondYearDurationBeforeEdit
        );
      }
    }
  };

  const calculateConsumedDays = (start, end) => {
    const startDate = start;
    const endDate = end;
    const consumedDays = dateCount(
      startDate,
      endDate,
      tenantConfig,
      getStandardDate,
      holidays,
      expireDate,
      setDuration,
      weekendFirst,
      weekendSecond
    );
    const firstYearWorkingDays = consumedDays.firstYearWorkingDays;
    const secondYearWorkingDays = consumedDays.secondYearWorkingDays;
    const duration = consumedDays.duration;
    setFirstYearConsumedDays(firstYearWorkingDays);
    setSecondYearConsumedDays(secondYearWorkingDays);
    return { firstYearWorkingDays, secondYearWorkingDays, duration };
  };

  const hasBalanceChecker = (start, end) => {
    return hasBalanceCheckerEdit(
      start,
      end,
      leaveRequest,
      expireDate,
      calculateConsumedDays,
      tenantConfig,
      durationBeforeEdit,
      userLeaveDetails
    );
  };

  const dateChecker = (endDate, startDate) => {
    const date = getStandardDate(endDate, startDate);
    const isOverlapping = overlapChecker(
      takenLeaveDates,
      date,
      fromEdit,
      startDateBeforeEdit,
      endDateBeforeEdit
    );
    const hasBalance = hasBalanceChecker(date.start, date.end);
    if (isOverlapping && !fromOnBehalf) {
      setValidateForm(false);
      setIsOverLapped(true);
      setFormValidateMessage("Leave request is overlapping");
      return;
    }
    if (!hasBalance && !fromOnBehalf) {
      setValidateForm(false);
      setFormValidateMessage(
        `Not enough leave balance for "${lodash.startCase(
          leaveRequest?.details?.leaveType
        )}"`
      );
      return;
    }
    setValidateForm(true);
    setIsOverLapped(false);
  };

  const handleStartDateChange = (start) => {
    setValidateForm(true);
    setFormValidateMessage("");
    setStartDate(dayjs(start).toDate());
    handleStartDateChangeEffect(dayjs(start).toDate());
  };

  const handleStartDateChangeEffect = (start) => {
    setEndDate(dayjs(start).toDate());
    dateChecker(dayjs(start).toDate(), dayjs(start).toDate());
  };

  const handleEndDateChange = (end) => {
    setValidateForm(true);
    setFormValidateMessage("");
    setEndDate(dayjs(end).toDate());
    dateChecker(dayjs(end).toDate(), startDate);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleMinDate = () => {
    let minDate = lodash.clone(sickLeaveMinimumDate);
    if (!tenantConfig[0]?.leavePolicy?.appliedNotice[leaveType]) {
      // For Own Leave Application
      return minDate.setDate(minDate.getDate() + 1);
    } else if (fromOnBehalf) {
      // For On-Behalf leave application
      return minDate.setDate(minDate.getDate() + 1);
    } else {
      return new Date();
    }
  };

  const isInArray = (array, DateValue) => {
    const dateValueStr = new Date(DateValue).toDateString();
    if (array?.length > 0) {
      return array.some(
        (date) => new Date(date).toDateString() === dateValueStr
      );
    }
  };

  const isInHolidayArray = (array, DateValue) => {
    const dateValueStr = new Date(DateValue).toDateString();
    return array.some(
      (item) => new Date(item.date).toDateString() === dateValueStr
    );
  };

  const renderHolidayTooltip = (date) => {
    if (isInHolidayArray(holidays, date)) {
      const holiday = holidays.find(
        (item) =>
          new Date(item.date).toDateString() === new Date(date).toDateString()
      );
      return `${holiday.title}`;
    } else {
      return "";
    }
  };

  const handleDisableDate = (current, pickerLabel) => {
    let takenLeaveDatesCopy = lodash.clone(takenLeaveDates);
    if (fromEdit && !fromOnBehalf) {
      takenLeaveDatesCopy = filterLeavesByDateRange(
        takenLeaveDates,
        startDateBeforeEdit,
        endDateBeforeEdit
      );
    }
    current = dayjs(current).toDate();
    const minDate = new Date(handleMinDate(pickerLabel));
    const maxDate = new Date(tenantConfig[1]?.expiredAt);
    maxDate.setDate(maxDate.getDate() - 1);
    const modifiedMin = getStandardDate(minDate, minDate);
    const modifiedCurrentDate = getStandardDate(current, current);
    if (current < expireDate[0]) {
      return (
        modifiedCurrentDate.start &&
        (modifiedCurrentDate.start < modifiedMin.start ||
          modifiedCurrentDate.start > maxDate ||
          isInHolidayArray(holidays, current) ||
          weekendFirst.includes(current.getDay()) ||
          isInArray(takenLeaveDatesCopy, current))
      );
    } else {
      return (
        modifiedCurrentDate.start &&
        (modifiedCurrentDate.start < modifiedMin.start ||
          modifiedCurrentDate.start > maxDate ||
          isInHolidayArray(holidays, current) ||
          weekendSecond.includes(current.getDay()) ||
          isInArray(takenLeaveDatesCopy, current))
      );
    }
  };

  const handleCellRender = (current) => {
    const currentDate = getTimeManipulatedDates(dayjs(current).toDate());
    if (isInArray(datesApproved, currentDate.start)) {
      return (
        <div
          className="ant-picker-cell-inner ant-picker-cell-inner-approved"
          title="approved leave"
        >
          {current.date()}
        </div>
      );
    }
    if (isInArray(datesPending, currentDate.start)) {
      return (
        <div
          className="ant-picker-cell-inner ant-picker-cell-inner-pending"
          title="pending leave"
        >
          {current.date()}
        </div>
      );
    }
    if (isInArray(datesModify, currentDate.start)) {
      return (
        <div
          className="ant-picker-cell-inner ant-picker-cell-inner-modification"
          title="need modification"
        >
          {current.date()}
        </div>
      );
    }
    if (isInHolidayArray(holidays, currentDate.start)) {
      return (
        <div
          className="ant-picker-cell-inner ant-picker-cell-inner-holiday"
          title={renderHolidayTooltip(currentDate.start)}
        >
          {current.date()}
        </div>
      );
    }
    return (
      <div className="ant-picker-cell-inner" title="">
        {current.date()}
      </div>
    );
  };

  return (
    <>
      <Modal
        title="Edit Leave Request"
        open={open}
        onCancel={handleClose}
        okButtonProps={{
          style: { display: "none" },
        }}
        cancelButtonProps={{
          style: { display: "none" },
        }}
        confirmLoading={confirmLoading}
        mask={true}
        maskClosable={false}
      >
        <Form
          form={form}
          onFinish={handleSave}
          layout="vertical"
        >
          <Form.Item>
            <div>
              <div className="neutral-text">Duration:</div>
              <div>{duration}</div>
            </div>
          </Form.Item>

          <Form.Item
            label={<div className="neutral-text">Start Date</div>}
            rules={[{ required: true, message: "Please select start date" }]}
            hasFeedback={true}
            name="startDate"
          >
            <DatePicker
              style={{ width: "100%" }}
              onChange={handleStartDateChange}
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              value={dayjs(startDate)}
              showToday={false}
              disabledDate={(current) =>
                handleDisableDate(current.toDate(), "startDate")
              }
              cellRender={(current) => handleCellRender(current)}
              allowClear={false}
              inputReadOnly={true}
              disabled={!updateLeaveArray && !fromOnBehalf ? true : false}
            />
          </Form.Item>

          <Form.Item
            label={<div className="neutral-text">End Date</div>}
            name={"endDate"}
            rules={[{ required: true, message: "Please select end date" }]}
            hasFeedback={true}
          >
            <DatePicker
              style={{ width: "100%" }}
              onChange={handleEndDateChange}
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              value={dayjs(endDate)}
              showToday={false}
              disabledDate={(current) =>
                handleDisableDate(current.toDate(), "endDate")
              }
              cellRender={(current) => handleCellRender(current)}
              allowClear={false}
              inputReadOnly={true}
              disabled={!updateLeaveArray && !fromOnBehalf ? true : false}
            />
          </Form.Item>

          <Form.Item
            label={<div className="neutral-text">Description</div>}
            name="description"
            rules={[
              { required: true, message: "Please enter a description" },
              {
                max: 200,
                message: "Description must not be larger than 200 characters",
              },
            ]}
          >
            <Input.TextArea
              value={description}
              onChange={(e) => handleDescription(e)}
              autoSize={{ minRows: 3, maxRows: 5 }}
              showCount
              maxLength={200}
            />
          </Form.Item>

          <Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "24px",
              }}
            >
              {(!validateForm || isOverLapped) && (
                <Alert
                  className="apply-alert"
                  message="Warning"
                  description={formValidateMessage}
                  type="warning"
                  showIcon
                />
              )}
            </div>
            <div className="right-aligned-buttons mt-30">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={inProgress}
                  icon={<SendOutlined />}
                  disabled={validateForm ? false : true}
                >
                  Submit
                </Button>
              </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default EditModal;
