import lodash from "lodash";
import { getConvertedDate } from "../../datetime/standardDateHandler/getStandardDate";

export const updateBalanceState = (
  expireDate,
  startDateBeforeEdit,
  endDateBeforeEdit,
  type,
  duration,
  durationBeforeEdit,
  firstYearDurationBeforeEdit,
  firstYearConsumedDays,
  secondYearDurationBeforeEdit,
  secondYearConsumedDays,
  userLeaveDetails,
  setUserLeaveDetails
) => {
  const startDate = getConvertedDate(startDateBeforeEdit);
  const endDate = getConvertedDate(endDateBeforeEdit);
  startDateBeforeEdit = new Date(startDate[0]);
  endDateBeforeEdit = new Date(endDate[0]);
  if (
    firstYearDurationBeforeEdit > 0 &&
    secondYearDurationBeforeEdit > 0 &&
    firstYearConsumedDays > 0 &&
    secondYearConsumedDays > 0
  ) {
    let leaveBalance = lodash.clone(userLeaveDetails);
    const durationDifferenceFirstYear = Math.abs(
      firstYearConsumedDays - firstYearDurationBeforeEdit
    );
    const durationDifferenceSecondYear = Math.abs(
      secondYearConsumedDays - secondYearDurationBeforeEdit
    );
    if (secondYearConsumedDays === 0) {
      leaveBalance.secondConfig[`${type}`] += secondYearDurationBeforeEdit;
    } else if (firstYearConsumedDays === 0) {
      leaveBalance.firstConfig[`${type}`] += firstYearDurationBeforeEdit;
    }
    if (firstYearDurationBeforeEdit < firstYearConsumedDays)
      leaveBalance.firstConfig[`${type}`] -= durationDifferenceFirstYear;
    else if (firstYearDurationBeforeEdit > firstYearConsumedDays)
      leaveBalance.firstConfig[`${type}`] += durationDifferenceFirstYear;
    if (secondYearDurationBeforeEdit < secondYearConsumedDays)
      leaveBalance.secondConfig[`${type}`] -= durationDifferenceSecondYear;
    else if (secondYearDurationBeforeEdit > secondYearConsumedDays)
      leaveBalance.secondConfig[`${type}`] += durationDifferenceSecondYear;
    setUserLeaveDetails(leaveBalance);
  } else {
    let leaveBalance = lodash.clone(userLeaveDetails);
    if (firstYearConsumedDays > 0 && secondYearConsumedDays === 0) {
      const durationDifference = Math.abs(duration - durationBeforeEdit);
      if (
        startDateBeforeEdit < expireDate[0] &&
        endDateBeforeEdit >= expireDate[0] &&
        endDateBeforeEdit < expireDate[1]
      ) {
        leaveBalance.secondConfig[`${type}`] += secondYearDurationBeforeEdit;
        leaveBalance.firstConfig[`${type}`] += firstYearDurationBeforeEdit;
        leaveBalance.firstConfig[`${type}`] -= firstYearConsumedDays;
      } else if (startDateBeforeEdit > expireDate[0]) {
        leaveBalance.secondConfig[`${type}`] += durationBeforeEdit;
        leaveBalance.firstConfig[`${type}`] -= duration;
      } else {
        if (durationBeforeEdit < duration) {
          leaveBalance.firstConfig[`${type}`] -= durationDifference;
        } else if (durationBeforeEdit > duration) {
          leaveBalance.firstConfig[`${type}`] += durationDifference;
        }
      }
      setUserLeaveDetails(leaveBalance);
    } else if (firstYearConsumedDays === 0 && secondYearConsumedDays > 0) {
      const durationDifference = Math.abs(duration - durationBeforeEdit);
      if (
        startDateBeforeEdit < expireDate[0] &&
        endDateBeforeEdit < expireDate[0]
      ) {
        leaveBalance.firstConfig[`${type}`] += durationBeforeEdit;
        leaveBalance.secondConfig[`${type}`] -= duration;
      } else if (
        startDateBeforeEdit < expireDate[0] &&
        endDateBeforeEdit >= expireDate[0] &&
        endDateBeforeEdit < expireDate[1]
      ) {
        leaveBalance.secondConfig[`${type}`] += secondYearDurationBeforeEdit;
        leaveBalance.firstConfig[`${type}`] += firstYearDurationBeforeEdit;
        leaveBalance.secondConfig[`${type}`] -= secondYearConsumedDays;
      } else {
        if (durationBeforeEdit < duration) {
          leaveBalance.secondConfig[`${type}`] -= durationDifference;
        } else if (durationBeforeEdit > duration) {
          leaveBalance.secondConfig[`${type}`] += durationDifference;
        }
      }
      setUserLeaveDetails(leaveBalance);
    } else {
      if (
        endDateBeforeEdit < expireDate[0] &&
        firstYearConsumedDays &&
        secondYearConsumedDays
      ) {
        leaveBalance.firstConfig[`${type}`] += durationBeforeEdit;
        leaveBalance.firstConfig[`${type}`] -= firstYearConsumedDays;
        leaveBalance.secondConfig[`${type}`] -= secondYearConsumedDays;
      } else if (
        startDateBeforeEdit >= expireDate[0] &&
        firstYearConsumedDays &&
        secondYearConsumedDays
      ) {
        leaveBalance.secondConfig[`${type}`] += durationBeforeEdit;
        leaveBalance.firstConfig[`${type}`] -= firstYearConsumedDays;
        leaveBalance.secondConfig[`${type}`] -= secondYearConsumedDays;
      }
      setUserLeaveDetails(leaveBalance);
    }
  }
};
