import React from "react";
import { Button } from "antd";
import CmlIcon from "../icon/CmlIcon";

const PlusButton = ({ isEdit, isNewConfig, onClick, title }) => {
  return (
    <Button
      type="primary"
      title={title}
      disabled={!isEdit && !isNewConfig}
      onClick={onClick}
      icon={<CmlIcon iconName={"add"} />}
      size="small">
        
    </Button>
  );
};

export default PlusButton;
