import { Auth } from "aws-amplify";
import secureLocalStorage from "react-secure-storage";

const timeConstant = 1000;

const refreshSession = async () => {
  try {
    const data = await Auth.currentSession();
    secureLocalStorage.setItem("authUser", JSON.stringify(data));
    return data;
  } catch (err) {
    console.log(err);
  }
};
export const isValidSession = async () => {
  const now = Math.floor(new Date() / timeConstant);
  const authUser = JSON.parse(secureLocalStorage.getItem("authUser"));
  const exp = authUser?.signInUserSession
    ? authUser?.signInUserSession?.idToken?.payload?.exp
    : authUser?.idToken?.payload?.exp;
  if (now > exp) {
    localStorage.removeItem("authUser");
    const user = await refreshSession();
    return user;
  } else {
    localStorage.removeItem("authUser");
    return JSON.parse(secureLocalStorage.getItem("authUser"));
  }
};
