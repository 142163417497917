import LeaveTypes from "./components/leaveTypes/LeaveTypes";
import NoticePeriod from "./components/noticePeriod/NoticePeriod";
import { Col, Row } from "antd";

const LeaveDefinition = ({
  isEdit,
  isNewConfig,
  onDisplayTenantConfig,
  setOnDisplayTenantConfig,
  noticePeriodList,
  setNoticePeriodList,
  leaveTypeList,
  setLeaveTypeList,
}) => {
  return (
    <Row>
      <Col sm={24} md={10}>
        <div className="cml-card mb-10">
          <LeaveTypes
            isEdit={isEdit}
            isNewConfig={isNewConfig}
            onDisplayTenantConfig={onDisplayTenantConfig}
            setOnDisplayTenantConfig={setOnDisplayTenantConfig}
            leaveTypeList={leaveTypeList}
            setLeaveTypeList={setLeaveTypeList}
          />
        </div>
      </Col>
      <Col sm={24} md={8}>
        {/* TODO: We need to implement a conditional CSS for mobile/small screens here */}
        <div className="cml-card mb-10 ml-30">
          <NoticePeriod
            isEdit={isEdit}
            isNewConfig={isNewConfig}
            onDisplayTenantConfig={onDisplayTenantConfig}
            setOnDisplayTenantConfig={setOnDisplayTenantConfig}
            noticePeriodList={noticePeriodList}
            setNoticePeriodList={setNoticePeriodList}
          />
        </div>
      </Col>
    </Row>
  );
};

export default LeaveDefinition;
