import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import lodash from "lodash";

import {
  adminHistoryAtom,
  adminHistoryEndDate,
  adminHistoryStartDate,
  apiCallAtom,
  apiCallDataFetchedAtom,
  authUserAtom,
  continueTokenAtom,
  selectedLeaveAtom,
  selectedMemberAtom,
  selectedRoleAtom,
  selectedSearchTypeAtom,
  selectedStatusAtom,
  selectedTeamForMembersRequestsAtom,
  showModalsAtom,
} from "../../../../../../../../contexts/generalStore/store";
import LeaveStatusTag from "../../../../../../../tags/LeaveStatusTag";
import { getDateInTextFormat } from "../../../../../../../../util/formatter/getDateInTextFormat";
import { handleHistorySearch } from "../../../../../../../../core/history/handleSearch/handleHistorySearch";
import DynamicTable from "../../../../../../../tables/dynamicTable/DynamicTable";
import { Empty } from "antd";
import DataDivTimeFilter from "../../../../../skeleton/common/DataDivTimeFilter";
import { NoResultFound } from "../../../../../common/noResultFound/NoResultFound";

const HistoryTable = () => {
  const [adminHistory, setAdminHistory] = useAtom(adminHistoryAtom);
  const [isLoading, setIsLoading] = useAtom(apiCallAtom);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useAtom(continueTokenAtom);
  const [allDataFetchedIndicator, setAllDataFetchedIndicator] = useAtom(
    apiCallDataFetchedAtom
  );
  const selectedTeam = useAtomValue(selectedTeamForMembersRequestsAtom);
  const [showModal, setShowModal] = useAtom(showModalsAtom);
  const selectedMember = useAtomValue(selectedMemberAtom);
  const selectedStatus = useAtomValue(selectedStatusAtom);
  const selectedRole = useAtomValue(selectedRoleAtom);
  const searchType = useAtomValue(selectedSearchTypeAtom);
  const authUser = useAtomValue(authUserAtom);
  const startDate = useAtomValue(adminHistoryStartDate);
  const endDate = useAtomValue(adminHistoryEndDate);

  const setSelectedLeave = useSetAtom(selectedLeaveAtom);

  const [searchText, setSearchText] = useState("");

  const handleCancel = async (row) => {
    if (row.status === "rejected" || row.status === "canceled") {
      return;
    }
    setShowModal({ ...showModal, showCancelModalAdmin: true });
    setSelectedLeave(row);
  };

  const statusCell = (cell, row) => {
    return <LeaveStatusTag status={row.status} />;
  };

  const actionCell = (cell, row, rowIndex, formatExtraData) => {
    return (
      <FontAwesomeIcon
        className={
          row.status === "rejected" || row.status === "canceled"
            ? "disabled-trashIcon"
            : "icon"
        }
        icon={faTrash}
        onClick={() => handleCancel(row)}
      />
    );
  };

  const durationCell = (cell, row, rowIndex, colIndex) => {
    return (
      <div>
        {row.details.duration}
        {parseInt(row.details.duration) === 1 && " Day"}
        {parseInt(row.details.duration) > 1 && " Days"}
      </div>
    );
  };

  const endDateCell = (cell, row, rowIndex, colIndex) => {
    const endDate = getDateInTextFormat(row.details.endDate);
    return <div>{endDate}</div>;
  };

  const startDateCell = (cell, row, rowIndex, colIndex) => {
    const startDate = getDateInTextFormat(row.details.startDate);
    return <div>{startDate}</div>;
  };

  const roleFormatter = (cell, row, rowIndex, colIndex) => {
    return <div>{lodash.startCase(row.fromWhom)}</div>;
  };

  const leaveTypeCell = (cell, row) => {
    return <div>{lodash.startCase(row.details.leaveType)}</div>;
  };

  const columns = [
    {
      dataIndex: "employeeEmail",
      title: "Email",
      width: "350px",
      align: "start",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return (
          String(getDateInTextFormat(record?.details?.startDate))
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(getDateInTextFormat(record?.details?.endDate))
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          record?.status?.toLowerCase().includes(value.toLowerCase()) ||
          record?.employeeEmail?.toLowerCase().includes(value.toLowerCase()) ||
          record?.details?.leaveType.toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      dataIndex: "leaveType",
      title: "Leave Type",
      width: "350px",
      align: "start",
      render: leaveTypeCell,
    },
    {
      dataIndex: "fromWhom",
      title: "From Whom",
      formatter: roleFormatter,
      width: "350px",
      align: "start",
    },
    {
      dataIndex: "start",
      title: "Start Date",
      width: "350px",
      align: "start",
      render: startDateCell,
    },
    {
      dataIndex: "end",
      title: "End Date",
      width: "350px",
      align: "start",
      render: endDateCell,
    },
    {
      dataIndex: "duration",
      title: "Duration",
      width: "30px",
      align: "center",
      render: durationCell,
    },
    {
      dataIndex: "status",
      title: "Status",
      width: "250px",
      align: "center",
      render: statusCell,
    },
    {
      title: "Action",
      width: "180px",
      align: "center",
      render: actionCell,
    },
  ];

  const requestMoreData = async () => {
    handleHistorySearch(
      selectedTeam,
      selectedMember,
      selectedStatus,
      selectedRole,
      startDate,
      endDate,
      allDataFetchedIndicator,
      setAllDataFetchedIndicator,
      isLoading,
      setIsLoading,
      lastEvaluatedKey,
      setLastEvaluatedKey,
      searchType,
      authUser,
      adminHistory,
      setAdminHistory
    );
  };

  const handleTableSearch = (value) => {
    setSearchText(value);
  };

  const tableProps = {
    loading: isLoading.inProgress,
  };

  return (
    <>
      {adminHistory.length > 0 ? (
        <DynamicTable
          tableTitle={"History Table"}
          rowKey="reqId"
          tableProps={tableProps}
          columns={columns}
          data={adminHistory}
          handleTableSearch={handleTableSearch}
          inProgress={isLoading.inProgress}
          isAllDataFetched={allDataFetchedIndicator.isAllDataFetched}
          callApiToLoadMore={requestMoreData}
          scroll={{ x: 768 }}
          size="small"
        />
      ) : adminHistory.length === 0 && !isLoading.inProgress ? (
        <>
          <NoResultFound />
        </>
      ) : (
        <div style={{ height: "200px" }}>
          <DataDivTimeFilter />
        </div>
      )}
    </>
  );
};

export default HistoryTable;
