import { breakDownSingleYear } from "../breakDownInSingleYear";

export const secondYearBreakDown = (
  hasBalance,
  userData,
  type,
  firstYearWorkingDays,
  secondYearWorkingDays,
  leaveBreakDown,
  end,
  start,
  userLeaveDetails,
  expireDate,
  remainingLeaveTypes
) => {
  if (!hasBalance) {
    const selectedLeaveTypeCount = userData?.leaveBalance?.secondConfig[type];
    const remainingAfterSelectedLeave =
      secondYearWorkingDays - selectedLeaveTypeCount;
    leaveBreakDown = {
      secondConfig: {
        [type]: selectedLeaveTypeCount,
      },
    };
    let durationToCheck = {
      firstYearWorkingDays: firstYearWorkingDays,
      secondYearWorkingDays: remainingAfterSelectedLeave,
    };
    breakDownSingleYear(
      userData,
      hasBalance,
      leaveBreakDown,
      end,
      start,
      userLeaveDetails,
      expireDate,
      remainingLeaveTypes,
      durationToCheck,
      "secondConfig",
      "secondYearWorkingDays"
    );
  } else {
    leaveBreakDown.secondConfig[type] = secondYearWorkingDays;
  }
  return leaveBreakDown;
};
