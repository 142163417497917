import { createNewTeam } from "../../../controller/admin/createNewTeam";
import { clientMessage } from "../../../util/constants/clientMessages";
import {
  spawnErrorToast,
  spawnSuccessToast,
} from "../../../util/toast/spawnToast";

export const handleCreateNewTeamRequest = async ({
  teamName,
  teamList,
  authUser,
  setIsLoading,
  setTeamList,
  handleModalClose,
}) => {
  setIsLoading(true);
  try {
    await createNewTeam(teamName, authUser);
    setTeamList([...teamList, teamName]);
    spawnSuccessToast(`${teamName} ${clientMessage?.teamBalance?.SUCCESS}`);
    handleModalClose();
  } catch (error) {
    spawnErrorToast(clientMessage?.teamBalance?.FAILED_TO_CREATE);
  } finally {
    setIsLoading(false);
  }
};
