import { Row, Tooltip } from "antd";
import React from "react";

const CmlProgress = ({
  remaining,
  max,
  percentage,
  remainingTitle,
  consumedTitle,
  tooltipPlacement,
  handleProgressClick,
}) => {
  const getStyles = (remaining, type) => {
    if (remaining >= 1 && type === "consumed") {
      return {
        width: `${100 - percentage}%`,
        borderRadius: "0px 10px 10px 0px",
      };
    } else if (remaining < max && type === "remaining") {
      return {
        width: `${percentage}%`,
        borderRadius: "10px 0px 0px 10px",
      };
    } else {
      return {
        width: type === "remaining" ? `${percentage}%` : `${100 - percentage}%`,
        borderRadius: "10px 10px",
      };
    }
  };

  return (
    <Row className="pointer" onClick={handleProgressClick}>
      <Tooltip placement={tooltipPlacement} title={remainingTitle}>
        <div
          className="remaining-percentage"
          style={getStyles(remaining, "remaining")}
        >
          {percentage > 30 && (
            <div className="progress-info-text ">{`${remaining} days`}</div>
          )}
        </div>
      </Tooltip>
      <Tooltip placement={tooltipPlacement} title={consumedTitle}>
        <div
          className="consumed-percentage"
          style={getStyles(remaining, "consumed")}
        >
          {percentage < 30 && (
            <div className="progress-info-text ">{`${
              max - remaining
            } days`}</div>
          )}
        </div>
      </Tooltip>
    </Row>
  );
};

export default CmlProgress;
