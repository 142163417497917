import React, { useContext, useEffect } from "react";
import TimezoneSelect from "react-timezone-select";
import lodash from "lodash";

import { GeneralContext } from "../../../../../../../../../../contexts/general/Context";

const Timezone = ({
  isEdit,
  isNewConfig,
  onDisplayTenantConfig,
  setOnDisplayTenantConfig,
}) => {
  const DEFAULT_TIMEZONE = {
    value: "Asia/Dhaka",
    offset: "+06:00",
  };

  const { currentTheme } = useContext(GeneralContext);

  useEffect(() => {}, [onDisplayTenantConfig?.timezone?.value]);

  const handleTimezoneSelection = (e) => {
    const offset = e?.offset;
    const value = e?.value;
    const tenantConfig = lodash.clone(onDisplayTenantConfig);
    tenantConfig.timezone.offset = offset;
    tenantConfig.timezone.value = value;
    setOnDisplayTenantConfig(tenantConfig);
  };

  return (
    <div>
      <div className={!isNewConfig ? "neutral-text" : "text"}>
        Default Timezone
      </div>
      <TimezoneSelect
        classNamePrefix="custom-timezone-select"
        value={
          onDisplayTenantConfig?.timezone?.value
            ? onDisplayTenantConfig?.timezone?.value
            : DEFAULT_TIMEZONE.value
        }
        onChange={(e) => handleTimezoneSelection(e)}
        isDisabled={isEdit && !isNewConfig}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: currentTheme?.accent,
            primary25: currentTheme?.hover,
            primary50: currentTheme?.primary,
            neutral0: currentTheme?.inputBox,
            neutral5: currentTheme?.inputBox,
            neutral10: currentTheme?.borderColor,
            neutral20: currentTheme?.borderColor,
            neutral30: currentTheme?.borderColor,
            neutral80: currentTheme.text,
          },
          spacing: {
            baseUnit: 4,
            controlHeight: 50,
            menuGutter: 8,
          },
        })}
      />
    </div>
  );
};

export default Timezone;
